import React, { useState, useEffect } from "react";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import CustomTable from "../Common/CustomTable";
import ErrorNotification from "../../Notification/ErrorNotification";
import { useTranslation } from "react-i18next";
import "./BillHistoryModal.css";

const BillHistoryModal = ({ isOpen, onClose, billId }) => {
  const [billAudits, setBillAudits] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen && billId) {
      fetchBillHistory();
    }
  }, [isOpen, billId]);

  const fetchBillHistory = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/reports/fetch_report_audit_bill?bill_id=${billId}&type=Bill`,
        null
      );
      if (response.status === 200) {
        setBillAudits(response.data.audits);
      }
    } catch (error) {
      if (error?.data?.errors) {
        error.data.errors.forEach((err) => ErrorNotification(err));
      } else {
        ErrorNotification("Failed to fetch bill history");
      }
    }
  };

  const prepareBillAuditData = (audits) => {
    return audits.map((audit) => ({
      Username: audit.username || "",
      Action: audit.action?.charAt(0)?.toUpperCase() + audit.action?.slice(1) || "",
      Changes: formatAuditChanges(audit.audited_changes),
      Status: audit.status_name || "",
      "Created At": new Date(audit.created_at).toLocaleString(),
    }));
  };

  const formatAuditChanges = (changes) => {
    if (!changes || Object.keys(changes).length === 0) return "-";
    
    return Object.entries(changes)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${key}: ${value[0]} → ${value[1]}`;
        }
        return `${key}: ${value}`;
      })
      .join(", ");
  };

  if (!isOpen) return null;

  return (
    <div className="bill-history-overlay">
      <div className="bill-history-modal">
        <div className="bill-history-header">
          <span className="bill-history-header-left">{t("Bill History")}</span>
          <button className="bill-history-header-right" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="bill-history-body">
          {billAudits.length > 0 ? (
            <CustomTable
              col_names={["Username", "Action", "Changes", "Status", "Created At"]}
              data={prepareBillAuditData(billAudits)}
            />
          ) : (
            <div className="no-history-message">{t("No history available")}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillHistoryModal;
