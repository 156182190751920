import React, { useEffect, useRef, useState } from "react";
import "./DeliveryChallan.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import NewDeliveryChallan from "./NewDeliveryChallan";
import Copyrights from "../../Copyrights";
import { BASE_URL, NO_RECORD_ERROR } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import PaginationControls from "../../Common/PaginationControls";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import DownloadComponent from "./DownloadComponent";
import ReactDOM from 'react-dom';
import DeliveryChallanGeneralTemplate from "../SaleTemplateFormate/DeliveryChallanTemplates/DeliveryChallanGeneralTemplate";
import DeliveryChallanThermalTemplate from "../SaleTemplateFormate/DeliveryChallanTemplates/DeliveryChallanThermalTemplate";
import DeliveryChallanLetterPadTemplate from "../SaleTemplateFormate/DeliveryChallanTemplates/DeliveryChallanLetterPadTemplate";
import ErrorNotification from "../../../Notification/ErrorNotification";
import DeliveryChallanLetterPad from "../CompanySaleInvoices/DeliveryChallanLetterPad";
// import { current_user } from "../../../Authentication/AuthenticationContext";

function DeliveryChallan() {
  const [visibleDeliveryChallanColumns, setVisibleDeliveryChallanColumns] = useState({
    buyerOrderNo: true,
    buyerOrderDate: true,
    refNo: true,
    // dueDays: true,
    status: true,
  });

  const [deliveryChallan, setDeliveryChallan] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showDeliveryColumnFilter, setShowDeliveryColumnFilter] = useState(false);
  const [showDeliveryDownIconAction, setShowDeliveryDownIconAction] = useState(false);
  const [tempVisibleColumns, setTempVisibleColumns] = useState({ ...visibleDeliveryChallanColumns });
  const [isNewDeliveryChallan, setNewDeliveryChallan] = useState(false);
  const [selectedDeliveryChallan, setSelectedDeliveryChallan] = useState("");
  const [saleDeliveryChallanCopy, setSaleDeliveryChallanCopy] = useState("");
  const [saleDeliveryChallanPrint, setSaleDeliveryChallanPrint] = useState("");
  const [isOpenDeliveryChallanPrint, setOpenDeliveryChallanPrint] = useState(false);
  const [saleDeliveryChallanUpdated, setSaleDeliveryChallanUpdated] = useState(false);
  const [actionDropdownPosition, setActionDropdownPosition] = useState({ top: 0, left: 0 });
  const [selectedDeliveryChallanTemplateId, setSelectedDeliveryChallanTemplateId] = useState('');
  const [enabledTemplateDetails, setEnabledTemplateDetails] = useState([]);

  const handleDeliveryColumnFilterClick = () => {
    setTempVisibleColumns({ ...visibleDeliveryChallanColumns })
    setShowDeliveryColumnFilter(true);
  }

  const handleCheckboxChange = (column) => {
    setTempVisibleColumns((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleDeliveryApplyFilter = () => {
    setVisibleDeliveryChallanColumns(tempVisibleColumns)
    setShowDeliveryColumnFilter(false);
  };

  const handleDeliveryDownIconAction = (deliveryChallan, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionDropdownPosition({
      top: rect.bottom + window.scrollY,
    });
    setSelectedDeliveryChallan(deliveryChallan)
    setShowDeliveryDownIconAction(true);
  }

  const OpenDeliveryChallanPopup = () => {
    setNewDeliveryChallan(true);
  }

  const closeDeliveryChallanPopup = () => {
    setNewDeliveryChallan(false);
    setSaleDeliveryChallanCopy("");
    setSaleDeliveryChallanUpdated(false);
  }

  const columnDeliveryFilterRef = useRef(null);
  const columnActionFilterRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnDeliveryFilterRef.current &&
        !columnDeliveryFilterRef.current.contains(event.target)
      ) {
        setShowDeliveryColumnFilter(false);
      }
    };

    if (showDeliveryColumnFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDeliveryColumnFilter]);

  useEffect(() => {
    const handleDownClickOutside = (event) => {
      if (
        columnActionFilterRef.current &&
        !columnActionFilterRef.current.contains(event.target)
      ) {
        setShowDeliveryDownIconAction(false);
      }
    };
    if (showDeliveryDownIconAction) {
      document.addEventListener("mousedown", handleDownClickOutside);
    } else {
      document.removeEventListener("mousedown", handleDownClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleDownClickOutside);
    };
  }, [showDeliveryDownIconAction]);

  const fetchDeliveryChallan = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/entries?type=delivery_challan&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setDeliveryChallan(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching quotations:", error);
    }
  };

  const handlDeliveryChallanDelete = async (deliveryId) => {
    if (deliveryId) {
      try {
        const response = await sendRequest("DELETE", `${BASE_URL}/v1/transactions/entries/${deliveryId}?type=delivery_challan`);
        if (response.status === 200) {
          console.log("Delivery Challan deleted successfully!");
          fetchDeliveryChallan(currentPage, recordsPerPage);
          setShowDeliveryDownIconAction(false);
        } else {
          console.log("Failed to delete the delivery challan. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the delivery challan:", error);
      }
    }
  };

  const handleSaleDeliveryCopy = async (saleDeliveryCopyId, updated, printed) => {
    if (updated) {
      setSaleDeliveryChallanUpdated(updated);
    }
    if (saleDeliveryCopyId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${saleDeliveryCopyId}?type=delivery_challan`);
        setSaleDeliveryChallanCopy(response.data.entry);
        if (printed) {
          handleDeliveryChallanPrint(selectedDeliveryChallanTemplateId, response.data.entry, templateDetails);
        } else {
          setNewDeliveryChallan(true);
        }
      } catch (error) {
        console.error("Error copy the sale delivery:", error);
        console.log("An error occurred while deleting the copy sale delivery.");
      }
    }
  };

  const handleSaleDeliveryPrint = async (saleDeliveryPrintId) => {
    if (saleDeliveryPrintId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${saleDeliveryPrintId}?type=delivery_challan`);
        setSaleDeliveryChallanPrint(response.data.entry);
        setOpenDeliveryChallanPrint(true);
      } catch (error) {
        console.error("Error copy the sale delivery print:", error);
        console.log("An error occurred while deleting the sale delivery print.");
      }
    }
  };

  useEffect(() => {
    fetchDeliveryChallan(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handleNewDeliveryChallanCreated = (value) => {
    setDeliveryChallan(prevDeliveryChallans => [...prevDeliveryChallans, ...value]);
    setTotalRecords(prevTotalRecords => prevTotalRecords + 1);
  }

  const handleDeliveryChallanClose = () => {
    setOpenDeliveryChallanPrint(false);
  }

  const fetchDefaultDeliveryChallanTemplate = async (type) => {
    try {
      const response = await sendRequest("GET", `${BASE_URL}/v1/template/templates/get_sales_templates?type=${type}`
      );
      setSelectedDeliveryChallanTemplateId(response?.data?.data?.id);
    } catch (error) {
      console.error("Error fetching default template:", error);
    }
  };

  useEffect(() => {
    fetchDefaultDeliveryChallanTemplate("deliverychallan");
  }, []);

  const handleDeliveryChallanPrint = (template_id, deliveryChallanPrint, templateDetails) => {
    setShowDeliveryDownIconAction(false);
    const getTemplateComponent = () => {
      switch (template_id) {
        case 18:
          return <DeliveryChallanGeneralTemplate deliveryChallanPrint={deliveryChallanPrint} enabledTemplateDetails={templateDetails} />;
        case 19:
          return <DeliveryChallanLetterPadTemplate deliveryChallanPrint={deliveryChallanPrint} enabledTemplateDetails={templateDetails} />;
        case 20:
          return <DeliveryChallanThermalTemplate deliveryChallanPrint={deliveryChallanPrint} enabledTemplateDetails={templateDetails} />;
        default:
          return null;
      }
    };
  
    const printContainer = document.createElement('div');
    printContainer.id = 'print-container';
    printContainer.style.position = 'absolute';
    printContainer.style.top = '0';
    printContainer.style.left = '0';
    printContainer.style.width = '100%';
    printContainer.style.zIndex = '-1';
    printContainer.style.visibility = 'hidden';
  
    document.body.appendChild(printContainer);
    ReactDOM.render(getTemplateComponent(), printContainer);
    setTimeout(() => {
      printContainer.style.visibility = 'visible'; 
      window.print();
      document.body.removeChild(printContainer);
    }, 300);
  };

  const getTemplateConfig = async (template) => {
    try {
      const url = `${BASE_URL}/v1/template/templates/${template}`;
      const response = await sendRequest('get', url);
      setEnabledTemplateDetails(response?.data?.data?.template_options);
    } catch (error) {
      ErrorNotification(NO_RECORD_ERROR('template'));
    }
  };

  useEffect(() => {
    if (selectedDeliveryChallanTemplateId) {
      getTemplateConfig(selectedDeliveryChallanTemplateId);
    }
  }, [selectedDeliveryChallanTemplateId]);

  const templateDetails = enabledTemplateDetails
  .filter(option => option?.template_config?.is_enable)
  .map(option => ({
    option_name: option?.option_name,
    free_text: option?.template_config?.free_text,
    is_enable: option?.template_config?.is_enable,
  }));
  
  return (
    <>
      <div className="delivery-challan-main-container">
        <div className="delivery-challan-container-first-div">
          <div className="delivery-challan-first-left">
            <span className="all-delivery-challan-show">All Delivery Challan ({totalRecords})</span>
          </div>

          <div className="delivery-challan-first-right">
            <button className="delivery-challan-first-right-botton"
              onClick={OpenDeliveryChallanPopup}>
              New Delivery Challan
              {/* <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span> */}
            </button>
          </div>
        </div>

        <div className="delivery-challan-container-second-div">
          <div className="delivery-challan-second-div-right">
            <div className="delivery-challan-pagination-header">
              <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
            </div>
            <div className="delivery-challan-second-drop-first">
              {/* <div className="delivery-challan-second-sub">
                <div className="delivery-challan-turn-icon-grp">
                  <span className="delivery-challan-turn-right-icon">
                    <FontAwesomeIcon icon={faArrowsTurnRight} />
                  </span>
                </div> */}
              {/* <span className="quotatin-icon-between"></span> */}
              {/* <div className="delivery-challan-down-icon-grp" >
                  <span className="delivery-challan-caret-down-icon">
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </div>
              </div> */}
              {/* <div className="delivery-challan-second-filter">
                <span className="delivery-challan-filter-icon">
                  <FontAwesomeIcon icon={faFilter} />
                </span>
              </div> */}
              <DownloadComponent />
              {/* <div
                className="delivery-challan-second-three-dot-grop"
                onClick={handleDeliveryColumnFilterClick}>
                <span className="delivery-challan-ellips-icon">
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </span>
              </div> */}
            </div>
          </div>
        </div>

        <div className="delivery-challan-table-group">
          <table className="delivery-challan-table">
            <thead>
              <tr className="delivery-challan-thead-row">
                <th className="delivery-challan-tab-th">Date</th>
                <th className="delivery-challan-tab-th">Challan No</th>
                <th className="delivery-challan-tab-th">Contact</th>
                {visibleDeliveryChallanColumns.buyerOrderNo && (
                  <th className="delivery-challan-tab-th">Buyer's Order No#</th>
                )}
                {visibleDeliveryChallanColumns.buyerOrderDate && (
                  <th className="delivery-challan-tab-th">Buyer's Order Date</th>
                )}
                {visibleDeliveryChallanColumns.refNo && (
                  <th className="delivery-challan-tab-th">Ref.No</th>
                )}
                {/* {visibleDeliveryChallanColumns.dueDays && (
                  <th className="delivery-challan-tab-th">Due Days</th>
                )} */}
                {visibleDeliveryChallanColumns.status && (
                  <th className="delivery-challan-tab-th">Status</th>
                )}
                <th className="delivery-challan-tab-th">Amount</th>
                <th className="delivery-challan-tab-th">Action</th>
              </tr>
            </thead>
            <tbody className="quotation-table-body">
              {deliveryChallan.map((row, index) => (
                <tr key={index} className="eway-bill-tbody-row">
                  <td className="delivery-challan-tab-td">{row.date}</td>
                  <td className="delivery-challan-tab-td">{row.bill_number}</td>
                  <td className="delivery-challan-tab-td">{row.customer.customer_name}</td>
                  {visibleDeliveryChallanColumns.buyerOrderNo && (
                    <td className="delivery-challan-tab-td">{row.order_no}</td>
                  )}
                  {visibleDeliveryChallanColumns.buyerOrderDate && (
                    <td className="delivery-challan-tab-td">{row.order_date}</td>
                  )}
                  {visibleDeliveryChallanColumns.refNo && (
                    <td className="delivery-challan-tab-td">{row.reference_no}</td>
                  )}
                  {/* {visibleDeliveryChallanColumns.dueDays && (
                    <td className="delivery-challan-tab-td">{row.due_days}</td>
                  )} */}
                  {visibleDeliveryChallanColumns.status && (
                    <td className="delivery-challan-tab-td">{row.status}</td>
                  )}
                  <td className="delivery-challan-tab-td">{row.total_amount}</td>
                  <td className="delivery-challan-tab-td">
                    <FontAwesomeIcon icon={faCaretDown} className="delivery-challan-table-body-td"
                      onClick={(e) => handleDeliveryDownIconAction(row, e)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}

        {deliveryChallan.length === 0 && (
          <div className="delivery-challan-no-data-message">
            No delivery challan found.
          </div>
        )}

        {showDeliveryColumnFilter && (
          <div className="delivery-challan-column-filter-popup" ref={columnDeliveryFilterRef}>
            <form className="delivery-challan-column-filter-form">
              {Object.keys(tempVisibleColumns).map((column) => (
                <div key={column} className="delivery-challan-label-input-grp">
                  <label>
                    <input
                      type="checkbox"
                      className="delivery-challan-visible-column-input"
                      checked={tempVisibleColumns[column]}
                      onChange={() => handleCheckboxChange(column)}
                    />
                    {column.charAt(0).toUpperCase() + column.slice(1)}
                  </label>
                </div>
              ))}
              <button type="button" onClick={handleDeliveryApplyFilter} className="delivery-challan-visible-box-btn">
                Apply
              </button>
            </form>
          </div>
        )}

        {showDeliveryDownIconAction && (
          <div className="delivery-challan-down-icon-action"
            ref={columnActionFilterRef}
            style={{
              position: 'absolute',
              top: `${actionDropdownPosition.top}px`,
            }}
          >
            <ul className="delivery-challan-down-icon-action-ul">
              <li className="delivery-challan-down-icon-action-li" onClick={() => handleSaleDeliveryCopy(selectedDeliveryChallan.id, true)}>Edit</li>
              <li className="delivery-challan-down-icon-action-li" onClick={() => handlDeliveryChallanDelete(selectedDeliveryChallan.id)}>
                Delete
              </li>
              {/* <li className="delivery-challan-down-icon-action-li">Storage</li> */}
              <li className="delivery-challan-down-icon-action-li" onClick={() => handleSaleDeliveryPrint(selectedDeliveryChallan.id)}>Print Preview</li>
              {/* <li className="delivery-challan-down-icon-action-li">Send</li>
              <li className="delivery-challan-down-icon-action-li">Send SMS</li>
              <li className="delivery-challan-down-icon-action-li">Send WhatsApp</li> */}
              <li className="delivery-challan-down-icon-action-li" onClick={() => handleSaleDeliveryCopy(selectedDeliveryChallan.id)}>Copy</li>
              <li className="delivery-challan-down-icon-action-li" onClick={()=> handleSaleDeliveryCopy(selectedDeliveryChallan.id, false, true)}>Print</li>
            </ul>
          </div>
        )}

        {isNewDeliveryChallan && (
          <div className="new-delivery-popup-container">
            <NewDeliveryChallan onClose={closeDeliveryChallanPopup} saleDeliveryChallanCopy={saleDeliveryChallanCopy} isUpdated={saleDeliveryChallanUpdated} deliveryChallanCreated={handleNewDeliveryChallanCreated} />
          </div>
        )}

        {isOpenDeliveryChallanPrint &&
          <div className="new-delivery-popup-container">
            <div className="new-delivery-popup-subcontainer">
              <DeliveryChallanLetterPad saleDeliveryChallanPrint={saleDeliveryChallanPrint} onClose={handleDeliveryChallanClose} />
            </div>
          </div>
        }

        <Copyrights />
      </div>
    </>
  );
}

export default DeliveryChallan;
