import React, { useState, useEffect } from "react";
import "./TransferItemPopup.css";
import { BsTrash } from "react-icons/bs";
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";
import { getToken } from "../SecureStorage/Token";
import { current_user } from "../../Authentication/AuthenticationContext";
import successNotification from "../../Notification/SuccessNotification";
import ErrorNotification from "../../Notification/ErrorNotification";

const TransferItemPopup = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState("transfer");
  const [transferredStocks, setTransferredStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = getToken();

  const [itemRows, setItemRows] = useState([
    {
      id: 1,
      itemName: "",
      itemId: null,
      availableQuantity: "",
      transferQuantity: "",
      searchResults: [],
      isSearching: false,
      salesPrice: 0,
      purchasePrice: 0,
    },
  ]);

  const branchId = localStorage.getItem('branchId');
  const [branches, setBranches] = useState([]);
  const [currentBranch, setCurrentBranch] = useState(branchId);
  const [transferBranch, setTransferBranch] = useState("");
  const [user, setUser] = useState(null);
  const [debounceTimer, setDebounceTimer] = useState(null);

  const handleItemSearchDropdown = async (query, rowIndex) => {
    try {
      const response = await sendRequest(
        "get",
        `${BASE_URL}/v1/inventory_managment/items?query=${encodeURIComponent(
          query
        )}&branch_id=${currentBranch}`
      );

      const searchResults = response?.data?.data
        ? Array.isArray(response.data.data)
          ? response.data.data
          : []
        : [];

      setItemRows((prevRows) => {
        const newItemRows = [...prevRows];
        if (newItemRows[rowIndex]) {
          newItemRows[rowIndex].searchResults = searchResults;
        }
        return newItemRows;
      });
    } catch (error) {
      console.error("Error searching item:", error);

      setItemRows((prevRows) => {
        const newItemRows = [...prevRows];
        if (newItemRows[rowIndex]) {
          newItemRows[rowIndex].searchResults = [];
        }
        return newItemRows;
      });
    }
  };

  const handleItemChange = (rowIndex, value) => {
    if (rowIndex < 0 || rowIndex >= itemRows.length) {
      console.error("Invalid row index");
      return;
    }

    const newItemRows = [...itemRows];

    if (newItemRows[rowIndex]) {
      newItemRows[rowIndex].itemName = value;
      newItemRows[rowIndex].isSearching = true;

      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      const handleDebounce = setTimeout(() => {
        if (value.trim()) {
          handleItemSearchDropdown(value, rowIndex);
        }
      }, 500);

      setDebounceTimer(handleDebounce);
      setItemRows(newItemRows);
    }
  };

  const handleItemSelect = (rowIndex, selectedItem) => {
    const newItemRows = [...itemRows];
    newItemRows[rowIndex].itemName = selectedItem.item_name;
    newItemRows[rowIndex].itemId = selectedItem.id;
    newItemRows[rowIndex].availableQuantity = selectedItem.quantity;
    newItemRows[rowIndex].salesPrice = selectedItem.sales_price;
    newItemRows[rowIndex].purchasePrice = selectedItem.purchase_price;
    newItemRows[rowIndex].isSearching = false;
    newItemRows[rowIndex].searchResults = [];

    setItemRows(newItemRows);
  };

  const handleTransferItems = async () => {
    if (currentBranch === transferBranch) {
      ErrorNotification(
        "Invalid Branch Selection",
        "Current and transfer branches cannot be the same"
      );
      return;
    }

    if (!currentBranch || !transferBranch) {
      ErrorNotification(
        "Branch Selection Required",
        "Please select both current and transfer branches"
      );
      return;
    }

    const transferItems = itemRows.filter(
      (row) => row.itemId && row.transferQuantity
    );

    if (transferItems.length === 0) {
      ErrorNotification(
        "Transfer Quantity Missing",
        "Please add transfer quantity for items"
      );
      return;
    }

    const invalidQuantityItems = itemRows.filter(
      (row) =>
        row.itemId &&
        row.transferQuantity &&
        parseInt(row.transferQuantity) > parseInt(row.availableQuantity)
    );

    if (invalidQuantityItems.length > 0) {
      ErrorNotification(
        "Quantity Exceeded",
        "Transfer quantity cannot be greater than available quantity"
      );
      return;
    }
    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/inventory_managment/items/transfer_items`,
        {
          stock: {
            from_branch_id: parseInt(currentBranch),
            to_branch_id: parseInt(transferBranch),
            status: "dispatched",
            stock_transfer_items_attributes: transferItems.map((row) => ({
              item_id: row.itemId,
              quantity: parseInt(row.transferQuantity),
              status: "dispatched",
              sales_price: row.salesPrice,
              purchase_price: row.purchasePrice, 
            })),
          },
        }
      );

      if (response.status === 200) {
        successNotification(
          "Transferred Successfully",
          "Items transferred successfully"
        );

        setItemRows([
          {
            id: 1,
            itemName: "",
            itemId: null,
            availableQuantity: "",
            transferQuantity: "",
            searchResults: [],
            isSearching: false,
            salesPrice: 0,
            purchasePrice: 0
          },
        ]);
        setCurrentBranch("");
        setTransferBranch("");

        setActiveTab("dispatched");
      }
    } catch (error) {
      console.error("Transfer error:", error);

      const errorMessage =
        error.response?.data?.errors || "Failed to transfer items";
      ErrorNotification("Transfer Error", errorMessage);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setUser(currentUser);
      setLoading(false);
      if (currentUser.user_detail.role.name !== "owner") {
        setCurrentBranch(currentUser?.user_detail?.branch_id);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      getBranchList();
    }
  }, [user]);

  const getBranchList = async () => {
    const response = await sendRequest(
      "GET",
      `${BASE_URL}/v1/onboarding/companies/${user.company.id}/branches`
    );
    if (response.status === 200) {
      setBranches(response.data.data);
    }
  };

  const handleAddRow = () => {
    setItemRows([
      ...itemRows,
      {
        id: itemRows.length + 1,
        itemName: "",
        availableQuantity: "",
        transferQuantity: "",
        salesPrice: 0,
        purchasePrice: 0,
      },
    ]);
  };
  const handleDeleteRow = (id) => {
    setItemRows(itemRows.filter((row) => row.id !== id));
  };

  const fetchDispatchedStocks = async (activeTab) => {
    setLoading(true);
    setError(null);

    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/items/get_transfer_stocks?status=${activeTab}`,
        null,
        token
      );
      if (response && response.data) {
        setTransferredStocks(response.data.stocks);
      } else {
        setTransferredStocks([]);
      }
    } catch (err) {
      setError("Failed to fetch dispatched stocks");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "dispatched" || activeTab == "received") {
      fetchDispatchedStocks(activeTab);
    }
  }, [activeTab]);

  const [data, setData] = useState([]);

  const handleStatusChange = async (id, newStatus) => {
    const updatedData = data.map((item) =>
      item.id === id ? { ...item, status: newStatus } : item
    );
    setData(updatedData);

    try {
      const url = `${BASE_URL}/v1/inventory_managment/items/update_transfer_stock_status/${id}`;
      const params = {
        status: newStatus,
      };

      const response = await sendRequest("patch", url, params);
      if (response.status !== 200) {
        console.error("Failed to update status:", response.data);
        setData(
          data.map((item) =>
            item.id === id ? { ...item, status: item.status } : item
          )
        );
      }
      fetchDispatchedStocks("dispatched");
    } catch (error) {
      console.error("Error updating status:", error);
      setData(
        data.map((item) =>
          item.id === id ? { ...item, status: item.status } : item
        )
      );
    }
  };

  const renderTransferTab = () => (
    <div className="transfer-tab-content">
          <div className="branch-selection">
          {!["manager", "staff", "cashier"].includes(user?.user_detail?.role?.name) && (
              <>
                <span className="branch-separator">From</span>
                <select
                  className="branch-dropdown"
                  value={currentBranch}
                  onChange={(e) => setCurrentBranch(e.target.value)}
                  disabled={Boolean(branchId)}
                  title={
                    branchId
                      ? "Branch is pre-selected based on your current branch"
                      : "Select current branch"
                  }
                >
                  <option value="">Select Current Branch</option>
                  {branches?.map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.branch_name}
                    </option>
                  ))}
                </select>
              </>
            )}
            <span className="branch-separator">to</span>
            <select
              className="branch-dropdown"
              value={transferBranch}
              onChange={(e) => setTransferBranch(e.target.value)}
            >
              <option value="">Select Transfer Branch</option>
              {branches
                ?.filter(branch => branch.id !== currentBranch)
                .map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.branch_name}
                  </option>
                ))}
            </select>
          </div>
      <div className="item-rows">
        {itemRows && itemRows.length > 0 ? (
          itemRows.map((row, index) => (
            <div key={row.id} className="item-row">
              <div className="item-search-wrapper">
                <input
                  type="text"
                  placeholder="Item Name"
                  className="item-input"
                  value={row.itemName || ""}
                  onChange={(e) => handleItemChange(index, e.target.value)}
                />
                {row.isSearching &&
                  row.searchResults &&
                  row.searchResults.length > 0 && (
                    <ul className="transfer-item-suggestions">
                      {row.searchResults.map((result) => (
                        <li
                          key={result.id}
                          onClick={() => handleItemSelect(index, result)}
                        >
                          {result.item_name}
                        </li>
                      ))}
                    </ul>
                  )}
              </div>
              <input
                type="number"
                placeholder="Available Quantity"
                className="item-input"
                value={row.availableQuantity || ""}
                readOnly
              />
              <input
                type="number"
                placeholder="Transfer Quantity"
                className="item-input"
                value={row.transferQuantity || ""}
                max={row.availableQuantity}
                onChange={(e) => {
                  const newItemRows = [...itemRows];
                  newItemRows[index].transferQuantity = e.target.value;
                  setItemRows(newItemRows);
                }}
              />
              <BsTrash
                className="transfer-delete-icon"
                onClick={() => handleDeleteRow(row.id)}
              />
            </div>
          ))
        ) : (
          <p>No items added</p>
        )}
      </div>

      <button className="add-item-btn" onClick={handleAddRow}>
        + Add Item
      </button>

      <div className="popup-actions">
        <button className="transfer-item-btn" onClick={handleTransferItems}>
          Transfer
        </button>
        <button className="cancel-btn" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );

  const renderReceivedTab = () => (
    <div className="received-tab-content">
      <table className="received-items-table">
        <thead>
          <tr>
            <th>Transfer ID</th>
            <th>From Branch</th>
            <th>To Branch</th>
            <th>Received At</th>
            <th>Status</th>
            <th>View Details</th>
          </tr>
        </thead>
        {transferredStocks.length > 0 ? (

        <tbody>
          {transferredStocks?.map((row) => (
            <tr key={row.id}>
              <td>{row.reference_no}</td>
              <td>{row.from_branch}</td>
              <td>{row.to_branch}</td>
              <td>{row.created_at}</td>
              <td className="text-start w-25">
                {row.status === "accepted" ||
                  row.status === "rejected" ? (
                  <span
                    style={{
                      color: row.status === "accepted" ? "green" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {row.status.charAt(0).toUpperCase() +
                      row.status.slice(1)}
                  </span>
                ) : (
                  <select
                    value={row.status}
                    className="form-select ms-0"
                    onChange={(e) =>
                      handleStatusChange(
                        row.stock_transfer_id,
                        e.target.value
                      )
                    }
                  >
                    <option value="dispatched">Pending</option>
                    <option value="accepted">Accepted</option>
                    <option value="rejected">Rejected</option>
                  </select>
                )}
              </td>

              <td>
                <button
                  className="view-details-btn"
                  onClick={() =>
                    window.open(
                      `/transfer-details?ref_no=${row.reference_no}`,
                      ""
                    )
                  }
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        ) : (
          <p>No Received stocks found</p>
        )}
      </table>
    </div>
  );
  const renderDispatchedTab = () => (
    <div className="received-tab-content">
      {/* {loading ? (
          <p>Loading dispatched stocks...</p>
        ) : error ? (
          <p className="error">{error}</p>
        ) : transferredStocks.length > 0 ? ( */}
      <table className="received-items-table">
        <thead>
          <tr>
            <th>Transfer ID</th>
            <th>From Branch</th>
            <th>To Branch</th>
            <th>Dispatched At</th>
            <th>Status</th>
            <th>View Details</th>
          </tr>
        </thead>
        {transferredStocks.length > 0 ? (

          <tbody>
            {transferredStocks.map((stock, index) => (
              <tr key={index}>
                <td>{stock.reference_no}</td>
                <td>{stock.from_branch}</td>
                <td>{stock.to_branch}</td>
                <td>{new Date(stock.created_at).toLocaleString()}</td>
                <td className="text-start w-25">
                  {stock.status === "accepted" ||
                    stock.status === "rejected" ? (
                    <span
                      style={{
                        color: stock.status === "accepted" ? "green" : "red",
                        fontWeight: "bold",
                      }}
                    >
                      {stock.status.charAt(0).toUpperCase() +
                        stock.status.slice(1)}
                    </span>
                  ) : (
                    <select
                      value={stock.status}
                      className="form-select ms-0"
                      onChange={(e) =>
                        handleStatusChange(
                          stock.stock_transfer_id,
                          e.target.value
                        )
                      }
                    >
                      <option value="dispatched">Pending</option>
                      <option value="accepted">Accepted</option>
                      <option value="rejected">Rejected</option>
                    </select>
                  )}
                </td>

                <td>
                  <button
                    className="view-details-btn"
                    onClick={() =>
                      window.open(
                        `/transfer-details?ref_no=${stock.reference_no}`,
                        ""
                      )
                    }
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <p>No Dispatched stocks found</p>
        )}
      </table>


    </div>
  );

  return (
    <div className="transfer-popup">
      <div className="transfer-popup-header">
        <div className="tabs">
          <button
            className={`tab ${activeTab === "transfer" ? "active" : ""}`}
            onClick={() => setActiveTab("transfer")}
          >
            Create Transfer
          </button>
          <button
            className={`tab ${activeTab === "received" ? "active" : ""}`}
            onClick={() => setActiveTab("received")}
          >
            Received Stocks
          </button>
          <button
            className={`tab ${activeTab === "dispatched" ? "active" : ""}`}
            onClick={() => setActiveTab("dispatched")}
          >
            Dispatched Stocks
          </button>
        </div>
        <button className="close-btn" onClick={onClose}>
          ×
        </button>
      </div>

      <div className="transfer-popup-content">
        {activeTab === "transfer" && renderTransferTab()}
        {activeTab === "received" && renderReceivedTab()}
        {activeTab === "dispatched" && renderDispatchedTab()}
      </div>
    </div>
  );
};

export default TransferItemPopup;
