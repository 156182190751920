import React, { useState, useEffect, useRef } from "react";
import "./TransferItemview.css";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faDownload } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";
import { getToken } from "../SecureStorage/Token";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function TransferItemview({ onClose }) {
  const [transferStockDetails, setTransferStockDetails] = useState(null);
  const token = getToken();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refNo = queryParams.get("ref_no");

  const fetchTransferStockDetails = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/items/get_transfer_stock_details?reference_no=${refNo}`,
        null,
        token
      );
      setTransferStockDetails(response.data);
    } catch (error) {
      console.error("Failed to fetch transfer stock details:", error);
    }
  };

  useEffect(() => {
    fetchTransferStockDetails();
  }, []);

  const renderTable = (items) => (
    <table className="transfer-letter-pad-invoice-table">
      <thead>
        <tr className="transfer-letter-pad-tab-head-row">
          <th className="transfer-letter-pad-table-header-attribute">S. No.</th>
          <th className="transfer-letter-pad-table-header-attribute">
            Item Name
          </th>
          <th className="transfer-letter-pad-table-header-attribute">Qty</th>
          <th className="transfer-letter-pad-table-header-attribute">Status</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index} className="transfer-letter-pad-table-body-row">
            <td className="transfer-letter-pad-table-body-data">{index + 1}</td>
            <td className="transfer-letter-pad-table-body-data">
              {item.item.item_code}
            </td>
            <td className="transfer-letter-pad-table-body-data">
              {item.quantity}
            </td>
            <td className="transfer-letter-pad-table-body-data">{item.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDownload = async () => {
    const element = componentRef.current;
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`TransferDetails_${refNo}.pdf`);
  };

  return (
    <div className="transfer-letter-pad-invoice-container" ref={componentRef}>
      <div className="transfer-challan-pad-button-group">
        <button className="transfer-button-icon">
          <FontAwesomeIcon icon={faPrint} onClick={handlePrint} />
        </button>
        <button className="transfer-button-icon" onClick={handleDownload}>
          <FontAwesomeIcon icon={faDownload} />
        </button>
      </div>
      <div className="transfer-letter-pad-com-info-container">
        <div className="transfer-letter-img-grp">
          <img
            src={transferStockDetails?.company?.logo_url}
            alt="Transfer Logo"
            className="transfer-letter-company-logo"
          />
        </div>
        <div className="transfer-letter-com-info-grp">
          <span className="transfer-letter-com-name-label">
            {transferStockDetails?.company?.name}
          </span>
          <span className="transfer-letter-com-data-label">
            GSTIN/UIN : {transferStockDetails?.company?.gst_number}
          </span>
        </div>
      </div>
      <div className="transfer-letter-pad-title-grp">
        <span className="transfer-letter-pad-title">Transfer Item</span>
      </div>
      <div className="transfer-horizontal-line"></div>
      <div className="transfer-letter-pad-invoice-billing-address">
        <div className="transfer-letter-pad-invoice-bill-div">
          <span className="transfer-letter-title">Transferred from</span>
          <span className="transfer-letter-pad-bill-issued-data">
            {transferStockDetails?.from_branch?.branch_name}
          </span>
        </div>
        <div className="transfer-letter-pad-invoice-bill-div">
          <span className="transfer-letter-issue-title">Transferred to</span>
          <span className="transfer-letter-pad-bill-issued-data">
            {transferStockDetails?.to_branch?.branch_name}
          </span>
        </div>
      </div>
      <div className="transfer-classic-line">
        <span> Ref No: </span>
        <span className="transfer-letter-pad-bill-issued-data">
          {transferStockDetails?.reference_no}
        </span>
      </div>
      <div className="transfer-horizontal-line"></div>
      {transferStockDetails
        ? renderTable(transferStockDetails.transfer_items)
        : renderTable([])}
      <div className="transfer-letter-pad-signature-section">
        <span className="transfer-letter-pad-footer-title">
          Authorized Signature
        </span>
      </div>
    </div>
  );
}

export default TransferItemview;

