import React, { useEffect, useState } from 'react';
import './Card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faIndianRupeeSign, faTachometerAlt, faTicket } from '@fortawesome/free-solid-svg-icons';
import TableReservation from '../PaxCaptain/TableReservation';
import { useNavigate } from 'react-router-dom';
import PaymentModal from '../PaymentModel';
import KotModal from "../KotDetails/KOTDetails";
import ErrorNotification from '../../../Notification/ErrorNotification';

const Card = ({ item, color, updateTableStatus, tableStatus, alreadyReserved }) => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isKotModalOpen, setIsKotModalOpen] = useState(false);
  const [tableNumber, setTableNumber] = useState("");
  const [tableData, setTableData] = useState({});
  const navigate = useNavigate();

  const handleCreateKot = (tableData) => {
    if ((tableData.itemStatus === 'available') && alreadyReserved) {
      setTableNumber(tableData.id);
      setTableData(tableData);
      setIsModalOpen(true);
    } else {
      const tableId = tableData.id;
      const orderType = 'dine_in'
      navigate('/kot-create', { state: { orderType, tableId, tableData, tableStatus } });
    }
  }

  const handleRupeeClick = (table) => { 
    setTableNumber(table.id);
    setTableData(table);
    setIsPaymentModalOpen(true);
  };

  const handleClosePaymentModal = () => {
    setIsPaymentModalOpen(false);
  };

  const handleKotDetailsClick = (table) => {
    if ((table.itemStatus === 'running') && (table.orderStatus === 'draft') || (table.orderStatus === 'pending')) {
      setIsKotModalOpen(true);
    }
    // } else if (table.orderStatus === 'pending'){
    //   ErrorNotification("Already Invoice Generated")
    // }
    else {
      ErrorNotification("No Details");
    }
  };

  const handleKotCloseModal = () => {
    setIsKotModalOpen(false);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const handleshowInvoicePage = (table) => {
    if ((table.itemStatus === 'running') && ((table.orderStatus === 'draft')  || (table.orderStatus === 'pending'))){
      navigate('/bill-item', { state: { table } });
    }
    else {
      ErrorNotification("No invoice");
    }
  };

  return (
    <div>
      <div className="card card-content" style={{ backgroundColor: color }}>
        <div className='card-contain-alignment-main'>
          <span className='card-title-name'>{item.title}</span>
          <span className='card-captain-name'>{item?.caption_details?.user?.first_name} {item?.caption_details?.user?.last_name}</span>
        </div>
        <h3 className="item-order-amount">{item?.orderAmount ? item.orderAmount.toFixed(2) : ''}</h3>
        <div className="mt-auto text-white icon-set d-flex justify-content-between">
          {/* {alreadyReserved && !hasCurrentOrder ? (
            <FontAwesomeIcon className="icon-pointer" icon={faTicket} title="Add Kot" onClick={() => handleSettingsClick(item)} />
          ) : (
            <FontAwesomeIcon className="icon-pointer" icon={faTicket} title="Add Kot" onClick={() => handleCreateKot(item)} />
          )} */}
          <FontAwesomeIcon className="icon-pointer" icon={faTicket} title="Add Kot" onClick={() => handleCreateKot(item)} />
          <FontAwesomeIcon className="icon-pointer" icon={faFileInvoice} title="Invoice" onClick={() => handleshowInvoicePage(item)} />
          <FontAwesomeIcon className="icon-pointer" icon={faIndianRupeeSign} title="Settlement" onClick={() => handleRupeeClick(item)} />
          <FontAwesomeIcon
            className="icon-pointer"
            icon={faTachometerAlt}
            title="Kot Details"
            onClick={() => handleKotDetailsClick(item)}
          />
        </div>
      </div>
      {isModalOpen && (
        <TableReservation onClose={handleCloseModal} tableId={tableNumber} tableData={tableData} tableStatus={tableStatus}/>
      )}
      {isPaymentModalOpen && (
        <PaymentModal isOpen={isPaymentModalOpen} onRequestClose={handleClosePaymentModal} tableNumber={tableNumber} tableData={tableData} updateTableStatus={updateTableStatus}/>
      )}
      <KotModal
        isOpen={isKotModalOpen}
        onClose={handleKotCloseModal}
        tableTitle={item}
        orderId={item?.orderId}
        tableOrderDataStatus= {item?.orderStatus}
      />
    </div>
  );
};

export default Card;
