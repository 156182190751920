import React, { useState } from "react";
import "./ItemUtilities.css";
import VoidItems from "./VoidItems";
import WayOffItems from "./WayOffItems";
import Copyrights from "../../Copyrights";

function ItemUtilities() {
  const [activeComponent, setActiveComponent] = useState("void");

  return (
    <>
      <div className="item-ut-main-container">
        <div className="item-ut-button-grp">
          <button
            className={`item-ut-btn ${activeComponent === "void" ? "active" : ""}`}
            onClick={() => setActiveComponent("void")}
          >
            Void Items
          </button>
          <button
            className={`item-ut-btn ${activeComponent === "wayOff" ? "active" : ""}`}
            onClick={() => setActiveComponent("wayOff")}
          >
            Way Off Items
          </button>
        </div>
        <div className="item-ut-content">
          {activeComponent === "void" && <VoidItems />}
          {activeComponent === "wayOff" && <WayOffItems />}
        </div>
      </div>
      <Copyrights />
    </>
  );
}

export default ItemUtilities;
