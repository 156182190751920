import React, { useState, useEffect } from "react";
import "./KotDetails.css";
import MainTransferKot from "./TransferKot";
import { sendRequest } from "../../../ApiRequest";
import { useNavigate } from "react-router-dom";
import CommonCloseButton from "../CommonCloseButton/CommonCloseButton";
import { BASE_URL } from "../../../Url/constants";
import { useTranslation } from "react-i18next";
// import ErrorNotification from "../../../Notification/ErrorNotification";

const KotModal = ({ isOpen, onClose, tableTitle, orderId, tableOrderDataStatus}) => {
  const [modalClosePopup, setModalClosePopup] = useState(false);
  const [kotDetails, setKotDetails] = useState(null);
  const [voidDetails, setVoidDetails] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation();
   
  
  const handleRouteVoidItem = (order) => {
    const tableInfo = {
      tableNo: voidDetails.floor_tables ? voidDetails.floor_tables[0].name : "",
      kotId: order.id || "",
      date: new Date(order.created_at).toLocaleString() || "",
      captain: voidDetails.user ? `${voidDetails.user.first_name} ${voidDetails.user.last_name}` : "",
      noOfPerson: voidDetails.no_of_guests || 0,
      orderItems: order.order_items || [],
      table: tableTitle,
      orderId: orderId,
    };
    navigate("/void-kot", { state: { tableInfo } });
  };

  useEffect(() => {
    if (isOpen && orderId) {
      const fetchKotDetails = async () => {
        setLoading(true);
        try {
          const response = await sendRequest(
            "GET",
            `${BASE_URL}/v1/restaurant/orders/orders/${parseInt(orderId)}`
          );
          if (response.status === 200) {
            const data = response.data.data;
            setVoidDetails(data);
            setKotDetails(data.order_groups);
          } else {
            console.error("Error fetching KOT details:", response);
          }
        } catch (error) {
          console.error("Error fetching KOT details:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchKotDetails();
    }
  }, [isOpen, orderId]);

  const handleCreateKot = (tableData) => {
    const tableId = tableData.id;
    const orderType = 'dine_in'
    navigate("/kot-create", { state: { tableId, tableData, orderType } });
  };

  const handleshowInvoicePage = (table) => {
    navigate('/bill-item', { state: { table } });
  };

  if (!isOpen) return null;
  if (loading) return <div>Loading Details...</div>;
  if (!kotDetails) {
    return <div className="no-kot-details">{t("No KOT details available")}</div>;
  }

  return (
    <div
      className={`new-modal-overlay ${modalClosePopup ? "new-close-modal" : ""
        }`}
    >
      <div style={{ backgroundColor: "#002989" }}></div>
      <div className="large-model-content">
        <div className="kot-detail-table-header">
          <span className="kot-detail-title-header">
           {t(" KOT Details - Table No. ")}{tableTitle.title}
          </span>
          <span className="comman-kot-table-close" onClick={onClose}>
            <CommonCloseButton />
          </span>
        </div>
        <div className="new-modal-content">
          <div className="new-modal-body">
            {kotDetails.map((order, orderIndex) => (
              <div
                key={order.id}
                className="kot-section">
                {order.order_items && order.order_items.length > 0 && (
                  <>
                    <div className="kot-info-sub">
                      <h5>
                       {t("KOT")}/{orderIndex + 1} -{" "}
                        {new Date(order.created_at).toLocaleString()}
                      </h5>
                      <div className="void-transfer-button-kot-details">
                        <button
                          className="item-void-button"
                          onClick={() => handleRouteVoidItem(order)}
                        >
                          {t("Void Items")}
                        </button>
                        {
                          tableOrderDataStatus === "draft" && 
                          <MainTransferKot
                            modalClosePopup={modalClosePopup}
                            setModalClosePopup={setModalClosePopup}
                            orderId={orderId}
                            kotDetails={kotDetails}
                            selectedOrderItems={order.order_items}
                            selectedOrderId={order.id}
                            tableData={tableTitle}
                            kotNumber={orderIndex + 1}
                         />
                        }
                      </div>
                    </div>
                    <div className="kot-details-table-main">
                      <table className="table-kot-view">
                        <thead className="table_header">
                          <tr>
                            <th className="kot_table_header">{t("SL No")}</th>
                            <th className="kot_table_header">{t("Item Name")}</th>
                            <th className="kot_table_header">{t("Quantity")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.order_items.map((item, itemIndex) => (
                            <tr key={item.id}>
                              <td className="kot_table_data">{itemIndex + 1}</td>
                              <td className="kot_table_data">{item.item_name}</td>
                              <td className="kot_table_data">{item.quantity}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="last-line">
                      <hr />
                    </div>
                  </>
                )}
              </div>
            ))}
            <div className="new-button-group">
              <button
                className="new-void-button"
                onClick={() => handleshowInvoicePage(tableTitle)}
              >
                {t("Generate Bill")}
              </button>
              <button
                className="new-transfer-button"
                onClick={() => handleCreateKot(tableTitle)}
              >
               {t("New KOT")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KotModal;
