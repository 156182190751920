import React, { useState, useEffect } from "react";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { saveToken } from "../SecureStorage/Token";
import { BASE_URL } from "../../Url/constants";
import ErrorNotification from "../../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import { current_user } from "../../Authentication/AuthenticationContext";
import { sendRequest } from "../../ApiRequest";
import '../../../src/components/styles/Login/Login.css';

function LoginOtp(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [resendTimer, setResendTimer] = useState(() => {
    const savedTimer = localStorage.getItem('resendTimer');
    return savedTimer ? parseInt(savedTimer, 10) : 120;
  });

  const handleInputChange = (value) => {
    const numericValue = value.replace(/\D/g, "");
    setOtp(numericValue);
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    if (otp === "") {
      ErrorNotification("Please enter OTP");
      return;
    }
    if (otp.length !== 6) {
      ErrorNotification("Please enter 6 digits OTP");
      return;
    }
    try {
      const otpParams = {
        user: {
          login: location.state.phone,
          otp: otp,
          company_id: location.state.company_id,
          devise_type: 'web'
        },
      };
      const response = await sendRequest('POST', `${BASE_URL}/v1/auth/login`, otpParams)
      if (response.status === 200) {
        const data = response.data;
        saveToken(data.token);
        const user = await current_user();
        props.setIsLoggedIn(true); 
        if (["admin", "owner", "manager"].includes(user.user_detail.role.name)) {
          navigate("/dashboard");
        } else if (["cashier", "staff"].includes(user.user_detail.role.name)) {
          navigate("/billing");
        } else if (user.user_detail.role.name === "chef") {
          navigate("/kitchen-ceff");
        }
      }
    } catch (error) {
      ErrorNotification(error.data.errors);
    }
  };

  const handleResendOtp = (e) => {
    e.preventDefault();
    setResendTimer(120);
    localStorage.setItem('resendTimer', 120);

    fetch(`${BASE_URL}/v1/auth/otps`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user: { login: location.state.phone, devise_type: 'web' } }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to resend OTP");
        }
        return response.json();
      })
      .then((data) => {
        setOtp("");
      })
      .catch((error) => {
        ErrorNotification(error.message);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setResendTimer((prevTimer) => {
        const newTimer = prevTimer > 0 ? prevTimer - 1 : 0;
        localStorage.setItem('resendTimer', newTimer);
        return newTimer;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTimer = () => {
    const minutes = Math.floor(resendTimer / 60);
    const seconds = resendTimer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}s`;
  };

  return (
    <div className="login-body">
      <div className="login-box">
        <div className="logo-content">
          <img
            src={require("../../Assets/Images/logo.jpg")}
            alt="Logo"
            className="company-unique-logo-image"
          />
        </div>

        <p>
          We have sent OTP to your registered mobile number on whatsapp{" "}
          <b>
            {"X".repeat(location.state.phone.length - 4)}
            {location.state.phone.slice(-4)}
          </b>{" "}
          .
        </p>
        {/* <h5>Otp : {otp}</h5> */}
        <form className="loginotp" onSubmit={handleVerifyOtp}>
          <label className="otp-enter-title">Enter OTP</label>
          <div>
            <OTPInput
              isInputNum
              shouldAutoFocus
              value={otp}
              onChange={handleInputChange}
              numInputs={6}
              inputStyle={{
                flex: '1 1 auto',
                height: 'auto',
                margin: '0 10px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              renderInput={(props, index) => (
                <input
                  {...props}
                  key={index}
                  maxLength={1}
                  pattern="[0-9]*"
                  style={{ width: '100%', textAlign: 'center', margin: '0 10px' }}
                />
              )}
            />
          </div>
          <div
            style={{
              color: "black",
              justifyContent: "center",
              textAlign: "center",
              margin: "20px",
            }}
          >
            {resendTimer > 0 && (
              <p>Resend Otp expires in: {formatTimer()}</p>
            )}
            {resendTimer === 0 && (
              <p>
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <span className="remeber-me">Remember Me</span>
                <Link onClick={handleResendOtp}>
                  Resend Otp{" "}
                  <FontAwesomeIcon
                    icon={faRedoAlt}
                    style={{ marginLeft: "5px" }}
                  />
                </Link>
              </p>
            )}
          </div>
          <div>
            <button type="submit" className="btn btn-primary verify-otp">
              Verify
            </button>
          </div>
        </form>
      </div>
      <NotificationContainer />
    </div>
  );
}

export default LoginOtp;
