import React, { useState, useEffect } from "react";
import "./itemadditionalsetting.css";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import successNotification from "../../../Notification/SuccessNotification";
import { useTranslation } from "react-i18next";

function ItemAdditionalSetting({ item, closeSettingPopup, type, setItem }) {
  const [options, setOptions] = useState([]);
  const [settings, setSettings] = useState({});
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchOptions();
  }, [item.id]);

  const fetchOptions = async () => {
    try {
      const response = await sendRequest('get', `${BASE_URL}/v1/setting/settings/item_setting_options?type=${type}`);
      const fetchedOptions = response.data.data;
      setOptions(fetchedOptions);
      fetchItemSettings(fetchedOptions);
    } catch (error) {
      console.log("Error fetching options:", error);
    }
  };

  const fetchItemSettings = async (options) => {
    try {
      let url = `${BASE_URL}/v1/setting/settings`;
      if (type === "stock_wise") {
        url += `?stock_id=${item.id}`;
      } else {
        url += `?item_id=${item.id}`;
      }
      const response = await sendRequest("GET", url);
      const fetchedSettings = response.data.reduce((acc, setting) => {
        const option = options.find(
          (opt) => opt.id === setting.item_setting_option_id
        );
        if (option) {
          acc[option.name] = {
            status: setting.status,
            free_text: setting.free_text,
          };
        }
        return acc;
      }, {});
      setSettings(fetchedSettings);
    } catch (error) {
      console.log("Error fetching item settings:", error);
    }
  };

  useEffect(() => {
    setIsSaveButtonEnabled(isChanged);
  }, [isChanged]);

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [id]: {
        ...prevSettings[id],
        status: checked,
        free_text: checked ? prevSettings[id]?.free_text || "" : "",
      },
    }));
    setIsChanged(true);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const numericFields5Digits = ["MRP", "Batch", "Model", "Size"];
    if (id === "MRP") {
      if (/^\d{0,5}$/.test(value)) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          [id]: { ...prevSettings[id], free_text: value },
        }));
      }
    } else if (numericFields5Digits.includes(id)) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          [id]: { ...prevSettings[id], free_text: value },
        }));
    } else {
      setSettings((prevSettings) => ({
        ...prevSettings,
        [id]: { ...prevSettings[id], free_text: value },
      }));
    }
    setIsChanged(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const itemSettings = options.map((option) => ({
      item_setting_option_id: option.id,
      status: settings[option.name]?.status || false,
      free_text: settings[option.name]?.free_text || "",
      is_billing: settings[option.name]?.is_billing || false,
      is_report: settings[option.name]?.is_report || false,
      is_template: settings[option.name]?.is_template || false,
    }));

    const payload = {
      ...(type === 'stock_wise'
        ? { stock_id: item.id }
        : { item_id: item.id }),
      item_settings: itemSettings,
    };
    try {
      const res = await sendRequest(
        "POST",
        `${BASE_URL}/v1/setting/settings`,
        payload
      );
      console.log(res);
      successNotification("Item Settings Updated");

      if (type === 'stock_wise' && setItem) {
        const itemResponse = await sendRequest(
          "GET", 
          `${BASE_URL}/v1/inventory_managment/items/${item.item_id}`
        );
        setItem(itemResponse.data.data);
      }

      fetchItemSettings(options);
      setIsChanged(false);
      closeSettingPopup();
    } catch (error) {
      console.log("Error updating settings:", error);
    }
  };

  return (
    <>
      <form className="item-add-settings-container mt-5" onSubmit={handleSubmit}>
        <div className="add-setting-header">
          <h3>
            {t("Item Setting")}: {item.item_name}
          </h3>
          <hr />
          {options.map((option) => (
            <div className="form-group-i-2" key={option.id}>
              <div className="item-add-setting-div-first">
                <div className="item-add-setting-data-label">
                  <input
                    type="checkbox"
                    className="checkboxes-i"
                    id={option.name}
                    checked={settings[option.name]?.status || false}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={option.name}>
                    {option.name.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </label>
                </div>
                <input
                  className="item-input-add-setting"
                  type={
                    option.name === "ExpiryDate" || option.name === "MfgDate"
                      ? "date"
                      : "text"
                  }
                  id={option.name}
                  value={settings[option.name]?.free_text || ""}
                  onChange={handleInputChange}
                  placeholder={option.name.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  disabled={!settings[option.name]?.status}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="col-md-12 mt-20 text-right">
          <button
            type="submit"
            className={`btn ${isSaveButtonEnabled ? "btn-primary" : "btn-disabled"}`}
            disabled={!isSaveButtonEnabled}
          >
            {t("Save")}
          </button>
        </div>
      </form>
    </>
  );
}

export default ItemAdditionalSetting;
