import React, { useEffect, useState } from 'react';
import './DebitNoteGeneralTemplate.css'
import { current_user } from '../../../../Authentication/AuthenticationContext';

function DebitNoteGeneralTemplate({ onClose, debitNotePrint, removeTablehead, enabledTemplateDetails }) {
  const [isCompanydata, setCompanyData] = useState("");
  const [isBranchData, setBranchData] = useState("");
  const [isUserData, setUserData] = useState("");

  const renderDebitNoteGenTable = () => {
    return (
      <div>
        {debitNotePrint?.bill_items && debitNotePrint.bill_items.length > 0 ? (
          <>
            <table className="debit-note-gen-temp-table">
              <thead>
                <tr className='debit-note-gen-temp-tab-head-row'>
                  <th className='debit-note-gen-temp-table-header-attribute'>SNo</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Item</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>HSN</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Qty</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Unit</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Rate</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Tax</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Amount</th>
                </tr>
              </thead>
              <tbody>
                {debitNotePrint?.bill_items && debitNotePrint?.bill_items.map((item, index) => (
                  <tr key={index} className='debit-note-gen-temp-table-body-row'>
                    <td>{index + 1}</td>
                    <td className='debit-note-gen-temp-table-body-data'>{item?.name}</td>
                    <td className='debit-note-gen-temp-table-body-data'>
                      {item.hsn_number}
                    </td>
                    <td className='debit-note-gen-temp-table-body-data'>{item?.quantity}</td>
                    <td className='debit-note-gen-temp-table-body-data'>{item?.unit?.unit_name}</td>
                    <td className='debit-note-gen-temp-table-body-data'>{item?.sales_price.toFixed(2)}</td>
                    <td className='debit-note-gen-temp-table-body-data'>
                      <div className='debit-note-gen-temp-tax-details'>
                        {item?.tax_details?.length > 0
                          ? `${item.tax_details.join(", ")} (${item?.tax_inclusive ? "Inc" : "Exc"})`
                          : ""}
                      </div>
                      {item?.item_total_tax.toFixed(2)}
                    </td>
                    <td className='debit-note-gen-temp-table-body-data'>{(item?.quantity * item?.sales_price).toFixed(2)}</td>
                  </tr>
                ))}
                <tr className='debit-note-gen-temp-calculation-row'>
                  <td colSpan={6} className='debit-note-gen-temp-extra-row'>
                    <span className='debit-note-gen-temp-extra-row-data'>Sub Total</span>
                  </td>
                  <td></td>
                  <td>{debitNotePrint?.sub_total?.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
            <table className="debit-note-gen-temp-footer-table">
              <tbody>
                <tr className='debit-note-gen-temp-table-footer-div'>
                  <td colSpan={3} rowSpan={5} className='debit-note-gen-temp-table-footer-bdebit-note'>
                    <div className="debit-note-gen-temp-footer-data">
                      <div className="debit-note-gen-temp-footer-data-row">
                        <span className="debit-note-gen-temp-footer-label">Sub Total:</span>
                        <span className="debit-note-gen-temp-footer-value">{debitNotePrint?.sub_total?.toFixed(2)}</span>
                      </div>
                      <div className="debit-note-gen-temp-footer-data-row">
                        <span className="debit-note-gen-temp-footer-label">Discount:</span>
                        <span className="debit-note-gen-temp-footer-value">{debitNotePrint?.total_discount?.toFixed(2) || '0.00'}</span>
                      </div>
                      <div className="debit-note-gen-temp-footer-data-row">
                        <span className="debit-note-gen-temp-footer-label">Parcel Charges:</span>
                        <span className="debit-note-gen-temp-footer-value">{debitNotePrint?.parcel_charges?.toFixed(2) || '0.00'}</span>
                      </div>
                      {debitNotePrint?.is_gst_enable && debitNotePrint?.is_igst_enable ? (
                        <div className="debit-note-gen-temp-footer-data-row">
                          <span className="debit-note-gen-temp-footer-label">IGST:</span>
                          <span className="debit-note-gen-temp-footer-value">{debitNotePrint?.total_gst?.toFixed(2) || '0.00'}</span>
                        </div>
                      ) : (
                        <>
                          <div className="debit-note-gen-temp-footer-data-row">
                            <span className="debit-note-gen-temp-footer-label">SGST:</span>
                            <span className="debit-note-gen-temp-footer-value">{(debitNotePrint?.total_gst / 2).toFixed(2) || '0.00'}</span>
                          </div>
                          <div className="debit-note-gen-temp-footer-data-row">
                            <span className="debit-note-gen-temp-footer-label">CGST:</span>
                            <span className="debit-note-gen-temp-footer-value">{(debitNotePrint?.total_gst / 2).toFixed(2) || '0.00'}</span>
                          </div>
                        </>
                      )}
                      <div className="debit-note-gen-temp-footer-data-row">
                        <span className="debit-note-gen-temp-footer-label">TOTAL:</span>
                        <span className="debit-note-gen-temp-footer-value">{debitNotePrint?.total_amount?.toFixed(2) || '0.00'}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <>
            <table className="debit-note-gen-temp-table">
              <thead>
                <tr className='debit-note-gen-temp-tab-head-row'>
                  <th className='debit-note-gen-temp-table-header-attribute'>SNo</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Item</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>HSN</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Qty</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Unit</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Rate</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Tax</th>
                  <th className='debit-note-gen-temp-table-header-attribute'>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr className='debit-note-gen-temp-table-body-row'>
                  <td>1</td>
                  <td className='debit-note-gen-temp-table-body-data'>item1</td>
                  <td className='debit-note-gen-temp-table-body-data'>
                    12345
                  </td>
                  <td className='debit-note-gen-temp-table-body-data'>1</td>
                  <td className='debit-note-gen-temp-table-body-data'>piece</td>
                  <td className='debit-note-gen-temp-table-body-data'>2000</td>
                  <td className='debit-note-gen-temp-table-body-data'>
                    <div className='debit-note-gen-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='debit-note-gen-temp-table-body-data'>2000</td>
                </tr>
                <tr className='debit-note-gen-temp-table-body-row'>
                  <td>2</td>
                  <td className='debit-note-gen-temp-table-body-data'>item2</td>
                  <td className='debit-note-gen-temp-table-body-data'>
                    12345
                  </td>
                  <td className='debit-note-gen-temp-table-body-data'>1</td>
                  <td className='debit-note-gen-temp-table-body-data'>piece</td>
                  <td className='debit-note-gen-temp-table-body-data'>2000</td>
                  <td className='debit-note-gen-temp-table-body-data'>
                    <div className='debit-note-gen-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='debit-note-gen-temp-table-body-data'>2000</td>
                </tr>
                <tr className='debit-note-gen-temp-table-body-row'>
                  <td>3</td>
                  <td className='debit-note-gen-temp-table-body-data'>item3</td>
                  <td className='debit-note-gen-temp-table-body-data'>
                    12345
                  </td>
                  <td className='debit-note-gen-temp-table-body-data'>1</td>
                  <td className='debit-note-gen-temp-table-body-data'>piece</td>
                  <td className='debit-note-gen-temp-table-body-data'>2000</td>
                  <td className='debit-note-gen-temp-table-body-data'>
                    <div className='debit-note-gen-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='debit-note-gen-temp-table-body-data'>2000</td>
                </tr>
                <tr className='debit-note-gen-temp-calculation-row'>
                  <td colSpan={6} className='debit-note-gen-temp-extra-row'>
                    <span className='debit-note-gen-temp-extra-row-data'>Sub Total</span>
                  </td>
                  <td></td>
                  <td>6000</td>
                </tr>
              </tbody>
            </table>
            <table className="debit-note-gen-temp-footer-table">
              <tbody>
                <tr className='debit-note-gen-temp-table-footer-div'>
                  <td colSpan={3} rowSpan={5} className='debit-note-gen-temp-table-footer-bdebit-note'>
                    <div className="debit-note-gen-temp-footer-data">
                      <div className="debit-note-gen-temp-footer-data-row">
                        <span className="debit-note-gen-temp-footer-label">Sub Total:</span>
                        <span className="debit-note-gen-temp-footer-value">6000</span>
                      </div>
                      <div className="debit-note-gen-temp-footer-data-row">
                        <span className="debit-note-gen-temp-footer-label">Discount:</span>
                        <span className="debit-note-gen-temp-footer-value">0.0</span>
                      </div>
                      <div className="debit-note-gen-temp-footer-data-row">
                        <span className="debit-note-gen-temp-footer-label">Parcel Charges:</span>
                        <span className="debit-note-gen-temp-footer-value">0.0</span>
                      </div>
                      <div className="debit-note-gen-temp-footer-data-row">
                        <span className="debit-note-gen-temp-footer-label">TOTAL:</span>
                        <span className="debit-note-gen-temp-footer-value">6000</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setCompanyData(currentUser.company);
      setBranchData(currentUser.company.branch_info);
      setUserData(currentUser.company.user_info);
    };
    fetchUser();
  }, []);

  const getOption = (optionName) => {
    return enabledTemplateDetails?.find((opt) => opt?.option_name === optionName) || {};
  };

  const currentTime = new Date().toLocaleTimeString();

  return (
    <>
      <div className="debit-note-gen-main-container">
        {!removeTablehead && (
          <div className="debit-note-gen-header-div">
            <span className='debit-note-gen-header-left-title'>Debit Note Template</span>
            <span className='debit-note-gen-header-right-title' onClick={onClose}>x</span>
          </div>
        )}
        <div className="debit-note-gen-sub-container">
          <div className="debit-note-gen-subcontainer-header">
            {getOption("company_logo")?.is_enable && (
              <div className="debit-note-gen-head-first-div">
                <img src={isCompanydata?.logo_url} alt="Company Logo" className="debit-note-gen-temp-company-logo" />
              </div>
            )}
            <div className="debit-note-gen-head-second-div">
              {getOption("company_logo")?.is_enable && (
                <span className='debit-note-gen-head-com-title'>{isCompanydata?.name}</span>
              )}
              {getOption("branch_name")?.is_enable && (
                <span className='debit-note-gen-head-branch-title'>{isBranchData?.branch_name}</span>
              )}
              {getOption("address")?.is_enable && (
                <span className='debit-note-gen-head-other-titles'>{isBranchData?.address}</span>
              )}
              {getOption("phone_number")?.is_enable && (
                <span className='debit-note-g
              en-head-other-titles'>{isUserData?.phone}</span>
              )}
              {getOption("email")?.is_enable && (
                <span className='debit-note-gen-head-other-titles'>{isUserData?.email}</span>
              )}
              {getOption("gst_number")?.is_enable && (
                <span className='debit-note-gen-head-other-titles'>{isCompanydata?.gst_number}</span>
              )}
            </div>
            <div className="debit-note-gen-head-third-div">
              {getOption("text_title")?.is_enable && (
                <div className='debit-note-gen-head-third-title-grp'>
                  <span className='debit-note-gen-head-third-title'>
                    {getOption("text_title")?.free_text ? getOption("text_title")?.free_text : "Debit Note"}
                  </span>
                </div>
              )}
              <div className='debit-note-gen-head-third-sub-title-grp'>
                {getOption("invoice_number")?.is_enable && (
                  <span className='debit-note-gen-head-third-sub-title'>q_text : {debitNotePrint?.bill_number}</span>
                )}
                {getOption("date")?.is_enable && (
                  <span className='debit-note-gen-head-third-sub-title'>date:
                    {debitNotePrint?.date
                      ? new Date(debitNotePrint?.date).toLocaleDateString('en-GB')
                      : ''}
                  </span>
                )}
                {getOption("time")?.is_enable && (
                  <span className='debit-note-gen-head-third-sub-title'>Time : {currentTime}</span>
                )}
              </div>
            </div>
          </div>
          <div className='debit-note-gen-temp-horizontal-line'></div>
          <div className="debit-note-general-cust-info-grp">
            <div className='debit-note-gen-cust-info-div'>
              <span className='debit-note-gen-cust-title'>
                Issued to
              </span>
              {getOption("cust_name")?.is_enable && (
                <span className='debit-note-gen-cust-sub-titles'>
                  Customer Name: {debitNotePrint?.customer?.customer_name}
                </span>
              )}
              {getOption("cust_gst")?.is_enable && (
                <span className='debit-note-gen-cust-sub-titles'>
                  GSTIN : {debitNotePrint?.customer?.gst_number}
                </span>
              )}
              {/* <span className='debit-note-gen-cust-sub-titles'>
                POS : Tamil Nadu
              </span> */}
            </div>
            <div className='debit-note-gen-cust-info-div'>
              <span className='debit-note-gen-cust-title'>
                Billing Address :
              </span>
              {getOption("cust_company")?.is_enable && (
                <span className='debit-note-gen-cust-sub-titles'>
                  {debitNotePrint?.customer?.company_name}
                </span>
              )}
              {getOption("cust_address")?.is_enable && (
                <span className='debit-note-gen-cust-sub-titles'>
                  {debitNotePrint?.customer?.billing_address_one
                    ||
                    debitNotePrint?.customer?.billing_address_two}
                </span>
              )}
              {getOption("cust_phone")?.is_enable && (
                <span className='debit-note-gen-cust-sub-titles'>
                  Phone : {debitNotePrint?.customer?.mobile_number}
                </span>
              )}
              {getOption("cust_email")?.is_enable && (
                <span className='debit-note-gen-cust-sub-titles'>
                  Email : {debitNotePrint?.customer?.email}
                </span>
              )}
            </div>
          </div>
          <div className='debit-note-gen-temp-horizontal-line'></div>
          <div className="debit-note-gen-order-info-grp">
            {getOption("cust_order_no")?.is_enable && (
              <div className="debit-note-gen-order-info">
                <span className='debit-note-gen-order-no-title'>Buyer's Order No#:</span>
                <span className='debit-note-gen-order-value'>{debitNotePrint?.order_no}</span>
              </div>
            )}
            {getOption("cust_order_date")?.is_enable && (
              <div className="debit-note-gen-order-info">
                <span className='debit-note-gen-order-date-title'>Buyer's Order Date:</span>
                <span className='debit-note-gen-order-value'>
                  {debitNotePrint?.order_date
                    ? new Date(debitNotePrint?.order_date).toLocaleDateString('en-GB')
                    : ''}
                </span>
              </div>
            )}
          </div>
          <div className='debit-note-gen-temp-horizontal-line'></div>
          <div className='debit-note-gen-table-grp-container'>
            {renderDebitNoteGenTable()}
          </div>
          <div className="debit-note-gen-temp-signature-section">
            <div className='debit-note-gen-signature-first-div'>
              {getOption("terms_conditions")?.is_enable && (
                <div className='debit-note-gen-signature-first-part'>
                  <span className='debit-note-gen-temp-first-title'>Terms & Conditions :</span>
                  <span className='debit-note-gen-temp-first-title'>
                    Goods once sold will not be taken back
                  </span>
                </div>
              )}
              <div className='debit-note-gen-signature-second-part'>
                <span className='debit-note-gen-temp-first-title'>For {isCompanydata?.name}</span>
              </div>
            </div>
            <div className='debit-note-gen-signature-second-div'>
              {getOption("cust_signature")?.is_enable && (
                <span className='debit-note-gen-temp-first-title'>Customer Signature</span>
              )}
              {getOption("authorized_signature")?.is_enable && (
                <span className='debit-note-gen-temp-first-title'>Authorized Signature</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DebitNoteGeneralTemplate;
