import React, { useEffect, useState } from "react";
import "./ReceiptThermalTemplate.css"
import { current_user } from "../../../../Authentication/AuthenticationContext";
import { toWords } from "number-to-words";

function ReceiptThermalTemplate({ onClose, receiptPrint, removeTablehead, enabledTemplateDetails }) {
  const [isCompanydata, setCompanyData] = useState("");
  const [isBranchData, setBranchData] = useState("");
  const [isUserData, setUserData] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setCompanyData(currentUser.company);
      setBranchData(currentUser.company.branch_info);
      setUserData(currentUser.company.user_info);
    };
    fetchUser();
  }, []);

  // const renderReceiptThermalTable = () => (
  //   <>
  //     <table className="receipt-thermal-temp-table">
  //       <thead>
  //         <tr className='receipt-thermal-temp-tab-head-row'>
  //           <th className='receipt-thermal-temp-table-header-attribute'>Item</th>
  //           <th className='receipt-thermal-temp-table-header-attribute'>Qty</th>
  //           <th className='receipt-thermal-temp-table-header-attribute'>Unit</th>
  //           <th className='receipt-thermal-temp-table-header-attribute'>Rate</th>
  //           <th className='receipt-thermal-temp-table-header-attribute'>Tax</th>
  //           <th className='receipt-thermal-temp-table-header-attribute'>Amount</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         <tr className='receipt-thermal-temp-table-body-row'>
  //           <td className='receipt-thermal-temp-table-body-data'>test</td>
  //           <td className='receipt-thermal-temp-table-body-data'>2</td>
  //           <td className='receipt-thermal-temp-table-body-data'>piece</td>
  //           <td className='receipt-thermal-temp-table-body-data'>54657</td>
  //           <td className='receipt-thermal-temp-table-body-data'>
  //             <div className='receipt-thermal-temp-tax-details'>
  //               67
  //             </div>
  //             567
  //           </td>
  //           <td className='receipt-thermal-temp-table-body-data'>546789</td>
  //         </tr>
  //         <tr className='receipt-thermal-temp-calculation-row'>
  //           <td colSpan={4} className='receipt-thermal-temp-extra-row'>
  //             <span className='receipt-thermal-temp-extra-row-data'>Sub Total</span>
  //           </td>
  //           <td></td>
  //           <td>45674567</td>
  //         </tr>
  //       </tbody>
  //     </table>
  //     <table className="receipt-thermal-temp-footer-table">
  //       <tbody>
  //         <tr className='receipt-thermal-temp-table-footer-div'>
  //           <td colSpan={3} rowSpan={5} className='receipt-thermal-temp-table-footer-border'>
  //             <div className="receipt-thermal-temp-footer-data">
  //               <div className="receipt-thermal-temp-footer-data-row">
  //                 <span className="receipt-thermal-temp-footer-label">Sub Total:</span>
  //                 <span className="receipt-thermal-temp-footer-value">34567</span>
  //               </div>
  //               <div className="receipt-thermal-temp-footer-data-row">
  //                 <span className="receipt-thermal-temp-footer-label">Discount:</span>
  //                 <span className="receipt-thermal-temp-footer-value">0</span>
  //               </div>
  //               {/* {saleInvoice.is_gst_enable && saleInvoice.is_igst_enable ? (
  //                 <div className="receipt-thermal-temp-footer-data-row">
  //                   <span className="receipt-thermal-temp-footer-label">IGST:</span>
  //                   <span className="receipt-thermal-temp-footer-value">{saleInvoice?.total_gst.toFixed(2) || '0.00'}</span>
  //                 </div>
  //               ) : (
  //                 <>
  //                   <div className="receipt-thermal-temp-footer-data-row">
  //                     <span className="receipt-thermal-temp-footer-label">SGST:</span>
  //                     <span className="receipt-thermal-temp-footer-value">{(saleInvoice?.total_gst / 2).toFixed(2) || '0.00'}</span>
  //                   </div>
  //                   <div className="receipt-thermal-temp-footer-data-row">
  //                     <span className="receipt-thermal-temp-footer-label">CGST:</span>
  //                     <span className="receipt-thermal-temp-footer-value">{(saleInvoice?.total_gst / 2).toFixed(2) || '0.00'}</span>
  //                   </div>
  //                 </>
  //               )} */}
  //               <div className="receipt-thermal-temp-footer-data-row">
  //                 <span className="receipt-thermal-temp-footer-label">TOTAL:</span>
  //                 <span className="receipt-thermal-temp-footer-value">3456789</span>
  //               </div>
  //             </div>
  //           </td>
  //         </tr>
  //       </tbody>
  //     </table>
  //   </>
  // );

  const amountPaid = receiptPrint?.amount_paid || "0"
  const getOption = (optionName) => {
    return enabledTemplateDetails?.find((opt) => opt?.option_name === optionName) || {};
  };
  const currentTime = new Date().toLocaleTimeString();

  return (
    <>
      <div className="receipt-thermal-main-container">
        {!removeTablehead && (
          <div className="receipt-thermal-header-div">
            <span className='receipt-thermal-header-left-title'>Receipt Template</span>
            <span className='receipt-thermal-header-right-title' onClick={onClose}>x</span>
          </div>
        )}
        <div className="receipt-thermal-temp-subcontainer-header">
          <div className="receipt-thermal-sub-container">
            <div className="receipt-thermal-company-info-grp">
              {getOption("company_logo")?.is_enable && (
                <div>
                  <img src={isCompanydata.logo_url} alt="Company Logo" className="receipt-thermal-company-logo" />
                </div>
              )}
              <div className="receipt-thermal-temp-horizontal-line"></div>
              {getOption("company_name")?.is_enable && (
                <div className="receipt-thermal-company-info">
                  <span className='receipt-thermal-company-name'>{isCompanydata.name}</span>
                </div>
              )}
              {getOption("address")?.is_enable && (
                <div className="receipt-thermal-company-info">
                  <span className="receipt-com-info-title">{isBranchData.address}</span>
                </div>
              )}
              {getOption("phone_number")?.is_enable && (
                <div className="receipt-thermal-company-info">
                  <span className="receipt-com-info-titles">Phone: {isUserData.phone}</span>
                </div>
              )}
            </div>
            <div className="receipt-thermal-some-other-info">
              {getOption("gst_number")?.is_enable && (
                <div className="receipt-thermal-company-info">
                  <span className="receipt-com-info-titles">GSTIN : {isCompanydata.gst_number}</span>
                </div>
              )}
              {getOption("email")?.is_enable && (
                <div className="receipt-thermal-company-info">
                  <span className="receipt-com-info-titles">Email: {isUserData.email}</span>
                </div>
              )}
            </div>
            <div className="receipt-thermal-temp-horizontal-line"></div>
            {getOption("text_title")?.is_enable && (
              <div className="receipt-thermal-title-grp-container">
                <span className="receipt-thermal-title-name">RECEIPT</span>
              </div>
            )}
            <div className="receipt-thermal-other-info">
              {getOption("invoice_number")?.is_enable && (
                <span className="receipt-thermal-cust-com-other-info">Receipt No. :  {receiptPrint?.bill_payment_number}</span>
              )}
              {getOption("date")?.is_enable && (
                <span className="receipt-thermal-cust-com-other-info">Date:
                  {receiptPrint?.date
                    ? new Date(receiptPrint?.date).toLocaleDateString('en-GB')
                    : ''}
                </span>
              )}
              {getOption("time")?.is_enable && (
                <span className="receipt-thermal-cust-com-other-info">Time: {currentTime}</span>
              )}
              <span className="receipt-thermal-cust-com-other-info">Payment Mode : {receiptPrint?.payment_mode}</span>
            </div>
          </div>
          <div className="receipt-thermal-temp-horizontal-line"></div>
          {/* <div className="receipt-thermal-order-grp-container">
            <div className="receipt-thermal-order-grp">
              <span className="receipt--thermal-order-grp-titles">Buyer's Order No#:</span>
              <span className="receipt-thermal-order-grp-values">ORD000125</span>
            </div>
            <div className="receipt-thermal-order-grp">
              <span className="receipt-thermal-order-grp-titles">Buyer's Order Date:</span>
              <span className="receipt-thermal-order-grp-values">13/11/2024</span>
            </div>
          </div> */}
          {/* <div className='receipt-thermal-table-grp-container'>
            {renderReceiptThermalTable()}
          </div> */}
          <div className="receipt-thermal-cust-first-data">
            <span className="receipt-thermal-cust-first-left-title">Amount Received from</span>
            <div className="receipt-thermal-cust-first-right-title">
              {getOption("cust_company")?.is_enable && (
                <span className="receipt-thermal-cust-com-other-info">
                  Customer Comapany: {receiptPrint?.customer?.company_name}</span>
              )}
              {getOption("cust_name")?.is_enable && (
                <span className="receipt-thermal-cust-com-other-info">
                  Customer Name: {receiptPrint?.customer?.customer_name} </span>
              )}
              {getOption("cust_address")?.is_enable && (
              <span className="receipt-thermal-cust-com-other-info">
                {receiptPrint?.customer?.billing_address_one
                  ||
                  receiptPrint?.customer?.billing_address_two}
              </span>
              )}
              {getOption("cust_phone")?.is_enable && (
              <span className="receipt-thermal-cust-com-other-info">Phone : {receiptPrint?.customer?.mobile_number}</span>
              )}
              {getOption("cust_email")?.is_enable && (
              <span className="receipt-thermal-cust-com-other-info">Email : {receiptPrint?.customer?.email}</span>
              )}
              {getOption("cust_gst")?.is_enable && (
              <span className="receipt-thermal-cust-com-other-info">GSTIN : {receiptPrint?.customer?.gst_number}</span>
              )}
            </div>
          </div>
          <div className="receipt-thermal-cust-second-data">
            <span className="receipt-thermal-cust-second-title">Amount Received (INR)</span>
            <span className="receipt-thermal-cust-second-value">Rs.{receiptPrint?.amount_paid}</span>
          </div>
          <div className="receipt-thermal-cust-third-data">
            <span className="receipt-thermal-cust-third-title">Amount Received in words</span>
            <span className="receipt-thermal-cust-third-value">{toWords(amountPaid)} Rupees only</span>
          </div>
          <div className="receipt-thermal-cust-fourth-data">
            <span className="receipt-thermal-cust-third-title">Remarks</span>
            <span className="receipt-thermal-cust-third-value">You are a Valuable Customer</span>
          </div>
          <div className="receipt-thermal-temp-horizontal-line"></div>
          <div className="receipt-thermal-temp-signature-section">
            <div className='receipt-thermal-signature-first-div'>
            {getOption("terms_conditions")?.is_enable && (
              <div className='receipt-thermal-signature-first-part'>
                <span className='receipt-thermal-temp-first-title'>Terms & Conditions :</span>
                <span className='receipt-thermal-temp-first-title'>
                  Goods once sold will not be taken back
                </span>
              </div>
            )}
              <div className='receipt-thermal-signature-second-part'>
                <span className='receipt-thermal-temp-first-title'>For {isCompanydata?.name}</span>
              </div>
            </div>
            <div className='receipt-thermal-signature-second-div'>
            {getOption("cust_signature")?.is_enable && (
              <span className='receipt-thermal-temp-first-title'>Customer Signature</span>
            )}
            {getOption("authorized_signature")?.is_enable && (
              <span className='receipt-thermal-temp-first-title'>Authorized Signature</span>
            )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReceiptThermalTemplate;
