import React, { useEffect, useState } from "react";
import "./CommonTemplateSetting.css"
import QuoteGeneralTemplate from "../QuoteTemplates/QuoteGeneralTemplate";
import { sendRequest } from "../../../../ApiRequest";
import { BASE_URL, NO_RECORD_ERROR, SUCCESS_MESSAGE, UPDATE_ERROR_MSG } from "../../../../Url/constants";
import QuoteLetterPadTemplate from "../QuoteTemplates/QuoteLetterPadTemplate";
import QuoteThermalTemplate from "../QuoteTemplates/QuoteThermalTemplate";
import OrderGeneralTemplate from "../OrderTemplates/OrderGeneralTemplate";
import OrderLetterPadTemplate from "../OrderTemplates/OrderLetterPadTemplate";
import OrderThermalTemplate from "../OrderTemplates/OrderThermalTemplate";
import InvoiceGeneralTemplate from "../InvoiceTemplates/InvoiceGeneralTemplate";
import InvoiceLetterPadTemplate from "../InvoiceTemplates/InvoiceLetterPadTemplate";
import InvoiceThermalTemplate from "../InvoiceTemplates/InvoiceThermalTemplate";
import DeliveryChallanGeneralTemplate from "../DeliveryChallanTemplates/DeliveryChallanGeneralTemplate";
import DeliveryChallanLetterPadTemplate from "../DeliveryChallanTemplates/DeliveryChallanLetterPadTemplate";
import DeliveryChallanThermalTemplate from "../DeliveryChallanTemplates/DeliveryChallanThermalTemplate";
import CreditNoteGeneralTemplate from "../CreditNoteTemplates/CreditNoteGeneralTemplate";
import CreditNoteLetterPadTemplate from "../CreditNoteTemplates/CreditNoteLetterPadTemplate";
import DebitNoteGeneralTemplate from "../DebitNoteTemplates/DebitNoteGeneralTemplate";
import DebitNoteLetterPadTemplate from "../DebitNoteTemplates/DebitNoteLetterPadTemplate";
import DebitNoteThermalTemplate from "../DebitNoteTemplates/DebitNoteThermalTemplate";
import ReceiptGeneralTemplate from "../ReceiptTemplates/ReceiptGeneralTemplate";
import ReceiptThermalTemplate from "../ReceiptTemplates/ReceiptThermalTemplate";
import successNotification from "../../../../Notification/SuccessNotification";
import ErrorNotification from "../../../../Notification/ErrorNotification";
import { FaCog } from "react-icons/fa";
import FreeTextEditPopup from "../FreeText/FreeTextEditPopup";
import CreditNoteThermalTemplate from "../CreditNoteTemplates/CreditNoteThermalTemplate";


function CommonTemplateSetting({ onClose, selectedTemplateType }) {

  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [templateOptionConfigData, setTemplateOptionConfigData] = useState([]);
  const [editedOption, setEditedOption] = useState(null);
  const [isEditedPopup, setIsEditedPopup] = useState(false);

  const fetchDefaultTemplate = async (type) => {
    try {
      const response = await sendRequest("GET", `${BASE_URL}/v1/template/templates/get_sales_templates?type=${type}`
      );
      setSelectedTemplateId(response?.data?.data?.id);
      handleTemplateChange(response?.data?.data?.id);
    } catch (error) {
      console.error("Error fetching default template:", error);
    }
  };

  useEffect(() => {
    fetchDefaultTemplate(selectedTemplateType);
  }, [selectedTemplateType]);

  const handleTemplateChange = async (templateId) => {
    try {
      const url = `${BASE_URL}/v1/template/templates/${templateId}`;
      const response = await sendRequest("GET", url);

      if (response.status === 200) {
        const templateOptions = response.data.data.template_options;

        const templateIds = templateOptions.map((option) => option.id);

        const configUrl = `${BASE_URL}/v1/template/templates/get_template_configs?template_id=${templateId}&template_option_id=${templateIds}`;
        const configResponse = await sendRequest("GET", configUrl);

        if (configResponse.status === 200 && configResponse.data.data.length > 0) {
          const updatedTemplateOptions = templateOptions.map((option) => {
            const matchingConfig = configResponse.data.data.find(
              (config) => config.template_option_id === option.id
            );

            return {
              ...option,
              template_config: matchingConfig || {},
              alignment: (matchingConfig && matchingConfig.alignment) || 'center',
            };
          });

          console.log(updatedTemplateOptions);

          setTemplateOptionConfigData(updatedTemplateOptions);
        } else {
          console.error("No configuration data found.");
          ErrorNotification("Failed to load template configuration.");
        }
      }
    } catch (error) {
      console.error("Error loading template configuration:", error);
      ErrorNotification("Failed to load template configuration");
    }
  };

  const handleOptionsCheckboxChange = (id) => {
    setTemplateOptionConfigData((prevState) =>
      prevState.map((option) =>
        option.id === id
          ? {
            ...option,
            template_config: {
              ...option.template_config,
              is_enable: !option.template_config?.is_enable,
            },
          }
          : option
      )
    );
  };

  const handleOptionSave = async () => {
    const data = {
      template_config_data: templateOptionConfigData.map(option => ({
        template_option_id: option.id,
        free_text: option.template_config?.free_text,
        is_enable: option.template_config?.is_enable,
      })),
    };

    try {
      const response = await sendRequest('PUT', `${BASE_URL}/v1/template/templates/${selectedTemplateId}`, data);
      if (response.status === 200) {
        onClose();
        successNotification(SUCCESS_MESSAGE('updated', 'template'));
      } else {
        ErrorNotification(UPDATE_ERROR_MSG('template'));
      }
    } catch (error) {
      ErrorNotification(UPDATE_ERROR_MSG('template'));
    }
  };

  const enabledTemplateDetails = templateOptionConfigData
    .filter(option => option?.template_config?.is_enable)
    .map(option => ({
      option_name: option?.option_name,
      free_text: option?.template_config?.free_text,
      is_enable: option?.template_config?.is_enable,
    }));

  const handleEditPopup = (option) => {
    setEditedOption(option);
    setIsEditedPopup(true);
  }

  const hadleClosePopup = () => {
    setIsEditedPopup(false);
    setEditedOption(null);
  }

  const handleTextChange = (text) => {
    setEditedOption(prevOption => ({
      ...prevOption,
      template_config: {
        ...prevOption.template_config,
        free_text: text,
      },
    }));
  };

  const handleSavePopup = () => {
    setTemplateOptionConfigData(prevState =>
      prevState.map(option =>
        option.id === editedOption.id
          ? {
            ...option,
            template_config: {
              ...option.template_config,
              ...editedOption.template_config,
            },
          }
          : option
      )
    );
    hadleClosePopup();
  };

  const headerAttributes =
    selectedTemplateId === 10 ||
      selectedTemplateId === 13 ||
      selectedTemplateId === 16 ||
      selectedTemplateId === 19 ||
      selectedTemplateId === 22 ||
      selectedTemplateId === 25
      ? ["date", "time", "invoice_number", "text_title"]
      : [
        "company_logo",
        "company_name",
        "branch_name",
        "address",
        "phone_number",
        "gst_number",
        "email",
        "date",
        "time",
        "invoice_number",
        "text_title",
      ];

  const categorizedOptions = {
    Header: templateOptionConfigData.filter((option) =>
      headerAttributes.includes(option.option_name)
    ),
    Grid: templateOptionConfigData.filter((option) =>
      [
        "cust_company",
        "cust_name",
        "cust_email",
        "cust_phone",
        "cust_address",
        "cust_gst",
        "cust_order_no",
        "cust_order_date",
      ].includes(option.option_name)
    ),
    Footer: templateOptionConfigData.filter((option) =>
      [
        "cust_signature",
        "authorized_signature",
        "terms_conditions",
      ].includes(option.option_name)
    ),
  };

  return (
    <>
      <div className="common-temp-setting-main-container">
        <div className="common-temp-setting-header-grp">
          <span className="temp-setting-head-left-title">Printing Setting</span>
          <span className="temp-setting-head-right-icon" onClick={onClose}>x</span>
        </div>
        <div className="common-temp-setting-sub-container">
          <div className="temp-setting-sub-left-box">
            {/* <div className="temp-setting-first-div">
              <div className="temp-setting-title-text-grp">
                <span>Title Text</span>
                <input type="text" className="form-control title-text-input" />
              </div>
              <div className="setting-temp-header-grp">
                <div className="setting-temp-head-title-grp">
                  <span className="setting-temp-head-title">Header</span>
                </div>
                <div className="setting-temp-header-grp-box">
                  <div className="setting-temp-header-left-grp">
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Logo</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Company Name</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Branch Name</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Address</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Phone Number</label>
                    </div>
                  </div>
                  <div className="setting-temp-header-right-grp">
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Email</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">GST Number</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">date</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Time</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Bill Number</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="setting-temp-grid-grp">
                <div className="setting-grid-title-grp">
                  <span className="setting-grid-title">Grid</span>
                </div>
                <div className="temp-setting-grid-box">
                  <div className="setting-grid-box-left">
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Customer Company</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Company Name</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Customer Email</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Customer Phone</label>
                    </div>
                  </div>
                  <div className="setting-grid-box-right">
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Customer GST</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Customer Address</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Order No.</label>
                    </div>
                    <div className="setting-temp-head-div-grp">
                      <input type="checkbox" className="head-temp-input-field" />
                      <label className="setting-temp-header-label">Order Date</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="setting-temp-footer-grp">
                <div className="setting-temp-footer-title-grp">
                  <span className="setting-temp-footer-title">Footer</span>
                </div>
                <div className="setting-temp-footer-left">
                  <div className="setting-temp-head-div-grp">
                    <input type="checkbox" className="head-temp-input-field" />
                    <label className="setting-temp-header-label">Terms&Conditions</label>
                  </div>
                  <div className="setting-temp-head-div-grp">
                    <input type="checkbox" className="head-temp-input-field" />
                    <label className="setting-temp-header-label">Customer Sign</label>
                  </div>
                  <div className="setting-temp-head-div-grp">
                    <input type="checkbox" className="head-temp-input-field" />
                    <label className="setting-temp-header-label">Authorized Sign</label>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="common-temp-setting-left-header-grp">
              <div>
                <span className="temp-setting-head-title">Printing OPtions</span>
              </div>
              <div className="temp-setting-left-button-grp">
                <button className="btn-btn-primary" onClick={handleOptionSave}>save</button>
              </div>
            </div>
            {/* {templateOptionConfigData.map((option, index) => (
              <div key={option.id} className="checkbox-item">
                <input
                  type="checkbox"
                  // value={option.id}
                  checked={option?.template_config?.is_enable}
                  onChange={() => handleOptionsCheckboxChange(index)}
                />
                <label>
                  {option.option_name.replace(/_/g, " ")}
                </label>
                {option.option_name === 'text_title' && (
                  <FaCog
                    className="settings-icon"
                    onClick={() => handleEditPopup(option)}
                  />
                )}
              </div>
            ))} */}

            <div className="temp-setting-left-box">
              <span className="temp-setting-option-title">Header</span>
              {categorizedOptions.Header.map((option) => (
                <div key={option.id} className="checkbox-item">
                  <input
                    type="checkbox"
                    checked={option?.template_config?.is_enable}
                    onChange={() => handleOptionsCheckboxChange(option.id)}
                    className="temp-cat-input-option"
                  />
                  <label>{option.option_name.replace(/_/g, " ")}</label>
                  {option.option_name === "text_title" && (
                    <FaCog
                      className="settings-icon"
                      onClick={() => handleEditPopup(option)}
                    />
                  )}
                </div>
              ))}
            </div>

            <div className="temp-setting-left-box">
              <span className="temp-setting-option-title">Grid</span>
              {categorizedOptions.Grid.map((option) => (
                <div key={option.id} className="checkbox-item">
                  <input
                    type="checkbox"
                    checked={option?.template_config?.is_enable}
                    onChange={() => handleOptionsCheckboxChange(option.id)}
                    className="temp-cat-input-option"
                  />
                  <label>{option.option_name.replace(/_/g, " ")}</label>
                </div>
              ))}
            </div>

            <div className="temp-setting-left-box">
              <span className="temp-setting-option-title">Footer</span>
              {categorizedOptions.Footer.map((option) => (
                <div key={option.id} className="checkbox-item">
                  <input
                    type="checkbox"
                    checked={option?.template_config?.is_enable}
                    onChange={() => handleOptionsCheckboxChange(option.id)}
                    className="temp-cat-input-option"
                  />
                  <label>{option.option_name.replace(/_/g, " ")}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="temp-setting-sub-right-box">
            {(selectedTemplateId === 9) &&
              <div className="temp-setting-individaul-template-show">
                <QuoteGeneralTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 10) &&
              <div className="temp-setting-individaul-template-show">
                <QuoteLetterPadTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 11) &&
              <div className="temp-setting-individaul-template-show">
                <QuoteThermalTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }

            {(selectedTemplateId === 12) &&
              <div className="temp-setting-individaul-template-show">
                <OrderGeneralTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 13) &&
              <div className="temp-setting-individaul-template-show">
                <OrderLetterPadTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 14) &&
              <div className="temp-setting-individaul-template-show">
                <OrderThermalTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }

            {(selectedTemplateId === 15) &&
              <div className="temp-setting-individaul-template-show">
                <InvoiceGeneralTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 16) &&
              <div className="temp-setting-individaul-template-show">
                <InvoiceLetterPadTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 17) &&
              <div className="temp-setting-individaul-template-show">
                <InvoiceThermalTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }

            {(selectedTemplateId === 18) &&
              <div className="temp-setting-individaul-template-show">
                <DeliveryChallanGeneralTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 19) &&
              <div className="temp-setting-individaul-template-show">
                <DeliveryChallanLetterPadTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 20) &&
              <div className="temp-setting-individaul-template-show">
                <DeliveryChallanThermalTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }

            {(selectedTemplateId === 21) &&
              <div className="temp-setting-individaul-template-show">
                <CreditNoteGeneralTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 22) &&
              <div className="temp-setting-individaul-template-show">
                <CreditNoteLetterPadTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 23) &&
              <div className="temp-setting-individaul-template-show">
                <CreditNoteThermalTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }

            {(selectedTemplateId === 24) &&
              <div className="temp-setting-individaul-template-show">
                <DebitNoteGeneralTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 25) &&
              <div className="temp-setting-individaul-template-show">
                <DebitNoteLetterPadTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 26) &&
              <div className="temp-setting-individaul-template-show">
                <DebitNoteThermalTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }

            {(selectedTemplateId === 27) &&
              <div className="temp-setting-individaul-template-show">
                <ReceiptGeneralTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
            {(selectedTemplateId === 28) &&
              <div className="temp-setting-individaul-template-show">
                <ReceiptThermalTemplate removeTablehead={true} enabledTemplateDetails={enabledTemplateDetails} />
              </div>
            }
          </div>
        </div>
      </div>

      {isEditedPopup && (
        <div className="free-text-popup-container-overlay">
          <FreeTextEditPopup
            optionToEdit={editedOption}
            onClose={hadleClosePopup}
            editedText={handleTextChange}
            onSave={handleSavePopup}
          />
        </div>
      )}
    </>
  );
}

export default CommonTemplateSetting;
