import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./KotDetails.css";
import CommonCloseButton from "../CommonCloseButton/CommonCloseButton";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { useTranslation } from "react-i18next";

const TransferKot = ({ orderId, tableData, closeModal, items, kotNumber, selectedOrderId }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [floors, setFloors] = useState({});
  const { t } = useTranslation();


  useEffect(() => {
    fetchTablesAndFloors();
  }, []);

  const fetchTablesAndFloors = async () => {
    try {
      const status = "running";
      const tableResponse = await sendRequest(
        "GET",
        `${BASE_URL}/v1/floors/floors/floor_table_status?status=${status}`
      );
      const allTables = tableResponse.data.floor_table;
      const filteredTables = allTables.filter(
        (table) => table.id !== tableData.id
      );

      const floorResponse = await sendRequest(
        "GET",
        `${BASE_URL}/v1/floors/floors?all_floors=true`
      );
      const floorsData = floorResponse?.data?.data?.selected_floor;

      const floorMap = floorsData.reduce((acc, floor) => {
        acc[floor.id] = floor.name;
        return acc;
      }, {});

      const groupedByFloor = filteredTables.reduce((acc, table) => {
        const floorName = floorMap[table.floor_id];
        if (!acc[floorName]) {
          acc[floorName] = [];
        }
        acc[floorName].push(table);
        return acc;
      }, {});

      setFloors(groupedByFloor);
    } catch (error) {
      ErrorNotification(error.message);
    }
  };

  const handleItemSelect = (itemId) => {
    setSelectedItems((prev) =>
      prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId]
    );
  };

  const handleTableSelect = (table) => {
    setSelectedTable(table);
    transferOrderItems(table.id);
  };

  const transferOrderItems = async (targetTableId) => {
    try {
      const sourceOrderId = orderId;
      const orderGroups = selectedItems.map((itemId) => {
        const item = items.find((item) => item.id === itemId);
        return { item_id: item.item_id, quantity: item.quantity };
      });

      const data = {
        order: {
          source_order_id: sourceOrderId,
          target_table_id: targetTableId,
          order_groups: orderGroups,
          order_ticket_id: selectedOrderId,
        },
      };

      await sendRequest(
        "PATCH",
        `${BASE_URL}/v1/restaurant/orders/orders/transfer_order_items`,
        data
      );
      closeModal();
      window.location.reload();
    } catch (error) {
      ErrorNotification(error.data.errors);
    }
  };

  return (
    <div className="transfer-container">
      <div className="kot-transfer-item">
        <span className="transfer-kot-title-header">
          {t(" KOT Transfer:")}{" "}
          <span className="transfer-kot-date-time-items">KOT: {kotNumber}</span>
        </span>
        <span></span>
        <span
          className="transfer-kot-close-button"
          onClick={() => {
            closeModal();
            window.location.reload();
          }}
        >
          <CommonCloseButton />
        </span>
        <div></div>
      </div>
      <div className="kot-transfer-table-details-group">
        <div className="tranfer-table-kot-first-face">
          <div className="items-section">
            <table>
              <thead>
                <tr>
                  <th>{t("SL No")}</th>
                  <th>{t("Item Name")}</th>
                  <th>{t("Quantity")}</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item.id)}
                        onChange={() => handleItemSelect(item.id)}
                      />
                    </td>
                    <td>{item.item_name}</td>
                    <td>{item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="show-transfer-table-kot-items">
            <span className="tranfer-table-item-total">{t("Total Items:")} {items.length}</span>
          </div>
        </div>
        <div className="item-tables-section-transfer-kot">
          <div className="tables-section">
            <div className="transfer-select-table-header">
              <span className="transfer-select-table-title">{t("Select Table to Transfer")}</span>
            </div>
            <div className="transfer-item-table-list">
              {Object.keys(floors).map((floor) => (
                <div key={floor} className="large-model-content">
                  <div className="tranfer-table-floor">
                    <span className="floor-name-header-transfer-items">
                      {floor}
                    </span>
                  </div>
                  <div className="scrollable-transfer-item-tables-list">
                    {floors[floor]
                      .filter((table) => table?.order_value?.order_status !== 'pending')
                      .map((table) => (
                        <button
                          id="tranfer-kot-button-style"
                          key={table.id}
                          className={selectedTable === table ? "selected" : ""}
                          onClick={() => handleTableSelect(table)}
                        >
                          {table.name}
                        </button>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MainTransferKot = ({
  modalClosePopup,
  setModalClosePopup,
  selectedOrderItems,
  tableData,
  orderId,
  kotNumber,
  selectedOrderId,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  // const { t, i18n } = useTranslation();

  const closeModalHandler = () => {
    setModalClosePopup(true);
    openModal();
  };

  return (
    <div>
      <button className="button-kot-transfer" onClick={closeModalHandler}>
        Transfer KOT
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Transfer KOT Modal"
        overlayClassName="modal-overlay"
        className="contant-kot-trans"
      >
        <TransferKot
          orderId={orderId}
          tableData={tableData}
          closeModal={closeModal}
          items={selectedOrderItems}
          kotNumber={kotNumber}
          selectedOrderId={selectedOrderId}
        />{" "}
      </Modal>
    </div>
  );
};

export default MainTransferKot;
