import React, { useEffect, useState } from "react";
import "./SaleTemplateSetting.css";
import Copyrights from "../../Copyrights";
import QuoteGeneralTemplate from "../SaleTemplateFormate/QuoteTemplates/QuoteGeneralTemplate";
import QuoteLetterPadTemplate from "../SaleTemplateFormate/QuoteTemplates/QuoteLetterPadTemplate";
import QuoteThermalTemplate from "../SaleTemplateFormate/QuoteTemplates/QuoteThermalTemplate";
import OrderGeneralTemplate from "../SaleTemplateFormate/OrderTemplates/OrderGeneralTemplate";
import OrderLetterPadTemplate from "../SaleTemplateFormate/OrderTemplates/OrderLetterPadTemplate";
import OrderThermalTemplate from "../SaleTemplateFormate/OrderTemplates/OrderThermalTemplate";
import InvoiceGeneralTemplate from "../SaleTemplateFormate/InvoiceTemplates/InvoiceGeneralTemplate";
import InvoiceLetterPadTemplate from "../SaleTemplateFormate/InvoiceTemplates/InvoiceLetterPadTemplate";
import InvoiceThermalTemplate from "../SaleTemplateFormate/InvoiceTemplates/InvoiceThermalTemplate";
import DeliveryChallanGeneralTemplate from "../SaleTemplateFormate/DeliveryChallanTemplates/DeliveryChallanGeneralTemplate";
import DeliveryChallanLetterPadTemplate from "../SaleTemplateFormate/DeliveryChallanTemplates/DeliveryChallanLetterPadTemplate";
import DeliveryChallanThermalTemplate from "../SaleTemplateFormate/DeliveryChallanTemplates/DeliveryChallanThermalTemplate";
import CreditNoteGeneralTemplate from "../SaleTemplateFormate/CreditNoteTemplates/CreditNoteGeneralTemplate";
import CreditNoteLetterPadTemplate from "../SaleTemplateFormate/CreditNoteTemplates/CreditNoteLetterPadTemplate";
import CreditNoteThermalTemplate from "../SaleTemplateFormate/CreditNoteTemplates/CreditNoteThermalTemplate";
import DebitNoteGeneralTemplate from "../SaleTemplateFormate/DebitNoteTemplates/DebitNoteGeneralTemplate";
import DebitNoteLetterPadTemplate from "../SaleTemplateFormate/DebitNoteTemplates/DebitNoteLetterPadTemplate";
import DebitNoteThermalTemplate from "../SaleTemplateFormate/DebitNoteTemplates/DebitNoteThermalTemplate";
import ReceiptGeneralTemplate from "../SaleTemplateFormate/ReceiptTemplates/ReceiptGeneralTemplate";
import ReceiptThermalTemplate from "../SaleTemplateFormate/ReceiptTemplates/ReceiptThermalTemplate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL, NO_RECORD_ERROR } from "../../../Url/constants";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";
import CommonTemplateSetting from "../SaleTemplateFormate/CommonTemplateSettingPopup/CommonTemplateSetting";

function SaleTemplateSetting() {
  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const [selectedQuotationTemplateId, setSelectedQuotationTemplateId] = useState('');
  const [selectedOrderTemplateId, setSelectedOrderTemplateId] = useState('');
  const [selectedInvoiceTemplateId, setSelectedInvoiceTemplateId] = useState('');
  const [selectedDeliveryChallanTemplateId, setSelectedDeliveryChallanTemplateId] = useState('');
  const [selectedCreditNoteTemplateId, setSelectedCreditNoteTemplateId] = useState('');
  const [selectedDebitNoteTemplateId, setSelectedDebitNoteTemplateId] = useState('');
  const [selectedReceiptTemplateId, setSelectedReceiptTemplateId] = useState('');
  const [customSettingPopup, setCustomSettingPopup] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState('');
  const [selectedTemplateDetails, setSelectedTemplateDetails] = useState([]);

  useEffect(() => {
    getSelectedTempDetails();
  }, [])

  useEffect(() => {
    if (selectedTemplate) {
      getTemplateConfig(selectedTemplate.id);
    }
  }, [selectedTemplate]);

  const getTemplateConfig = async (template) => {
    try {
      const url = `${BASE_URL}/v1/template/templates/${template}`;
      const response = await sendRequest('get', url);
      setSelectedTemplateDetails(response?.data?.data?.template_options);
      console.log(response.data.data);
    } catch (error) {
      ErrorNotification(NO_RECORD_ERROR('template'));
    }
  };

  const templateDetails = selectedTemplateDetails
    .filter(option => option?.template_config?.is_enable)
    .map(option => ({
      option_name: option?.option_name,
      free_text: option?.template_config?.free_text,
      is_enable: option?.template_config?.is_enable,
    }));

  const getSelectedTempDetails = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/template/templates/selected_default_template_details`
      );
      if (response?.data?.seleted_templates) {
        response?.data?.seleted_templates.forEach((template) => {
          if (template.hasOwnProperty("quotation")) {
            setSelectedQuotationTemplateId(template.quotation || null);
          } else if (template.hasOwnProperty("order")) {
            setSelectedOrderTemplateId(template.order || null);
          } else if (template.hasOwnProperty("invoice")) {
            setSelectedInvoiceTemplateId(template.invoice || null);
          } else if (template.hasOwnProperty("deliverychallan")) {
            setSelectedDeliveryChallanTemplateId(template.deliverychallan || null);
          } else if (template.hasOwnProperty("creditnote")) {
            setSelectedCreditNoteTemplateId(template.creditnote || null);
          } else if (template.hasOwnProperty("debitnote")) {
            setSelectedDebitNoteTemplateId(template.debitnote || null);
          } else if (template.hasOwnProperty("receipt")) {
            setSelectedReceiptTemplateId(template.receipt || null);
          }
        });
      } else {
        console.log("No selected templates found.");
      }
    } catch (error) {
      console.error("Error fetching template details:", error);
      ErrorNotification("An error occurred while fetching template details");
    }
  };

  const handleSelectClick = async (id, type) => {
    let params = {};
    if (type === "quotation") {
      setSelectedQuotationTemplateId(id);
      params = {
        quotation_template_id: id,
      };
    } else if (type === "order") {
      setSelectedOrderTemplateId(id);
      params = {
        order_template_id: id,
      };
    } else if (type === "invoice") {
      setSelectedInvoiceTemplateId(id);
      params = {
        invoice_template_id: id,
      };
    } else if (type === "deliverychallan") {
      setSelectedDeliveryChallanTemplateId(id);
      params = {
        delivery_challan_template_id: id,
      };
    } else if (type === "creditnote") {
      setSelectedCreditNoteTemplateId(id);
      params = {
        creditnote_template_id: id,
      };
    } else if (type === "debitnote") {
      setSelectedDebitNoteTemplateId(id);
      params = {
        debitnote_template_id: id,
      };
    } else if (type === "receipt") {
      setSelectedReceiptTemplateId(id);
      params = {
        receipt_template_id: id,
      };
    }
    try {
      const response = await sendRequest(
        "PUT",
        `${BASE_URL}/v1/template/templates/set_default_template`,
        params
      );
      if (response.status === 200) {
        successNotification("Template set successfully");
      } else {
        ErrorNotification("Failed to set template");
      }
    } catch (error) {
      console.error("Error setting template:", error);
      ErrorNotification("An error occurred while setting the template");
    }
  };

  const handleImageClick = (template) => {
    setSelectedTemplate(template);
  };

  const closeSaleTempBox = () => {
    setSelectedTemplate(false);
  }

  const QuoteTemplates = [
    { id: 9, name: "QuoteTemp1", image: "/QGT.png" },
    { id: 10, name: "QuoteTemp2", image: "/QLPT.png" },
    { id: 11, name: "QuoteTemp3", image: "/thermalprint.png" },
  ];

  const SaleOrderTemplates = [
    { id: 12, name: "OrderTemp1", image: "/SOGT.png" },
    { id: 13, name: "OrderTemp2", image: "/SOLPT.png" },
    { id: 14, name: "OrderTemp3", image: "/thermalprint.png" },
  ];

  const SaleInvoiceTemplates = [
    { id: 15, name: "InvoiceTemp1", image: "/SIGT.png" },
    { id: 16, name: "InvoiceTemp2", image: "/SILPT.png" },
    { id: 17, name: "InvoiceTemp3", image: "/thermalprint.png" },
  ];

  const DeliveryChallanTemplates = [
    { id: 18, name: "DeliveryChallanTemp1", image: "/SDCGT.png" },
    { id: 19, name: "DeliveryChallanTemp2", image: "SDCLPT.png" },
    { id: 20, name: "DeliveryChallanTemp3", image: "/thermalprint.png" },
  ];

  const CreditNoteTemplates = [
    { id: 21, name: "CreditNoteTemp1", image: "/SCNGT.png" },
    { id: 22, name: "CreditNoteTemp2", image: "/SCNLPT.png" },
    { id: 23, name: "CreditNoteTemp3", image: "/thermalprint.png" },
  ];

  const DebitNoteTemplates = [
    { id: 24, name: "DebitNoteTemp1", image: "/SDNGT.png" },
    { id: 25, name: "DebitNoteTemp2", image: "/SDNLPT.png" },
    { id: 26, name: "DebitNoteTemp3", image: "/thermalprint.png" },
  ];

  const ReceiptTemplates = [
    { id: 27, name: "ReceiptTemp1", image: "/SRGT.png" },
    { id: 28, name: "ReceiptTemp2", image: "/thermalprint.png" },
  ];

  const handleCustomizeSetting = (templateType) => {
    setCustomSettingPopup(true);
    setSelectedTemplateType(templateType);
  }

  const closeCommonTempSetting = () => {
    setCustomSettingPopup(false);
  }

  return (
    <>
      <div className="sale-temp-setting-main-container">
        <div className="sale-quote-temp-group-container">
          <div className="sale-quote-temp-left-part">
            <span>Quote Templates</span>
            <button onClick={() => handleCustomizeSetting("quotation")}>Customize Settings</button>
          </div>
          <div className="sale-quote-temp-right-part">
            <div className="templates-grid">
              {QuoteTemplates.map((QuoteTemplate) => (
                <div>
                  <div className="template-card" key={QuoteTemplate?.id}>
                    <img
                      src={QuoteTemplate.image}
                      alt={QuoteTemplate.name}
                      onClick={() => handleImageClick(QuoteTemplate)}
                      className={
                        selectedQuotationTemplateId === QuoteTemplate.id ? "selected" : ""
                      }
                    />
                    {selectedQuotationTemplateId === QuoteTemplate.id ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="sale-temp-check-icon"
                      />
                    ) : (
                      <button
                        className="select-button"
                        onClick={() => handleSelectClick(QuoteTemplate.id, "quotation")}
                      >
                        Select
                      </button>
                    )}
                  </div>
                  <span className="template-name-title">{QuoteTemplate?.id === 9 && "General"}</span>
                  <span className="template-name-title">{QuoteTemplate?.id === 10 && "LetterPad"}</span>
                  <span className="template-name-title">{QuoteTemplate?.id === 11 && "Thermal"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="sale-quote-temp-group-container">
          <div className="sale-quote-temp-left-part">
            <span>SaleOrder Templates</span>
            <button onClick={() => handleCustomizeSetting("order")}>Customize Settings</button>
          </div>
          <div className="sale-quote-temp-right-part">
            <div className="templates-grid">
              {SaleOrderTemplates.map((SaleOrderTemplate) => (
                <div>
                  <div className="template-card" key={SaleOrderTemplate?.id}>
                    <img
                      src={SaleOrderTemplate.image}
                      alt={SaleOrderTemplate.name}
                      onClick={() => handleImageClick(SaleOrderTemplate)}
                      className={
                        selectedOrderTemplateId === SaleOrderTemplate.id ? "selected" : ""
                      }
                    />
                    {selectedOrderTemplateId === SaleOrderTemplate.id ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="sale-temp-check-icon"
                      />
                    ) : (
                      <button
                        className="select-button"
                        onClick={() => handleSelectClick(SaleOrderTemplate.id, "order")}
                      >
                        Select
                      </button>
                    )}
                  </div>
                  <span className="template-name-title">{SaleOrderTemplate?.id === 12 && "General"}</span>
                  <span className="template-name-title">{SaleOrderTemplate?.id === 13 && "LetterPad"}</span>
                  <span className="template-name-title">{SaleOrderTemplate?.id === 14 && "Thermal"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="sale-quote-temp-group-container">
          <div className="sale-quote-temp-left-part">
            <span>SaleInvoice Templates</span>
            <button onClick={() => handleCustomizeSetting("invoice")}>Customize Settings</button>
          </div>
          <div className="sale-quote-temp-right-part">
            <div className="templates-grid">
              {SaleInvoiceTemplates.map((SaleInvoiceTemplate) => (
                <div>
                  <div className="template-card" key={SaleInvoiceTemplate?.id}>
                    <img
                      src={SaleInvoiceTemplate.image}
                      alt={SaleInvoiceTemplate.name}
                      onClick={() => handleImageClick(SaleInvoiceTemplate)}
                      className={
                        selectedInvoiceTemplateId === SaleInvoiceTemplate.id ? "selected" : ""
                      }
                    />
                    {selectedInvoiceTemplateId === SaleInvoiceTemplate.id ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="sale-temp-check-icon"
                      />
                    ) : (
                      <button
                        className="select-button"
                        onClick={() => handleSelectClick(SaleInvoiceTemplate.id, "invoice")}
                      >
                        Select
                      </button>
                    )}
                  </div>
                  <span className="template-name-title">{SaleInvoiceTemplate?.id === 15 && "General"}</span>
                  <span className="template-name-title">{SaleInvoiceTemplate?.id === 16 && "LetterPad"}</span>
                  <span className="template-name-title">{SaleInvoiceTemplate?.id === 17 && "Thermal"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="sale-quote-temp-group-container">
          <div className="sale-quote-temp-left-part">
            <span>Delivery Challan Templates</span>
            <button onClick={() => handleCustomizeSetting("deliverychallan")}>Customize Settings</button>
          </div>
          <div className="sale-quote-temp-right-part">
            <div className="templates-grid">
              {DeliveryChallanTemplates.map((DeliveryChallanTemplate) => (
                <div>
                  <div className="template-card" key={DeliveryChallanTemplate?.id}>
                    <img
                      src={DeliveryChallanTemplate.image}
                      alt={DeliveryChallanTemplate.name}
                      onClick={() => handleImageClick(DeliveryChallanTemplate)}
                      className={
                        selectedDeliveryChallanTemplateId === DeliveryChallanTemplate.id ? "selected" : ""
                      }
                    />
                    {selectedDeliveryChallanTemplateId === DeliveryChallanTemplate.id ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="sale-temp-check-icon"
                      />
                    ) : (
                      <button
                        className="select-button"
                        onClick={() => handleSelectClick(DeliveryChallanTemplate.id, "deliverychallan")}
                      >
                        Select
                      </button>
                    )}
                  </div>
                  <span className="template-name-title">{DeliveryChallanTemplate?.id === 18 && "General"}</span>
                  <span className="template-name-title">{DeliveryChallanTemplate?.id === 19 && "LetterPad"}</span>
                  <span className="template-name-title">{DeliveryChallanTemplate?.id === 20 && "Thermal"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="sale-quote-temp-group-container">
          <div className="sale-quote-temp-left-part">
            <span>CreditNote Templates</span>
            <button onClick={() => handleCustomizeSetting("creditnote")}>Customize Settings</button>
          </div>
          <div className="sale-quote-temp-right-part">
            <div className="templates-grid">
              {CreditNoteTemplates.map((CreditNoteTemplate) => (
                <div>
                  <div className="template-card" key={CreditNoteTemplate?.id}>
                    <img
                      src={CreditNoteTemplate.image}
                      alt={CreditNoteTemplate.name}
                      onClick={() => handleImageClick(CreditNoteTemplate)}
                      className={
                        selectedCreditNoteTemplateId === CreditNoteTemplate.id ? "selected" : ""
                      }
                    />
                    {selectedCreditNoteTemplateId === CreditNoteTemplate.id ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="sale-temp-check-icon"
                      />
                    ) : (
                      <button
                        className="select-button"
                        onClick={() => handleSelectClick(CreditNoteTemplate.id, "creditnote")}
                      >
                        Select
                      </button>
                    )}
                  </div>
                  <span className="template-name-title">{CreditNoteTemplate?.id === 21 && "General"}</span>
                  <span className="template-name-title">{CreditNoteTemplate?.id === 22 && "LetterPad"}</span>
                  <span className="template-name-title">{CreditNoteTemplate?.id === 23 && "Thermal"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="sale-quote-temp-group-container">
          <div className="sale-quote-temp-left-part">
            <span>DebitNote Templates</span>
            <button onClick={() => handleCustomizeSetting("debitnote")}>Customize Settings</button>
          </div>
          <div className="sale-quote-temp-right-part">
            <div className="templates-grid">
              {DebitNoteTemplates.map((DebitNoteTemplate) => (
                <div>
                  <div className="template-card" key={DebitNoteTemplate?.id}>
                    <img
                      src={DebitNoteTemplate.image}
                      alt={DebitNoteTemplate.name}
                      onClick={() => handleImageClick(DebitNoteTemplate)}
                      className={
                        selectedDebitNoteTemplateId === DebitNoteTemplate.id ? "selected" : ""
                      }
                    />
                    {selectedDebitNoteTemplateId === DebitNoteTemplate.id ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="sale-temp-check-icon"
                      />
                    ) : (
                      <button
                        className="select-button"
                        onClick={() => handleSelectClick(DebitNoteTemplate.id, "debitnote")}
                      >
                        Select
                      </button>
                    )}
                  </div>
                  <span className="template-name-title">{DebitNoteTemplate?.id === 24 && "General"}</span>
                  <span className="template-name-title">{DebitNoteTemplate?.id === 25 && "LetterPad"}</span>
                  <span className="template-name-title">{DebitNoteTemplate?.id === 26 && "Thermal"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="sale-quote-temp-group-container">
          <div className="sale-quote-temp-left-part">
            <span>Receipt Templates</span>
            <button onClick={() => handleCustomizeSetting("receipt")}>Customize Settings</button>
          </div>
          <div className="sale-quote-temp-right-part">
            <div className="templates-grid">
              {ReceiptTemplates.map((ReceiptTemplate) => (
                <div>
                  <div className="template-card" key={ReceiptTemplate?.id}>
                    <img
                      src={ReceiptTemplate.image}
                      alt={ReceiptTemplate.name}
                      onClick={() => handleImageClick(ReceiptTemplate)}
                      className={
                        selectedReceiptTemplateId === ReceiptTemplate.id ? "selected" : ""
                      }
                    />
                    {selectedReceiptTemplateId === ReceiptTemplate.id ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="sale-temp-check-icon"
                      />
                    ) : (
                      <button
                        className="select-button"
                        onClick={() => handleSelectClick(ReceiptTemplate.id, "receipt")}
                      >
                        Select
                      </button>
                    )}
                  </div>
                  <span className="template-name-title">{ReceiptTemplate?.id === 27 && "General"}</span>
                  <span className="template-name-title">{ReceiptTemplate?.id === 28 && "Thermal"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {selectedTemplate && selectedTemplate.id === 9 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <QuoteGeneralTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 10 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <QuoteLetterPadTemplate onClose={closeSaleTempBox}  enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 11 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <QuoteThermalTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 12 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <OrderGeneralTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 13 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <OrderLetterPadTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 14 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <OrderThermalTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 15 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <InvoiceGeneralTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 16 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <InvoiceLetterPadTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 17 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <InvoiceThermalTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 18 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <DeliveryChallanGeneralTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 19 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <DeliveryChallanLetterPadTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 20 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <DeliveryChallanThermalTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 21 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <CreditNoteGeneralTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 22 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <CreditNoteLetterPadTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 23 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <CreditNoteThermalTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 24 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <DebitNoteGeneralTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 25 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <DebitNoteLetterPadTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 26 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <DebitNoteThermalTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 27 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <ReceiptGeneralTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {selectedTemplate && selectedTemplate.id === 28 && (
        <div className="template-box-popup-container">
          <div className="template-box-sub-popup-container">
            <ReceiptThermalTemplate onClose={closeSaleTempBox} enabledTemplateDetails={templateDetails} />
          </div>
        </div>
      )}

      {customSettingPopup && (
        <div className="template-box-popup-container">
          <CommonTemplateSetting onClose={closeCommonTempSetting} selectedTemplateType={selectedTemplateType}/>
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default SaleTemplateSetting;
