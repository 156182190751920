import React, { useState, useEffect } from "react";
import "../../components/Restaurant/RestaurantHeader.css";
import Modal from "react-modal";
import MergeTable from "./mergeTable/MergeTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsLeftRightToLine, faRightLeft } from "@fortawesome/free-solid-svg-icons";
import TransferTable from "./TransferTable";
import { useNavigate } from "react-router-dom";
import BackButton from "../../BackButton";
import { useTranslation } from "react-i18next";
import { current_user } from "../../Authentication/AuthenticationContext";
import ErrorNotification from "../../Notification/ErrorNotification";

const RestaurantHeader = ({ floors, selectedFloor, onTableFilter, onMergeSuccess, onTransferSuccess }) => {
  const [allTables, setAllTables] = useState([]);
  const [user, setUser] = useState(null);
  const [availableTables, setAvailableTables] = useState([]);
  const [runningTables, setRunningTables] = useState([]);
  const [reservedTables, setReservedTables] = useState([]);
  const [activeFilter, setActiveFilter] = useState("allTables");
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const navigate = useNavigate();
  const { t} = useTranslation();

  useEffect(() => {
    if (selectedFloor) {
      fetchTables();
    }
  }, [selectedFloor]);

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setUser(currentUser);
    };
    fetchUser();
  }, []);

  const handleTransferSuccess = (destinationFloorId) => {
    onTransferSuccess(destinationFloorId);
  };

  const handleMergeSuccess = (targetFloorId) => {
    onMergeSuccess(targetFloorId);
  };

  const fetchTables = () => {
    // const allTables = floors.flatMap((floor) => floor.tables);
    const allTables = selectedFloor?.tables;
    const availableTables = allTables.filter((table) => table.status === "available");
    const runningTables = allTables.filter((table) => table.status === "running");
    const reservedTables = allTables.filter((table) => table.status === "reserved");
    setAllTables(allTables);
    setAvailableTables(availableTables);
    setRunningTables(runningTables);
    setReservedTables(reservedTables);
  };

  const hasFeature = (featureName) => {
    return user && user.features?.some(feature => feature?.name === featureName && feature?.enable);
  };

  const handleTableFilter = (filter) => {
    setActiveFilter(filter);
    onTableFilter(filter);
  };

  const openTransferModal = () => {
    setIsTransferModalOpen(true);
  };

  const closeTransferModal = () => {
    setIsTransferModalOpen(false);
  };

  const openMergeModal = () => {
    setIsMergeModalOpen(true);
  };

  const closeMergeModal = () => {
    setIsMergeModalOpen(false);
  };

  const handleHomeDelivery = () => {
    navigate("/delivery-order");
  }

  const customStylesMerge = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '70%',
      height: '70%',
      border: 'none',
      padding: '0px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '70%',
      height: '70%%',
      border: 'none',
      padding: '0px',
      overflow: 'none'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
  }

  const handleTakeAwayOrder = () => {
    const orderType = 'take_away' 
    navigate('/kot-create', { state: { orderType } });
  }

  const handleKotTransaction = () => {
    navigate('/kot-transaction');
  }

  return (
    <div className="restaurant-header">
  
      <div className="header-container">
        <div className="table-buttons">
        {/* <BackButton /> */}
          <button className="restaurant-main-header-buttons">{t("Floors")}</button>
          {hasFeature('Cafe') ? (
              <button className="restaurant-main-header-buttons" onClick={handleTakeAwayOrder}>{t("Self Service")}</button>

          ): (
            <button className="restaurant-main-header-buttons" onClick={handleTakeAwayOrder}>{t("Take Away/Parcel")}</button>
          )}
          <button className="restaurant-main-header-buttons" onClick={handleHomeDelivery}>{t("Home Delivery")}</button>
          <button className="restaurant-main-header-buttons" onClick={handleKotTransaction}>{t("KOT Transaction")}</button>
        </div>
        <div className="table-buttons">
          <div className={`table1 ${activeFilter === "allTables" ? "active" : ""}`}>
            <button
              className={`table-button ${activeFilter === "allTables" ? "active" : ""}`}
              onClick={() => handleTableFilter("allTables")}
            >
              {t("All Tables")} ({allTables.length})
            </button>
          </div>
          <div className={`table2 ${activeFilter === "openTables" ? "active" : ""}`}>
            <button
              className={`table-button ${activeFilter === "openTables" ? "active" : ""}`}
              onClick={() => handleTableFilter("openTables")}
            >
              {t("Available Tables")} ({availableTables.length})
            </button>
          </div>
          <div className={`table3 ${activeFilter === "bookedTables" ? "active" : ""}`}>
            <button
              className={`table-button ${activeFilter === "bookedTables" ? "active" : ""}`}
              onClick={() => handleTableFilter("bookedTables")}
            >
              {t("Running Tables")} ({runningTables.length})
            </button>
          </div>
          <div className={`table4 ${activeFilter === "reservedTables" ? "active" : ""}`}>
            <button
              className={`table-button ${activeFilter === "reservedTables" ? "active" : ""}`}
              onClick={() => handleTableFilter("reservedTables")}
            >
              {t("Reserved Tables")} ({reservedTables.length})
            </button>
          </div>

          <button className="restaurant-main-header-buttons" onClick={openTransferModal}>
            <FontAwesomeIcon icon={faRightLeft} />{t("Transfer Table")}
          </button>
          <button className="restaurant-main-header-buttons" onClick={openMergeModal}>
            <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
            {t("Merge Table")}
          </button>
        </div>
      </div>

      <Modal
        isOpen={isTransferModalOpen}
        onRequestClose={closeTransferModal}
        contentLabel="Transfer Table"
        ariaHideApp={false}
        style={customStylesMerge}
      >
        <TransferTable 
          onClose={closeTransferModal} 
          onTransferSuccess={handleTransferSuccess}
        />
      </Modal>

      <Modal
        isOpen={isMergeModalOpen}
        onRequestClose={closeMergeModal}
        contentLabel="Merge Table"
        ariaHideApp={false}
        style={customStyles}
      >
        <MergeTable onClose={closeMergeModal} onMergeSuccess={handleMergeSuccess} />
      </Modal>
    </div>
  );
};

export default RestaurantHeader;
