import React, { useEffect, useState } from 'react';
import './OrderGeneralTemplate.css'
import { current_user } from '../../../../Authentication/AuthenticationContext';

function OrderGeneralTemplate({ onClose, orderPrint, removeTablehead, enabledTemplateDetails }) {
  const [isCompanydata, setCompanyData] = useState("");
  const [isBranchData, setBranchData] = useState("");
  const [isUserData, setUserData] = useState("");

  const renderOrderGenTable = () => {
    return (
      <div>
        {orderPrint?.bill_items && orderPrint.bill_items.length > 0 ? (
          <>
            <table className="order-gen-temp-table">
              <thead>
                <tr className='order-gen-temp-tab-head-row'>
                  <th className='order-gen-temp-table-header-attribute'>SNo</th>
                  <th className='order-gen-temp-table-header-attribute'>Item</th>
                  <th className='order-gen-temp-table-header-attribute'>HSN</th>
                  <th className='order-gen-temp-table-header-attribute'>Qty</th>
                  <th className='order-gen-temp-table-header-attribute'>Unit</th>
                  <th className='order-gen-temp-table-header-attribute'>Rate</th>
                  <th className='order-gen-temp-table-header-attribute'>Tax</th>
                  <th className='order-gen-temp-table-header-attribute'>Amount</th>
                </tr>
              </thead>
              <tbody>
                {orderPrint?.bill_items && orderPrint?.bill_items.map((item, index) => (
                  <tr key={index} className='order-gen-temp-table-body-row'>
                    <td>{index + 1}</td>
                    <td className='order-gen-temp-table-body-data'>{item?.name}</td>
                    <td className='order-gen-temp-table-body-data'>
                      {item.hsn_number}
                    </td>
                    <td className='order-gen-temp-table-body-data'>{item?.quantity}</td>
                    <td className='order-gen-temp-table-body-data'>{item?.unit?.unit_name}</td>
                    <td className='order-gen-temp-table-body-data'>{item?.sales_price.toFixed(2)}</td>
                    <td className='order-gen-temp-table-body-data'>
                      <div className='order-gen-temp-tax-details'>
                        {item?.tax_details?.length > 0
                          ? `${item.tax_details.join(", ")} (${item?.tax_inclusive ? "Inc" : "Exc"})`
                          : ""}
                      </div>
                      {item?.item_total_tax.toFixed(2)}
                    </td>
                    <td className='order-gen-temp-table-body-data'>{(item?.quantity * item?.sales_price).toFixed(2)}</td>
                  </tr>
                ))}
                <tr className='order-gen-temp-calculation-row'>
                  <td colSpan={6} className='order-gen-temp-extra-row'>
                    <span className='order-gen-temp-extra-row-data'>Sub Total</span>
                  </td>
                  <td></td>
                  <td>{orderPrint?.sub_total?.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
            <table className="order-gen-temp-footer-table">
              <tbody>
                <tr className='order-gen-temp-table-footer-div'>
                  <td colSpan={3} rowSpan={5} className='order-gen-temp-table-footer-border'>
                    <div className="order-gen-temp-footer-data">
                      <div className="order-gen-temp-footer-data-row">
                        <span className="order-gen-temp-footer-label">Sub Total:</span>
                        <span className="order-gen-temp-footer-value">{orderPrint?.sub_total?.toFixed(2)}</span>
                      </div>
                      <div className="order-gen-temp-footer-data-row">
                        <span className="order-gen-temp-footer-label">Discount:</span>
                        <span className="order-gen-temp-footer-value">{orderPrint?.total_discount?.toFixed(2) || '0.00'}</span>
                      </div>
                      <div className="order-gen-temp-footer-data-row">
                        <span className="order-gen-temp-footer-label">Parcel Charges:</span>
                        <span className="order-gen-temp-footer-value">{orderPrint?.parcel_charges?.toFixed(2) || '0.00'}</span>
                      </div>
                      {orderPrint?.is_gst_enable && orderPrint?.is_igst_enable ? (
                        <div className="order-gen-temp-footer-data-row">
                          <span className="order-gen-temp-footer-label">IGST:</span>
                          <span className="order-gen-temp-footer-value">{orderPrint?.total_gst?.toFixed(2) || '0.00'}</span>
                        </div>
                      ) : (
                        <>
                          <div className="order-gen-temp-footer-data-row">
                            <span className="order-gen-temp-footer-label">SGST:</span>
                            <span className="order-gen-temp-footer-value">{(orderPrint?.total_gst / 2).toFixed(2) || '0.00'}</span>
                          </div>
                          <div className="order-gen-temp-footer-data-row">
                            <span className="order-gen-temp-footer-label">CGST:</span>
                            <span className="order-gen-temp-footer-value">{(orderPrint?.total_gst / 2).toFixed(2) || '0.00'}</span>
                          </div>
                        </>
                      )}
                      <div className="order-gen-temp-footer-data-row">
                        <span className="order-gen-temp-footer-label">TOTAL:</span>
                        <span className="order-gen-temp-footer-value">{orderPrint?.total_amount?.toFixed(2) || '0.00'}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <>
            <table className="order-gen-temp-table">
              <thead>
                <tr className='order-gen-temp-tab-head-row'>
                  <th className='order-gen-temp-table-header-attribute'>SNo</th>
                  <th className='order-gen-temp-table-header-attribute'>Item</th>
                  <th className='order-gen-temp-table-header-attribute'>HSN</th>
                  <th className='order-gen-temp-table-header-attribute'>Qty</th>
                  <th className='order-gen-temp-table-header-attribute'>Unit</th>
                  <th className='order-gen-temp-table-header-attribute'>Rate</th>
                  <th className='order-gen-temp-table-header-attribute'>Tax</th>
                  <th className='order-gen-temp-table-header-attribute'>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr className='order-gen-temp-table-body-row'>
                  <td>1</td>
                  <td className='order-gen-temp-table-body-data'>item1</td>
                  <td className='order-gen-temp-table-body-data'>
                    12345
                  </td>
                  <td className='order-gen-temp-table-body-data'>1</td>
                  <td className='order-gen-temp-table-body-data'>piece</td>
                  <td className='order-gen-temp-table-body-data'>2000</td>
                  <td className='order-gen-temp-table-body-data'>
                    <div className='order-gen-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='order-gen-temp-table-body-data'>2000</td>
                </tr>
                <tr className='order-gen-temp-table-body-row'>
                  <td>2</td>
                  <td className='order-gen-temp-table-body-data'>item2</td>
                  <td className='order-gen-temp-table-body-data'>
                    12345
                  </td>
                  <td className='order-gen-temp-table-body-data'>1</td>
                  <td className='order-gen-temp-table-body-data'>piece</td>
                  <td className='order-gen-temp-table-body-data'>2000</td>
                  <td className='order-gen-temp-table-body-data'>
                    <div className='order-gen-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='order-gen-temp-table-body-data'>2000</td>
                </tr>
                <tr className='order-gen-temp-table-body-row'>
                  <td>3</td>
                  <td className='order-gen-temp-table-body-data'>item3</td>
                  <td className='order-gen-temp-table-body-data'>
                    12345
                  </td>
                  <td className='order-gen-temp-table-body-data'>1</td>
                  <td className='order-gen-temp-table-body-data'>piece</td>
                  <td className='order-gen-temp-table-body-data'>2000</td>
                  <td className='order-gen-temp-table-body-data'>
                    <div className='order-gen-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='order-gen-temp-table-body-data'>2000</td>
                </tr>
                <tr className='order-gen-temp-calculation-row'>
                  <td colSpan={6} className='order-gen-temp-extra-row'>
                    <span className='order-gen-temp-extra-row-data'>Sub Total</span>
                  </td>
                  <td></td>
                  <td>6000</td>
                </tr>
              </tbody>
            </table>
            <table className="order-gen-temp-footer-table">
              <tbody>
                <tr className='order-gen-temp-table-footer-div'>
                  <td colSpan={3} rowSpan={5} className='order-gen-temp-table-footer-border'>
                    <div className="order-gen-temp-footer-data">
                      <div className="order-gen-temp-footer-data-row">
                        <span className="order-gen-temp-footer-label">Sub Total:</span>
                        <span className="order-gen-temp-footer-value">6000</span>
                      </div>
                      <div className="order-gen-temp-footer-data-row">
                        <span className="order-gen-temp-footer-label">Discount:</span>
                        <span className="order-gen-temp-footer-value">0.0</span>
                      </div>
                      <div className="order-gen-temp-footer-data-row">
                        <span className="order-gen-temp-footer-label">Parcel Charges:</span>
                        <span className="order-gen-temp-footer-value">0.0</span>
                      </div>
                      <div className="order-gen-temp-footer-data-row">
                        <span className="order-gen-temp-footer-label">TOTAL:</span>
                        <span className="order-gen-temp-footer-value">6000</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setCompanyData(currentUser.company);
      setBranchData(currentUser.company.branch_info);
      setUserData(currentUser.company.user_info);
    };
    fetchUser();
  }, []);

  const getOption = (optionName) => {
    return enabledTemplateDetails?.find((opt) => opt?.option_name === optionName) || {};
  };

  const currentTime = new Date().toLocaleTimeString();

  return (
    <>
      <div className="order-gen-main-container">
        {!removeTablehead && (
          <div className="order-gen-header-div">
            <span className='order-gen-header-left-title'>Order Template</span>
            <span className='order-gen-header-right-title' onClick={onClose}>x</span>
          </div>
        )}
        <div className="order-gen-sub-container">
          <div className="order-gen-subcontainer-header">
            {getOption("company_logo")?.is_enable && (
              <div className="order-gen-head-first-div">
                <img src={isCompanydata?.logo_url} alt="Company Logo" className="order-gen-temp-company-logo" />
              </div>
            )}
            <div className="order-gen-head-second-div">
              {getOption("company_name")?.is_enable && (
                <span className='order-gen-head-com-title'>{getOption("company_name")?.free_text ? getOption("company_name")?.free_text : isCompanydata?.name}</span>
              )}
              {getOption("branch_name")?.is_enable && (
                <span className='order-gen-head-branch-title'>{getOption("branch_name")?.free_text ? getOption("branch_name")?.free_text : isBranchData?.branch_name}</span>
              )}
              {getOption("address")?.is_enable && (
                <span className='order-gen-head-other-titles'>{getOption("address")?.free_text ? getOption("address")?.free_text : isBranchData?.address}</span>
              )}
              {getOption("phone_number")?.is_enable && (
                <span className='order-gen-head-other-titles'>{getOption("phone_number")?.free_text ? getOption("phone_number")?.free_text : isUserData?.phone}</span>
              )}
              {getOption("email")?.is_enable && (
                <span className='order-gen-head-other-titles'>{getOption("email")?.free_text ? getOption("email")?.free_text : isUserData?.email}</span>
              )}
              {getOption("gst_number")?.is_enable && (
                <span className='order-gen-head-other-titles'>{getOption("gst_number")?.free_text ? getOption("gst_number")?.free_text : isCompanydata?.gst_number}</span>
              )}
            </div>
            <div className="order-gen-head-third-div">
              {getOption("text_title")?.is_enable && (
                <div className='order-gen-head-third-title-grp'>
                  <span className='order-gen-head-third-title'>{getOption("text_title")?.free_text ? getOption("text_title")?.free_text : "Sale Order"}</span>
                </div>
              )}
              <div className='order-gen-head-third-sub-title-grp'>
                {getOption("invoice_number")?.is_enable && (
                  <span className='order-gen-head-third-sub-title'>q_text : {orderPrint?.bill_number}</span>
                )}
                {getOption("date")?.is_enable && (
                  <span className='order-gen-head-third-sub-title'>date: {orderPrint?.date
                    ? new Date(orderPrint?.date).toLocaleDateString('en-GB')
                    : ''}
                  </span>
                )}
                {getOption("time")?.is_enable && (
                  <span className='order-gen-head-third-sub-title'>Time : {currentTime}</span>
                )}
              </div>
            </div>
          </div>
          <div className='order-gen-temp-horizontal-line'></div>
          <div className="order-general-cust-info-grp">
            <div className='order-gen-cust-info-div'>
              <span className='order-gen-cust-title'>
                Issued to
              </span>
              {getOption("cust_name")?.is_enable && (
                <span className='order-gen-cust-sub-titles'>
                  Customer Name: {orderPrint?.customer?.customer_name}
                </span>
              )}
              {getOption("cust_gst")?.is_enable && (
                <span className='order-gen-cust-sub-titles'>
                  GSTIN : {orderPrint?.customer?.gst_number}
                </span>
              )}
              {/* <span className='order-gen-cust-sub-titles'>
                POS : Tamil Nadu
              </span> */}
            </div>
            <div className='order-gen-cust-info-div'>
              <span className='order-gen-cust-title'>
                Billing Address :
              </span>
              {getOption("cust_company")?.is_enable && (
                <span className='order-gen-cust-sub-titles'>
                  {orderPrint?.customer?.company_name}
                </span>
              )}
              {getOption("cust_address")?.is_enable && (
                <span className='order-gen-cust-sub-titles'>
                  {orderPrint?.customer?.billing_address_one
                    ||
                    orderPrint?.customer?.billing_address_two}
                </span>
              )}
              {getOption("cust_phone")?.is_enable && (
                <span className='order-gen-cust-sub-titles'>
                  Phone : {orderPrint?.customer?.mobile_number}
                </span>
              )}
              {getOption("cust_email")?.is_enable && (
                <span className='order-gen-cust-sub-titles'>
                  Email : {orderPrint?.customer?.email}
                </span>
              )}
            </div>
          </div>
          <div className='order-gen-temp-horizontal-line'></div>
          <div className="order-gen-order-info-grp">
            {getOption("cust_order_no")?.is_enable && (
              <div className="order-gen-order-info">
                <span className='order-gen-order-no-title'>Buyer's Order No#:</span>
                <span className='order-gen-order-value'>{orderPrint?.order_no}</span>
              </div>
            )}
            {getOption("cust_order_date")?.is_enable && (
              <div className="order-gen-order-info">
                <span className='order-gen-order-date-title'>Buyer's Order Date:</span>
                <span className='order-gen-order-value'>
                  {orderPrint?.order_date
                    ? new Date(orderPrint?.order_date).toLocaleDateString('en-GB')
                    : ''}
                </span>
              </div>
            )}
          </div>
          <div className='order-gen-temp-horizontal-line'></div>
          <div className='order-gen-table-grp-container'>
            {renderOrderGenTable()}
          </div>
          <div className="order-gen-temp-signature-section">
            <div className='order-gen-signature-first-div'>
              {getOption("terms_conditions")?.is_enable && (
                <div className='order-gen-signature-first-part'>
                  <span className='order-gen-temp-first-title'>Terms & Conditions :</span>
                  <span className='order-gen-temp-first-title'>
                    Goods once sold will not be taken back
                  </span>
                </div>
              )}
              <div className='order-gen-signature-second-part'>
                <span className='order-gen-temp-first-title'>For {isCompanydata?.name}</span>
              </div>
            </div>
            <div className='order-gen-signature-second-div'>
              {getOption("cust_signature")?.is_enable && (
                <span className='order-gen-temp-first-title'>Customer Signature</span>
              )}
              {getOption("authorized_signature")?.is_enable && (
                <span className='order-gen-temp-first-title'>Authorized Signature</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderGeneralTemplate;
