import React, { createContext, useState } from "react";

const selectedItemsContext = createContext({
  itemCodes: {}, // Store item codes in an object
  barCodes: {},  // Store bar codes in an object
  setSelectedItems: () => {},  // Function to update the selected items context
  removeItemCodesAndBarCodes: () => {},  // Function to remove item codes and bar codes
});

export const SelectedItemsProvider = ({ children }) => {
  const [itemCodes, setItemCodes] = useState({});
  const [barCodes, setBarCodes] = useState({});

  const removeItemCodesAndBarCodes = (id) => {
    setItemCodes((prevItemCodes) => {
      const updatedItemCodes = { ...prevItemCodes };
      delete updatedItemCodes[id]; // Remove item code by id
      return updatedItemCodes;
    });

    setBarCodes((prevBarCodes) => {
      const updatedBarCodes = { ...prevBarCodes };
      delete updatedBarCodes[id]; // Remove bar code by id
      return updatedBarCodes;
    });
  };

  const setSelectedItems = (newItemCodes, newBarCodes) => {
    setItemCodes(newItemCodes);
    setBarCodes(newBarCodes);
  };

  return (
    <selectedItemsContext.Provider value={{ itemCodes, barCodes, setSelectedItems, removeItemCodesAndBarCodes }}>
      {children}
    </selectedItemsContext.Provider>
  );
};

export default selectedItemsContext;
