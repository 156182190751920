import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";
import { debounce } from "lodash";
import "./CommonItemTable.css";
import ErrorNotification from "../../Notification/ErrorNotification";
import { orderBillingSettings } from "../../../src/Authentication/AuthenticationContext";

const CommonItemTable = (props) => {
  const [igst, setIgst] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [taxRate, setTaxRate] = useState("noTax");
  const [shippingCharge, setShippingCharge] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [rowSearchTerms, setRowSearchTerms] = useState({});
  const [rowItems, setRowItems] = useState({});
  const [rowVisible, setRowVisible] = useState({});
  const [igstEnable, setIgstEnable] = useState(false);
  const [gstEnable, setGstEnable] = useState(false);

  const [rows, setRows] = useState([
    {
      s_no: 1,
      id: 1,
      item: "",
      item_id: "",
      hsn: "",
      tax: "",
      taxLabel: "",
      qty: "",
      unit: "",
      rate: "",
      mrp: "",
      discount: "",
      saleRate: "",
      amount: "",
      salesPrice: "",
      purchasePrice: "",
      discountAmount: "",
      taxAmount: "",
      total_actual_price: "",
      total_taxable_price: "",
      stock_ids: [],
      actual_quantity: "",
    },
  ]);

  useEffect(() => {
    if (rows.length === 0) {
      setRows([
        {
          s_no: 1,
          id: 1,
          item: "",
          item_id: "",
          hsn: "",
          tax: "",
          taxLabel: "",
          qty: "",
          unit: "",
          rate: "",
          mrp: "",
          discount: "",
          saleRate: "",
          amount: "",
          salesPrice: "",
          purchasePrice: "",
          discountAmount: "",
          taxAmount: "",
          total_actual_price: "",
          total_taxable_price: "",
          stock_ids: [],
          actual_quantity: "",
        },
      ]);
    }
  }, [rows]);

  useEffect(() => {
    const arrayOfEditedRowData =
      Array.isArray(props.editedRowData) && props.editedRowData.length > 0
        ? props.editedRowData
        : [props.editedRowData];

    if (arrayOfEditedRowData.length > 0) {
      let cumulativeDiscount = 0;
      let subTotal = 0;
      let totalAmount = 0;
      let allRows = [];

      for (const editedRowData of arrayOfEditedRowData) {
        subTotal += editedRowData?.sub_total || 0;
        totalAmount += editedRowData?.total_amount || 0;
        cumulativeDiscount += editedRowData?.total_discount || 0;

        if (editedRowData?.bill_items) {
          const initializedRows = editedRowData.bill_items.map(
            (item, index) => {
              debugger
              const matchingStockDetail = editedRowData.stock_details?.find(
                (stockDetail) => stockDetail.item_id === item.item_id
              );
              const purchasePrice = matchingStockDetail?.price || 0;

              const itemTotalAmount =
                (item.purchase_price || purchasePrice) * (item.quantity || 0);
              const itemDiscount = (item.discount || 0) * (item.quantity || 0);

              return {
                s_no: index + 1,
                id: item.id,
                item: item.name || "",
                item_id: item.item_id || "",
                hsn: item.hsn_number || "",
                tax: item.tax_inclusive ? "GST" : "No Tax",
                qty: item.quantity || 0,
                unit: item.unit?.unit_name || "",
                purchasePrice: purchasePrice || item.purchase_price,
                salePrice: item.sales_price || 0,
                discount: itemDiscount,
                amount: item.item_total_amount,
                discountAmount: item.item_total_discount || 0,
                taxAmount: item.item_total_tax || 0,
                total_actual_price: itemTotalAmount || item.item_total_amount,
                total_taxable_price: itemTotalAmount,
                isItemSelected: false,
              };
            }
          );

          allRows = allRows.concat(initializedRows);
        }
      }

      setSubTotal(subTotal);
      setTotalAmount(totalAmount);
      setDiscount(cumulativeDiscount);
      setShippingCharge(arrayOfEditedRowData[0]?.parcel_charges);
      setRows(allRows);
    }
  }, [props.editedRowData]);

  useEffect(() => {
    props.onFormDataChange(rows);
    props.subTotalValue(subTotal);
    props.totalValue(totalAmount);
    handleItemPriceCalculations(rows);
  }, [rows, props, subTotal, totalAmount, taxRate]);

  useEffect(() => {
    fetchGSTSetting();
    fetchSettings();
  }, []);

  const fetchGSTSetting = async () => {
    const url = `${BASE_URL}/v1/setting/settings/get_setting_status?name=GST`;
    try {
      const response = await sendRequest("GET", url);
      if (response.status === 200) {
        const gstSetting = response.data;
        if (gstSetting) {
          setGstEnable(gstSetting.status);
        }
      }
    } catch (error) {
      console.error("Error fetching GST setting!", error);
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await orderBillingSettings();
      const igstSetting = response.find(
        (setting) => setting.name === "inter_state_GST"
      );
      if (igstSetting && igstSetting?.billing_setting_config?.status) {
        setIgstEnable(true);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const addItem = () => {
    setRows((prevRows) => {
      const newId = prevRows.length
        ? Math.max(...prevRows.map((row) => row.s_no)) + 1
        : 1;
      const newRow = {
        s_no: newId,
        id: "",
        item: "",
        item_id: "",
        hsn: "",
        tax: "",
        qty: "",
        unit: "",
        purchasePrice: "",
        salePrice: "",
        amount: "",
        saleAmount: "",
        discountAmount: "",
        taxAmount: "",
        total_actual_price: "",
        total_taxable_price: "",
        subTotal,
        totalAmount,
        totalDiscount: discount,
      };

      const newRows = [...prevRows, newRow];
      props?.onFormDataChange(newRows);
      return newRows;
    });
  };

  const handleInputChange = (id, field, value) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        const updatedRow = { ...row, [field]: value };

        const qty = updatedRow.qty || 0;
        const purchasePrice = updatedRow.purchasePrice || 0;

        if (field === "qty" || field === "purchasePrice") {
          updatedRow.amount = (purchasePrice || 0) * (qty || 0);
          updatedRow.total_actual_price =
            updatedRow.purchasePrice * updatedRow.qty || 0;
        }

        return updatedRow;
      }
      return row;
    });

    setRows(updatedRows);

    const newSubTotal = updatedRows.reduce(
      (sum, row) => sum + (row.amount || 0),
      0
    );
    const newTotalAmount = updatedRows.reduce(
      (sum, row) => sum + (row.amount || 0),
      0
    );
    const newCumulativeDiscount = updatedRows.reduce(
      (sum, row) => sum + (row.discountAmount || 0),
      0
    );

    setSubTotal(newSubTotal);
    setTotalAmount(newTotalAmount);
    setDiscount(newCumulativeDiscount);
  };

  const handlePurchasePriceChange = (id, value) => {
    handleInputChange(id, "purchasePrice", value);
  };

  const handleDiscountChange = (e) => {
    const newDiscount = e.target.value === "" ? 0 : Number(e.target.value);
    if (newDiscount >= 0 && newDiscount <= subTotal) {
      setDiscount(newDiscount);
      handleItemPriceCalculations(rows);
    }
  };

  const handleItemPriceCalculations = (rows) => {
    const itemsTaxableTotal = rows.reduce((acc, item) => {
      return (
        acc +
        (props.type !== "purchase_quotation" &&
        props.type !== "purchase_order" &&
        props.type !== "purchase_bill"
          ? item?.saleAmount
          : item?.purchasePrice) *
          item.qty
      );
    }, 0);

    const itemsTotalTax = rows.reduce((acc, item) => {
      return acc + item?.taxAmount * item.qty;
    }, 0);
    const sub = itemsTaxableTotal ? itemsTaxableTotal : 0;
    const dis = discount ? parseFloat(discount) : 0;
    const tax = itemsTotalTax ? itemsTotalTax : 0;

    const calculateGst =
      taxRate !== "noTax" ? sub * (taxRate / 100).toFixed(2) : 0;
    if (!props.isTotalTaxApplied) {
      if (calculateGst > 0) {
        if (gstEnable && igstEnable) {
          setIgst(calculateGst.toFixed(2));
        } else if (gstEnable) {
          setCgst((calculateGst / 2).toFixed(2));
          setSgst((calculateGst / 2).toFixed(2));
        }
      }
    } else {
      if (gstEnable && igstEnable) {
        setIgst(tax.toFixed(2));
      } else if (gstEnable) {
        setCgst((tax / 2).toFixed(2));
        setSgst((tax / 2).toFixed(2));
      }
    }
    setSubTotal(sub);
    const discountedSubtotal = sub - dis;
    const totalAmountValue =
      discountedSubtotal +
      (!props.isTotalTaxApplied && gstEnable
        ? calculateGst
        : props.isTotalTaxApplied && gstEnable
        ? tax
        : 0) +
      (shippingCharge > 0 ? parseFloat(shippingCharge) : 0);
    const total = totalAmountValue > 0 ? totalAmountValue : 0;
    const totalGSt =
      !props.isTotalTaxApplied && gstEnable
        ? calculateGst
        : props.isTotalTaxApplied && gstEnable
        ? tax
        : 0;

    setTotalAmount(total);
  };

  const removeItem = (id) => {
    if (rows.length > 1) {
      const updatedRows = rows.filter((item) => item.id !== id);
      const { subTotal, totalAmount, cumulativeDiscount } = calculateTotals(
        updatedRows,
        discount,
        taxRate,
        shippingCharge
      );
      setRows(updatedRows);
      setSubTotal(subTotal);
      setTotalAmount(totalAmount);
      setDiscount(cumulativeDiscount);
    }
  };

  const calculateTotals = (rows, discount, taxRate, shippingCharge) => {
    const subTotal = rows.reduce((sum, item) => sum + item.amount, 0);
    const cumulativeDiscount = discount || 0;

    let taxAmount = 0;
    if (taxRate !== "noTax") {
      taxAmount = subTotal * (taxRate / 100);
    }

    const totalAmount =
      subTotal - cumulativeDiscount + taxAmount + (shippingCharge || 0);

    return {
      subTotal,
      totalAmount: Math.max(totalAmount, 0),
      cumulativeDiscount,
    };
  };

  const handleSearchChange = (value, rowId) => {
    setRowVisible((prev) => ({ ...prev, [rowId]: value.length >= 2 }));

    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const rowIndex = updatedRows.findIndex((row) => row.id === rowId);
      if (rowIndex !== -1) {
        updatedRows[rowIndex].item = value;
      }
      return updatedRows;
    });

    if (value.length >= 2) {
      searchItems(value, rowId);
    }
  };

  const searchItems = useCallback(
    debounce(async (value, rowId) => {
      try {
        const url = `${BASE_URL}/v1/inventory_managment/items?query=${value}`;
        const response = await sendRequest("get", url);
        setRowItems((prev) => ({ ...prev, [rowId]: response.data.data }));
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    }, 300),
    []
  );

  const handleSelectItem = (item, rowId) => {
    const isItemAlreadySelected = rows.some(
      (row) => row.item_id === item.id && row.item_code === item.item_code
    );

    if (isItemAlreadySelected) {
      ErrorNotification("This item is already selected");
      return;
    }

    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        const itemDiscount = item.discount * 1 || 0;
        const itemTotalAmount = item.purchase_price * 1 || 0;
        const itemSaleAmount = item.sales_price * 1 || 0;

        return {
          ...row,
          item: item.item_name,
          item_id: item.id,
          item_code: item.item_code,
          hsn: item.hsn || "",
          tax: item.tax_inclusive ? "GST" : "No Tax",
          qty: 1,
          unit: item.unit_name || "",
          purchasePrice: item.purchase_price || 0,
          salePrice: item.sales_price || 0,
          discount: itemDiscount,
          amount: itemTotalAmount,
          saleAmount: itemSaleAmount,
          discountAmount: item.item_total_discount || 0,
          shippingCharge: shippingCharge || 0,
          taxAmount: item.item_total_tax || 0,
          total_actual_price: itemTotalAmount,
          total_taxable_price: itemTotalAmount,
          isItemSelected: false,
        };
      }
      return row;
    });

    setRowVisible((prev) => ({ ...prev, [rowId]: false }));
    setRows(updatedRows);

    const newSubTotal = updatedRows.reduce((sum, row) => sum + row.amount, 0);
    const newTotalAmount = updatedRows.reduce(
      (sum, row) => sum + row.amount,
      0
    );
    const newCumulativeDiscount = updatedRows.reduce(
      (sum, row) => sum + row.discountAmount,
      0
    );

    setSubTotal(newSubTotal);
    setTotalAmount(newTotalAmount);
    setDiscount(newCumulativeDiscount);
  };

  const handlePackingChargesChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setTotalAmount((prevAmount) => prevAmount - shippingCharge);
      setShippingCharge("");
    } else {
      const newPackingCharges = parseFloat(value) || 0;
      setShippingCharge(newPackingCharges);

      setTotalAmount(
        (prevAmount) => prevAmount + newPackingCharges - shippingCharge
      );
      props.packagingValue(newPackingCharges);
    }
  };

  function getInputStyles(isCreated) {
    return {
      backgroundColor: isCreated ? "#e9ecef" : "white",
      cursor: isCreated ? "not-allowed" : "text",
    };
  }

  return (
    <>
      <div className="purchase-common-table-group">
        <table className="table table-light table-bordered purchase-common-table">
          <thead>
            <tr className="purchase-common-thead-row">
              {props.headers.map((column) => (
                <th key={column.key} className="purchase-common-th">
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="purchase-common-table-body">
            {rows.map((row) => (
              <tr key={row.id} className="purchase-common-tbody-row">
                <td className="purchase-common-td text-center">{row.s_no}</td>
                <td className="purchase-common-td">
                  <input
                    type="text"
                    className="form-control purchase-common-table-item-input"
                    placeholder="Item Name"
                    value={rowSearchTerms[row.id] || row.item}
                    onChange={(e) => handleSearchChange(e.target.value, row.id)}
                    readOnly={props.isCreated}
                    style={getInputStyles(props.isCreated || props.isSearchReference)}
                  />
                  {!row.isItemSelected && rowVisible[row.id] && (
                    <ul className="common-item-dropdown">
                      {(rowItems[row.id] || []).map((item) => (
                        <li
                          key={item.id}
                          className="common-dropdown-item"
                          onClick={() => handleSelectItem(item, row.id)}
                        >
                          {item.item_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </td>
                <td className="purchase-common-td">
                  <input
                    type="text"
                    className="form-control purchase-common-table-input"
                    placeholder="HSN"
                    value={row.hsn}
                    onChange={(e) =>
                      handleInputChange(row.id, "hsn", e.target.value)
                    }
                    readOnly={props.isCreated}
                    style={getInputStyles(props.isCreated || props.isSearchReference)}
                  />
                </td>
                <td className="purchase-common-td">
                  <input
                    type="text"
                    className="form-control purchase-common-table-input"
                    placeholder="Tax"
                    value={row.tax === "No Tax" ? 0 : row.tax}
                    onChange={(e) =>
                      handleInputChange(row.id, "tax", e.target.value)
                    }
                    readOnly={props.isCreated}
                    style={getInputStyles(props.isCreated || props.isSearchReference)}
                  />
                </td>
                <td className="purchase-common-td">
                  <input
                    type="number"
                    className="form-control purchase-common-table-input"
                    placeholder="Quantity"
                    value={row.qty || ""}
                    onChange={(e) =>
                      handleInputChange(row.id, "qty", Number(e.target.value))
                    }
                    min="1"
                    readOnly={props.isCreated || props.isSearchReference}
                    style={getInputStyles(props.isCreated || props.isSearchReference)}
                  />
                </td>
                <td className="purchase-common-td">
                  <input
                    type="text"
                    className="form-control purchase-common-table-input"
                    placeholder="Unit"
                    value={row.unit}
                    onChange={(e) =>
                      handleInputChange(row.id, "unit", e.target.value)
                    }
                    readOnly={props.isCreated}
                    style={getInputStyles(props.isCreated || props.isSearchReference)}
                  />
                </td>
                <td className="purchase-common-td">
                  <input
                    type="number"
                    className="form-control purchase-common-table-input"
                    placeholder="Purchase Price"
                    value={row.purchasePrice ?? ""}
                    onChange={(e) => {
                      const updatedPrice = parseFloat(e.target.value);
                      handlePurchasePriceChange(row.id, updatedPrice);
                    }}
                    readOnly={props.isCreated || props.isSearchReference}
                    style={getInputStyles(props.isCreated || props.isSearchReference)}
                  />
                </td>

                <td className="purchase-common-td">
                  <input
                    type="number"
                    className="form-control purchase-common-table-input"
                    value={
                      row.discount ? Number(row.discount).toFixed(2) : "0.00"
                    }
                    readOnly={props.isCreated || props.isSearchReference}
                    style={getInputStyles(props.isCreated || props.isSearchReference)}
                  />
                </td>
                <td className="purchase-common-td">
                  <input
                    type="number"
                    className="form-control purchase-common-table-input"
                    value={
                      row.total_actual_price
                        ? Number(row.total_actual_price).toFixed(2)
                        : "0.00"
                    }
                    onChange={(e) =>
                      handleInputChange(
                        row.id,
                        "total_actual_price",
                        Number(e.target.value)
                      )
                    }
                    readOnly={props.isCreated}
                    style={getInputStyles(props.isCreated || props.isSearchReference)}
                  />
                </td>
                <td className="purchase-common-td text-center">
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => removeItem(row.id)}
                    className="text-danger"
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={9}></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="purchase-common-table-footer-container justify-content-between">
        <div className="ms-2">
          <button className="btn btn-primary" onClick={() => addItem()}>
            <FontAwesomeIcon icon={faPlus} /> Add Item
          </button>
        </div>
        <div className="purchase-common-table-footer-right">
          <div className="purchase-common-footer-div">
            <span className="purchase-common-footer-label">Sub Total</span>
            <input
              type="number"
              value={(subTotal ?? 0).toFixed(2)}
              readOnly
              onChange={(e) => setSubTotal(e.target.value)}
              className="form-control"
              placeholder="sub total"
              style={getInputStyles(props.isCreated || props.isSearchReference)}
            />
          </div>
          <div className="purchase-common-footer-div">
            <span className="purchase-common-footer-label">Shipping Charge</span>
            <input
              type="number"
              value={shippingCharge}
              onChange={handlePackingChargesChange}
              className="form-control"
              placeholder="Charges"
              style={getInputStyles(props.isCreated || props.isSearchReference)}
            />
          </div>

          {(props.type === "purchase_quotation" ||
            props.type === "purchase_order") && (
            <div className="tax-container">
              <label className="tax-label">Tax</label>
              <select
                value={taxRate}
                onChange={(e) => setTaxRate(e.target.value)}
                className="form-select tax-select"
                style={getInputStyles(props.isCreated || props.isSearchReference)}
              >
                <option value="noTax">No Tax</option>
                <option value={12}>GST 12%</option>
                <option value={18}>GST 18%</option>
                <option value={28}>GST 28%</option>
              </select>
            </div>
          )}

          {taxRate !== "noTax" && (
            <>
              {gstEnable && igstEnable ? (
                <div className="purchase-common-footer-div">
                  <span className="purchase-common-footer-label">
                    IGST ({taxRate}%)
                  </span>
                  <input
                    type="number"
                    value={igst}
                    readOnly
                    className="form-control"
                    placeholder="GST"
                    style={getInputStyles(props.isCreated || props.isSearchReference)}
                  />
                </div>
              ) : (
                <>
                  <div className="purchase-common-footer-div">
                    <span className="purchase-common-footer-label">
                      CGST ({taxRate / 2}%)
                    </span>
                    <input
                      type="number"
                      value={(Number(cgst) || 0).toFixed(2)}
                      readOnly
                      className="form-control"
                      placeholder="GST"
                      style={getInputStyles(props.isCreated || props.isSearchReference)}
                    />
                  </div>
                  <div className="purchase-common-footer-div">
                    <span className="purchase-common-footer-label">
                      SGST ({taxRate / 2}%)
                    </span>
                    <input
                      type="number"
                      value={(Number(sgst) || 0).toFixed(2)}
                      readOnly
                      className="form-control"
                      placeholder="GST"
                      style={getInputStyles(props.isCreated || props.isSearchReference)}
                    />
                  </div>
                </>
              )}
            </>
          )}

          {props.type !== "purchase_quotation" &&
            props.type !== "purchase_order" && (
              <div className="purchase-common-footer-div">
                <span className="purchase-common-footer-label">Discount</span>
                <input
                  type="number"
                  value={discount.toFixed(2)}
                  onChange={handleDiscountChange}
                  className="form-control"
                  placeholder="discount"
                  min="0"
                  max={subTotal}
                />
              </div>
            )}

          <div className="purchase-common-footer-div">
            <span className="purchase-common-footer-label">Total Amount</span>
            <input
              type="number"
              readOnly
              value={(totalAmount ?? 0).toFixed(2)}
              className="form-control"
              placeholder="total"
              style={getInputStyles(props.isCreated || props.isSearchReference)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonItemTable;
