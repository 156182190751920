import Cable from "actioncable";

const baseUrl = "wss://stage-api.billeasyapp.com/cable";
const createCable = () => {
  console.log("===============", baseUrl);
  return Cable.createConsumer(baseUrl);
};


export default createCable;
