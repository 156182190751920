import React, { useEffect, useState } from "react";
import "./ItemUtilities.css";
import Search from "../../Search/Search";
// import { sendRequest } from "../../utils/api"; // Assuming this is the correct import path
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";


function WayOffItems() {
  const [wayOffItems, setWayOffItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchWayOffItems = async () => {
    try {
      const response = await sendRequest(
         "GET",
        `${BASE_URL}/v1/billings/get_item_void_wayoff?page=${currentPage}&per_page=10&way_off=true`,
      );
      
      if (response.status == 200) {
        setWayOffItems(response.data.data);
        setTotalPages(response.total_pages);
      }
    } catch (error) {
      console.error("Error fetching way off items:", error);
    }
  };

  useEffect(() => {
    fetchWayOffItems();
  }, [currentPage]);

  return (
    <>
      <div>
        <div className="item-ut-void-first">
        </div>
        <table>
          <thead>
            <tr>
              <th>SNo.</th>
              <th>Item Name</th>
              <th>Item Code</th>
              <th>Quantity</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
          {Array.isArray(wayOffItems) && wayOffItems.map((item, index) => (
              <tr key={item.id}>
                <td>{(currentPage - 1) * 10 + index + 1}</td>
                <td>{item.item_name}</td>
                <td>{item.item_code}</td>
                <td>{item.quantity}</td>
                <td>{item.item_total_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default WayOffItems;