import React, { useEffect, useState } from "react";
import "./ItemInvoice.css";
import ReactDOM from "react-dom";
import ParcelCharges from "../ParcelCharges/ParcelCharges";
import ItemDiscountInvoice from "../ItemDiscountInvoice/ItemDiscountInvoice";
import { useLocation, useNavigate } from "react-router-dom";
import { sendRequest } from "../../../ApiRequest";
import ErrorNotification from "../../../Notification/ErrorNotification";
import {
  BASE_URL,
  SUCCESS_MESSAGE,
  statusLabels,
} from "../../../Url/constants";
import SelectGuest from "../SelectGuest/SelectGuest";
import successNotification from "../../../Notification/SuccessNotification";
import CommonKeypadButton from "../CommonKeypadButton/CommonKeypadButton";
import NoCharge from "../NoCharges/NoCharge";
import DeliveryPerson from "../HomeDelivery/DeliveryPerson";
import { useTranslation } from "react-i18next";
import PrintableInvoice from "../../PrintableInvoice";
import { orderBillingSettings } from "../../../../src/Authentication/AuthenticationContext";

function ItemInvoice() {
  const [isParcelPopup, setParcelPopup] = useState(false);
  const [isDiscountPopup, setDiscountPopup] = useState(false);
  const [billNumber, setBillNumber] = useState(1);
  const [invoiceData, setInvoiceData] = useState([]);
  const [isOpenGuestPopup, setOpenGuestPopup] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { order, table } = location.state || {};
  const currDate = new Date().toLocaleDateString();
  const [discountValue, setDiscountValue] = useState("");
  const [ParcelCharge, setParcelCharge] = useState("");
  const [isDiscountProvided, setIsDiscountProvided] = useState(false);
  const [discountType, setDiscountType] = useState(false);
  const [serviceChargeProvided, setServiceChargeProvided] = useState("");
  const [isServiceChargedEnabled, setisServiceChargedEnabled] = useState(true);
  const [sgst, setSgst] = useState("");
  const [cgst, setCgst] = useState("");
  const [isParceled, setIsParceled] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState("");
  // const [selectedCustomer, setSelectedCustomer] = useState("");
  const [isGstEnabled, setIsGstEnabled] = useState(true);
  const [discountReason, setDiscountReason] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isNoChargePopup, setNoChargePopup] = useState(false);
  const [selectedNoChargeValue, setSelectedNoChargeValue] = useState("");
  const [SelctedNoChargeId, setselctedNoChargeId] = useState("");
  const [discountPercent, setDiscountPercent] = useState("");
  const [initialDiscountValue, setInitialDiscountValue] = useState("");
  const [isBikerPopup, setBikerPopup] = useState(false);
  const [selectedBiker, setSelectedBiker] = useState("");
  const { t } = useTranslation();

  const [couponDiscount, setCouponDiscount] = useState([]);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponDiscountValue, setCouponDiscountValue] = useState("");
  const [billingSettings, setBillingSettings] = useState([]);
  const [enableGST, setEnableGST] = useState(false);
  // const [stockDiffrenceDiscount, setStockDiffrenceDiscount] = useState("");
  const [manualDiscountValue, setManualDiscountValue] = useState("");

  const openBikerPopup = () => {
    setBikerPopup(true);
  };

  const closeDeliveryPersonPopup = () => {
    setBikerPopup(false);
  };

  useEffect(() => {
    fetchInitialBillNumber();
    fetchOrderBillingSettings();
    fetchGSTSetting();
    if (table) {
      fetchOrderData(table);
    }
    if (order) {
      fetchOrderData(order);
    }
  }, [table, order]);

  useEffect(() => {
    calculateGstAmount(2.5);
    calculateServiceCharges(2.0);
    // setSelectedCustomer(1)
  }, [invoiceData]);

  const handlePrintInvoice = async (data) => {
    try {
      // Fetch the default template configuration
      const configResponse = await sendRequest(
        "GET",
        `${BASE_URL}/v1/template/templates/get_default_receipt`
      );
      const templateId = configResponse.data.data.id;
      const templateConfig = configResponse.data.data.template_options;

      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Bill</title>
          </head>
          <body>
            <div id="printable-bill"></div>
          </body>
        </html>
      `);
      printWindow.document.close();
      ReactDOM.render(
        <PrintableInvoice
          invoiceData={data}
          templateId={templateId}
          templateConfig={templateConfig}
        />,
        printWindow.document.getElementById("printable-bill")
      );

      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 500);
    } catch (error) {
      ErrorNotification("Failed to print bill:", error);
    }
  };

  const fetchOrderBillingSettings = async () => {
    const response = await orderBillingSettings();
    setBillingSettings(response);
  };

  const fetchGSTSetting = async () => {
    const url = `${BASE_URL}/v1/setting/settings/get_setting_status?name=GST`;
    const response = await sendRequest("GET", url);
    if (response.status === 200) {
      const gstSetting = response.data;
      if (gstSetting) {
        setEnableGST(gstSetting.status);
      }
    }
  };

  const isCheckboxEnabled = (optionKey) => {
    const option = billingSettings.find(
      (setting) => setting.name === optionKey
    );
    return option && option.billing_setting_config
      ? option.billing_setting_config.status
      : false;
  };

  const fetchInitialBillNumber = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/billings/last_invoice_bill_number`
      );
      const lastBillNumber = response.data;
      setBillNumber(lastBillNumber);
    } catch (error) {
      ErrorNotification("");
    }
  };

  const formatBillNumber = (number) => {
    return number.toString().padStart(4, "0");
  };

  const fetchOrderData = async (data) => {
    let url = `${BASE_URL}/v1/restaurant/orders/orders/get_order_details`;
    if (table) {
      url += `?floor_table_id=${data?.id}&floor_id=${data?.floor_id}`;
    } else {
      url += `?order_id=${data.id}`;
    }
    const response = await sendRequest("GET", url);
    if (response.status === 200) {
      const res = response.data;
      setInvoiceData(res);
      setCurrentOrderId(res.id);
    }
  };

  useEffect(() => {
    if (invoiceData.parcel_charges) {
      setIsParceled(true);
      setParcelCharge(invoiceData.parcel_charges);
    }
    if (invoiceData.total_discount) {
      setDiscountValue(invoiceData.total_discount);
      // setStockDiffrenceDiscount(invoiceData.total_discount)
    }
  }, [invoiceData]);

  const createTableBill = async (is_print = false) => {
    try {
      const order_params = {
        order: {
          sub_total: subTotal,
          total_discount:
            ((isDiscountProvided || isCouponApplied) &&
            isCheckboxEnabled("manual_discount")
              ? Number(totalDiscountedValue)
              : 0) + (discountValue ? Number(discountValue) : 0),
          discount_reason_ids: discountReason,
          total_amount: invocieTotalAmount,
          total_gst:
            isGstEnabled && enableGST ? Number(sgst) + Number(cgst) : 0,
          status: statusLabels["Pending"],
          customer_id: selectedGuest?.id || invoiceData?.customer_id,
          nc_account_id: SelctedNoChargeId ? SelctedNoChargeId : "",
          user_id: selectedBiker?.id,
          coupon_id: isCouponApplied ? couponDiscount.id : "",
          parcel_charges: isParceled ? ParcelCharge : 0,
          is_gst_enable: enableGST,
          is_igst_enable: isCheckboxEnabled("inter_state_GST") && enableGST,
          service_charges:
            isCheckboxEnabled("service_charge_enable") &&
            isServiceChargedEnabled
              ? serviceChargeProvided
              : 0,
        },
        is_sent: is_print === true ? is_print : "",
        sent_via_whats_app: isCheckboxEnabled("send_to_all")
          ? isCheckboxEnabled("send_to_all")
          : isCheckboxEnabled("whats_app"),
        sent_via_sms: isCheckboxEnabled("send_to_all")
          ? isCheckboxEnabled("send_to_all")
          : isCheckboxEnabled("message"),
        sent_via_email: isCheckboxEnabled("send_to_all")
          ? isCheckboxEnabled("send_to_all")
          : isCheckboxEnabled("send_by_email"),
      };
      const response = await sendRequest(
        "PUT",
        `${BASE_URL}/v1/restaurant/orders/orders/invoice_update?order_id=${currentOrderId}`,
        order_params
      );
      if (response.status === 200) {
        successNotification(SUCCESS_MESSAGE("created", "bill"));
        handlePrintInvoice(response.data.data);
        if (table) {
          navigate("/restaurants", { state: { floor_id: table?.floor_id } });
        } else {
          navigate("/delivery-order");
        }
      }
    } catch (error) {
      ErrorNotification(error.data.errors);
    }
  };

  const openParcelPopup = () => {
    setParcelPopup(true);
  };

  const closeParcelPopup = () => {
    setParcelPopup(false);
  };

  const discountPopupOpen = () => {
    setDiscountPopup(true);
  };

  // const guestPopupOpen = () => {
  //   setGuestPopup(true);
  // }

  // const closeSelectGuestPopup = () => {
  //   setGuestPopup(false);
  // }

  const openGuestPopup = () => {
    setOpenGuestPopup(true);
  };

  const closeGuestPopup = (guest) => {
    if (guest) {
      setSelectedGuest(guest);
    }
    setOpenGuestPopup(false);
  };
  const openNoChargePopup = () => {
    setNoChargePopup(true);
  };

  const closeNoChargePopup = () => {
    setNoChargePopup(false);
  };

  const closeItemDiscountPopup = () => {
    setDiscountPopup(false);
  };

  const backToRestaurantTable = () => {
    navigate("/restaurants", { state: { floor_id: table?.floor_id } });
  };

  const handleParcelCharges = (value) => {
    setIsParceled(value);
  };

  const handleParcelChargeValue = (value) => {
    setParcelCharge(value);
  };

  const handleDiscountValue = (value) => {
    setInitialDiscountValue(value);
    setIsDiscountProvided(true);
    const type = discountType ? discountType : "fixed";
    calculateDiscount(value, type);
  };

  const handleDiscountType = (value) => {
    setDiscountType(value);
    if (initialDiscountValue) {
      calculateDiscount(initialDiscountValue, value);
    }
  };

  const handleServiceCharge = (event) => {
    setisServiceChargedEnabled(!event.target.checked);
  };

  const handleDiscountReason = (value) => {
    setDiscountReason((prevReasons) => {
      const flattenedArray = [...prevReasons, value].flat();
      const uniqueReasons = [...new Set(flattenedArray)];
      return uniqueReasons;
    });
  };

  const handleGstTaxChange = (event) => {
    setIsGstEnabled(!event.target.checked);
  };

  const calculateDiscount = (value, type) => {
    let perdiscount = (calculateItemsTotal() * (value / 100)).toFixed(2);
    let fixDiscount = value;

    // if (stockDiffrenceDiscount) {
    //     perdiscount = (parseFloat(perdiscount) + parseFloat(stockDiffrenceDiscount)).toFixed(2);
    //     fixDiscount = (parseFloat(fixDiscount) + parseFloat(stockDiffrenceDiscount)).toFixed(2);
    // }

    if (isDiscountProvided) {
      if (type === "percent") {
        setManualDiscountValue(perdiscount);
        setDiscountPercent(value);
      } else {
        setManualDiscountValue(fixDiscount);
        setDiscountPercent();
      }
    }
  };

  const handleCouponDiscount = (value) => {
    setCouponDiscount(value);
    setIsCouponApplied(true);
    calculateCouponDiscount(value);
  };

  const calculateCouponDiscount = (value) => {
    const perdiscount = (
      calculateItemsTotal() *
      (Number(value.value) / 100)
    ).toFixed(2);
    const fixDiscount = Number(value.value);
    // if (isCouponApplied) {
    if (value.coupon_type === "percent") {
      setCouponDiscountValue(perdiscount);
    } else if (value.coupon_type === "fixed") {
      setCouponDiscountValue(fixDiscount);
    }
    // }
  };

  const handleSelctedNoCharge = (charge) => {
    setSelectedNoChargeValue(charge.account_name);
    setselctedNoChargeId(charge.id);
  };

  const calculateServiceCharges = (amount) => {
    const servicecharge = (calculateItemsTotal() * (amount / 100)).toFixed(2);
    setServiceChargeProvided(servicecharge);
  };

  const calculateGstAmount = (amount) => {
    const gst = (calculateItemsTotal() * (amount / 100)).toFixed(2);
    setCgst(gst);
    setSgst(gst);
  };

  const calculateItemsTotal = () => {
    // return invoiceData.order_items ? invoiceData.order_items.reduce((total, item) => total + ((item.rate * item.quantity) - item?.stock_diffrence_discount), 0) : 0;
    return invoiceData.order_items
      ? invoiceData.order_items.reduce(
          (total, item) => total + item.rate * item.quantity,
          0
        )
      : 0;
  };

  const handleKeyPress = (value) => {
    setInputValue((prevValue) => prevValue + value);
  };

  const handleDelete = () => {
    setInputValue(inputValue.slice(0, -1));
  };

  const handleClear = () => {
    setInputValue("");
  };

  const handleEnter = () => {
    console.log(`Entered value: ${inputValue}`);
  };

  const handleSelectBIker = (selectedBiker) => {
    setSelectedBiker(selectedBiker);
    setBikerPopup(false);
  };

  const handleSaveButtonClick = () => {
    createTableBill(true);
  };

  const totalDiscountedValue = (
    (isDiscountProvided && isCheckboxEnabled("manual_discount")
      ? Number(manualDiscountValue)
      : 0) + (isCouponApplied ? Number(couponDiscountValue) : 0)
  ).toFixed(2);

  const itemsTotal = calculateItemsTotal().toFixed(2);
  const subTotal = (
    calculateItemsTotal() -
    ((isDiscountProvided || isCouponApplied) &&
    isCheckboxEnabled("manual_discount")
      ? Number(totalDiscountedValue)
      : 0)
  ).toFixed(2);

  const invocieTotalAmount = (
    Number(subTotal) +
    (isParceled ? Number(ParcelCharge) : 0) +
    (!["home_delivery", "take_away"].includes(invoiceData?.order_type) &&
    isCheckboxEnabled("service_charge_enable") &&
    isServiceChargedEnabled
      ? Number(serviceChargeProvided)
      : 0) +
    (isGstEnabled && enableGST ? Number(sgst) + Number(cgst) : 0)
  ).toFixed(2);

  return (
    <>
      <div className="item-invoice-main-container-group">
        <div className="item-invoice-main">
          <div className="invoice-box-first">
            <div className="invoice-page-header">
              <div className="row">
                <div className="col-md-3">
                  {t("Table No")}: {table?.title || table?.id}
                </div>
                <div className="col-md-3">
                  {t("KOT Nos")}: {invoiceData?.order_groups?.length}
                </div>
                <div className="col-md-3">
                  {t("Invoice")}: ({formatBillNumber(billNumber + 1)})
                </div>
                <div className="col-md-3">
                  {t("Date")}: {currDate}
                </div>
              </div>
              <div className="row">
                {order && order?.order_type === "home_delivery" ? (
                  <div className="col-md-3">
                    {t("Captain")}: {selectedBiker?.first_name}{" "}
                    {selectedBiker?.last_name}
                  </div>
                ) : (
                  <div className="col-md-3">
                    {t("Captain")}: {invoiceData?.user?.first_name}{" "}
                    {invoiceData?.user?.last_name}
                  </div>
                )}
                <div className="col-md-3"></div>
                <div className="col-md-3">
                  {t("No Of Person")} :{invoiceData?.no_of_guests || 0}
                </div>
                <div className="col-md-3">
                  {t("Guest")}:{" "}
                  {selectedGuest?.email ||
                    selectedGuest?.phone ||
                    invoiceData?.customer?.email ||
                    invoiceData?.customer?.phone ||
                    ""}
                </div>
              </div>
            </div>
            <div className="invoice-table-contain">
              <table className="first-table-invoice-item">
                <thead className="invoice-table-header">
                  <tr>
                    <th className="invoice-tab-head-sub">{t("Item Name")}</th>
                    <th className="invoice-tab-head-sub">{t("Rate")}</th>
                    {isCheckboxEnabled("auto_discount") && (
                      <th className="invoice-tab-head-sub">{t("Discount")}</th>
                    )}
                    <th className="invoice-tab-head-sub">{t("Quantity")}</th>
                    <th className="invoice-tab-head-sub">{t("Amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData.order_items &&
                    invoiceData.order_items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.item_name}</td>
                        <td>₹{item.item_sales_price || "0.00"}</td>
                        {isCheckboxEnabled("auto_discount") && (
                          // <td>{((item?.item_discount * item?.quantity) + item?.stock_diffrence_discount).toFixed(2) || "0.00"}</td>
                          <td>
                            {(item?.item_discount * item?.quantity).toFixed(
                              2
                            ) || "0.00"}
                          </td>
                        )}
                        <td>{item.quantity}</td>
                        {/* <td>{item.rate ? ((item.rate * item.quantity) - item.stock_diffrence_discount).toFixed(2) : "0.00"}</td> */}
                        <td>
                          ₹
                          {item.rate
                            ? (item.rate * item.quantity).toFixed(2)
                            : "0.00"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="invoice-total-item-count">
              <span className="total-item-count-title">
                {t("Total Items")}: {invoiceData?.order_items?.length}
              </span>
            </div>
            <div className="calculation-and-calculator-format">
              {/* <div className='calculator-format'>
              <div className='calculator-container'>
                <input type='text' className='form-control invoice-keypad-input-field' value={inputValue}
                  readOnly />
                <div className="invoice-keypad-buttons">
                  <CommonKeypadButton
                    onKeyPress={handleKeyPress}
                    onClear={handleClear}
                    onDelete={handleDelete}
                    onEnter={handleEnter}
                  />
                </div>
              </div>
            </div> */}
              <div className="item-invoice-calculation">
                <div className="invoice-calculator-scroll">
                  <div className="table-2-invoice-scroll">
                    <div className="second-table-invoice-item">
                      <div className="sub-table-invoice-header">
                        <div className="table-invoice-cell">{t("FOOD")}</div>
                        <div className="table-invoice-cell-value">
                          ₹{calculateItemsTotal().toFixed(2) || "0.00"}
                        </div>
                      </div>
                      <div className="table-invoice-cell-body">
                        {(isCheckboxEnabled("manual_discount") ||
                          isCheckboxEnabled("auto_discount")) && (
                          <>
                            {(invoiceData?.total_discount ||
                              isDiscountProvided) && (
                              <div className="row">
                                {discountType === "percent" &&
                                !isCheckboxEnabled("auto_discount") ? (
                                  <div className="invoice-table-sub-data">
                                    {t("Discount on FOOD")} @({discountPercent}
                                    %)
                                  </div>
                                ) : (
                                  <div className="invoice-table-sub-data">
                                    {t("Discount")}
                                  </div>
                                )}
                                <div className="invoice-table-sub-data-value">
                                  {(
                                    (discountValue
                                      ? Number(discountValue)
                                      : 0) +
                                    (manualDiscountValue
                                      ? Number(manualDiscountValue)
                                      : 0)
                                  ).toFixed(2) || "0.00"}
                                </div>
                              </div>
                            )}
                            {isCouponApplied && (
                              <div className="row">
                                <div className="invoice-table-sub-data">
                                  Coupon Discount
                                </div>
                                <div className="invoice-table-sub-data-value">
                                  ₹{(couponDiscountValue || 0).toFixed(2)}
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        {currentOrderId &&
                          invoiceData?.order_type === "dine_in" &&
                          isCheckboxEnabled("service_charge_enable") &&
                          isServiceChargedEnabled && (
                            <div className="row">
                              <div className="invoice-table-sub-data">
                                {t("Service Charges on FOOD @(2%)")}
                              </div>
                              <div className="invoice-table-sub-data-value">
                                ₹{serviceChargeProvided || "0.00"}
                              </div>
                            </div>
                          )}

                        <div className="row">
                          <div className="invoice-table-sub-data">
                            {t("Sub Total")}
                          </div>
                          <div className="invoice-table-sub-data-value">
                            ₹{subTotal || "0.00"}
                          </div>
                        </div>

                        {isParceled && (
                          <div className="row">
                            <div className="invoice-table-sub-data">
                              {t("Parcel Charges")}
                            </div>
                            <div className="invoice-table-sub-data-value">
                              ₹{ParcelCharge || "0.00"}
                            </div>
                          </div>
                        )}
                        {isGstEnabled && enableGST && (
                          <>
                            <div className="row">
                              <div className="invoice-table-sub-data">
                                {t("SGST on FOOD @ (2.50%)")}
                              </div>
                              <div className="invoice-table-sub-data-value">
                                {sgst || "0.00"}
                              </div>
                            </div>
                            <div className="row">
                              <div className="invoice-table-sub-data">
                                {t("CGST on FOOD @ (2.50%)")}
                              </div>
                              <div className="invoice-table-sub-data-value">
                                {cgst || "0.00"}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="row">
                          <div className="invoice-table-sub-data">
                            {t("Total Amount")}
                          </div>
                          <div className="invoice-table-sub-data-value">
                            ₹{invocieTotalAmount || "0.00"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="invoice-total-round-and-total">
                  {/* <div className='invoice-total-1-round'>
                  <span className='invoice-round-up'>Round Up</span>
                  <span className='invoice-val-round-up'>-7.08</span>
                </div> */}
                  <div className="invoice-net-total-2">
                    <span className="invoice-net-value-label">
                      {t("Net Total")}
                    </span>
                    <span className="invoice-net-value">
                      ₹{invocieTotalAmount || "0.00"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="invoice-box-second">
            {selectedNoChargeValue && (
              <div className="invoice-no-charge-div">
                <span className="no-charges-title-name">
                  {t("NC Account:")} {selectedNoChargeValue}
                </span>
              </div>
            )}
            <span
              className="btn btn-info table-back-button"
              onClick={backToRestaurantTable}
            >
              {t("Back to Table List")}
            </span>
          </div>
        </div>

        <div className="item-invoice-footer">
          <div className="invoice-footer-left">
            <button className="invoice-btn-parcel" onClick={openParcelPopup}>
              {t("Parcel")}
            </button>
            <button className="btn-invoice-nc" onClick={openNoChargePopup}>
              {t("NC")}
            </button>
          </div>
          <div className="invoice-footer-center">
            {currentOrderId &&
              invoiceData?.order_type === "dine_in" &&
              isCheckboxEnabled("service_charge_enable") && (
                <div className="invoice-footer-center-checkbox-1">
                  <input
                    className="invoice-footer-input-checkbox"
                    type="checkbox"
                    checked={!isServiceChargedEnabled}
                    onChange={handleServiceCharge}
                  />
                  <span>{t("No Ser. Charge?")}</span>
                </div>
              )}
            {enableGST && (
              <div className="invoice-footer-center-checkbox-2">
                <input
                  className="invoice-footer-input-checkbox"
                  type="checkbox"
                  checked={!isGstEnabled}
                  onChange={handleGstTaxChange}
                />
                <span>{t("Tax Exemption?")}</span>
              </div>
            )}
          </div>
          <div className="invoice-footer-right">
            {order && order?.order_type === "home_delivery" ? (
              <button
                className="btn-invoice-page-save"
                onClick={openBikerPopup}
              >
                {t("Save")}
              </button>
            ) : (
              <button
                className="btn-invoice-page-save"
                onClick={createTableBill}
              >
                {t("Save")}
              </button>
            )}
            {/* <button className="btn-invoice-page-save" onClick={createTableBill}>Save</button> */}
            <button
              className="invoice-button-success"
              onClick={handleSaveButtonClick}
            >
              {t("Save & Print")}
            </button>
            <button className="invoice-guest-button" onClick={openGuestPopup}>
              {t("Guest")}
            </button>
            {isCheckboxEnabled("manual_discount") && (
              <button
                className="invoice-dis-button"
                onClick={discountPopupOpen}
              >
                {t("Manual Discount")}
              </button>
            )}
          </div>
        </div>
      </div>

      {isParcelPopup && (
        <div className="invoice-parcel-popup-show">
          <ParcelCharges
            ParcelChargeValue={ParcelCharge}
            setParcelValue={handleParcelChargeValue}
            isPracelChargeable={handleParcelCharges}
            onClose={closeParcelPopup}
          />
        </div>
      )}
      {isDiscountPopup && (
        <div className="invoice-parcel-popup-show">
          <ItemDiscountInvoice
            InitialCouponDiscountValue={couponDiscount}
            initialDiscountValue={initialDiscountValue}
            discountValue={handleDiscountValue}
            initialDiscountType={discountType}
            discountType={handleDiscountType}
            couponResponse={handleCouponDiscount}
            initialSelectedDiscountReason={discountReason}
            discountReason={handleDiscountReason}
            onClose={closeItemDiscountPopup}
            subTotalValue={itemsTotal}
          />
        </div>
      )}
      {isOpenGuestPopup && (
        <div className="invoice-guest-popup-show">
          <SelectGuest onClose={closeGuestPopup} />
        </div>
      )}

      {isNoChargePopup && (
        <div className="no-charge-invoice-popup-show">
          <NoCharge
            selectedNoCharge={handleSelctedNoCharge}
            onClose={closeNoChargePopup}
          />
        </div>
      )}

      {isBikerPopup && (
        <div className="no-charge-invoice-popup-show">
          <DeliveryPerson
            onClose={closeDeliveryPersonPopup}
            onSelectBiker={handleSelectBIker}
          />
        </div>
      )}
    </>
  );
}

export default ItemInvoice;
