import React from "react";
import QRCode from "qrcode.react";

const PrintableInvoice = ({ invoiceData, templateId, templateConfig }) => {
  const isEnabled = (optionName) => {
    const option = templateConfig.find((opt) => opt.option_name === optionName);
    return option ? option.template_config?.is_enable : false;
  };

  const getFreeText = (optionName) => {
    const option = templateConfig.find((opt) => opt.option_name === optionName);
    return option ? option.template_config.free_text : "";
  };

  const getAlign = (optionName) => {
    const option = templateConfig.find((opt) => opt.option_name === optionName);
    return option ? option.template_config?.alignment || "center" : "center";
  };

  const getPrintStyle = () => {
    return `
      @media print {
        body {
          font-family: 'Arial', sans-serif;
          font-size: 12px;
          line-height: 1.2;
          margin: 0;
          padding: 0;
          width: 80mm;
        }
        .thermal-receipt {
          width: 100%;
          padding: 5mm;
        }
        .receipt-header {
          text-align: center;
          margin-bottom: 5mm;
        }
        .receipt-header img {
          max-width: 250px;
          max-height: 250px;
          margin: 0 auto;
          display: block;
        }
        .receipt-header h1 {
          font-size: 18px !important;
          margin: 3mm 0;
        }
        .receipt-header p {
          margin: 1mm 0;
          font-size: 12px;
        }
        .invoice-details p {
          margin: 1mm 0;
          font-size: 12px;
        }
        .receipt-items table {
          width: 100%;
          border-collapse: collapse;
          margin: 3mm 0;
        }
        .receipt-items th, .receipt-items td {
          text-align: left;
          padding: 1mm 0;
          border-bottom: 1px dotted #000;
          font-size: 12px;
        }
        .receipt-items th:last-child, .receipt-items td:last-child {
          text-align: right;
        }
        .receipt-summary {
          margin: 3mm 0;
          font-size: 12px;
        }
        .summary-line {
          display: flex;
          justify-content: space-between;
          margin: 1mm 0;
        }
        .total {
          font-weight: bold;
          border-top: 1px solid #000;
          padding-top: 1mm;
        }
        .receipt-footer {
          text-align: center;
          margin-top: 5mm;
          font-size: 12px;
        }
        .qr-code {
          margin-top: 5mm;
          text-align: center;
        }
        .preview-receipt-items{
          text-align: center;
        }
        .print-com-name {
          font-size: 28px;
          font-weight: bold;
          word-break: break-word;
        }
        .restro-table{
          fontSize: "20px",
          wordBreak: "break-word",
          overflowWrap: "break-word",
          marginLeft: "20px"
        }
        .restro-subtotal{
          wordBreak: "break-word",
          overflowWrap: "break-word",
          display: "flex",
          justifyContent: "flex-start",
          marginLeft: "15px"
        }
        .restro-subtotal-value{
          wordBreak: "break-word",
          overflowWrap: "break-word",
          display: "flex", 
          justifyContent: "flex-end", 
          marginRight: "25px"
        }
        .restro-total{
        fontSize: "28px",
        wordBreak: "break-word",
        overflowWrap: "break-word",
        display: "flex", justifyContent: "flex-start", marginLeft: "20px"
        }
        .restro-total-value{
          wordBreak: "break-word", 
          overflowWrap: "break-word", 
          display: "flex", 
          justifyContent: "flex-end", 
          marginRight: "20px" 
        }
      }
    `;
  };  
  const getPreviewStyle = () => {
    const commonStyles = `
      .preview-thermal-receipt {
        font-family: 'Arial', sans-serif;
        font-size: 14px;
        line-height: 1.4;
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
        padding: 20px;
        background-color: white;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      }
      .preview-receipt-header {
        text-align: center;
        margin-bottom: 10px;
      }
      .preview-receipt-header h1 {
        font-size: 24px;
        margin: 0 0 10px 0;
      }
      .preview-receipt-header p {
        margin: 0 0 5px 0;
      }
      .preview-receipt-items table {
        width: 100%;
        margin: 0 0 5px 0;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      .preview-receipt-items th, .preview-receipt-items td {
        text-align: center;
        padding: 10px 0 ;
        border-bottom: 1px solid #ddd;
        margin-right: 2mm;
      }
      .preview-receipt-summary {
        margin-bottom: 20px;
      }
      .preview-summary-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
      .preview-total {
        font-weight: bold;
        border-top: 2px solid #000;
        padding-top: 10px;
      }
      .preview-receipt-footer {
        text-align: center;
        margin-top: 20px;
      }
      .preview-qr-code {
        margin-top: 20px;
        text-align: center;
      }
    `;

    switch (templateId) {
      case 5:
        return `
        ${commonStyles}
        .preview-thermal-receipt-bill {
          font-family: 'Arial', sans-serif;
          font-size: 14px;
          line-height: 1.4;
          width: 100%;
          max-width: 380px;
          margin: 0 auto;
          padding: 20px;
          background-color: white;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }
        .preview-receipt-header {
          text-align: center;
          margin-bottom: 10px;
          margin-top: 15px;
        }
        .preview-receipt-header h1 {
          font-size: 40px;
          margin: 0 0 5px 0;
        }
        @media print {
        .thermal-receipt {
          width: 100%;
          padding: 5mm;
        }
        .receipt-header h1 {
          font-size: 28px !important; // Increased from 18px
          margin: 3mm 0;
        }
        .receipt-header p {
          margin: 1mm 0;
          font-size: 18px; // Increased from 12px
        }
        .receipt-items table {
          font-size: 20px; // Increased from 12px
          margin: 1mm 0;
        }
        .receipt-items th, .receipt-items td {
          text-align: left;
          padding: 10px 0 ;
          border-bottom: 1px solid #ddd;
          margin-right: 2mm;
        }
        .receipt-summary {
          font-size: 20px; // Increased from 12px
        }
        .receipt-footer {
          font-size: 20px; // Increased from 12px
        }
      }
        // Add more specific styles to match template 5
      `;
      case 6:
        return `
        ${commonStyles}
        .preview-thermal-receipt-bill {
          font-family: 'Arial', sans-serif;
          font-size: 12px;
          line-height: 1.2;
        }
        .preview-receipt-header {
          text-align: center;
          margin-bottom: 10px;
          margin-top: 15px;
        }
        .preview-receipt-header h1 {
          font-size: 20px;
          margin: 0 0 10px 0;
          border-bottom: 2px solid black;
          padding-bottom: 5px;
        }
        // Add more specific styles to match template 6
      `;
      case 7:
        return `
        ${commonStyles}
        .preview-thermal-receipt-bill {
          font-family: 'Courier', monospace;
          font-size: 11px;
          line-height: 1.3;
        }
        .preview-receipt-header {
          text-align: center;
          margin-bottom: 10px;
          margin-top: 15px;
        }
        .preview-receipt-header h1 {
          font-size: 16px;
          margin: 0 0 5px 0;
          border: 1px solid black;
          padding: 3px;
        }
        // Add more specific styles to match template 7
      `;
      case 8:
        return `
        ${commonStyles}
        .preview-thermal-receipt-bill {
          font-family: 'Arial', sans-serif;
          font-size: 12px;
          line-height: 1.2;
        }
        .preview-receipt-header {
          text-align: center;
          margin-bottom: 10px;
          margin-top: 15px;
        }
        .preview-receipt-header h1 {
          font-size: 18px;
          margin: 0 0 5px 0;
          font-weight: bold;
        }
        // Add more specific styles to match template 8
      `;
      default:
        return commonStyles;
    }
  };

  const safelyGetValue = (obj, path, defaultValue = "") => {
    return (
      path.split(".").reduce((acc, part) => acc && acc[part], obj) ||
      defaultValue
    );
  };

  // Helper function to safely format numbers
  const safelyFormatNumber = (value, decimalPlaces = 2) => {
    const number = parseFloat(value);
    return isNaN(number) ? "0.00" : number.toFixed(decimalPlaces);
  };

  const formatAmount = (amount) => {
    return parseFloat(amount).toFixed(2).padStart(8);
  };

  const calculateItemPrice = (quantity, item_total_amount) => {
    const qty = parseFloat(quantity) || 0;
    const price = parseFloat(item_total_amount) || 0;
    return qty * price;
  };

  return (
    <>
      <style>{getPreviewStyle()}</style>
      <style media="print">{getPrintStyle()}</style>
      {invoiceData?.bill_type === "Restaurant" ? (
        <div className={`preview-thermal-receipt template-${templateId}`}>
          <div className="preview-receipt-header">
            {isEnabled("company_logo") && (invoiceData?.logo_url) ?(
              <img
                src={invoiceData?.logo_url || "default-logo.png"}
                alt="Company Logo"
                style={{ maxWidth: "160px", maxHeight: "160px" }}
              />
            ): ''}
            {isEnabled("company_name") && (
              <div className="print-com-name">
                {getFreeText("company_name") ||
                  safelyGetValue(invoiceData, "company.name", "").replace(
                    /_/g,
                    " "
                  )}
              </div>
            )}
            {isEnabled("address") && (
              <div style={{
                textAlign: getAlign("address"),
                fontSize: "18px" // Increased from 12px
              }}>
                {getFreeText("address") ||
                  safelyGetValue(invoiceData, "branch.branch_address")}
              </div>
            )}
            {isEnabled("phone_number") && (
              <div style={{ textAlign: getAlign("phone_number"),fontSize: "18px", }}>
                Phone:{" "}
                {getFreeText("phone_number") ||
                  safelyGetValue(invoiceData, "company.phone")}
              </div>
            )}
            {isEnabled("insta_id") && (
              <div style={{ textAlign: getAlign("insta_id"),fontSize: "18px", }}>
                insta_id:{" "}
                {getFreeText("insta_id") ||
                  safelyGetValue(invoiceData, "no insta id")}
              </div>
            )}
            {isEnabled("email") && (
              <div style={{ textAlign: getAlign("email"),fontSize: "18px", }}>
                Email:{" "}
                {getFreeText("email") ||
                  safelyGetValue(invoiceData, "company.email")}
              </div>
            )}
          </div>

          {isEnabled("invoice_number") && (
            <div style={{ textAlign: getAlign("invoice_number"), fontSize: "18px", }}>
              Invoice Number: {invoiceData.id}
            </div>
          )}
          {isEnabled("date") && (
            <div style={{ textAlign: getAlign("date"), fontSize: "18px", }}>
              Date: {new Date().toLocaleDateString()}
            </div>
          )}
          {isEnabled("time") && (
            <div style={{ textAlign: getAlign("time"), fontSize: "18px", }}>
              Time: {new Date().toLocaleTimeString()}
            </div>
          )}

          <div className="preview-receipt-items">
            <table className="restro-table">
              {" "}
              {/* increase font size of the table */}
              <thead>
                <tr>
                  <th style={{ fontSize: "15px" }}>Item</th>
                  {/* increase font size of the header cells */}
                  <th style={{ fontSize: "15px" }}>Qty</th>
                  <th style={{ fontSize: "15px" }}>Rate</th>
                  <th style={{ fontSize: "15px" }}>Price</th>
                </tr>
              </thead>
              <tbody>
                {safelyGetValue(invoiceData, "order_groups", []).map(
                  (orderGroup, groupIndex) =>
                    orderGroup.order_items.map((item, itemIndex) => (
                      <tr key={`${groupIndex}-${itemIndex}`}>
                        <td style={{ fontSize: "12px", wordWrap: "break-word", width: "40%", marginRight: "2px"  }}>{item.item_name}</td>
                        {/* increase font size of the table cells */}
                        <td style={{ fontSize: "12px", wordWrap: "break-word", width: "15%", marginRight: "2px"  }}>{item.quantity}</td>
                        <td style={{ fontSize: "12px", wordWrap: "break-word", width: "25%", marginRight: "2px"  }}>{item.sales_price}</td>
                        <td style={{ fontSize: "12px", wordWrap: "break-word", width: "30%", marginRight: "2px"  }}>
                          {safelyFormatNumber(calculateItemPrice(item.quantity, item.item_total_amount))}
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
          <div className="preview-receipt-summary" style={{ fontSize: "20px" }}>
            <div className="preview-summary-line">
            <span className="restro-subtotal">
                Subtotal:
              </span>
              <span className="restro-subtotal-value">{safelyFormatNumber(invoiceData.sub_total + invoiceData.total_discount)}</span>
            </div>
            <div className="preview-summary-line">
              <span
                className="restro-subtotal"
              >
                Discount:
              </span>
              <span className="restro-subtotal-value">{safelyFormatNumber(invoiceData?.total_discount)}</span>
            </div>
            <div className="preview-summary-line">
              <span
                className="restro-subtotal"
              >
                Parcel Charges:
              </span>
              <span className="restro-subtotal-value">{safelyFormatNumber(invoiceData?.parcel_charges)}</span>
            </div>
            <div className="preview-summary-line">
              <span
                className="restro-subtotal"
              >
                Service Charges:
              </span>
              <span className="restro-subtotal-value">{safelyFormatNumber(invoiceData?.service_charges)}</span>
            </div>
            {invoiceData.is_gst_enable && invoiceData.is_igst_enable ? (
              <div className="preview-summary-line">
                <span>IGST:</span>
                <span>{safelyFormatNumber(invoiceData.total_gst)}</span>
              </div>
            ) : invoiceData.is_gst_enable ? (
              <>
                <div className="preview-summary-line">
                  <span>CGST:</span>
                  <span>{safelyFormatNumber(invoiceData.total_gst / 2)}</span>
                </div>
                <div className="preview-summary-line">
                  <span>SGST:</span>
                  <span>{safelyFormatNumber(invoiceData.total_gst / 2)}</span>
                </div>
              </>
            ) : (
              <div className="preview-summary-line">
                <span>Tax:</span>
                <span>{safelyFormatNumber(invoiceData.total_tax || 0)}</span>
              </div>
            )}
            <div className="preview-summary-line preview-total">
            <span className="restro-total">
                Total:
              </span>
              <span className="restro-total-value">{safelyFormatNumber(invoiceData.total_amount)}</span>
            </div>
          </div>

          <div className="preview-receipt-footer">
            {isEnabled("return_policy") && (
              <p style={{ textAlign: getAlign("return_policy"), fontSize: "20px" }}>
                {getFreeText("return_policy") || "Thank you for your business!"}
              </p>
            )}
          </div>

          <div style={{display: "flex", justifyContent: "center"}}>
            <span style={{ fontSize: "12px"}}>Powered by JMW</span>
          </div>

          {isEnabled("barcode") && (
            <div className="preview-qr-code">
              <QRCode value={`Invoice #${invoiceData.id}`} size={100} />
            </div>
          )}
        </div>
      ) : (
        <div className={`preview-thermal-receipt-bill template-${templateId}`}>
          <div className="preview-receipt-header">
            {isEnabled("company_logo") && (invoiceData?.logo_url) ?(
              <img
                src={invoiceData?.logo_url || "default-logo.png"}
                alt="Company Logo"
                style={{ maxWidth: "160px", maxHeight: "160px" }}
              />
            ) : ''}
            {isEnabled("company_name") && (
              <h1
                style={{
                  textAlign: getAlign("company_name"),
                  fontSize: "28px",
                  fontWeight: "bold",
                  wordBreak: "break-word", // add this property
                }}
              >
                {getFreeText("company_name") ||
                  safelyGetValue(invoiceData, "company.name", "").replace(
                    /_/g,
                    " "
                  )}
              </h1>
            )}
            {isEnabled("address") && (
              <p
                style={{
                  textAlign: getAlign("address"),
                  fontSize: "18px", // increase font size
                }}
              >
                {getFreeText("address") ||
                  safelyGetValue(invoiceData, "company.address")}
              </p>
            )}
            {isEnabled("phone_number") && (
              <p
                style={{
                  textAlign: getAlign("phone_number"),
                  fontSize: "18px", // increase font size
                }}
              >
                Phone:{" "}
                {getFreeText("phone_number") ||
                  safelyGetValue(invoiceData, "company.phone")}
              </p>
            )}
            {isEnabled("insta_id") && (
              <p
                style={{
                  textAlign: getAlign("insta_id"),
                  fontSize: "18px", // increase font size
                }}
              >
                Insta Id:{" "}
                {getFreeText("insta_id") ||
                  safelyGetValue(invoiceData, "no insta id")}
              </p>
            )}
            {isEnabled("email") && (
              <p
                style={{
                  textAlign: getAlign("email"),
                  fontSize: "18px", // increase font size
                }}
              >
                Email:{" "}
                {getFreeText("email") ||
                  safelyGetValue(invoiceData, "company.email")}
              </p>
            )}
            {isEnabled("invoice_number") && (
              <p
                style={{
                  textAlign: getAlign("invoice_number"),
                  fontSize: "18px", // increase font size
                }}
              >
                Invoice Number: {safelyGetValue(invoiceData, "bill_number")}
              </p>
            )}
            {isEnabled("date") && (
              <p
                style={{
                  textAlign: getAlign("date"),
                  fontSize: "18px", // increase font size
                }}
              >
                Date: {new Date().toLocaleDateString()}
              </p>
            )}

            {isEnabled("time") && (
              <p
                style={{
                  textAlign: getAlign("time"),
                  fontSize: "18px", // increase font size
                }}
              >
                Time: {new Date().toLocaleTimeString()}
              </p>
            )}
          </div>
          <div>
            <table style={{ fontSize: "16px"}}>
              <thead>
                <tr>
                  <th style={{ fontSize: "15px", textAlign: "left"}}>Item</th>{" "}
                  {/* increase font size of the header cells */}
                  <th style={{ fontSize: "15px", textAlign: "left" }}>Qty</th>
                  <th style={{ fontSize: "15px", marginRight: "2px", textAlign: "left" }}>Rate</th>
                  <th style={{ fontSize: "15px", textAlign: "left" }}>Price</th>
                </tr>
              </thead>
              <tbody>
                {safelyGetValue(invoiceData, "bill_items", []).map(
                  (item, index) => (
                    <tr key={index} style={{ fontSize: "12px", marginRight: "3px" }}>
                      <td className="bill-table-data" style={{ fontSize: "12px", wordWrap: "break-word", width: "40%", marginRight: "2px"  }}>
                        {safelyGetValue(item, "name")}
                      </td>
                      <td className="bill-table-data" style={{ fontSize: "12px", wordWrap: "break-word", width: "10%", marginRight: "2px" }}>
                        {safelyGetValue(item, "quantity")}
                      </td>
                      <td className="bill-table-data"style={{ fontSize: "12px", wordWrap: "break-word", width: "10%", marginRight: "2px" }}>
                        {safelyGetValue(item, "sales_price")}
                      </td>
                      <td className="bill-table-data" style={{ Size: "12px", wordWrap: "break-word", width: "30%"}}>
                        {safelyFormatNumber(
                          calculateItemPrice(
                            safelyGetValue(item, "quantity"),
                            safelyGetValue(item, "item_total_amount")
                          )
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <div style={{
            borderBottom: "2px solid black", 
            width: "100%", 
            margin: "10px 0"
          }}></div>
          <div className="preview-receipt-summary" style={{ fontSize: "20px" }}>
            <div className="preview-summary-line">
              <span
                style={{ wordBreak: "break-word", overflowWrap: "break-word", display: "flex", justifyContent: "flex-start" }}
              >
                Subtotal:
              </span>
              <span
                style={{ wordBreak: "break-word", overflowWrap: "break-word", display: "flex", justifyContent: "flex-end"}}
              >
                {safelyFormatNumber(safelyGetValue(invoiceData, "sub_total") + safelyGetValue(invoiceData, "total_discount", 0))}
              </span>
            </div>
            <div className="preview-summary-line">
              <span
                style={{
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                  display: "flex", justifyContent: "flex-start"
                }}
              >
                Discount:
              </span>
              <span
                style={{
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                  display: "flex", justifyContent: "flex-end"
                }}
              >
                {safelyGetValue(invoiceData, "total_discount", "0.00")}
              </span>
            </div>
            {invoiceData.is_gst_enable && invoiceData.is_igst_enable ? (
              <div className="preview-summary-line">
                <span style={{ wordBreak: "break-word", overflowWrap: "break-word", display: "flex", justifyContent: "flex-start"}}>
                  IGST:
                </span>
                <span style={{ wordBreak: "break-word", overflowWrap: "break-word", display: "flex", justifyContent: "flex-end"}}>
                  {safelyFormatNumber(safelyGetValue(invoiceData, "total_gst"))}
                </span>
              </div>
            ) : invoiceData.is_gst_enable ? (
              <>
                <div className="preview-summary-line">
                  <span style={{ wordBreak: "break-word", overflowWrap: "break-word", display: "flex", justifyContent: "flex-start" }}>
                    CGST:
                  </span>
                  <span style={{ wordBreak: "break-word", overflowWrap: "break-word", display: "flex", justifyContent: "flex-end" }}>
                    {safelyFormatNumber(safelyGetValue(invoiceData, "total_gst") / 2)}
                  </span>
                </div>
                <div className="preview-summary-line">
                  <span style={{ wordBreak: "break-word", overflowWrap: "break-word", display: "flex", justifyContent: "flex-start" }}>
                    SGST:
                  </span>
                  <span style={{ wordBreak: "break-word", overflowWrap: "break-word", display: "flex", justifyContent: "flex-end" }}>
                    {safelyFormatNumber(safelyGetValue(invoiceData, "total_gst") / 2)}
                  </span>
                </div>
              </>
            ) : (
              <div className="preview-summary-line">
                <span style={{ wordBreak: "break-word", overflowWrap: "break-word", display: "flex", justifyContent: "flex-start" }}>
                  Tax:
                </span>
                <span style={{ wordBreak: "break-word", overflowWrap: "break-word", display: "flex", justifyContent: "flex-end" }}>
                  {safelyFormatNumber(safelyGetValue(invoiceData, "total_tax"))}
                </span>
              </div>
            )}
            <div className="preview-summary-line preview-total">
              <span
                style={{
                  fontSize: "28px",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                  display: "flex", justifyContent: "flex-start"
                }}
              >
                Total:
              </span>
              <span
                style={{ wordBreak: "break-word", overflowWrap: "break-word", display: "flex", justifyContent: "flex-end" }}
              >
                {safelyFormatNumber(
                  safelyGetValue(invoiceData, "total_amount")
                )}
              </span>
            </div>
          </div>

          <div className="preview-receipt-footer">
            {isEnabled("return_policy") && (
              <p style={{ textAlign: getAlign("return_policy"), fontSize: "20px" }}>
                {getFreeText("return_policy") || "Thank you for your business!"}
              </p>
            )}
          </div>

          <div style={{display: "flex", justifyContent: "center"}}>
            <span style={{ fontSize: "12px"}}>Powered by JMW</span>
          </div>

          {isEnabled("barcode") && (
            <div className="preview-qr-code">
              <QRCode
                value={`Invoice #${safelyGetValue(invoiceData, "bill_number")}`}
                size={100}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PrintableInvoice;
