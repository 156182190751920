import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import Copyrights from "../../Copyrights";
import { useTranslation } from "react-i18next";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import "bootstrap/dist/css/bootstrap.min.css";
import "./item-details.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faHistory } from "@fortawesome/free-solid-svg-icons";
import ItemAdditionalSetting from "../ItemPopup/ItemAdditionalSetting";
import { BsPencilSquare } from "react-icons/bs";
import QuantitEdit from "../ItemPopup/QuantityEdit.jsx";
import CustomTable from "../../Common/CustomTable.jsx";

function ItemDetails() {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const { t } = useTranslation();
  const [selectedSettingStcok, setSelectedSettingStock] = useState("");
  const [isSettingPopupOpen, setIsSettingPopupOpen] = useState(false);
  const [isQtyEditPopupOpen, setIsQtyEditPopupOpen] = useState(false);
  const [selectedStock, setSelectedStock] = useState(null);
  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false);
  const [itemAuditData, setItemAuditData] = useState([]); // Separate state for item audits
  const [isStockHistoryModalOpen, setIsStockHistoryModalOpen] = useState(false);
  const [stockAuditData, setStockAuditData] = useState([]); // Separate state for stock audits

  const formatAuditChanges = (changes) => {
    if (!changes || typeof changes !== 'object') return "No changes";

    try {
      return Object.entries(changes)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return `${key}: ${value[0]} → ${value[1]}`;
          }
          return `${key}: ${value}`;
        })
        .join(", ");
    } catch (error) {
      console.error("Error formatting audit changes:", error);
      return "Unable to format changes";
    }
  };

  // Separate fetch functions for item and stock audits
  const fetchItemAuditData = async (itemId) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/items/fetch_item_or_stock_audit/${itemId}?type=Item`
      );
      if (response.data && response.data.audits) {
        setItemAuditData(response.data.audits);
      }
    } catch (error) {
      if (error.data && error.data.errors) {
        error.data.errors.map((error) => ErrorNotification(error));
      } else {
        ErrorNotification("Failed to fetch item audit data");
      }
    }
  };


  const fetchStockAuditData = async (stockId) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/items/fetch_item_or_stock_audit/${stockId}?type=Stock`
      );
      if (response.data && response.data.audits) {
        setStockAuditData(response.data.audits);
      }
    } catch (error) {
      if (error.data && error.data.errors) {
        error.data.errors.map((error) => ErrorNotification(error));
      } else {
        ErrorNotification("Failed to fetch stock audit data");
      }
    }
  };

  // Prepare audit data for CustomTable
  const prepareItemAuditData = (audits) => {
    return audits.map(audit => ({
      'Username': audit.username,
      'User Type': audit.user_type,
      'Action': audit.action,
      'Changes': formatAuditChanges(audit.audited_changes),
      'Date': new Date(audit.created_at).toLocaleString(),
    }));
  };

  const formatStockAuditChanges = (changes, action) => {
    if (!changes || typeof changes !== 'object') {
      return "No changes";
    }

    try {
      if (action === 'create') {
        const formattedChanges = [];
        if (changes.created_at) {
          formattedChanges.push(`Created at: ${new Date(changes.created_at).toLocaleString()}`);
        }

        Object.entries(changes).forEach(([key, value]) => {
          if (key !== 'created_at' && key !== 'updated_at') {
            formattedChanges.push(`${key}: ${value}`);
          }
        });

        return formattedChanges.join(", ");
      }

      return Object.entries(changes)
        .map(([key, [oldVal, newVal]]) => {
          if (key === 'updated_at') {
            return `Updated: ${new Date(oldVal).toLocaleString()} → ${new Date(newVal).toLocaleString()}`;
          }
          return `${key}: ${oldVal} → ${newVal}`;
        })
        .join(", ");

    } catch (error) {
      console.error("Error formatting audit changes:", error);
      return "Unable to format changes";
    }
  };

  const prepareStockAuditData = (audits) => {
    if (!Array.isArray(audits) || audits.length === 0) {
      return [];
    }

    return audits.map(audit => ({
      'Username': audit.username || '',
      'Action': audit.action || '',
      'Changes': formatStockAuditChanges(audit.audited_changes, audit.action),
    }));
  };

  const handleOpenAuditModal = async () => {
    await fetchItemAuditData(id);
    setIsAuditModalOpen(true);
  };

  const openStockHistoryModal = async (stock) => {
    try {
      await fetchStockAuditData(stock.id);
      setIsStockHistoryModalOpen(true);
    } catch (error) {
      ErrorNotification("Failed to fetch stock audit data");
    }
  };

  const handleVisibleChange = async(stockId, checked) => {
    try {
      await sendRequest(
        "PATCH",
        `${BASE_URL}/v1/inventory_managment/items/update_stock_availablity/${stockId}`,
        {status: checked}
      );
      fetchItem();
    }
    catch(error){
      error.data.errors.map((error) => ErrorNotification(error));
    }
  };

  useEffect(() => {
    fetchItem();
  }, []);

  const fetchItem = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/items/${id}`
      );
      setItem(response.data.data);
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  };

  const openSettingPopup = (stock) => {
    setSelectedSettingStock(stock);
    setIsSettingPopupOpen(true);
  };

  const closeSettingPopup = () => {
    setIsSettingPopupOpen(false);
    setSelectedSettingStock(null);
  };

  const openQtyEditPopup = (stock) => {
    const modifiedStock = {
      ...stock,
      item_id: id,
      quantity: stock.quantity
    };
    setSelectedStock(modifiedStock);
    setIsQtyEditPopupOpen(true);
  };

  const closeQtyEditPopup = () => {
    setIsQtyEditPopupOpen(false);
    setSelectedStock(null);
    fetchItem();
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            {item ? (
              <div className="card p-8">
                <div className="card-body">
                  <div className="card-body-first-div">
                    <h5 className="item_label">{t("Item Details")}</h5>
                    <span
                      className="text-primary cursor-pointer hover:underline"
                      onClick={handleOpenAuditModal}
                    >
                      History
                    </span>
                  </div>
                  {/* </div> */}
                  <table>
                    <tbody>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Item Name")}
                        </th>
                        <td>{item.item_name}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Item Code")}
                        </th>
                        <td>{item.item_code}</td>
                      </tr>
                      {item.bar_code_url && (
                        <tr>
                          <th className="bg-light text-black">
                            {t("Bar Code")}
                          </th>
                          <td>
                            <img
                              src={`${BASE_URL}${item?.bar_code_url}`}
                              alt="Bar Code"
                              className="img-fluid"
                            />
                            <div className="item-bar-code-value">
                              {item.bar_code}
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th className="bg-light text-black">{t("Hsn No")}</th>
                        <td>{item.hsn}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Category")}</th>
                        <td>{item.cat_name}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Unit")}</th>
                        <td>{item.unit_name}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Quantity")}</th>
                        <td>{item.quantity}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Purchase Price")}
                        </th>
                        <td>₹{item.purchase_price || 0.0}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Wholesale Price")}
                        </th>
                        <td>₹{item.wholesale_price || 0.0}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Sales Price")}
                        </th>
                        <td>₹{item.sales_price || 0.0}</td>
                      </tr>
                      {/* <tr>
                        <th className="bg-light text-black">
                          {t("Discount Type")}
                        </th>
                        <td>{item.discount_type}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Sales Discount")}
                        </th>
                        <td>₹{item.sales_discount || 0.00}</td>
                      </tr> */}
                      <tr>
                        <th className="bg-light text-black">
                          {t("Discount Type")}
                        </th>
                        <td>{item.discount_type}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Sales Discount")}
                        </th>
                        <td>
                          {item.discount_type === "Percentage"
                            ? `${item.sales_discount || 0.0}%`
                            : `₹${item.sales_discount || 0.0}`}
                        </td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Discount Price")}
                        </th>
                        <td>₹{item.discount_price || 0.0}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Tax")}</th>
                        <td>
                          {item.tax_info?.tax_value} ({item?.tax_info?.tax_type})
                        </td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Tax Type")}</th>
                        <td>{item.tax_type}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Tax Inclusive")}
                        </th>
                        <td>{item.is_tax_inclusive ? "✓" : "x"}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Purchase Tax")}</th>
                        <td>
                          {item.purchase_tax_info?.purchase_tax_value}{item.purchase_tax_info?.purchase_tax_type}
                        </td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">{t("Purchase Tax Type")}</th>
                        <td>{item.purchase_tax_type}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-black">
                          {t("Purchase Tax Inclusive")}
                        </th>
                        <td>{item.is_purchase_tax_inclusive ? "✓" : "x"}</td>
                      </tr>
                      {/* <tr>
                        <th className="bg-light text-black">
                          {t("Sales Amount")}
                        </th>
                        <td>₹{item.sale_amount || 0.0}</td>
                        
                      </tr> */}
                      <tr>
                        <th className="bg-light text-black">
                          {t("Sales Amount")}
                        </th>
                        <td>
                          ₹{item.sale_amount || 0.0}
                        </td>
                      </tr>
                      {item.item_setting_configs.map((config, index) => (
                        <tr key={index}>
                          <th className="bg-light text-black">
                            {t(config.name)}
                          </th>
                          <td>{config.free_text}</td>
                        </tr>
                      ))}
                      {item.item_url && (
                      <tr>
                        <th className="bg-light text-black">
                          {t("Item Image")}
                        </th>
                        <td>
                          <img
                            src={`${BASE_URL}${item?.item_url}`}
                            alt="Item Image"
                            className="img-fluid"
                            style={{ height: "150px", width: "300px", objectFit: "cover" }}
                          />
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="loading">{t("Loading...")}</div>
            )}
          </div>

          {isAuditModalOpen && (
            <div className="audit-modal-overlay">
              <div className="audit-modal">
                <div className="audit-modal-header">
                  <span className="audit-model-left">{t("Item Audit Details")}</span>
                  <span
                    className="audit-model-right"
                    onClick={() => setIsAuditModalOpen(false)}
                  >
                    x
                  </span>
                </div>
                <div className="audit-modal-body">
                  <CustomTable
                    col_names={['Username', 'Action', 'Changes', 'Date']}
                    data={prepareItemAuditData(itemAuditData)}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="col mt-4 mt-md-0">
            {item && item.stocks && item.stocks.length > 0 ? (
              <div className="card p-8">
                <div className="card-body">
                  <h5 className="card-title item_label">
                    {t("Stock Details")}
                  </h5>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>{t("Purchase Price")}</th>
                        <th>{t("Quantity")}</th>
                        <th>{t("Sales Price")}</th>
                        <th>{t("Expiry Date")}</th>
                        <th>{t("MRP")}</th>
                        <th>{t("Batch No")}</th>
                        <th>{t("Is Billable Stock")}</th>
                        <th>{t("History")}</th>
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.stocks.map((stock) => (
                        <tr key={stock?.id}>
                          <td>{stock?.price}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span>{stock?.quantity}</span>
                              <span className="ms-2 Qty-edit-icon">
                                <BsPencilSquare
                                  onClick={() => openQtyEditPopup(stock)}
                                  style={{ cursor: 'pointer' }}
                                />
                              </span>
                            </div>
                          </td>
                          <td>{stock?.sale_price}</td>
                          <td>{stock?.expiry_date}</td>
                          <td>{stock?.mrp}</td>
                          <td>{stock?.batch_no}</td>
                          <td>
                            <div className="">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={stock.is_billable}
                                onChange={()=>handleVisibleChange(stock.id, !stock.is_billable)}
                              />
                              <span className="slider round"></span>
                            </label>
                            </div>
                          </td>
                          <td>
                            {/* Added History icon */}
                            <FontAwesomeIcon
                              icon={faHistory}
                              className="history-icon"
                              onClick={() => openStockHistoryModal(stock)}
                              title="Stock History"
                              style={{ cursor: 'pointer' }}
                            />
                          </td>
                          <td>
                            <div>
                              <FontAwesomeIcon
                                icon={faCog}
                                className="setting-icon"
                                onClick={() => openSettingPopup(stock)}
                                title="Settings"
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="card p-4">
                <div className="card-body">
                  <p className="text-muted">
                    {t("No stock details available.")}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Add Stock History Modal */}
          {isStockHistoryModalOpen && (
            <div className="stock-history-modal-overlay">
              <div className="stock-history-modal">
                <div className="stock-history-modal-header">
                  <span className="stock-history-modal-title">{t("Stock History")}</span>
                  <span
                    className="stock-history-modal-close"
                    onClick={() => setIsStockHistoryModalOpen(false)}
                  >
                    x
                  </span>
                </div>
                <div className="stock-history-modal-body">
                  {stockAuditData && stockAuditData.length > 0 ? (
                    <CustomTable
                      col_names={['Username', 'Action', 'Changes']}
                      data={prepareStockAuditData(stockAuditData)}
                    />
                  ) : (
                    <div className="text-center p-4">
                      <p>{t("No audit history available")}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {isSettingPopupOpen && (
          <div className="stock-add-popup">
            <div className="add-stock-setting-close-sign">
              <ItemAdditionalSetting
                item={selectedSettingStcok}
                closeSettingPopup={closeSettingPopup}
                type={"stock_wise"}
                setItem={setItem}
              />
              <span className="add-close" onClick={closeSettingPopup}>
                &times;
              </span>
            </div>
          </div>
        )}
        {isQtyEditPopupOpen && (
          <div className="popup-overlay">
            <QuantitEdit
              className="add-close"
              selectedStock={selectedStock}  // Pass the selectedStock prop
              onClose={closeQtyEditPopup}
              setItems={setItem}
            />
          </div>
        )}
        <NotificationContainer />
      </div>
      <Copyrights />
    </>
  );
}

export default ItemDetails;