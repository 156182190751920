import React, { useEffect, useState, useCallback } from "react";
import "./Order.css";
import { BASE_URL } from "../../Url/constants";
import RestaurantHeader from "./RestaurantHeader";
import Card from "./Card/Card";
import ErrorNotification from "../../Notification/ErrorNotification";
import Modal from "react-modal";
import { sendRequest } from "../../ApiRequest";
import CreateTable from "./createTable/createTable";
import successNotification from "../../Notification/SuccessNotification";
import { NotificationContainer } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";
import ModalPermission from "../../ModalPermission";

function Table() {
  const [floors, setFloors] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [tables, setTables] = useState([]);
  const [view, setView] = useState("floor");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFloorId, setSelectedFloorId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const { floor_id } = location.state || {};
  const [showTableReservation, setShowTableReservation] = useState(false);
  const [errorUnauthorize, setErrorUnauthorize] = useState(false);
  const navigate = useNavigate();
  
  const { t} = useTranslation();

  useEffect(() => {
    fetchFloors();
  }, []);

  useEffect(() => {
    getConfigOptions();
  }, []);

  const getConfigOptions = async () => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/restaurant/orders/orders/get_config_options`);
    if (response.status === 200) {
      const res = response.data.data;
      setShowTableReservation(res[0].config_options.configs.status);
    }
  }

  // useEffect(() => {
  //   if (floor_id && floors.length > 0) {
  //     const matchedFloor = floors.find((floor) => floor.id === floor_id);
  //     if (matchedFloor) {
  //       setSelectedFloor(matchedFloor);
  //       setSelectedFloorId(matchedFloor.id);
  //       setTables(matchedFloor.tables);
  //     }
  //   }
  // }, [floor_id, floors]);
  useEffect(() => {
    if (floor_id) {
      handleFloorClick(floor_id);
    }
  }, [floor_id, floors])

  const fetchFloors = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/floors/floors`,
        null
      );
      if (response.status === 200) {
        const data = response.data.data;
        setFloors(data?.floors);
        if (!floor_id) {
          const Floor = data?.selected_floor;
          if (Floor?.length > 0) {
            setSelectedFloor(Floor[0]);
            setSelectedFloorId(Floor[0]?.id || "");
            setTables(Floor[0]?.tables || []);
          } else {
            setSelectedFloor(null);
            setSelectedFloorId("");
            setTables([]);
          }
        }
      }
      
    } catch (error) {
      if (error && error.data && error.data.errors) {
        if (error.data.errors.length === 49) {
          setErrorUnauthorize(true);
        } else {
          error.data.errors.forEach((err) => ErrorNotification(err));
        }
      } else {
        ErrorNotification('An unexpected error occurred', error);
      }
    } 
  };

  const handleCloseItemModel = () => {
    setErrorUnauthorize(false);
    navigate("/dashboard");
  }

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.trim() === "") {
        const allTables = floors.flatMap((floor) => floor.tables);
        setTables(allTables);
        setView("floor");
        return;
      }

      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/floors/search_table?floor_id=${selectedFloorId}&table_name=${query}`,
          null
        );

        if (response.status === 200) {
          const data = response.data.floor_table;
          if (data.length > 0) {
            setTables(data);
            setView("search");
          } else {
            setTables([]);
            setView("search");
          }
        }
      } catch (error) {
        error.data.errors.map((error) => ErrorNotification(error));
      }
    }, 500),
    [floors, selectedFloorId]
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleTableFilter = async (filter) => {
    let status;
    switch (filter) {
      case "allTables":
        // const allTables = floors.flatMap((floor) => floor.tables);
        const allTables = selectedFloor?.tables;
        setTables(allTables);
        setSearchQuery("");
        setView("floor");
        return;
      case "openTables":
        status = "available";
        break;
      case "bookedTables":
        status = "running";
        break;
      case "reservedTables":
        status = "reserved";
        break;
      case "unavailableTables":
        status = "unavailable";
        break;
      default:
        status = "available";
    }

    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/floors/floors/floor_table_status?status=${status}&floor_id=${selectedFloor?.id}`,
        null
      );
      if (response.status === 200) {
        const data = response.data;
        setTables(data.floor_table);
        setView("filtered");
        setSearchQuery(""); // Clear search query
      }
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  };

  const handleFloorClick = async (id) => {
    setSearchQuery("");
    setView("floor");
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/floors/floors?floor_id=${id}`,
        null
      );
      if (response.status === 200) {
        const data = response.data.data;
        const Floor = data?.selected_floor;
          setSelectedFloor(Floor[0]);
          setSelectedFloorId(Floor[0]?.id || "");
          setTables(Floor[0]?.tables);
      } else {
        setTables([]); 
      }
    }
    catch (error){   
    }
  };
  

  const updateTableStatus = (tableId, status, floor_id) => {
    handleFloorClick(floor_id);
    setTables((prevTables) =>
      prevTables.map((table) =>
        table.id === tableId ? { ...table, status } : table
      )
    );
  };

 

  const getTableColor = (status, orderStatus) => {
    switch (status) {
      case "available":
        return "#0a951c";
      case "running":
        if (orderStatus === "pending") {
          return "#f44336";
        }
        return "#FFA500";
      case "reserved":
        return "#0056b3";
      case "unavailable":
        return "#f44336";
      default:
        return "#0a951c";
    }
  };

  const handleCreateTable = async (prefix, quantity) => {
    try {
      const formParams = {
        prefix: prefix,
        quantity: quantity,
        floor_id: selectedFloorId,
      };
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/floors/floors/create_table`,
        formParams
      );
      if (response.status === 200) {
        setModalIsOpen(false);
        successNotification("Tables created successfully.");
        fetchFloors();
      }
    } catch (error) {
      error.data.errors.map((err) => ErrorNotification(err));
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // const handleMergeSuccess = () => {
  //   fetchFloors();
  // };

  // const onTransferSuccess = () => {
  //   fetchFloors();
  // };

  const onTransferSuccess = (destinationFloorId) => {
    handleFloorClick(destinationFloorId);
  };

  const handleMergeSuccess = (targetFloorId) => {
    handleFloorClick(targetFloorId);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };
  return (
    <>
      <div className="container-fluid">
        <RestaurantHeader
          floors={floors}
          selectedFloor={selectedFloor}
          onTableFilter={handleTableFilter}
          onMergeSuccess={handleMergeSuccess}
          onTransferSuccess={onTransferSuccess}
        />
        <div className="row">
          <div className="floor_container">
            <div className="floor_data">
              {floors.map((floor) => (
                <button
                  key={floor.id}
                  onClick={() => handleFloorClick(floor?.id)}
                  className={
                    selectedFloor?.id === floor.id
                      ? "active btn btn-secondary"
                      : "btn btn-light restaurant-floor-buttons"
                  }
                >
                  {floor.name}
                </button>
              ))}
            </div>
            <div className="tables_count">
              <label>
                {t("Total Tables")}: {tables?.length === 0 ? t("No Tables") : tables?.length}
              </label>
            </div>
            <div className="search_tables">
              <input
                type="text"
                placeholder={t("Search Tables")}
                className="form-control"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            {selectedFloor && (
              // <div className="create_table">
              <button className="create_table" onClick={openModal}>
                {t("Create Table")}
              </button>
              // </div>
            )}
          </div>
        </div>
        <div className="tables-container">
          {/* <h3 style={{ marginLeft: "10px" }}>
            {view === "floor" ? selectedFloor?.name : t("Filtered Tables")}
          </h3> */}
          <div className="restaurant-table-group">
            <div className="table-grid">
              {tables?.length === 0 ? (
                <p className="ms-2">{t("No tables found")}</p>
              ) : (
                tables.map((table) => (
                  <Card
                    key={table?.id}
                    item={{
                      id: table?.id,
                      title: table?.name,
                      itemStatus: table?.status,
                      floor_id: table?.floor_id || selectedFloor?.id,
                      orderAmount:
                        table?.status === "running"
                          ? table?.order_value?.order_value
                          : "",
                      orderStatus: table?.status === "running" ? table?.order_value?.order_status : "",
                      orderId: table?.order_value?.order_id,
                      caption_details:
                        table?.status === "running" ? table?.caption_details : "",
                    }}
                    color={getTableColor(
                      table?.status,
                      table?.order_value?.order_status
                    )}
                    updateTableStatus={updateTableStatus}
                    tableStatus={table?.status}
                    alreadyReserved={showTableReservation}
                  />
                ))
              )}
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Create Table"
          ariaHideApp={false}
        >
          <CreateTable onCreate={handleCreateTable} closeModal={closeModal} />
        </Modal>
        <NotificationContainer />
      </div>
      {
        errorUnauthorize && (
          <div className="item-unauthorize-modal-popup">
            <div className="">
              <ModalPermission onClose={handleCloseItemModel}/>
            </div>
          </div>
        )
      }
    </>
  );
}

export default Table;
