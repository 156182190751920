import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCog } from "@fortawesome/free-solid-svg-icons";
import "./item.css";
import { useEffect, useState, useCallback, useRef } from "react"; // Added useCallback and useRef
import { BASE_URL } from "../../Url/constants";
import Copyrights from "../Copyrights";
import Search from "../Search/Search.jsx";
import { NotificationContainer } from "react-notifications";
import CustomTable from "../Common/CustomTable.jsx";
import PaginationControls from "../Common/PaginationControls.jsx";
import { BsEye, BsPencilSquare, BsPrinter } from "react-icons/bs";
import QuantitEdit from "./ItemPopup/QuantityEdit.jsx";
import ItemAdditionalSetting from "./ItemPopup/ItemAdditionalSetting.jsx";
import { useTranslation } from "react-i18next";
import ErrorNotification from "../../Notification/ErrorNotification.jsx";
import { sendRequest } from "../../ApiRequest.js";
import BarcodePrintOption from "./BarcodePrint/BarcodePrintOption.jsx";
import ItemEntriesPerPageDropdown from "./ItemEntriesPerPageDropdown.jsx";
import Loader from "../Common/Loader.jsx"
import { current_user } from "../../Authentication/AuthenticationContext.jsx";
import debounce from 'lodash/debounce'; // Added debounce import
import { useNavigate } from "react-router-dom";
import NewBarcodeOption from "./BarcodePrint/NewBarcodeOption.jsx";
import TransferItemPopup from "./TransferItemPopup.jsx";
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
function ItemTable() {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setrecordPerPage] = useState(50);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isQtyEditPopupOpen, setIsQtyEditPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSettingPopupOpen, setIsSettingPopupOpen] = useState(false);
  const [selectedSettingItem, setSelectedSettingItem] = useState(null);
  const [barcodePopupOpen, setBarcodePopupOpen] = useState(false);
  const [userLanguage, setUserLanguage] = useState("en");
  const [errorUnauthorize, setErrorUnauthorize] = useState(false);
  const baseURL = BASE_URL
  const navigate = useNavigate();
  const [isPharmaceutical, setIsPharmaceutical] = useState(false);
  const [expiryFilter, setExpiryFilter] = useState(null);
  const [customDate, setCustomDate] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [sortedItems, setSortedItems] = useState([]);
  const [barcodeTemplate, setBarcodeTemplate] = useState(null);
  const [isCompanydata, setCompanyData] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [expiryFilterDate, setExpiryFilterDate] = useState(null);
  const [expiryFilterType, setExpiryFilterType] = useState(null);
  const [isBarcodeFeatureEnabled, setIsBarcodeFeatureEnabled] = useState(false);
  const [isStockTransfer, setIsStockTransfer] = useState(false);
  const [barCodeImageUrl, setBarCodeImageUrl] = useState();
  const [isTransferPopupOpen, setIsTransferPopupOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [setting, setSetting] = useState({})
  const [settingConfig, setSettingConfig] = useState("");

  const startIndex = (currentPage - 1) * recordPerPage + 1;
  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);

  const filterAndSortByExpiry = (items, filterType, customDate = null) => {
    if (!items || !filterType) return items;
  
    const today = new Date();
    let endDate = new Date();
  
    switch (filterType) {
      case '15days':
        endDate.setDate(today.getDate() + 15);
        break;
      case '1month':
        endDate.setMonth(today.getMonth() + 1);
        break;
      case '6months':
        endDate.setMonth(today.getMonth() + 6);
        break;
      case 'custom':
        endDate = new Date(customDate);
        break;
      default:
        return items;
    }

    const filteredItems = items.filter(item => {
      const expiryInfo = getNearestExpiryInfo(item.stocks);
      if (!expiryInfo.date) return false;
      
      const expiryDate = new Date(expiryInfo.date);
      return expiryDate >= today && expiryDate <= endDate;
    });
  
    return filteredItems.sort((a, b) => {
      const aExpiryInfo = getNearestExpiryInfo(a.stocks);
      const bExpiryInfo = getNearestExpiryInfo(b.stocks);
      
      const aDate = new Date(aExpiryInfo.date);
      const bDate = new Date(bExpiryInfo.date);
      
      return aDate - bDate;
    });
  };

  const fetchCategories = async () => {
    try {

      const response = await sendRequest(
        'GET',
        `${BASE_URL}/v1/inventory_managment/categories/fetch_categories_with_item?devise_type=web`
      );

      // setCategories(response.data.data || []);

      const topLevelCategories = response.data.data.filter(category => category.parent_id === null);
    // Set only the top-level categories in state
    setCategories(topLevelCategories || []);
    } catch (error) {
      ErrorNotification('Error fetching categories');
    }
  };

  // Add function to fetch subcategories
  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await sendRequest(
        'GET',
        `${BASE_URL}/v1/inventory_managment/categories/fetch_categories_with_item?category_id=${categoryId}&devise_type=web`
      );
      setSubCategories(response.data.data || []);
    } catch (error) {
      ErrorNotification('Error fetching subcategories');
    }
  };

  useEffect(() => {
    getSettings();
  },[])

  const getSettings = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/setting/settings/item_setting_options?type=global`,
        null,
      );

      setSetting(response.data.data);
      setSettingConfig(response.data.data)
    } catch (error) {
      console.error("Error fetching options config:", error.response || error.message);
    }
  };

  // useEffect(() => {
  //     if (Array.isArray(setting)) {
  //       setSettingConfig(setting);
  //     } else {
  //       setSettingConfig([]);
  //     }
  //   }, [setting]);

  const fetchUser = async () => {
    try {
      const currentUser = await current_user();
      setIsPharmaceutical(currentUser.company.business_type === 'Pharmaceutical');
      
      const barcodeFeature = currentUser.features.find(feature => feature.name === 'Custom_Barcode');
      setIsBarcodeFeatureEnabled(barcodeFeature?.enable || false);

      const stockTransfer = currentUser.features.find(feature => feature.name === 'Stock_Transfer');
      setIsStockTransfer(stockTransfer?.enable || false);
  
    } catch (error) {
      console.error('Error fetching user:', error);
      setIsPharmaceutical(false);
      setIsBarcodeFeatureEnabled(false);
      setIsStockTransfer(false);
    }
  };

  let isMRPEnable = "";
  let isIMEIEnable = "";
  let isBatchNoEnable = "";
  let isExpiryDateEnable = "";
  let isMfgDateEnable = "";
  let isModelEnable = "";
  let isSizeEnable = "";
  let isItemAvailableForBillingEnable = "";
  let isClassEnable = "";
  let isSubCategoryEnable = "";

  if (Array.isArray(settingConfig)) {
    const mrp = settingConfig.find(
      (config) => config.name === "MRP"
    );
    isMRPEnable = mrp?.item_setting_config?.status;
    
    const imei = settingConfig.find(
      (config) => config.name === "IMEI"
    );
    isIMEIEnable = imei?.item_setting_config?.status;

    const batchNum = settingConfig.find(
      (config) => config.name === "Batch"
    );
    isBatchNoEnable = batchNum?.item_setting_config?.status;

    const expiryDate = settingConfig.find(
      (config) => config.name === "ExpiryDate"
    );
    isExpiryDateEnable = expiryDate?.item_setting_config?.status;

    const mfgDate = settingConfig.find(
      (config) => config.name === "MfgDate"
    );
    isMfgDateEnable = mfgDate?.item_setting_config?.status;

    const iModel = settingConfig.find(
      (config) => config.name === "Model"
    );
    isModelEnable = iModel?.item_setting_config?.status;

    const iSize = settingConfig.find(
      (config) => config.name === "Size"
    );
    isSizeEnable = iSize?.item_setting_config?.status;

    const itemAvailable = settingConfig.find(
      (config) => config.name === "IsItemAvailableForBilling"
    );
    isItemAvailableForBillingEnable = itemAvailable?.item_setting_config?.status;

    const itemClass = settingConfig.find(
      (config) => config.name === "Class"
    );
    isClassEnable = itemClass?.item_setting_config?.status;

    const subCategorySetting = settingConfig.find(
      (config) => config.name === "SubCategory"
    );
    isSubCategoryEnable = subCategorySetting?.item_setting_config?.status;
  }

  useEffect(() => {
    const initializeUser = async () => {
      await fetchUser();
    };
        initializeUser();
  }, []);

  useEffect(() => {
    fetchItems(currentPage);
  }, [recordPerPage, searchQuery, currentPage, selectedCategory, selectedSubCategory]);




  useEffect(() => {
    const language = localStorage.getItem("selectedLanguage") || "en";
    setUserLanguage(language);
  }, []);

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query.length >= 2) {
        setSearchQuery(query);
      } else {
        setSearchQuery("");
        setCurrentPage(1);
      }
    }, 500), // 500ms delay
    []
  );

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setCompanyData(currentUser.company);
    };
    fetchUser();
  }, []);

  const fetchItems = async (pageNumber) => {
    setLoading(true);
    try {
      let request_url = `${BASE_URL}/v1/inventory_managment/items?page=${pageNumber}&per_page=${recordPerPage}`;

      // Add category and subcategory filters to URL if selected
      if (selectedCategory) {
        request_url += `&category_id=${selectedCategory}`;
      }
      if (selectedSubCategory) {
        request_url += `&subcategory_id=${selectedSubCategory}`;
      }
      if (searchQuery) {
        request_url += `&query=${searchQuery}`;
      }

      const response = await sendRequest('GET', request_url);
      const data = await response.data;

      setItems(data.data);
      setTotalPages(Math.ceil(data.total_count / recordPerPage));
      setTotalRecords(searchQuery ? data.total_filtered_count : data.total_count);
    } catch (error) {
      if (error && error.data && error.data.errors) {
        if (error.data.errors.length === 49) {
          // setErrorUnauthorize(true); // Handle unauthorized case
        } else {
          error.data.errors.forEach((err) => ErrorNotification(err)); // Handle other errors
        }
      } else {
        ErrorNotification('Unknown error occurred', error); // In case the error structure is different
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);
    setSelectedSubCategory(null); // Reset subcategory when category changes
    if (categoryId) {
      fetchSubCategories(categoryId);
    } else {
      setSubCategories([]); // Clear subcategories if no category selected
    }
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleSubCategoryChange = (e) => {
    const subCategoryId = e.target.value;
    setSelectedSubCategory(subCategoryId);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  // Add useEffect to fetch categories on component mount
  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCloseItemModel = () => {
    setErrorUnauthorize(false);
    navigate("/dashboard");
  }
  const getNearestExpiryInfo = (stocks) => {
    if (!stocks || stocks.length === 0) return { date: null, quantity: 0 };
    
    const today = new Date();
    const validStocks = stocks.filter(stock => 
      stock.expiry_date && 
      new Date(stock.expiry_date) > today && 
      stock.quantity > 0
    );
    
    if (validStocks.length === 0) return { date: null, quantity: 0 };
    
    const nearestStock = validStocks.reduce((nearest, current) => {
      if (!nearest) return current;
      return new Date(current.expiry_date) < new Date(nearest.expiry_date) 
        ? current 
        : nearest;
    });
    
    return {
      date: nearestStock.expiry_date,
      quantity: nearestStock.quantity
    };
  };

  const fetchBarCode = async (item_id) => {
    try {
      const response = await sendRequest('GET', `${BASE_URL}/v1/inventory_managment/items/barcode_image?&id=${item_id}`);

      if (response.status === 200) {
        setBarCodeImageUrl(response.data.barcode_image_url);
        return true;
      }
    } catch (error) {
      setBarCodeImageUrl();
      ErrorNotification('No BarCode found');
    }
  }

  const openBarcodePrint = async (item) => {
    const barcodeFound = await fetchBarCode(item.id);
    if (!barcodeFound) {
      return;
    }
    if (isBarcodeFeatureEnabled) {
      try {
        const response = await sendRequest('GET', `${BASE_URL}/v1/onboarding/companies/show_barcode_temp`);
        if (response.data.default_template) {
          setBarcodeTemplate(response.data.default_template.html_code);
        }
      } catch (error) {
        ErrorNotification('Error fetching barcode template:', error);
      }
    }
    setSelectedItem(item);
    setBarcodePopupOpen(true);
  };
  
  const filterItemsByExpiry = (items) => {
    if (!expiryFilter) return items;
    
    const today = new Date();
    let filterDate = new Date();
    
    switch (expiryFilter) {
      case '15days':
        filterDate.setDate(today.getDate() + 15);
        break;
      case '1month':
        filterDate.setMonth(today.getMonth() + 1);
        break;
      case '6months':
        filterDate.setMonth(today.getMonth() + 6);
        break;
      case 'custom':
        filterDate = customDate;
        break;
      default:
        return items;
    }
    return items.sort((a, b) => {
      const aExpiry = getNearestExpiryInfo(a.stocks).date;
      const bExpiry = getNearestExpiryInfo(b.stocks).date;
      return new Date(aExpiry) - new Date(bExpiry);
    });
  };

  const getTableColumns = () => {
    
    let columns = [
      'S. No.',
      'Item Name',
      'Item Code',
      'Sales Price',
      'Purchase Price',
      'Quantity',
      'Tax',
      'Tax Type',
      'Unit',
      'Tax Inclusive',
      'Category',
      'Actions'
    ];

    if (isSubCategoryEnable) {
      columns.splice(columns.indexOf('Actions'), 0, 'Sub Category');
    } 

    if (isClassEnable) {
      columns.splice(columns.indexOf('Actions'), 0, 'Class');
    }
    
    if (isMRPEnable) {
      columns.splice(columns.indexOf('Actions'), 0, 'MRP');
    }

    if (isIMEIEnable) {
      columns.splice(columns.indexOf('Actions'), 0, 'IMEI');
    } 
    
    if (isBatchNoEnable) {
      columns.splice(columns.indexOf('Actions'), 0, 'Batch');
    }

    if (isModelEnable) {
      columns.splice(columns.indexOf('Actions'), 0, 'Model');
    }

    if (isSizeEnable) {
      columns.splice(columns.indexOf('Actions'), 0, 'Size');
    }

    if (!isPharmaceutical) {
      if (isExpiryDateEnable) {
        columns.splice(columns.indexOf('Actions'), 0, 'ExpiryDate');
      }
    }
    if (isMfgDateEnable) {
      columns.splice(columns.indexOf('Actions'), 0, 'MfgDate');
    }

    if (isPharmaceutical) {
      columns.splice(6, 0, 'Expiry Date');
    } else {
      columns.splice(6, 0, 'Sales Discount', 'Discount Price');
    }

    return columns;
  };
  
  

  const handleSort = (columnName, filterType, customDate = null) => {
    if (columnName === 'Expiry Date') {
      setExpiryFilterType(filterType);
      setExpiryFilterDate(customDate);
      const sortedItems = filterAndSortByExpiry(items, filterType, customDate);
      setSortedItems(sortedItems);
    }
  };
  
  useEffect(() => {
    setSortedItems(items);
  }, [items]);

  const handleSearch = (query) => {
    debouncedSearch(query);
  };

  const openImagePopup = (image) => {
    setSelectedImage(image);
    const fullImageURL = image.startsWith("http") ? image : `${baseURL}${image}`;
    setSelectedImage(fullImageURL);
  };

  const closeImagePopup = () => {
    setSelectedImage(null);
  };

  const openQtyEditPopup = (item) => {
    setSelectedItem(item);
    setIsQtyEditPopupOpen(true);
  };

  const closeQtyEditPopup = () => {
    setIsQtyEditPopupOpen(false);
    setSelectedItem(null);
  };

  const openSettingPopup = (item) => {
    setSelectedSettingItem(item);
    setIsSettingPopupOpen(true);
  };

  const closeSettingPopup = () => {
    setIsSettingPopupOpen(false);
    setSelectedSettingItem(null);
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setrecordPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getShortTaxType = (taxType) => {
    const taxTypeMap = {
      fixed: "fix",
      percentage: "per",
    };
    return taxTypeMap[taxType] || taxType;
  };

  const handlePrintBarcodes = (quantity) => {
    if (quantity > selectedItem.quantity) {
      ErrorNotification(`barcode Quantity should not greater than ${selectedItem.quantity}`);
      return;
    }
    const printWindow = window.open("");

    printWindow.document.write(`
      <html>
        <head>
          <title>Print Barcodes</title>
          <style>
            @media print {
              body * {
                visibility: hidden;
              }
              #printable-bill, #printable-bill * {
                visibility: visible;
              }
              #printable-bill {
                position: absolute;
                left: 8mm;
                top: 5;
                right: 1mm;
                width: calc(100% - 2mm);
              }
            }
            .sticker {
              width: 60mm;
              height: 30mm;
              border: 1px solid #000;
              page-break-inside: avoid;
              padding: 1mm;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-top: 1.5mm;
              margin-right: 1.5mm;
              margin-bottom: 1mm;
              margin-left: -8mm;
            }

            .left-section, .right-section {
              width: 100%;
              text-align: center;
            }

            .left-section {
              display: flex;
              justify-content: space-between;
            }

            .barcode-image img {
              width: 100%;
              height: 7mm;
              margin-top: 1mm;
              margin-bottom: 1mm;
            }

            .barcode-number, .price-font-size {
              font-size: 16px;
              max-width: 100%;
              text-align: center;
            }

            .barcode-number {
              margin-bottom: 2mm;
              font-weight: bold;
            }

            .price-font-size {
              display: flex;
              flex-flow: column;
            }

            .item-sales-price-value {
              font-weight: bold;
            }

            .item-sale-price-symbol{
              width: 5mm;
            }
          </style>
        </head>
        <body>
          <div id="printable-bill">
            ${Array(quantity).fill('').map(() => `
              <div class="sticker">
                <div class="left-section">
                  <div class="price-font-size">
                    <span class="item-sale-price-symbol">MRP</span>
                    <span class="item-sales-price-value">${selectedItem?.sales_price ? `${selectedItem.sales_price} ₹` : ""}</span>
                  </div>
                  <div> ${selectedItem?.cat_name ? selectedItem?.cat_name : ""}</div>
                </div>
                <div class="right-section barcode-image">
                  <img src="${barCodeImageUrl}" alt="Barcode" />
                  <div class="barcode-number">${selectedItem?.bar_code}</div>
                </div>
              </div>
            `).join('')}
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
    };
  };
  const NewBarcodeOptions = (quantity, template) => {
    if (quantity > selectedItem.quantity) {
      ErrorNotification(`Barcode quantity should not be greater than ${selectedItem.quantity}`);
      return;
    }

    const printWindow = window.open("");

    // Extract template styles
    const styleContent = barcodeTemplate.match(/<style>([\s\S]*?)<\/style>/)?.[1] || '';
    const templateContent = barcodeTemplate.replace(/<style>[\s\S]*?<\/style>/, '');

    // Additional print-specific styles
    const additionalStyles = `
  @page {
  size: 58mm 40mm portrait;
  margin: 0;
  }
  body {
  margin: 0;
  padding: 0;
  width: 58mm;
  }
  #printable-content {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;    
  margin: 0 auto;         
  width: 58mm;            
  height: 100%;           
  box-sizing: border-box; 
}
`;

    const company_name = isCompanydata?.name;
    const company_gst = isCompanydata?.gstin;
    const company_address = isCompanydata.branch_info.address
    const user_email = isCompanydata.user_info.email
    const user_phone = isCompanydata.user_info.phone

    const templates = Array(quantity).fill('').map(() => {
      return templateContent.replace(/{([^}]+)}/g, (match, key) => {
        const replacements = {
          gstin_no: selectedItem?.gstin || '',
          store_address: selectedItem?.address || '',
          item_name: selectedItem?.item_name || '',
          unit: selectedItem?.unit || '',
          sales_price: selectedItem?.sales_price ? `₹${selectedItem?.sales_price}` : '',
          sales_discount: selectedItem?.discount_price ? `₹${selectedItem?.discount_price}` : '',
          purchase_price: selectedItem?.purchase_price ? `₹${selectedItem.purchase_price.toFixed(2)}` : '',
          mgp_price: selectedItem?.mgp_price ? `₹${selectedItem.mgp_price}` : '',
          barcode_url: barCodeImageUrl ? barCodeImageUrl : '',
          barcode: selectedItem?.bar_code || '',
          fssai_no: selectedItem?.fssai || '',
          packaging_date: selectedItem?.packaging_date || new Date().toISOString().slice(0, 10),
          company_name: company_name || '',
          company_address: company_address || '',
          user_email: user_email || '',
          user_phone: user_phone || '',
        };
        return replacements[key] || '';
      });
    }).join('');

    printWindow.document.write(`
<!DOCTYPE html>
<html>
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Print Barcodes</title>
<style>
${additionalStyles}
${styleContent}
</style>
</head>
<body>
<div id="printable-content">
${templates}
</div>
<script>
window.onload = () => {
window.print();
window.onafterprint = () => window.close();
};
</script>
</body>
</html>
`);
    printWindow.document.close();
  };

  const handleCloseBarcodePopup = () => {
    setBarcodePopupOpen(false);
  }

  const handleselectedTemplate = (template) => {
    if (template) {
      setBarcodeTemplate(template);
    }
  };
  
  return (
    <>
      <div className="item-table-content">
        {loading && <Loader />}

        <div className="row mt-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <h3 className="heading-title mb-0">
                {t("All Items")} ({totalRecords})
              </h3>
            </div>
            {isStockTransfer && (
              <div className="transfer-item-table">
                <Link
                  className="btn btn-primary mb-3"
                  onClick={() => setIsTransferPopupOpen(true)}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} className="me-2" />
                  <span>{t("Transfer Items")}</span>
                </Link>
              </div>
            )}
            <div>
              <Link
                to="/item"
                className="btn btn-primary mb-3"
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                <span>{t("Add Item")}</span>
              </Link>
            </div>
          </div>
{/* 
          <div className="pagination-dropdown">
            <div className="pagination-controls">
              <ItemEntriesPerPageDropdown
                recordPerPage={recordPerPage}
                handleRecordPerPageChange={handleRecordPerPageChange}
              />
            </div>
            <div
              className="text-right search-adjust"
              value={searchQuery}
            >
              <Search 
                placeholder={"Search Items ..."} 
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div> */}

        <div className="pagination-dropdown">
          <div className="pagination-controls">
            <ItemEntriesPerPageDropdown
              recordPerPage={recordPerPage}
              handleRecordPerPageChange={handleRecordPerPageChange}
            />
          </div>
          <div className="pagination-new-right-align">
          <div className="filter-controls">
            {/* Category dropdown */}
            <select
              className="form-select dis-extra-height"
              onChange={handleCategoryChange}
              value={selectedCategory || ''}
            >
              <option value="">Select Category</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>

            {/* Subcategory dropdown - only show if subcategories exist */}
            {subCategories.length > 0 && (
              <select
                className="form-select dis-extra-height"
                onChange={handleSubCategoryChange}
                value={selectedSubCategory || ''}
              >
                <option value="">Select Sub Category</option>
                {subCategories.map(subCategory => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.name}
                  </option>
                ))}
              </select>
            )}
          </div>
            <div className="text-right search-adjust">
              <Search
                placeholder={"Search Items ..."}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
        </div>


        </div>
        <div className="table-view" style={{minHeight: "33em"}}>
        <CustomTable
          col_names={getTableColumns()}
          data={filterItemsByExpiry(sortedItems).map((item, index) => {
            let translatedName;
            try {
              translatedName = item.translated_name ? JSON.parse(item.translated_name) : {};
            } catch {
              translatedName = {};
            }

            const truncateItemName = (name) => {
              if (!name || typeof name !== 'string') return '';
              
              const maxLength = 25;
              return name.length <= maxLength 
                ? name 
                : name.slice(0, maxLength) + '...';
            };

            const dynamicObject = {};
            const dateFields = {};

            if (Array.isArray(item.item_setting_configs)) {
              item.item_setting_configs.forEach((config) => {
                dynamicObject[config.name] = config.free_text || "";
              });
            }

            const baseData = {
              "S. No.": startIndex + index,
              "Item Name":
                userLanguage === "hi" &&
                item.translated_name &&
                translatedName.hi
                  ? truncateItemName(translatedName.hi)
                  : truncateItemName(item.item_name),
                "Item Code": item.item_code,
                "Sales Price": `₹${item.sales_price}`,
                "Purchase Price": item?.purchase_price ? `₹${item?.purchase_price?.toFixed(2)}` : `₹0.00`, 
                Quantity: (
                  <div>
                    <span className="qty-item-value">{item.quantity}</span>
                    {item.quantity !== -1 && (
                      <span className="Qty-edit-icon">
                        <BsPencilSquare onClick={() => openQtyEditPopup(item)} />
                      </span>
                    )}
                  </div>
                ),
                "Tax": (
                  <div>
                    {item.tax_amount} 
                    {item.tax_amount !== 0 && item.tax_type !== "Group" && item?.tax_info?.tax_type && ( 
                      <span className="tax-item">
                        ({getShortTaxType(item.tax_info.tax_type)})
                      </span> 
                    )}
                  </div>
                ),
                  "Tax Type": item.tax_type,
                  "Unit": item.unit_name,
                  "Tax Inclusive": (
                    <span className={item.is_tax_inclusive ? "check-symbol" : "cross-symbol"}>
                      {item.is_tax_inclusive ? "✓" : "x"}
                    </span>
                  ),
                  "Category": item.cat_name,
                  "Sub Category": item.sub_cat_name,
                  ...dynamicObject,
                  "Item Image": item.item_url ? 
                    <Link onClick={() => openImagePopup(item.item_url)}>{t("View Image")}</Link> 
                    : null,
                "Actions": (
                  <div className="icons">
                    <Link to={`/item-details/${item.id}`} title="View Details">
                      <BsEye className="item-eye-icon" />
                    </Link>
                    <Link to={`/item-update/${item.id}`} title="Edit Item">
                      <BsPencilSquare className="edit-item-attributes" />
                    </Link>
                    <BsPrinter 
                      className="barcode-print-icon" 
                      onClick={() => openBarcodePrint(item)} 
                      title="Print Barcode" 
                    />
                  </div>
                ),
              };

              // Add pharmaceutical-specific or non-pharmaceutical fields
              if (isPharmaceutical) {
                const { date: expiryDate, quantity: expiryQuantity } = getNearestExpiryInfo(item.stocks);
                baseData['Expiry Date'] = expiryDate ? (
                  <div className="expiry-info">
                    {new Date(expiryDate).toLocaleDateString()}
                    <span className="expiry-quantity" style={{ color: 'red' }}>
                      ({expiryQuantity})
                    </span>
                  </div>
                ) : '';
              } else {
                baseData['Sales Discount'] = item.sales_discount;
                baseData['Discount Price'] = item.discount_price;
              }

              return baseData;
            })}
            onSort={handleSort}
            sortConfig={sortConfig}
            isPharmaceutical={isPharmaceutical}
          />
          {items.length === 0 && searchQuery.length > 1 && (
            <div className="no-report-data-message">{t("No match found")}</div>
          )}
          {items.length === 0 && searchQuery.length === 0 && (
            <div className="no-report-data-message">{t("No records found")}</div>
          )}
        </div>

        {totalRecords >= recordPerPage && (
          <div>
            <div className="report-pagination-info">
              <div className="report-pagination-container">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                  totalRecords={totalRecords}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedImage && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="close-sign">
              <span className="image-close" onClick={closeImagePopup}>
                &times;
              </span>
            </div>
            <div>
              <img src={selectedImage} alt="Item" className="modal-image" />
            </div>
          </div>
        </div>
      )}

      {isQtyEditPopupOpen && (
        <div className="popup-overlay">
          <QuantitEdit
            className="add-close"
            item={selectedItem}
            onClose={closeQtyEditPopup}
            setItems={setItems}
          />
        </div>
      )}

      {barcodePopupOpen && (
        <div className="popup-overlay">
          {isBarcodeFeatureEnabled ? (
            <NewBarcodeOption
              onClose={handleCloseBarcodePopup}
              selectedQuantity={NewBarcodeOptions}
              selectedItem={selectedItem}
              TemplateToPrint={handleselectedTemplate}
              barcodeTemplate={barcodeTemplate}
            />
          ) : (
            <BarcodePrintOption
              onClose={handleCloseBarcodePopup}
              selectedQuantity={handlePrintBarcodes}
            />
          )}
        </div>
      )}
      {isTransferPopupOpen && (
        <div className="popup-overlay">
          <TransferItemPopup onClose={() => setIsTransferPopupOpen(false)} />
        </div>
      )}
      {isSettingPopupOpen && (
        <div className="item-add-popup">
          <div className="add-setting-close-sign">
            <ItemAdditionalSetting
              item={selectedSettingItem}
              closeSettingPopup={closeSettingPopup}
              type={"individual"}
            />
            <span className="add-close" onClick={closeSettingPopup}>
              &times;
            </span>
          </div>
        </div>
      )}
      <Copyrights />
      <NotificationContainer />
    </>
  );
}

export default ItemTable;
