import React from "react";
import { BsFileEarmarkText } from "react-icons/bs";
import { BASE_URL } from "../../Url/constants";
import ErrorNotification from "../../Notification/ErrorNotification";
import successNotification from "../../Notification/SuccessNotification";
import "./TaxBillingSettings.css";
import { sendRequest } from "../../ApiRequest";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";


const formatName = (name) => {
  return name.replace(/_/g, ' ');
};

function TaxBill({ settings, setSettings }) {
  const { t} = useTranslation();

  const handleToggle = async (optionName) => {
    setSettings(prevSettings =>
      prevSettings.map(setting =>
        setting.name === optionName
          ? {
            ...setting,
            billing_setting_config: {
              ...setting.billing_setting_config,
              status: !setting.billing_setting_config?.status,
            },
          }
          : setting
      )
    );

    const settingToUpdate = settings.find(setting => setting.name === optionName);

    const data = {
      billing_setting_config_data: [{
        billing_setting_option_id: settingToUpdate.id,
        status: !settingToUpdate.billing_setting_config?.status
      }]
    };

    try {
      await sendRequest('put', `${BASE_URL}/v1/setting/settings/update_billing_settings`, data);
      secureLocalStorage.removeItem('billing_settings');
      successNotification("Settings updated successfully");
    } catch (error) {
      ErrorNotification("Failed to save data. Please try again.");
    }
  };

  return (
    <div className="settings-container tax-billing">
      <h3><BsFileEarmarkText />{t("Tax Bill Settings")}</h3>
      <hr className="line" />
      <ul className="settings-list">
        {settings.map((setting, index) => (
          <li key={setting.name} className="settings-item">
            <span className="each-card-show">
              <label className="taxbill-item-setting-label">
                {formatName(setting.name)}
                <label className="toggle-buttons">
                  <input
                    type="checkbox"
                    className="taxbill-setting-item-check"
                    checked={setting.billing_setting_config?.status || false}
                    onChange={() => handleToggle(setting.name)}
                  />
                  <span className="tax-billing-sliders"></span>
                </label>
              </label>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TaxBill;
