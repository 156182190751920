import React, { useState, useEffect } from "react";
import './NewBarcodeOption.css';
import ErrorNotification from "../../../Notification/ErrorNotification";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import DOMPurify from 'dompurify';
import { BsCheckCircleFill } from 'react-icons/bs';

const NewBarcodeOption = ({ onClose, selectedQuantity, selectedItem, TemplateToPrint }) => {
  const [quantity, setQuantity] = useState("");
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [defaultTemplate, setDefaultTemplate] = useState(null);
  const [showAllTemplates, setShowAllTemplates] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const response = await sendRequest(
        'GET',
        `${BASE_URL}/v1/onboarding/companies/show_barcode_temp`
      );
      const defaultTemp = response.data.default_template;
      setDefaultTemplate(defaultTemp);
      setSelectedTemplate(defaultTemp);
      setTemplates(response.data.all_templates || []);
    } catch (error) {
      ErrorNotification("Failed to load barcode templates");
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateSelect = async (template) => {
    try {
      await sendRequest(
        'PATCH',
        `${BASE_URL}/v1/onboarding/companies/update_barcode_temp`,
        {
          template_id: template.id
        }
      );
      setSelectedTemplate(template);
      // Make sure to pass the html_code
      TemplateToPrint(template.html_code);
    } catch (error) {
      ErrorNotification("Failed to select template");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const quantityNumber = parseInt(quantity, 10);
    
    if (!selectedTemplate || !selectedTemplate.html_code) {
      ErrorNotification("Please select a valid template");
      return;
    }
  
    if (!isNaN(quantityNumber) && quantityNumber > 0) {
      // Pass the entire template object instead of just html_code
      selectedQuantity(quantityNumber, selectedTemplate);
      onClose();
    } else {
      ErrorNotification("Please enter a valid quantity");
    }
  };

  const renderTemplatePreview = (template) => {
    if (!template || !selectedItem) return null;
    
    let previewHtml = template.html_code
      .replace(/{item_name}/g, selectedItem.item_name || '')
      .replace(/{sales_price}/g, selectedItem.sales_price || '')
      .replace(/{barcode}/g, selectedItem.bar_code || '')
      .replace(/{company_name}/g, selectedItem.company_name || '')
      // Add more replacements as needed
    
    return (
      <div className="template-preview-wrapper">
        <div 
          className="template-preview-content"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previewHtml) }}
        />
        {template.id === selectedTemplate?.id && (
          <div className="template-selected-indicator">
            <BsCheckCircleFill className="check-icon" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="new-barcode-print-container">
      <div className="new-barcode-print-modal-content">
        <button className="new-barcode-close-btn" onClick={onClose}>&#10005;</button>
        
        <div className="new-barcode-print-layout">
          {/* Left Section - Quantity Input */}
          <div className="new-quantity-section">
            <h4>Print Settings</h4>
            <form className="new-barcode-qty-form" onSubmit={handleSubmit}>
              <div className="new-barcode-qty-form-group">
                <label htmlFor="quantity">Quantity to Print:</label>
                <input
                  id="quantity"
                  className="form-control"
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  placeholder="Enter Quantity"
                  min="1"
                />
              </div>
              
              <button 
                className="new-barcode-print-btn" 
                type="submit"
                disabled={!selectedTemplate || !quantity}
              >
                Print Barcodes
              </button>
            </form>
          </div>

          {/* Right Section - Template Preview */}
          <div className="template-section">
            <h4>Template Selection</h4>
            {loading ? (
              <div className="loading-spinner">Loading templates...</div>
            ) : (
              <div className="template-selection-container">
                <div className="default-template">
                  <h5>Default Template</h5>
                  <div 
                    className={`barcode-template-preview ${defaultTemplate?.id === selectedTemplate?.id ? 'selected' : ''}`}
                    onClick={() => handleTemplateSelect(defaultTemplate)}
                  >
                    {renderTemplatePreview(defaultTemplate)}
                  </div>
                </div>

                {templates.length > 1 && (
                  <>
                    <h5>Other Templates</h5>
                    <div className="templates-grid">
                      {templates.map((template) => (
                        template.id !== defaultTemplate?.id && (
                          <div
                            key={template.id}
                            className={`template-item ${selectedTemplate?.id === template.id ? 'selected' : ''}`}
                            onClick={() => handleTemplateSelect(template)}
                          >
                            {renderTemplatePreview(template)}
                            <p className="template-name">{template.name}</p>
                          </div>
                        )
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBarcodeOption;