import React, { useState, useEffect } from "react";
import "./AddPurchase.css";
import { getToken } from "../../SecureStorage/Token";
import Select from "react-select";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import { FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSearch } from "@fortawesome/free-solid-svg-icons";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { NotificationManager } from "react-notifications";
import { Link, useLocation } from "react-router-dom";
// import UnitOptions from "../UnitOption";
import { useTranslation } from "react-i18next";
import PaginationControls from "../../Common/PaginationControls";
import CustomTable from "../../Common/CustomTable";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import Search from "../../Search/Search";
// import FilterPurchase from "../filter-purchase/filter-purchase";
// import PurchaseReportForm from "../transaction/purchase-reports-form";
// import { BsJustify } from "react-icons/bs";
import StockPayment from "./StockPayment";
import { Modal, Button, Form } from 'react-bootstrap';
import successNotification from "../../../Notification/SuccessNotification";
import Copyrights from "../../Copyrights";
import { current_user } from "../../../Authentication/AuthenticationContext";
import PharmaPurchaseItemSettings from "./PharmaPurchaseItemSettings";
import { BsPencilSquare } from "react-icons/bs";
import PurchaseAmountEdit from "./PurchaseAmountEdit";
import InvoiceNoUpdate from "./InvoiceNoUpdate";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: "263px",
    marginBottom: "1rem",
    borderColor: state.isFocused ? "#86b7fe" : provided.borderColor,
    boxShadow: state.isFocused
      ? "0 0 0 0.25rem rgba(13, 110, 253, 0.25)"
      : provided.boxShadow,
    transition: "border-color .15s ease-in-out, box-shadow .15s ease-in-out",
    "&:hover": {
      borderColor: state.isFocused ? "#86b7fe" : provided.borderColor,
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#86b7fe" : null,
    transition: "border-color .15s ease-in-out, box-shadow .15s ease-in-out",
    color: state.isFocused ? "white" : "black",
    ":hover": {
      backgroundColor: "#002989",
      color: "white",
    },
  }),
};

const CustomDropdownIndicator = (props) => {
  return (
    <div className="search-item-cont" {...props}>
      <FontAwesomeIcon icon={faSearch} />
    </div>
  );
};

const SuccessNotification = (message) => {
  NotificationManager.success(message);
};

const AddPurchase = ({ setShowModal }) => {
  const [items, setItems] = useState([
    {
      item_name: "",
      hsn: "",
      quantity: "",
      item_code: "",
      tax_rate_id: "None",
      amount: "",
      // company_id: 1,
      unit: "",
      purchase_price: "",
    },
  ]);
  const [supplierPhone, setSupplierPhone] = useState("");
  const [taxTitles, setTaxTitles] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [itemSearchStates, setItemSearchStates] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showTaxColumns, setShowTaxColumns] = useState({ 0: false });
  const token = getToken();
  const [searchResults, setSearchResults] = useState("");
  const { t } = useTranslation();
  const [ShowAddSupplierModal, setShowAddSupplierModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isSettingPopupOpen, setIsSettingPopupOpen] = useState(false);
  const [selectedSettingItem, setSelectedSettingItem] = useState(null);
  const [isPharmaceutical, setIsPharmaceutical] = useState(false);
  const [itemSettings, setItemSettings] = useState({});

  const [totalTax, setTotalTax] = useState(0);
  // const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [currentYear] = useState(new Date().getFullYear());
  const [totalRecords, setTotalRecords] = useState(0);
  // const [selectedDuration, setSelectedDuration] = useState("TODAY");
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  // const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [PurchaseStockData, setPurchaseStockData] = useState([]);
  const [itemWiseTax, setItemWiseTax] = useState("");
  const [inputTaxValue, setInputTaxValue] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [PurchaseStatus, setPurchaseStatus] = useState("");
  const [suppliersList, setSuppliersList] = useState([]);
  const [invoiceimage, setInvoiceImage] = useState(null);
  const [fileName, setFileName] = useState("");
  const [paymentUpdated, setPaymentUpdated] = useState(false);
  const [stockCreatedBy, setStockCreatedBy] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const location = useLocation();
  const tab = location.state?.tab;
  const [activeView, setActiveView] = useState(
    tab === "transactions" ? "transactions" : "addPurchase"
  );

  const handleItemSearchDropdown = async (query, index) => {
    try {
      const response = await sendRequest(
        "get",
        `${BASE_URL}/v1/inventory_managment/items?query=${encodeURIComponent(
          query
        )}&is_normal_billing=true`
      );

      setSearchResults(
        Array.isArray(response.data.data) ? response.data.data : []
      );
    } catch (error) {
      ErrorNotification("Error searching item");
    }
  };

  const handleTaxTypeChange = (index, event) => {
    const newItems = [...items];
    newItems[index].tax_type = event.target.value;
    setItems(newItems);
    fetchTaxData(newItems[index].tax_type);
  };

  // useEffect(() => {
  //   fetchTaxData("all");
  // }, []);

  const fetchTaxData = async (tax_type) => {
    let url = `${BASE_URL}/v1/taxes/taxes`;

    if (tax_type === "fixed") {
      url += "?fixed=true";
    } else if (tax_type === "percentage") {
      url += "?percentage=true";
    }

    try {
      const response = await sendRequest(
        "GET",
        url
      );
      if (response.status === 200) {
        // const taxData = await response.json();
        const titles = response?.data?.data;
        setTaxTitles(titles);
      } else {
        ErrorNotification("Failed to fetch tax data");
      }
    } catch (error) {
      ErrorNotification("Failed to fetch tax data");
    }
  };

  const handleAddItem = () => {
    setSelectedItem(null);
    const updatedItems = Object.keys(itemSearchStates).reduce((acc, key) => {
      acc[key] = { ...items[key], isSearchingItem: false };
      return acc;
    }, {});

    setItemSearchStates(updatedItems);
    setItems([
      ...items,
      {
        item_name: "",
        hsn: "",
        quantity: "",
        item_code: "",
        tax_rate_id: "",
        amount: "",
        price_unit: "",
        tax_type: "",
      },
    ]);
  };

  const handleRemoveItem = (indexToRemove) => {
    setItems((prevItems) =>
      prevItems.filter((_, index) => index !== indexToRemove)
    );
  };

  const fetchUser = async () => {
    try {
      const currentUser = await current_user();
      setIsPharmaceutical(currentUser.company.business_type === 'Pharmaceutical');
    } catch (error) {
      console.error('Error fetching user:', error);
      setIsPharmaceutical(false);
    }
  };

  useEffect(() => {
    const initializeUser = async () => {
      await fetchUser();
    };
        initializeUser();
  }, []);

  const openSettingPopup = (item, index) => {
    setSelectedSettingItem({...item, index});
    setIsSettingPopupOpen(true);
  };

  const handleSettingsUpdate = (settings, itemIndex) => {
    setItemSettings(prev => ({
      ...prev,
      [itemIndex]: {
        mrp: settings?.MRP?.free_text || "",
        batch_no: settings?.Batch?.free_text || "",
        expiry_date: settings?.ExpiryDate?.free_text || ""
      }
    }));
  };

  const closeSettingPopup = () => {
    setIsSettingPopupOpen(false);
    setSelectedSettingItem(null);
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    if (field === "item_name") {
      newItems[index][field] = value;
      setItems(newItems);

      if (value.trim() === "") {
        setItemSearchStates((prevStates) => ({
          ...prevStates,
          [index]: {
            ...prevStates[index],
            isSearchingItem: false,
          },
        }));
        return;
      }

      setItemSearchStates((prevStates) => ({
        ...prevStates,
        [index]: {
          ...prevStates[index],
          isSearchingItem: true,
        },
      }));

      const handleDebounce = setTimeout(() => {
        handleItemSearchDropdown(value, index);
      }, 500);

      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      setDebounceTimer(handleDebounce);
    } else if (
      field === "quantity" ||
      field === "purchase_price" ||
      field === "item_code"
    ) {
      newItems[index][field] = value;

      const quantity = newItems[index].quantity || 0;
      const purchasePrice = newItems[index].purchase_price || 0;
      const amount = quantity * purchasePrice;
      newItems[index].amount = amount;

      if (selectedItem) {
        newItems[index].item_code = selectedItem.item_code;
        newItems[index].purchase_price = selectedItem.purchase_price;
        newItems[index].unit = selectedItem.unit;
        const selectedTaxRate = taxTitles.find(
          (tax) => tax.title === selectedItem.tax_rate_id
        );
        newItems[index].tax_rate_id = selectedTaxRate
          ? selectedTaxRate.title
          : "None";
        newItems[index].hsn = selectedItem.hsn;
        newItems[index].tax_amount = selectedTaxRate
          ? selectedTaxRate.tax_value
          : 0;
        newItems[index].amount += newItems[index].tax_amount;
      }
    } else if (field === "tax_rate_id") {
      newItems[index][field] = value;

      const taxRate = taxTitles.find((tax) => tax.title === value);

      if (taxRate) {
        let totalTaxAmount = taxRate.tax_value;
        const taxValue = taxRate.tax_value;
        const taxType = taxRate.tax_type;
        const amount = newItems[index].amount || 0;

        if (taxType === "Fixed") {
          totalTaxAmount = taxRate.tax_value;
        } else if (taxType === "Percentage") {
          totalTaxAmount = (amount * taxValue) / 100;
        }
        newItems[index].tax_amount = totalTaxAmount;
        newItems[index].amount = amount;
        newItems[index].purchase_tax_rate_id = taxRate.id;
        newItems[index].purchase_tax_group_id = taxRate.tax_group_id;
      } else {
        newItems[index].tax_amount = 0;
        newItems[index].purchase_tax_rate_id = null;
        newItems[index].purchase_tax_group_id = null;
      }
    } else {
      newItems[index][field] = value;
    }

    setItems(newItems);
    setSelectedItem(null);
    setSearchResults([]);
  };

  useEffect(() => {
    const calculateTotals = () => {
      const totalTax = items.reduce((acc, item) => {
        return acc + (item.tax_amount * item.quantity || 0);
      }, 0);
      setTotalTax(totalTax);
      const newtotalAmount = items.reduce((acc, item) => {
        return acc + (item.amount || 0);
      }, 0);
      setTotalAmount(newtotalAmount + totalTax);
    };

    calculateTotals();
  }, [items]);

  const handleSupplierChange = (selectedOption, actionMeta) => {
    if (actionMeta.action === "select-option") {
      const phoneNumber = selectedOption.phone || "";
      setSupplierPhone(phoneNumber);
      setSelectedSupplier(selectedOption.value[1]);
    }

    if (actionMeta.action === "clear") {
      setSupplierPhone("");
    }
  };

  const fetchSuppliers = async (query) => {
    if (query.trim() !== "") {
      try {
        const response = await sendRequest(
          "get",
          `${BASE_URL}/v1/onboarding/suppliers?query=${encodeURIComponent(
            query
          )}`,
          null
        );
        const suppliersData = response.data.data || [];

        const suggestions = suppliersData.map((supplier) => ({
          value: [supplier.email, supplier.id],
          label: `${supplier.first_name} ${supplier.last_name}`,
          phone: supplier.phone_number,
        }));
        setSuggestions(suggestions);
      } catch (error) {
        ErrorNotification("Error searching supplier");
      }
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    const getSupplierData = async () => {
      const response = await sendRequest("GET", `${BASE_URL}/v1/onboarding/suppliers`)
      if (response.status === 200) {
        setSuppliersList(response.data.data);
        console.log(response.data.data);
      }
    }
    getSupplierData();
  }, [])

  const handleInputChange = (inputValue) => {
    fetchSuppliers(inputValue);
  };

  const handleSaveBill = async () => {
    setIsButtonDisabled(true);
    try {
      const billItemsData = [];
      let validationError = false;

      for (const [index, item] of items.entries()) {
        if (!item.quantity || item.quantity <= 0) {
          ErrorNotification(`Quantity is required for : ${item.item_name}`);
          validationError = true;
        }

        if (!item.purchase_price || item.purchase_price <= 0) {
          ErrorNotification(
            `Purchase price is required for : ${item.item_name}`
          );
          validationError = true;
        }

        if (!item.item_id) {
          ErrorNotification(`${item.item_name} item not present`);
          validationError = true;
        }

        if (!item.sale_price) {
          ErrorNotification(`please add sale price for ${item?.item_name}`);
          validationError = true;
        }
        if (item.sale_price && (item.sale_price < item.purchase_price)) {
          ErrorNotification(`sale price must be greater than purchase price`);
          validationError = true;
        }

        if (item.item_id) {
          billItemsData.push({
            item_id: item.item_id,
            quantity: item.quantity,
            item_total_amount:
              item.quantity * parseFloat(item.purchase_price || 0),
            item_total_tax: item.quantity * parseFloat(item.tax_rate_id || 0),
            purchase_price: parseFloat(item.purchase_price) || 0,
            sale_price: parseFloat(item.sale_price) || 0,
            settings: itemSettings[index] || {}
          });
        }
      }

      if (validationError) {
        setIsButtonDisabled(false);
        return;
      }

      // const billData = {
      //   bill: {
      //     supplier_id: 1,
      //     total_amount: totalAmount,
      //     total_tax: totalTax ? totalTax : inputTaxValue ? inputTaxValue : 0,
      //     serial_number: "",
      //     status: 3,
      //     bill_type: 0,
      //     payment_mode: 1,
      //     bill_items_attributes: billItemsData,
      //     supplier_id: selectedSupplier ? selectedSupplier : "",
      //   }
      // };

      const formData = new FormData();

      formData.append("bill[supplier_id]", 1);
      formData.append("bill[due_amount]", inputTaxValue ? (totalAmount + inputTaxValue) : totalAmount);
      formData.append("bill[total_amount]", inputTaxValue ? (totalAmount + inputTaxValue) : totalAmount);
      formData.append("bill[total_tax]", totalTax ? totalTax : inputTaxValue ? inputTaxValue : 0);
      formData.append("bill[status]", "pending");
      formData.append("bill[bill_type]", 0);
      formData.append("bill[payment_mode]", 1);
      formData.append("bill[supplier_id]", selectedSupplier ? selectedSupplier : "");
      // formData.append("bill[bill_items_attributes]", JSON.stringify(billItemsData));
      formData.append("bill[bill_items_attributes]", JSON.stringify(billItemsData.map(item => ({
        ...item,
        settings: {
          mrp: item.settings.mrp,
          batch_no: item.settings.batch_no,
          expiry_date: item.settings.expiry_date
        }
      }))));
      if (fileName instanceof File) {
        formData.append("bill[bill_image]", fileName);
      }

      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/inventory_managment/purchase_stocks`,
        formData
      );
      if (response.status === 200) {
        SuccessNotification("Purchase Bill created. Please check reports.");
        setActiveView('transactions')
        setItems([
          {
            item_name: "",
            hsn: "",
            quantity: "",
            item_code: "",
            tax_rate_id: "",
            amount: "",
            price_unit: "",
            tax_type: "",
          },
        ]);
        setIsButtonDisabled(false);
        // navigate("/reports", { state: response.data.data.type });
      } else {
        setIsButtonDisabled(false);
        ErrorNotification("Error creating bills");
        const errorMessages = response.data.error;
        if (Array.isArray(errorMessages)) {
          const quantityError = errorMessages.find((error) =>
            error.includes("Insufficient stock for item")
          );
          if (quantityError) {
            alert(quantityError);
          } else {
            ErrorNotification("Unable to create bill");
          }
        } else {
          ErrorNotification("Unable to create bill");
        }
      }
    } catch (error) {
      ErrorNotification("Unable to create bill");
    }
  };

 
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickedInsideDropdown =
        event.target.value &&
        suggestions.some((suggestion) =>
          suggestion.label
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase())
        );

      if (!isClickedInsideDropdown) {
        setSearchResults([]);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [suggestions]);

  const handleTaxOptionChange = (e, index) => {
    const value = e.target.value;
    setShowTaxColumns((prevState) => ({
      ...prevState,
      [index]: value === "without_tax",
    }));
  };

  const fetchTransactions = async (
    pageNumber = 1,
    searchQuery = ""
  ) => {
    try {
      let request_url = `${BASE_URL}/v1/inventory_managment/purchase_stocks?page=${pageNumber}&per_page=${recordPerPage}`
      if (searchQuery) {
        request_url += `&query=${searchQuery}`;
      }
      if (supplierId) {
        request_url += `&supplier_id=${supplierId}`
      }
      if (PurchaseStatus) {
        request_url += `&status=${PurchaseStatus}`
      }

      const response = await sendRequest("GET", request_url);
      if (response.status === 200) {
        const res = response?.data;
        setPurchaseStockData(res?.data);
        console.log(res?.data);
        setPaymentUpdated(false);
        setTotalPages(res?.total_pages);
        setTotalRecords(res?.total_records);
      }
    } catch (error) {
      error?.data?.errors.map((error) => ErrorNotification(error));
    }
  };

  useEffect(() => {
    if (activeView === "transactions") {
      fetchTransactions(currentPage, searchQuery, supplierId, PurchaseStatus, currentYear);
    }
  }, [
    activeView,
    currentPage,
    searchQuery,
    // startDate,
    // endDate,
    // selectedDuration,
    // selectedMonth,
    supplierId,
    PurchaseStatus,
    paymentUpdated,
    recordPerPage,
  ]);

  const handleButtonClick = (activeView) => {
    setActiveView(activeView);
    if (activeView === "transactions") {
      fetchTransactions(currentPage, searchQuery, supplierId, PurchaseStatus);
    }
  };

  // const col_names = [
  //   t("S No."),
  //   t("Supplier Details"),
  //   t("Invoice No"),
  //   t("Amount"),
  //   t("Payment Status"),
  //   t("Status"),
  //   t("Billing Date"),
  //   t("Payment"),
  //   t("View Invoice"),
  // ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransactionModelOpen, setIsTransactionModelOpen] = useState(false);
  const [currentStockDetails, setCurrentStockDetails] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState([]);

  const openModal = (details, createdBy) => {
    if (details && details.length > 0) {
      setCurrentStockDetails(details);
      setStockCreatedBy(createdBy);
      setIsModalOpen(true);
    } else {
      ErrorNotification("No Stocks");
    }

  };

  const openTransactionModal = (details) => {
    if (details?.length > 0) {
      setTransactionDetails(details);
      setIsTransactionModelOpen(true);
    } else {
      ErrorNotification("No Transactions Available");
    }
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeTransactionModel = () => {
    setIsTransactionModelOpen(false);
  }

  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const [selectedStockData, setSelectedStockData] = useState(null);
  const [isAmountEditPopupOpen, setIsAmountEditPopupOpen] = useState(false);
  const [isInvoiceUpdatePopup, setIsInvoiceUpdatepopup] = useState(false);

  const openPaymentPopup = (stockData) => {
    setSelectedStockData(stockData);
    setIsPaymentPopupOpen(true);
  };

  const closePaymentPopup = () => {
    setIsPaymentPopupOpen(false);
    setSelectedStockData(null);
  };

  const openAmountEditPopup = (stockData) => {
    setSelectedStockData(stockData);
    setIsAmountEditPopupOpen(true);
  }

  const closeAmountEditPopup = () => {
    setIsAmountEditPopupOpen(false);
    setSelectedStockData(null);
  }

  const openInvoiceNoUpdatePopup = (stockData) => {
    setSelectedStockData(stockData);
    setIsInvoiceUpdatepopup(true);
  }
  const closeInvoiceUpdatePopup = () => {
    setIsInvoiceUpdatepopup(false);
    setSelectedStockData(null);
  }

  const updateInvoiceNo = (id, newInvoiceNo) => {
    const updatedData = PurchaseStockData.map(stock => 
      stock.id === id ? { ...stock, invoice_no: newInvoiceNo } : stock
    );
    setPurchaseStockData(updatedData);
  };

  const updatePurchaseAmount = (id, newPurchaseAmount) => {
    const updateData = PurchaseStockData.map(stock =>
      stock.id === id ? { ...stock, purchase_amount: newPurchaseAmount } : stock
    );
    setPurchaseStockData(updateData);
  }

  const col_names = [
    t("S No."),
    t("Purchase Id"),
    t("Invoice No."),
    t("Supplier Details"),
    t("Created By"),
    t("Purchase Amount"),
    t("status"),
    t("Created At"),
    t("Stocks Details"),
    t("Transactions Details"),
    t("Payments"),
    t("Purchase Receipt"),
    t("View Details")
  ]

  const tableData = PurchaseStockData.map((stockData, index) => ({
    [t("S No.")]: index + 1,
    [t("Purchase Id")]: stockData.purchase_unique_id,
    [t("Invoice No.")]: stockData?.invoice_no ? stockData?.invoice_no : 
    (<div>
      <span className="Qty-edit-icon">
        <BsPencilSquare onClick={() => openInvoiceNoUpdatePopup(stockData)} />
      </span>
    </div>),
    [t("Supplier Details")]: stockData?.supplier_details?.first_name,
    [t("Created By")]: stockData?.created_by ? stockData?.created_by : "",
    // [t("Purchase Amount")]: `₹${stockData.purchase_amount}`,
    [t("Purchase Amount")]: stockData.purchase_amount
    ? `₹${stockData.purchase_amount}`
    : (
      <div>
        <span className="Qty-edit-icon">
          <BsPencilSquare onClick={() => openAmountEditPopup(stockData)} />
        </span>
      </div>
    ),
    [t("status")]: stockData.status,
    [t("Created At")]: new Date(stockData.created_at)
      .toLocaleString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }).replace(",", ""),
    [t("Stocks Details")]: <button className="btn btn-primary mt-0" onClick={() => openModal(stockData.stock_details, stockData.created_by)}>{t("View Stocks")}</button>,
    [t("Transactions Details")]: <button className="btn btn-primary mt-0" onClick={() => openTransactionModal(stockData.transaction_details)}>{t("View Transactions")}</button>,
    [t("Payments")]: stockData.status === "success" ? (
      <button className="disabled-btn-paynow" disabled>
        {t("Pay Now")}
      </button>
    ) : (
      <button
        className="btn btn-primary mt-0"
        onClick={() => openPaymentPopup(stockData)}
      >
        {t("Pay Now")}
      </button>
    ),
    [t("Purchase Receipt")]: stockData.bill_image ? <Link onClick={() => openImagePopup(stockData.bill_image)}>{t("View Image")}</Link> : null,
    [t("View Details")]: stockData.status === "success" ? (
      <span>
        <Link to={`/invoice/${stockData?.bill_details?.bill_id}`} target="_blank">
          {t("View Details")}
        </Link>
      </span>
    ) : "",
  }));

  // const tableData = stockDatas.map((transaction, index) => ({
  //   [t("S No.")]: index + 1,
  //   [t("Supplier Details")]: `${
  //     transaction.first_name
  //       ? transaction.first_name.charAt(0).toUpperCase() +
  //         transaction.first_name.slice(1)
  //       : ""
  //   } ${
  //     transaction.last_name
  //       ? transaction.last_name.charAt(0).toUpperCase() +
  //         transaction.last_name.slice(1)
  //       : ""
  //   }`,
  //   [t("Invoice No")]: transaction.bill_number,
  //   [t("Amount")]: transaction.total_amount,
  //   [t("Payment Status")]:
  //     transaction.payment_mode.charAt(0).toUpperCase() +
  //     transaction.payment_mode.slice(1),
  //   [t("Status")]:
  //     transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1),
  //   [t("Billing Date")]: new Date(transaction.created_at).toLocaleDateString(),
  //   [t("Payment")]:
  //     transaction.status === "success" ? (
  //       <button className="btn btn-primary mt-0" disabled>
  //         {t("Pay Now")}
  //       </button>
  //     ) : (
  //       <Link
  //         to={`/transaction-details/${transaction.id}`}
  //         className="btn btn-primary mt-0"
  //       >
  //         {t("Pay Now")}
  //       </Link>
  //     ),
  //   [t("View Invoice")]: (
  //     <span>
  //       <Link to={`/invoice/${transaction.id}`} target="_blank">
  //         {t("View Details")}
  //       </Link>
  //     </span>
  //   ),
  // }));

  const handleSearch = (query) => {
    if (query.length >= 2) {
      setSearchQuery(query.trim());
      setCurrentPage(1);
    } else {
      setSearchQuery("");
      setCurrentPage(1);
    }
  };

  const paginate = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      await fetchTransactions(pageNumber);
    }
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setRecordPerPage(perPage);
    setCurrentPage(1);
  };

  // const [filter, setFilter] = useState({
  //   selectedDuration: "TODAY",
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   selectedYear: new Date().getFullYear(),
  // });

  // const handleFilterChange = (newFilter) => {
  //   setFilter(newFilter);
  //   setSelectedDuration(newFilter.selectedDuration);
  //   setStartDate(newFilter.startDate);
  //   setEndDate(newFilter.endDate);
  //   setSelectedYear(newFilter.selectedYear);
  //   setSelectedMonth(newFilter.selectedMonth);
  // };

  const handleItemWiseChange = () => {
    setItemWiseTax(!itemWiseTax);
  }

  const handleTotalTax = (tax) => {
    setInputTaxValue(tax);
  }

  const openImagePopup = (image) => {
    setInvoiceImage(image);
  };

  const closeImagePopup = () => {
    setInvoiceImage(null);
  };


  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFileName(event.target.files[0]);
    }
  };

  const handleFileButtonClick = () => {
    document.getElementById('file-input').click();
  };

  const truncateFileName = (name, length = 20) => {
    if (name.length > length) {
      return name.substring(0, length) + '...';
    }
    return name;
  };
  const handleAddSupplierClick = () => {
    setShowAddSupplierModal(true);
  };

  const handleCloseModal = () => {
    setShowAddSupplierModal(false);
  };

  const getStockDetailsTableData = currentStockDetails.map((detail) => ({
    [t("Item ID")]: detail?.item_id,
    [t("Item Name")]: detail?.item_name,
    [t("Price")]: detail?.price,
    [t("Sale Price")]: detail?.sale_price,
    [t("Quantity")]: detail?.quantity,
    [t("Expiry Date")]: detail?.expiry_date,
    [t("Created At")]: new Date(detail?.created_at)
      .toLocaleString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(",", ""),
    [t("Created By")]: stockCreatedBy ? stockCreatedBy : "",
  }));

  const stockDetailsColNames = [
    t("Item ID"),
    t("Item Name"),
    t("Price"),
    t("Sale Price"),
    t("Quantity"),
    t("Expiry Date"),
    t("Created At"),
    t("Created By")
  ];

  const getTransactionDetailsTableData = transactionDetails.map((detail) => ({
    [t("Transaction Id")]: detail?.transaction_id,
    [t("Transaction Amount")]: detail?.transaction_amount,
    [t("Due Amount")]: detail?.due_amount,
    [t("Transaction Date")]: new Date(detail?.transaction_date)
      .toLocaleString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(",", ""),
    [t("File")]: detail?.file_url,
  }));
  

  const transactionDetailsColNames = [
    t("Transaction Id"),
    t("Transaction Amount"),
    t("Due Amount"),
    t("Transaction Date"),
    t("File")
  ];

  const handlepaymentUpdated = (status) => {
    setPaymentUpdated(status);

  }
  const [supplierData, setSupplierData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company_name: '',
    company_address: '',
  });

  const handleClose = () => {
    setShowAddSupplierModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupplierData({ ...supplierData, [name]: value });
  };

  const handleAddSupplier = async () => {
    const { first_name, last_name, email, phone_number, supp_company, com_address } = supplierData;

    if (!first_name || !last_name || !email || !phone_number || !supp_company || !com_address) {
      ErrorNotification("All fields are required");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      ErrorNotification("Please enter a valid email address");
      return;
    }

    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(phone_number)) {
      ErrorNotification("Please enter a valid 10-digit phone number");
      return;
    }

    const supplier_params = {
      supplier: {
        first_name: supplierData.first_name,
        last_name: supplierData.last_name,
        email: supplierData.email,
        phone_number: supplierData.phone_number,
        supp_company: supplierData.supp_company,
        com_address: supplierData.com_address,
      },
    };

    try {
      const response = await sendRequest("POST", `${BASE_URL}/v1/onboarding/suppliers`, supplier_params);
      if (response.status === 200) {
        successNotification("Supplier created successfully");
        setSupplierPhone(supplierData?.phone_number);
      }
    } catch (error) {
      ErrorNotification(error.data.errors[0]);
    }
  };


  return (
    <>
      <div className="content">
        <div className="billing-page">
          <div className="billed-items-list">
            <div className="d-flex gap-2">
              <button
                className={`bg_purchase_button p-4 rounded-3 ${activeView === "addPurchase" ? "active_purchase_button" : ""
                  }`}
                onClick={() => handleButtonClick("addPurchase")}
              >
                {t("Add Stocks")}
              </button>
              <button
                className={`bg_purchase_button p-4 rounded-3 ${activeView === "transactions" ? "active_purchase_button" : ""
                  }`}
                onClick={() => handleButtonClick("transactions")}
              >
                {t("Transactions")}
              </button>
            </div>

            {activeView === "addPurchase" && (
              <>
                <div className="supplier-form">
                  <div className="supplier-form-sub-fields">
                    <Select
                      styles={customStyles}
                      className="select-purchase-input"
                      onChange={handleSupplierChange}
                      onInputChange={handleInputChange}
                      options={suggestions}
                      placeholder={t("Search Or Select Supplier")}
                      components={{ DropdownIndicator: CustomDropdownIndicator }}
                      handleAddSupplierClick={() => setShowAddSupplierModal(true)}
                      isClearable={true}
                      noOptionsMessage={() => (
                        <div className="no-suppliers-message" onClick={handleAddSupplierClick} style={{ cursor: 'pointer' }}>
                          <span>{t("No suppliers found.")}</span>
                          <span className="add-supplier-link">{t("Add Supplier")}</span>
                        </div>


                      )}
                    />
                    <Modal show={ShowAddSupplierModal} onHide={() => setShowAddSupplierModal(false)}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Supplier</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={handleAddSupplier}>
                          <Form.Group controlId="first_name" className="d-flex align-items-center mb-3">
                            <Form.Label className="flex-shrink-0 me-3" style={{ width: '150px' }}>First Name</Form.Label>
                            <Form.Control type="text" name="first_name" value={supplierData.first_name} maxLength={30} onChange={handleChange} required />
                          </Form.Group>
                          <Form.Group controlId="last_name" className="d-flex align-items-center mb-3">
                            <Form.Label className="flex-shrink-0 me-3" style={{ width: '150px' }}>Last Name</Form.Label>
                            <Form.Control type="text" name="last_name" value={supplierData.last_name} maxLength={30} onChange={handleChange} required />
                          </Form.Group>
                          <Form.Group controlId="email" className="d-flex align-items-center mb-3">
                            <Form.Label className="flex-shrink-0 me-3" style={{ width: '150px' }}>Email</Form.Label>
                            <Form.Control type="email" name="email" value={supplierData.email} onChange={handleChange} required />
                          </Form.Group>
                          <Form.Group controlId="phone_number" className="d-flex align-items-center mb-3">
                            <Form.Label className="flex-shrink-0 me-3" style={{ width: '150px' }}>Phone Number</Form.Label>
                            <Form.Control type="text" name="phone_number" value={supplierData.phone_number} maxLength={10} onChange={handleChange} required />
                          </Form.Group>
                          <Form.Group controlId="supp_company" className="d-flex align-items-center mb-3">
                            <Form.Label className="flex-shrink-0 me-3" style={{ width: '150px' }}>Company Name</Form.Label>
                            <Form.Control type="text" name="supp_company" value={supplierData.supp_company} maxLength={30} onChange={handleChange} required />
                          </Form.Group>
                          <Form.Group controlId="com_address" className="d-flex align-items-center mb-3">
                            <Form.Label className="flex-shrink-0 me-3" style={{ width: '150px' }}>Company Address</Form.Label>
                            <Form.Control type="text" name="com_address" value={supplierData.com_address} maxLength={60} onChange={handleChange} required />
                          </Form.Group>
                          <div className="d-flex justify-content-center mt-3">
                            <Button variant="primary" className="me-2" onClick={() => setShowAddSupplierModal(false)}>Cancel</Button>
                            <Button variant="primary" type="submit" onClick={() => setShowAddSupplierModal(false)}>Save</Button>
                          </div>
                        </Form>
                      </Modal.Body>
                    </Modal>
                    <div className="form-group">
                      <input
                        className="form-control purchase-group-input-phone"
                        placeholder={t("Phone Number")}
                        type="text"
                        value={supplierPhone}
                        onChange={(e) => setSupplierPhone(e.target.value)}
                      />
                    </div>
                    <div className="purchase-toggle-div">
                      <label className="toggle-container">
                        <input
                          type="checkbox"
                          checked={itemWiseTax}
                          onChange={handleItemWiseChange}
                        />
                        <span className="toggle-slider"></span>
                      </label>
                      <span className="purchase-toggle-label">
                        {t("Item wise Tax")}
                      </span>
                    </div>
                  </div>
                  <div>
                    <button className="purchase-bill-file" onClick={handleFileButtonClick}>
                      Upload bill file
                    </button>
                    <input
                      id="file-input"
                      type="file"
                      style={{ display: 'none' }}
                      accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
                      onChange={handleFileChange}
                    />
                    {fileName && <p style={{ marginBottom: '10px', textAlign: 'center' }}>{truncateFileName(fileName?.name)}</p>}
                  </div>
                </div>
                <table>
                  <thead className="table-thead">
                    <tr className="table-tr">
                      <th className="table-th s_no">{t("S No.")}</th>
                      <th className="table-th item_name">{t("Item Name")}</th>
                      <th className="table-th item_code">{t("Item Code")}</th>
                      <th className="table-th hsn_no">{t("HSN No.")}</th>
                      <th className="table-th qty">{t("Quantity")}</th>
                      <th className="table-th price_per_unit">{t("Price/Unit")}</th>
                      {itemWiseTax && (
                        <th className="table-th item_tax">
                          {t("Tax")}
                        </th>
                      )}
                      <th className="table-th item_amount">{t("Amount")}</th>
                    </tr>
                  </thead>
                  <tbody className="t-body">
                    {items.map((item, index) => (
                      <tr key={index} className="table-row">
                        <td>{index + 1}</td> {/* Serial number */}
                        <td className="table-td">
                          <input
                            type="text"
                            className="form-control"
                            value={item.item_name}
                            placeholder={t("Add or Search")}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "item_name",
                                e.target.value
                              )
                            }
                          />
                          {itemSearchStates[index]?.isSearchingItem &&
                            searchResults.length > 0 && (
                              <ul className="item-suggestions-sale">
                                {searchResults.length > 0 ? (
                                  searchResults.map((result) => (
                                    <li
                                      className="item_list"
                                      key={result.id}
                                      onClick={() => {
                                        const newItems = [...items];
                                        newItems[index].item_name =
                                          result.item_name;
                                        newItems[index].item_id = result.id;
                                        newItems[index].purchase_price =
                                          result.purchase_price;
                                        newItems[index].sale_price =
                                          result.sale_price;
                                        newItems[index].item_code =
                                          result.item_code;
                                        newItems[index].unit = result.unit_name;
                                        newItems[index].hsn = result.hsn;
                                        newItems[index].tax_amount =
                                          result.purchase_tax_info.tax_value;
                                        const selectedTaxRateId =
                                          result.tax_rate_id;
                                        const selectedTaxTitle = taxTitles.find(
                                          (tax) => tax.id === selectedTaxRateId
                                        )?.title;

                                        newItems[index].tax_rate_id =
                                          selectedTaxTitle || "None";
                                        const selectedTax = taxTitles.find(
                                          (tax) => tax.id === selectedTaxRateId
                                        );
                                        newItems[index].tax_type = selectedTax
                                          ? selectedTax.tax_type.toLowerCase()
                                          : "";
                                        setItems(newItems);
                                        setSelectedItem(null);
                                        setItemSearchStates((prevStates) => ({
                                          ...prevStates,
                                          [index]: {
                                            ...prevStates[index],
                                            isSearchingItem: false,
                                          },
                                        }));
                                        setSearchResults([]);
                                      }}
                                    >
                                      {result.item_name}
                                    </li>
                                  ))
                                ) : (
                                  <li>{t("No results found")}</li>
                                )}
                              </ul>
                            )}
                        </td>
                        <td className="number-input-wrapper table-td">
                          <input
                            readOnly
                            type="text"
                            value={item.item_code || ""}
                            name="item_code"
                            placeholder={t("Code")}
                            className="qty_input input-select number-input form-control it"
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "item_code",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td className="number-input-wrapper table-td">
                          <input
                            readOnly
                            type="text"
                            className="input-select number-input form-control hs"
                            value={item.hsn || ""}
                            placeholder={t("HSN No.")}
                            onChange={(e) =>
                              handleItemChange(index, "hsn", e.target.value)
                            }
                          />
                        </td>
                        <td
                          className="number-input-wrapper table-td"
                          style={{ width: "120px" }}
                        >
                          <input
                            type="number"
                            value={item.quantity || ""}
                            name="quantity"
                            className="input-select number-input form-control qu"
                            placeholder={t("Quantity")}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "quantity",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                        </td>
                        <td className="number-input-wrapper table-td">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              color: "white",
                            }}
                          >
                            <input
                              type="text"
                              style={{ width: "120px" }}
                              className="number-input form-control"
                              value={item.purchase_price || ""}
                              name="purchase_price"
                              placeholder={t("Purchase Price")}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  handleItemChange(index, "purchase_price", value === "" ? "" : parseFloat(value));
                                }
                              }}
                            />
                            <input
                              type="text"
                              style={{ width: "120px" }}
                              className="number-input form-control"
                              value={item.sale_price || ""}
                              name="sale_price"
                              placeholder={t("Sales Price")}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  handleItemChange(index, "sale_price", value === "" ? "" : parseFloat(value));
                                }
                              }}
                            />
                            <select
                              className="form-select mb-2"
                              onChange={(e) => handleTaxOptionChange(e, index)}
                            >
                              <option className="option_input" value="with_tax">
                                {t("With Tax")}
                              </option>
                              <option value="without_tax">
                                {t("Without Tax")}
                              </option>
                            </select>
                          </div>
                        </td>
                        {
                          <>
                            {itemWiseTax && (
                              <>
                                <td className="table-td tx">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    className="gap-2"
                                  >
                                    <select
                                      value={items[index].tax_type}
                                      onChange={(event) =>
                                        handleTaxTypeChange(index, event)
                                      }
                                      className="form-select mb-2"
                                    >
                                      <option value="all" key="all">
                                        {t("Type")}
                                      </option>
                                      <option value="fixed" key="fixed">
                                        {t("Fixed")}
                                      </option>
                                      <option value="percentage" key="percentage">
                                        {t("Percentage")}
                                      </option>
                                    </select>

                                    <select
                                      className="form-select mb-2"
                                      value={item.tax_rate_id}
                                      onChange={(e) =>
                                        handleItemChange(
                                          index,
                                          "tax_rate_id",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">{t("Taxes")}</option>
                                      {taxTitles.map((taxTitle, index) => (
                                        <option key={index} value={taxTitle.title}>
                                          {taxTitle.title}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                              </>
                            )}
                            <td className="table-td ">
                              <div className="amount">
                                <input
                                  className="input-select form-control am"
                                  type="number"
                                  value={
                                    item.quantity > 0
                                      ? parseFloat(
                                        (item.amount ? item.amount : 0) +
                                        (item.tax_amount
                                          ? item.tax_amount * item.quantity
                                          : 0)
                                      ).toFixed(2) || ""
                                      : 0
                                  }
                                  disabled
                                />
                               
                                <FontAwesomeIcon
                                  icon={faCog}
                                  className="setting-icon"
                                  onClick={() => openSettingPopup(item, index)}
                                  title="Settings"
                                />
                                <FaTrash
                                  className="edit-trash"
                                  onClick={() => handleRemoveItem(index)}
                                />
                              </div>
                            </td>
                          </>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="total-section">
                  <div className="total-sub-section">
                    <span onClick={handleAddItem} className="add-btn">
                      + {t("Add Item")}
                    </span>
                    {!itemWiseTax && (
                      <span>
                        <span className="purchase-item-tax">
                          <input
                            type="number"
                            style={{ width: "120px" }}
                            className="number-input form-control"
                            value={inputTaxValue}
                            placeholder={t("Total Tax")}
                            onChange={(e) =>
                              handleTotalTax(parseFloat(e.target.value))
                            }
                          />
                        </span>
                      </span>
                    )}
                  </div>
                  <span className="cal-total-purchase">
                    {inputTaxValue ? (
                      <>
                        {t("Total")}: ₹ {(totalAmount + inputTaxValue)?.toFixed(2) || "0.00"}
                        ({t("Tax")}: ₹{" "} {inputTaxValue || "0.00"})
                      </>
                    ) : (
                      <>
                        {t("Total")}: ₹ {(totalAmount).toFixed(2)}({t("Tax")}: ₹{" "}
                        {totalTax || "0.00"})
                      </>

                    )}

                  </span>
                </div>
                <button className="save-btn" onClick={handleSaveBill} disabled={isButtonDisabled}>
                  {t("Save")}
                </button>
              </>
            )}

            {activeView === "transactions" && (
              <div className="transactions-list">
                {/* <PurchaseReportForm t={t} onFilterChange={handleFilterChange} /> */}
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
                      <EntriesPerPageDropdown
                        recordPerPage={recordPerPage}
                        handleRecordPerPageChange={handleRecordPerPageChange}
                      />
                    </div>
                    {/* <div className="col mt-2">
                      <FilterPurchase
                        selectedDuration={filter.selectedDuration}
                        startDate={filter.startDate}
                        endDate={filter.endDate}
                        selectedYear={filter.selectedYear}
                        selectedMonth={filter.selectedMonth}
                      />
                    </div> */}

                    <div className="col-md-2 align-content-center">
                      <select
                        className="form-select"
                        value={supplierId}
                        onChange={(e) => {
                          setSupplierId(e.target.value);
                        }}
                      >
                        <option value="">Select Supplier</option>
                        {suppliersList.map((supplier) => (
                          <option key={supplier.id} value={supplier.id}>
                            {`${supplier.first_name} ${supplier.last_name}`}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-2 align-content-center">
                      <select
                        className="form-select"
                        value={PurchaseStatus}
                        onChange={(e) => {
                          setPurchaseStatus(e.target.value);
                        }}
                      >
                        <option value="">Select Status</option>
                        <option value="pending">Pending</option>
                        <option value="success">Success</option>
                        <option value="failed">Failed</option>
                      </select>
                    </div>
                    <div
                      className="col-md-2 align-content-center"
                      value={searchQuery}
                      onChange={(e) => handleSearch(e.target.value)}
                    >
                      <Search placeholder={"Search Transactions ..."} />
                    </div>
                  </div>
                </div>
                <div className="bd-example sp-table sp-table-header">
                  <div className="report-table-data">
                    <CustomTable col_names={col_names} data={tableData} />
                  </div>
                </div>

                {PurchaseStockData.map((index) => (
                  <div key={index}></div>
                ))}

                {PurchaseStockData.length > 0 && (
                  <div>
                    {totalRecords >= recordPerPage && (
                      <div className="report-pagination-info">
                        <div className="report-pagination-container">
                          <PaginationControls
                            currentPage={currentPage}
                            totalPages={totalPages}
                            totalRecords={totalRecords}
                            setCurrentPage={setCurrentPage}
                            paginate={paginate}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {PurchaseStockData.length === 0 && searchQuery.length > 1 && (
                  <div className="no-report-data-message">{t("No match found")}</div>
                )}
                 {PurchaseStockData.length === 0 && searchQuery.length === 0 && (
                    <div className="no-report-data-message">{t("No records found")}</div>
                )}
              </div>
            )}
            {isModalOpen && (
              <div className="stock-purchase-modal-overlay">
                <div className="stock-purchase-modal-content">
                  <div className="stock-purchase-close-button" onClick={closeModal}>
                    &times;
                  </div>
                  <div className="stock-purchase-detail-modal-body">
                    {currentStockDetails ? (
                      <CustomTable
                        col_names={stockDetailsColNames}
                        data={getStockDetailsTableData}
                      />
                    ) : (
                      <p>{t("No stock details available.")}</p>
                    )}
                  </div>
                </div>
              </div>
            )}
            {isTransactionModelOpen && (
              <div className="stock-purchase-modal-overlay">
                <div className="stock-purchase-modal-content">
                  <div className="stock-purchase-close-button" onClick={closeTransactionModel}>
                    &times;
                  </div>
                  <div className="stock-purchase-modal-body">
                    {transactionDetails ? (
                      <CustomTable
                        col_names={transactionDetailsColNames}
                        data={getTransactionDetailsTableData}
                      />
                    ) : (
                      <p>{t("No stock details available.")}</p>
                    )}
                  </div>
                </div>
              </div>
            )}
            {isPaymentPopupOpen && (
              <StockPayment stockData={selectedStockData} onClose={closePaymentPopup} setPaymentUpdated={handlepaymentUpdated} supplierList={suppliersList} />
            )}
            {invoiceimage && (
              <div className="purchase-invoice-popup-overlay">
                <div className="purchase-invoice-popup-content">
                  <div className="purchase-invoice-close-sign">
                    <span className="purchase-image-popup-close" onClick={closeImagePopup}>
                      &times;
                    </span>
                  </div>
                  <div>
                    <img
                      src={invoiceimage}
                      alt="Purchase Invoice"
                      className="purchase-invoice-image"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isSettingPopupOpen && (
        <div className="item-add-popup">
          <div className="add-setting-close-sign">
            <PharmaPurchaseItemSettings
              item={selectedSettingItem}
              closeSettingPopup={closeSettingPopup}
              type={"stock_wise"}
              isPharmaceutical={isPharmaceutical}
              handleSettingsUpdate={handleSettingsUpdate}
            />
            <span className="add-close" onClick={closeSettingPopup}>
              &times;
            </span>
          </div>
        </div>
      )}
      {isInvoiceUpdatePopup && (
        <div className="purchase-amount-popup-overlay">
          <InvoiceNoUpdate
            className="add-close"
            item={selectedStockData}
            onClose={closeInvoiceUpdatePopup}
            updateInvoiceNo={updateInvoiceNo}
          />
        </div>
      )}
      {isAmountEditPopupOpen && (
        <div className="purchase-amount-popup-overlay">
          <PurchaseAmountEdit
            className="add-close"
            item={selectedStockData}
            onClose={closeAmountEditPopup}
            updatePurchaseAmount={updatePurchaseAmount}
          />
        </div>
      )}
      <Copyrights />
    </>
  );
};

export default AddPurchase;
