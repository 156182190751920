import React, { useEffect, useState } from 'react';
import './DeliveryChallanLetterPadTemplate.css';
import { current_user } from '../../../../Authentication/AuthenticationContext';

function DeliveryChallanLetterPadTemplate({ onClose, deliveryChallanPrint, removeTablehead, enabledTemplateDetails }) {
  const [isCompanydata, setCompanyData] = useState("");

  const renderDeliveryChallanLetterPadTable = () => {
    return (
      <div>
        {deliveryChallanPrint?.bill_items && deliveryChallanPrint.bill_items.length > 0 ? (
          <>
            <table className="delivery-challan-letter-pad-temp-table">
              <thead>
                <tr className='delivery-challan-letter-pad-temp-tab-head-row'>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>SNo</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Item</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>HSN</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Qty</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Unit</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Rate</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Tax</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Amount</th>
                </tr>
              </thead>
              <tbody>
                {deliveryChallanPrint?.bill_items && deliveryChallanPrint?.bill_items.map((item, index) => (
                  <tr key={index} className='delivery-challan-letter-pad-temp-table-body-row'>
                    <td>{index + 1}</td>
                    <td className='delivery-challan-letter-pad-temp-table-body-data'>{item?.name}</td>
                    <td className='delivery-challan-letter-pad-temp-table-body-data'>
                      {item.hsn_number}
                    </td>
                    <td className='delivery-challan-letter-pad-temp-table-body-data'>{item?.quantity}</td>
                    <td className='delivery-challan-letter-pad-temp-table-body-data'>{item?.unit?.unit_name}</td>
                    <td className='delivery-challan-letter-pad-temp-table-body-data'>{item?.sales_price.toFixed(2)}</td>
                    <td className='delivery-challan-letter-pad-temp-table-body-data'>
                      <div className='delivery-challan-letter-pad-temp-tax-details'>
                        {item?.tax_details?.length > 0
                          ? `${item.tax_details.join(", ")} (${item?.tax_inclusive ? "Inc" : "Exc"})`
                          : ""}
                      </div>
                      {item?.item_total_tax.toFixed(2)}
                    </td>
                    <td className='delivery-challan-letter-pad-temp-table-body-data'>{(item?.quantity * item?.sales_price).toFixed(2)}</td>
                  </tr>
                ))}
                <tr className='delivery-challan-letter-pad-temp-calculation-row'>
                  <td colSpan={6} className='delivery-challan-letter-pad-temp-extra-row'>
                    <span className='delivery-challan-letter-pad-temp-extra-row-data'>Sub Total</span>
                  </td>
                  <td></td>
                  <td>{deliveryChallanPrint?.sub_total?.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
            <table className="delivery-challan-letter-pad-temp-footer-table">
              <tbody>
                <tr className='delivery-challan-letter-pad-temp-table-footer-div'>
                  <td colSpan={3} rowSpan={5} className='delivery-challan-letter-pad-temp-table-footer-binvoice'>
                    <div className="delivery-challan-letter-pad-temp-footer-data">
                      <div className="delivery-challan-letter-pad-temp-footer-data-row">
                        <span className="delivery-challan-letter-pad-temp-footer-label">Sub Total:</span>
                        <span className="delivery-challan-letter-pad-temp-footer-value">{deliveryChallanPrint?.sub_total?.toFixed(2)}</span>
                      </div>
                      <div className="delivery-challan-letter-pad-temp-footer-data-row">
                        <span className="delivery-challan-letter-pad-temp-footer-label">Discount:</span>
                        <span className="delivery-challan-letter-pad-temp-footer-value">{deliveryChallanPrint?.total_discount?.toFixed(2) || '0.00'}</span>
                      </div>
                      <div className="delivery-challan-letter-pad-temp-footer-data-row">
                        <span className="delivery-challan-letter-pad-temp-footer-label">Parcel Charges:</span>
                        <span className="delivery-challan-letter-pad-temp-footer-value">{deliveryChallanPrint?.parcel_charges?.toFixed(2) || '0.00'}</span>
                      </div>
                      {deliveryChallanPrint?.is_gst_enable && deliveryChallanPrint?.is_igst_enable ? (
                        <div className="delivery-challan-letter-pad-temp-footer-data-row">
                          <span className="delivery-challan-letter-pad-temp-footer-label">IGST:</span>
                          <span className="delivery-challan-letter-pad-temp-footer-value">{deliveryChallanPrint?.total_gst?.toFixed(2) || '0.00'}</span>
                        </div>
                      ) : (
                        <>
                          <div className="delivery-challan-letter-pad-temp-footer-data-row">
                            <span className="delivery-challan-letter-pad-temp-footer-label">SGST:</span>
                            <span className="delivery-challan-letter-pad-temp-footer-value">{(deliveryChallanPrint?.total_gst / 2).toFixed(2) || '0.00'}</span>
                          </div>
                          <div className="delivery-challan-gen-temp-footer-data-row">
                            <span className="delivery-challan-gen-temp-footer-label">CGST:</span>
                            <span className="delivery-challan-gen-temp-footer-value">{(deliveryChallanPrint?.total_gst / 2).toFixed(2) || '0.00'}</span>
                          </div>
                        </>
                      )}
                      <div className="delivery-challan-letter-pad-temp-footer-data-row">
                        <span className="delivery-challan-letter-pad-temp-footer-label">TOTAL:</span>
                        <span className="delivery-challan-letter-pad-temp-footer-value">{deliveryChallanPrint?.total_amount?.toFixed(2) || '0.00'}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <>
            <table className="delivery-challan-letter-pad-temp-table">
              <thead>
                <tr className='delivery-challan-letter-pad-temp-tab-head-row'>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>SNo</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Item</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>HSN</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Qty</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Unit</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Rate</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Tax</th>
                  <th className='delivery-challan-letter-pad-temp-table-header-attribute'>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr className='delivery-challan-letter-pad-temp-table-body-row'>
                  <td>1</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>item1</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>
                    12345
                  </td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>1</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>piece</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>2000</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>
                    <div className='delivery-challan-letter-pad-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>2000</td>
                </tr>
                <tr className='delivery-challan-letter-pad-temp-table-body-row'>
                  <td>2</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>item2</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>
                    12345
                  </td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>1</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>piece</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>2000</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>
                    <div className='delivery-challan-letter-pad-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>2000</td>
                </tr>
                <tr className='delivery-challan-letter-pad-temp-table-body-row'>
                  <td>3</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>item3</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>
                    12345
                  </td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>1</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>piece</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>2000</td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>
                    <div className='delivery-challan-letter-pad-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='delivery-challan-letter-pad-temp-table-body-data'>2000</td>
                </tr>
                <tr className='delivery-challan-letter-pad-temp-calculation-row'>
                  <td colSpan={6} className='delivery-challan-letter-pad-temp-extra-row'>
                    <span className='delivery-challan-letter-pad-temp-extra-row-data'>Sub Total</span>
                  </td>
                  <td></td>
                  <td>6000</td>
                </tr>
              </tbody>
            </table>
            <table className="delivery-challan-letter-pad-temp-footer-table">
              <tbody>
                <tr className='delivery-challan-letter-pad-temp-table-footer-div'>
                  <td colSpan={3} rowSpan={5} className='delivery-challan-letter-pad-temp-table-footer-binvoice'>
                    <div className="delivery-challan-letter-pad-temp-footer-data">
                      <div className="delivery-challan-letter-pad-temp-footer-data-row">
                        <span className="delivery-challan-letter-pad-temp-footer-label">Sub Total:</span>
                        <span className="delivery-challan-letter-pad-temp-footer-value">6000</span>
                      </div>
                      <div className="delivery-challan-letter-pad-temp-footer-data-row">
                        <span className="delivery-challan-letter-pad-temp-footer-label">Discount:</span>
                        <span className="delivery-challan-letter-pad-temp-footer-value">0.0</span>
                      </div>
                      <div className="delivery-challan-letter-pad-temp-footer-data-row">
                        <span className="delivery-challan-letter-pad-temp-footer-label">Parcel Charges:</span>
                        <span className="delivery-challan-letter-pad-temp-footer-value">0.0</span>
                      </div>
                      <div className="delivery-challan-letter-pad-temp-footer-data-row">
                        <span className="delivery-challan-letter-pad-temp-footer-label">TOTAL:</span>
                        <span className="delivery-challan-letter-pad-temp-footer-value">6000</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setCompanyData(currentUser.company);
    };
    fetchUser();
  }, []);

  const getOption = (optionName) => {
    return enabledTemplateDetails?.find((opt) => opt?.option_name === optionName) || {};
  };

  const currentTime = new Date().toLocaleTimeString();

  return (
    <>
      <div className="delivery-challan-letter-pad-main-container">
        {!removeTablehead && (
          <div className="delivery-challan-letter-pad-header-div">
            <span className='delivery-challan-letter-pad-header-left-title'>
              Template</span>
            <span className='delivery-challan-letter-pad-header-right-title' onClick={onClose}>x</span>
          </div>
        )}
        <div className="delivery-challan-letter-pad-sub-container">
          <div className='delivery-challan-letter-pad-header-grp'>
            {getOption("text_title")?.is_enable && (
              <span className='delivery-challan-letter-pad-header-title'>
                {getOption("text_title")?.free_text ? getOption("text_title")?.free_text : "Delivery Challan"}
              </span>
            )}
            <div className='delivery-challan-letter-pad-horizontal-line'></div>
          </div>
          <div className="delivery-challan-letter-pad-cust-info-grp">
            <div className='delivery-challan-letter-pad-cust-info-div'>
              <span className='delivery-challan-letter-pad-cust-title'>
                Issued to
              </span>
              <span className='delivery-challan-letter-pad-cust-sub-titles'>
                Customer Company: {deliveryChallanPrint?.customer?.company_name}
              </span>
              {getOption("cust_name")?.is_enable && (
                <span className='delivery-challan-letter-pad-cust-sub-titles'>
                  Customer Name: {deliveryChallanPrint?.customer?.customer_name}
                </span>
              )}
              {getOption("cust_address")?.is_enable && (
                <span className='delivery-challan-letter-pad-cust-sub-titles'>
                  {deliveryChallanPrint?.customer?.billing_address_one
                    ||
                    deliveryChallanPrint?.customer?.billing_address_two}
                </span>
              )}
              {getOption("cust_phone")?.is_enable && (
                <span className='delivery-challan-letter-pad-cust-sub-titles'>
                  Phone : {deliveryChallanPrint?.customer?.mobile_number}
                </span>
              )}
              {getOption("cust_email")?.is_enable && (
                <span className='delivery-challan-letter-pad-cust-sub-titles'>
                  Email : {deliveryChallanPrint?.customer?.email}
                </span>
              )}
              {getOption("cust_gst")?.is_enable && (
                <span className='delivery-challan-letter-pad-cust-sub-titles'>
                  GSTIN : {deliveryChallanPrint?.customer?.gst_number}
                </span>
              )}
              {/* <span className='delivery-challan-letter-pad-cust-sub-titles'>
                POS : Tamil Nadu
              </span> */}
            </div>
            <div className='delivery-challan-letter-pad-cust-info-div'>
              {getOption("invoice_number")?.is_enable && (
                <span className='delivery-challan-letter-pad-cust-sub-titles'>
                  q_text : {deliveryChallanPrint?.bill_number}
                </span>
              )}
              {getOption("date")?.is_enable && (
                <span className='delivery-challan-letter-pad-cust-sub-titles'>
                  Date : {deliveryChallanPrint?.date
                    ? new Date(deliveryChallanPrint?.date).toLocaleDateString('en-GB')
                    : ''}
                </span>
              )}
              {getOption("time")?.is_enable && (
                <span className='delivery-challan-letter-pad-cust-sub-titles'>
                  Time : {currentTime}
                </span>
              )}
            </div>
          </div>
          <div className='delivery-challan-letter-pad-horizontal-line'></div>
          <div className="delivery-challan-letter-pad-order-info-grp">
            {getOption("cust_order_no")?.is_enable && (
              <div className="delivery-challan-letter-pad-order-info">
                <span className='delivery-challan-letter-pad-order-no-title'>Buyer's Order No#:</span>
                <span className='delivery-challan-letter-pad-order-value'>{deliveryChallanPrint?.order_no}</span>
              </div>
            )}
            {getOption("cust_order_date")?.is_enable && (
              <div className="delivery-challan-letter-pad-order-info">
                <span className='delivery-challan-letter-pad-order-date-title'>Buyer's Order Date:</span>
                <span className='delivery-challan-letter-pad-order-value'>
                  {deliveryChallanPrint?.order_date
                    ? new Date(deliveryChallanPrint?.order_date).toLocaleDateString('en-GB')
                    : ''}
                </span>
              </div>
            )}
          </div>
          <div className='delivery-challan-letter-pad-horizontal-line'></div>
          <div className='delivery-challan-letter-pad-table-grp-container'>
            {renderDeliveryChallanLetterPadTable()}
          </div>
          <div className="delivery-challan-letter-pad-temp-signature-section">
            <div className='delivery-challan-letter-pad-signature-first-div'>
              {getOption("terms_conditions")?.is_enable && (
                <div className='delivery-challan-letter-pad-signature-first-part'>
                  <span className='delivery-challan-letter-pad-temp-first-title'>Terms & Conditions :</span>
                  <span className='delivery-challan-letter-pad-temp-first-title'>
                    Goods once sold will not be taken back
                  </span>
                </div>
              )}
              <div className='delivery-challan-letter-pad-signature-second-part'>
                <span className='delivery-challan-letter-pad-temp-first-title'>For {isCompanydata?.name}</span>
              </div>
            </div>
            <div className='delivery-challan-letter-pad-signature-second-div'>
              {getOption("cust_signature")?.is_enable && (
                <span className='delivery-challan-letter-pad-temp-first-title'>Customer Signature</span>
              )}
              {getOption("authorized_signature")?.is_enable && (
                <span className='delivery-challan-letter-pad-temp-first-title'>Authorized Signature</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryChallanLetterPadTemplate;
