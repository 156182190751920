import React, { useState } from "react";
import './RestourentBillings.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faHeart } from "@fortawesome/free-solid-svg-icons";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";

const GroupSubCategoryItem = ({ item, isActive }) => {
  const firstFlag = item.flags?.[0];
  const itemTypeClass = firstFlag === "veg" ? 'veg' : firstFlag === "non-veg" ? 'non-veg' : '';
  const [updateItem, setUpdatedItem] = useState([]);

  const handleFavouriteItem = async () => {
    const response = await sendRequest(
      "PUT",
      `${BASE_URL}/v1/inventory_managment/items/${item.id}`,
      { item: { is_favourite: true } },
    );
    if (response.status === 200) {
      const fav = response.data.data
      setUpdatedItem(fav);
    }
  };

  const truncateText = (text, maxLength) => {
    if (!text) return ""; 
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };  

  return (
    <div>
      <div
        className={`card-content-item-category-kot ${isActive?.length > 0 && isActive?.includes(item.id) ? 'active' : ''
          }`}
        style={{
          backgroundImage: item?.item_image_url
            ? `url(${item.item_image_url})`
            : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="card-content-both">
          {firstFlag === 'veg' || firstFlag === 'non-veg' ? (
            <span className="item-type">
              <FontAwesomeIcon
                icon={faCircle}
                className={`item-kot-type ${itemTypeClass}`}
              />
            </span>
          ) : (
            ''
          )}
          <span className="item-card-heart-icon">
            <FontAwesomeIcon
              icon={faHeart}
              className={`heart-icon ${updateItem.is_favourite || item.is_favourite ? 'item-heart-icon-red' : ''}`}
              onClick={handleFavouriteItem}
            />
          </span>
        </div>
        <div className="kot-container">
          <div className="price-of-item-kot">
            <span className="item-price">
              {item?.sale_amount?.toLocaleString('en-IN', {
                style: 'currency',
                currency: 'INR',
              })}
            </span>
          </div>
        </div>
        <div className="item-kot-name">
          <span className="cat-font-size">
            {truncateText(item?.item_name, 15)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupSubCategoryItem;
