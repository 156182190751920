import React, { useState, useEffect } from "react";
import "./CommonSalePopupTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import { orderBillingSettings } from "../../../Authentication/AuthenticationContext"; 

function CommonSalePopupTable({
  updatedSelectedItems,
  totalAmountValue,
  subTotalValue,
  gstValue,
  discountValue,
  packagingValue,
  quotationCopy,
  saleOrderCopy,
  saleInvoiceCopy,
  saleDeliveryChallanCopy,
  saleCreditNoteCopy,
  saleDebitNoteCopy,
  isQuotationInvoice,
  isQuotationSaleOrder,
  isSaleOrderInvoice,
  isSaleOrderDeliveryChallan,
  selectedChallanRecords,
  isGstEnable,
  isIgstEnable,
  isTotalTaxApplied,
  selectedBillItems,
  challanOptions,
  purCreditNoteCopy,
  purchaseReceiptCopy,
  purDebitNoteCopy,
  type,
}) {

  const mapBillItems = (billItems = []) => {
    return billItems.map((billItem) => {
      const quantity = billItem?.quantity || 0;
      const salesPrice = billItem?.sales_price || 0;
      const purchasePrice = billItem.purchase_price || 0;
      const itemTotalTax = billItem?.item_total_tax || 0;
      const itemTotalAmount = billItem?.item_total_amount || 0;
      const itemTaxDetails = billItem?.tax_details ? billItem?.tax_details : 0;

      return {
        id: billItem?.id || "",
        item: billItem?.name || "",
        item_id: billItem?.item_id || "",
        hsn: billItem?.hsn_number || "",
        tax: itemTotalTax,
        qty: quantity,
        unit: billItem?.unit?.unit_name || "",
        discount: billItem?.item_total_discount || 0,
        amount: itemTotalAmount,
        saleAmount: itemTotalAmount,
        salesPrice: salesPrice,
        purchasePrice: purchasePrice,
        discountAmount: billItem?.item_total_discount || 0,
        taxAmount: (itemTotalTax * quantity).toFixed(2),
        total_actual_price: (salesPrice * quantity).toFixed(2),
        total_taxable_price: (itemTotalAmount * quantity).toFixed(2),
        taxLabel: itemTaxDetails,
      };
    });
  };

  const mapIfExists = (copy) => {
    return Array.isArray(copy?.bill_items) ? mapBillItems(copy.bill_items) : [];
  };

  // Constructing the existingBillItems array.
  const existingBillItems = [
    ...mapIfExists(quotationCopy),
    ...mapIfExists(saleOrderCopy),
    ...mapIfExists(saleInvoiceCopy),
    ...mapIfExists(saleDeliveryChallanCopy),
    ...mapIfExists(saleCreditNoteCopy),
    ...mapIfExists(saleDebitNoteCopy),
    ...mapIfExists(isQuotationInvoice),
    ...mapIfExists(isQuotationSaleOrder),
    ...mapIfExists(isSaleOrderInvoice),
    ...mapIfExists(isSaleOrderDeliveryChallan),
    ...mapIfExists(challanOptions),
    ...mapIfExists(purCreditNoteCopy),
    ...mapIfExists(purchaseReceiptCopy),
    ...mapIfExists(purDebitNoteCopy),
    ...(Array.isArray(selectedChallanRecords)
      ? selectedChallanRecords.reduce((acc, record) => {
        if (Array.isArray(record?.bill_items)) {
          acc.push(...mapBillItems(record.bill_items));
        }
        return acc;
      }, [])
      : []),
  ];

  useEffect(() => {
    if (selectedBillItems && selectedBillItems.length > 0) {
      const mappedItems = mapBillItems(selectedBillItems);
      setRows(mappedItems);
      updatedSelectedItems(mappedItems);
    } else {
      setRows([]);
    }
  }, [selectedBillItems]);

  const [rows, setRows] = useState(
    existingBillItems.length > 0
      ? existingBillItems.map((item) => ({
        id: item.id || 1,
        item: item.item || "",
        item_id: item.item_id || "",
        hsn: item.hsn || "",
        tax: item.tax || "",
        taxLabel: item.taxLabel || "",
        qty: item.qty || "",
        unit: item.unit || "",
        rate: item.rate || "",
        mrp: item.mrp || "",
        discount: item.discount || "",
        saleRate: item.saleRate || "",
        amount: item.amount || "",
        saleAmount: item.saleAmount || "",
        salesPrice: item.salesPrice || "",
        purchasePrice: item.purchasePrice || "",
        discountAmount: item.discountAmount || "",
        taxAmount: item.taxAmount || "",
        total_actual_price: item.total_actual_price || "",
        total_taxable_price: item.total_taxable_price || "",
        stock_ids: item.stock_ids || [],
        actual_quantity: item.actual_quantity || "",
      }))
      : [
        {
          id: 1,
          item: "",
          item_id: "",
          hsn: "",
          tax: "",
          taxLabel: "",
          qty: "",
          unit: "",
          rate: "",
          mrp: "",
          discount: "",
          saleRate: "",
          amount: "",
          saleAmount: "",
          salesPrice: "",
          purchasePrice: "",
          discountAmount: "",
          taxAmount: "",
          total_actual_price: "",
          total_taxable_price: "",
          stock_ids: [],
          actual_quantity: "",
        },
      ]
  );

  const [packingCharges, setPackingCharges] = useState(0);
  const [taxRate, setTaxRate] = useState("noTax");
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [igst, setIgst] = useState(0);
  const [igstEnable, setIgstEnable] = useState(false);
  const [gstEnable, setGstEnable] = useState(false);
  const [rowSearchTerms, setRowSearchTerms] = useState({});
  const [rowDropdownVisibility, setRowDropdownVisibility] = useState({});
  const [rowFilteredItems, setRowFilteredItems] = useState({});

  useEffect(() => {
    fetchGSTSetting();
    fetchSettings();
  }, []);

  const fetchGSTSetting = async () => {
    const url = `${BASE_URL}/v1/setting/settings/get_setting_status?name=GST`;
    try {
      const response = await sendRequest("GET", url);
      if (response.status === 200) {
        const gstSetting = response.data;
        if (gstSetting) {
          setGstEnable(gstSetting.status);
          if (isGstEnable) {
            isGstEnable(gstSetting.status);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching GST setting!", error);
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await orderBillingSettings
      const igstSetting = response.find(
        (setting) => setting.name === "inter_state_GST"
      );
      if (igstSetting && igstSetting?.billing_setting_config?.status) {
        setIgstEnable(true);
        isIgstEnable(true);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  useEffect(() => {
    handleItemPriceCalculations(rows);
  }, [
    rows,
    packingCharges,
    taxRate,
    subtotal,
    discount,
    totalAmount,
    igstEnable,
    gstEnable,
  ]);

  const handleItemPriceCalculations = (rows) => {
    const itemsActualTotal = rows.reduce((acc, item) => {
      return acc + item?.salesPrice * item.qty;
    }, 0);

    const itemsTaxableTotal = rows.reduce((acc, item) => {
      return (
        acc +
        (type !== "purchase_quotation"
          ? item?.saleAmount
          : item?.purchasePrice) *
        item.qty
      );
    }, 0);

    const itemsTotalTax = rows.reduce((acc, item) => {
      return acc + item?.taxAmount * item.qty;
    }, 0);

    const itemsDiscountAmount = rows.reduce((acc, item) => {
      return acc + item?.discountAmount * item?.qty;
    }, 0);

    const sub = itemsTaxableTotal ? itemsTaxableTotal : 0;
    const dis = discount ? parseFloat(discount) : 0;
    const tax = itemsTotalTax ? itemsTotalTax : 0;
    const calculateGst =
      taxRate !== "noTax" ? sub * (taxRate / 100).toFixed(2) : 0;
    if (!isTotalTaxApplied) {
      if (calculateGst > 0) {
        if (gstEnable && igstEnable) {
          setIgst(calculateGst.toFixed(2));
        } else if (gstEnable) {
          setCgst((calculateGst / 2).toFixed(2));
          setSgst((calculateGst / 2).toFixed(2));
        }
      }
    } else {
      if (gstEnable && igstEnable) {
        setIgst(tax.toFixed(2));
      } else if (gstEnable) {
        setCgst((tax / 2).toFixed(2));
        setSgst((tax / 2).toFixed(2));
      }
    }
    setSubtotal(sub);
    const discountedSubtotal = sub - dis;
    const totalAmount =
      discountedSubtotal +
      (!isTotalTaxApplied && gstEnable
        ? calculateGst
        : isTotalTaxApplied && gstEnable
          ? tax
          : 0) +
      (packingCharges > 0 ? parseFloat(packingCharges) : 0);
    const total = totalAmount > 0 ? totalAmount : 0;
    const totalGSt =
      !isTotalTaxApplied && gstEnable
        ? calculateGst
        : isTotalTaxApplied && gstEnable
          ? tax
          : 0;

    const totalDis = ((dis ? dis : 0) + (itemsDiscountAmount ? itemsDiscountAmount : 0)).toFixed(2);

    setTotalAmount(total);
    totalAmountValue(total);
    subTotalValue(sub);
    gstValue(totalGSt);
    discountValue(totalDis);
    packagingValue(packingCharges);
  }

  const handleDiscountChange = (e) => {
    const newDiscount = e.target.value === "" ? "" : Number(e.target.value);
    if (newDiscount === "" || (newDiscount >= 0 && newDiscount <= subtotal)) {
      setDiscount(newDiscount);
      handleItemPriceCalculations(rows);
    }
  };

  useEffect(() => {
    fetchGSTSetting();
    fetchSettings();
  }, []);

  useEffect(() => {
    if (quotationCopy || selectedChallanRecords || saleInvoiceCopy || saleOrderCopy || saleDeliveryChallanCopy || saleCreditNoteCopy || saleDebitNoteCopy || isQuotationInvoice || isQuotationSaleOrder || isSaleOrderInvoice || isSaleOrderDeliveryChallan
      || challanOptions || purchaseReceiptCopy || purCreditNoteCopy || purDebitNoteCopy
    ) {
      setRows(existingBillItems);
      updatedSelectedItems(existingBillItems);
    }
  }, [
    quotationCopy,
    selectedChallanRecords,
    saleOrderCopy,
    saleInvoiceCopy,
    saleDeliveryChallanCopy,
    saleCreditNoteCopy,
    saleDebitNoteCopy,
    isQuotationInvoice,
    isQuotationSaleOrder,
    isSaleOrderInvoice,
    isSaleOrderDeliveryChallan,
    challanOptions,
    purchaseReceiptCopy,
    purDebitNoteCopy,
    purCreditNoteCopy,
  ]);

  const fetchItems = async (searchTerm, rowId) => {
    try {
      let request_url = `${BASE_URL}/v1/inventory_managment/items?query=${searchTerm}&is_billing=true`;
      const response = await sendRequest('GET', request_url);
      setRowFilteredItems(prev => ({ ...prev, [rowId]: response.data.data }));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const handleSearchChange = (e, rowId) => {
    const value = e.target.value;
    setRowSearchTerms((prev) => ({ ...prev, [rowId]: value }));
    if (value.length >= 2) {
      fetchItems(value, rowId);
      setRowDropdownVisibility((prev) => ({ ...prev, [rowId]: true }));
    } else {
      setRowDropdownVisibility((prev) => ({ ...prev, [rowId]: false }));
    }
    const updatedRows = [...rows];
    const rowIndex = updatedRows.findIndex((row) => row.id === rowId);
    updatedRows[rowIndex].item = value;
    setRows(updatedRows);
  };

  const handleQtyChange = (id, newQty, type) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        const price =
          type === "purchase_quotation" ? row.purchasePrice : row.salesPrice;
        const updatedAmount = newQty * price;
        const totalActualPrice = (price * newQty).toFixed(2);
        const totalTaxablePrice = (row.saleAmount * newQty).toFixed(2);
        return {
          ...row,
          qty: newQty,
          amount: updatedAmount,
          total_actual_price: totalActualPrice,
          total_taxable_price: totalTaxablePrice,
        };
      }
      return row;
    });

    setRows(updatedRows);
    updatedSelectedItems(updatedRows);
    handleItemPriceCalculations(updatedRows);
  };

  const handleClearItem = (rowId) => {
    const updatedRows = [...rows];
    const rowIndex = updatedRows.findIndex((row) => row.id === rowId);

    if (rowIndex > -1) {
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        item: updatedRows[rowIndex].item || "",
        item_id: updatedRows[rowIndex].item_id || "",
        hsn: updatedRows[rowIndex].hsn || "",
        tax: updatedRows[rowIndex].tax || "",
        taxLabel: updatedRows[rowIndex].taxLabel || "",
        qty: updatedRows[rowIndex].qty || "",
        unit: updatedRows[rowIndex].unit || "",
        rate: updatedRows[rowIndex].rate || "",
        mrp: updatedRows[rowIndex].mrp || "",
        saleAmount: updatedRows[rowIndex].saleAmount || "",
        salesPrice: updatedRows[rowIndex].salesPrice || "",
        purchasePrice: updatedRows[rowIndex].purchasePrice || "",
        discountAmount: updatedRows[rowIndex].discountAmount || "",
        taxAmount: updatedRows[rowIndex].taxAmount || "",
        total_actual_price: updatedRows[rowIndex].total_actual_price || "",
        total_taxable_price: updatedRows[rowIndex].total_taxable_price || "",
        stock_ids: updatedRows[rowIndex].stock_ids || [],
        actual_quantity: updatedRows[rowIndex].actual_quantity || "",
      };
    }

    setRows(updatedRows);
    setRowSearchTerms((prev) => ({ ...prev, [rowId]: "" }));
    setRowDropdownVisibility((prev) => ({ ...prev, [rowId]: false }));
    updatedSelectedItems(updatedRows);
    handleItemPriceCalculations(updatedRows);
  };

  const handleSelectItem = (item, rowId) => {
    const updatedRows = [...rows];
    const rowIndex = updatedRows.findIndex((row) => row.id === rowId);

    if (!item.item_name) {
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        item: "",
        item_id: "",
        hsn: "",
        tax: "",
        taxLabel: "",
        qty: 0,
        unit: "",
        saleRate: 0,
        amount: 0,
        saleAmount: 0,
        salesPrice: 0,
        purchasePrice: 0,
        taxAmount: 0,
        discountAmount: 0,
        total_actual_price: 0,
        total_taxable_price: 0,
        stock_ids: [],
        actual_quantity: 0,
      };
    } else {
      const totalStockQuantity = item?.stocks?.reduce(
        (sum, stock) => sum + stock.quantity,
        0
      );
      const isPurchaseQuotation = type === "purchase_quotation";

      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        item: item?.item_name,
        item_id: item?.id,
        hsn: item?.hsn,
        qty: 1,
        unit: item?.unit_name,
        saleRate: isPurchaseQuotation ? item.purchase_price : item.sales_price,
        saleAmount: isPurchaseQuotation
          ? item.purchase_price
          : item.sale_amount,
        salesPrice: isPurchaseQuotation
          ? item.purchase_price
          : item.sales_price,
        purchasePrice: item.purchase_price,
        tax: item?.tax_amount,
        taxLabel: item?.tax_info
          ? item?.tax_info?.title?.toLowerCase().includes("gst")
            ? `${item?.tax_info?.tax_value}${item?.tax_info?.tax_type === "percentage" ? "%" : "F"
            } GST`
            : `${item?.tax_info?.tax_value}${item?.tax_info?.tax_type === "percentage" ? "%" : "F"
            } ${item?.tax_info?.title}`
          : "",
        discountAmount: item?.discount_amount,
        taxAmount: (item?.tax_amount * 1).toFixed(2),
        total_actual_price: (
          (isPurchaseQuotation ? item.purchase_price : item.sales_price) * 1
        ).toFixed(2),
        total_taxable_price: (
          (isPurchaseQuotation ? item.purchase_price : item.sale_amount) * 1
        ).toFixed(2),
        actual_quantity: totalStockQuantity,
      };
    }

    setRows(updatedRows);
    setRowDropdownVisibility((prev) => ({ ...prev, [rowId]: false }));
    setRowSearchTerms((prev) => ({ ...prev, [rowId]: item.item_name }));
    updatedSelectedItems(updatedRows);
    handleItemPriceCalculations(updatedRows);
  };

  const handleAddItem = () => {
    const newId = rows.length + 1;
    setRows((prevRows) => [
      ...prevRows,
      {
        id: newId,
        item: "",
        item_id: "",
        hsn: "",
        tax: "",
        taxLabel: "",
        qty: 0,
        unit: "",
        rate: "",
        mrp: "",
        saleRate: "",
        amount: "",
        saleAmount: "",
        salesPrice: "",
        purchasePrice: "",
        total_actual_price: "",
        total_taxable_price: "",
        taxAmount: "",
      },
    ]);

    setRowSearchTerms((prev) => ({ ...prev, [newId]: "" }));
    setRowDropdownVisibility((prev) => ({ ...prev, [newId]: false }));
  };

  const handleDeleteRow = (id) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.filter((row) => row.id !== id);
      if (updatedRows.length === 0) {
        setSubtotal(0);
        setDiscount(0);
        setCgst(0);
        setSgst(0);
        setTotalAmount(0);
        gstValue(0);
        discountValue(0);
        totalAmountValue(0);
        subTotalValue(0);
      } else {
        handleItemPriceCalculations(updatedRows);
        updatedSelectedItems(updatedRows);
      }
      return updatedRows;
    });
  };

  return (
    <>
      <div className="sales-common-table-group">
        <table className="sales-common-table">
          <thead>
            <tr className="sales-common-thead-row">
              <th className="sales-common-th">S.No</th>
              <th className="sales-common-th">Item</th>
              <th className="sales-common-th">HSN</th>
              <th className="sales-common-th">Tax</th>
              <th className="sales-common-th">Qty</th>
              <th className="sales-common-th">Unit</th>
              <th className="sales-common-th">
                {type === "purchase_quotation"
                  ? "Purchase Price ₹"
                  : "Sales Price ₹"}
              </th>
              <th className="sales-common-th">Discount</th>
              <th className="sales-common-th">Amount</th>
              <th className="sales-common-th text-center">Action</th>
            </tr>
          </thead>
          <tbody className="sales-common-table-body">
            {rows.map((row, index) => (
              <tr key={row.id} className="sales-common-tbody-row">
                <td className="sales-common-td">{index + 1}</td>
                {((Array.isArray(isQuotationSaleOrder?.bill_items) &&
                  isQuotationSaleOrder?.bill_items.length > 0) ||
                  (Array.isArray(isQuotationInvoice?.bill_items) &&
                    isQuotationInvoice?.bill_items.length > 0) ||
                  (Array.isArray(isSaleOrderDeliveryChallan?.bill_items) &&
                    isSaleOrderDeliveryChallan?.bill_items.length > 0) ||
                  (Array.isArray(isSaleOrderInvoice?.bill_items) &&
                    isSaleOrderInvoice?.bill_items.length > 0) ||
                  (Array.isArray(saleInvoiceCopy?.bill_items) &&
                    saleInvoiceCopy?.bill_items.length > 0)) &&
                  ((Array.isArray(isQuotationSaleOrder?.reference_no) &&
                    isQuotationSaleOrder?.reference_no.length > 0) ||
                    (Array.isArray(isQuotationInvoice?.reference_no) &&
                      isQuotationInvoice?.reference_no.length > 0) ||
                    (Array.isArray(isSaleOrderDeliveryChallan?.reference_no) &&
                      isSaleOrderDeliveryChallan?.reference_no.length > 0) ||
                    (Array.isArray(isSaleOrderInvoice?.reference_no) &&
                      isSaleOrderInvoice?.reference_no.length > 0) ||
                    (Array.isArray(saleInvoiceCopy?.reference_no) &&
                      saleInvoiceCopy?.reference_no.some(
                        (ref) => ref !== ""
                      ))) ? (
                  <td className="sales-common-td">
                    <input
                      type="text"
                      className="form-control sales-common-table-item-input"
                      placeholder="Item Name"
                      value={rowSearchTerms[row.id] || row.item}
                      onChange={(e) => handleSearchChange(e, row.id)}
                      onBlur={() => {
                        if (!row.item) {
                          handleClearItem(row.id);
                        }
                      }}
                      disabled={row.id}
                    />
                    {rowDropdownVisibility[row.id] && (
                      <ul className="common-item-dropdown">
                        {(rowFilteredItems[row.id] || []).map((item) => (
                          <li
                            key={item.id}
                            className="common-dropdown-item"
                            onClick={() => handleSelectItem(item, row.id)}
                          >
                            {item.item_name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                ) : (
                  <td className="sales-common-td">
                    <input
                      type="text"
                      className="form-control sales-common-table-item-input"
                      placeholder="Item Name"
                      value={rowSearchTerms[row.id] || row.item}
                      onChange={(e) => handleSearchChange(e, row.id)}
                      onBlur={() => {
                        if (!row.item) {
                          handleClearItem(row.id);
                        }
                      }}
                    />
                    {rowDropdownVisibility[row.id] && (
                      <ul className="common-item-dropdown">
                        {(rowFilteredItems[row.id] || []).map((item) => (
                          <li
                            key={item.id}
                            className="common-dropdown-item"
                            onClick={() => handleSelectItem(item, row.id)}
                          >
                            {item.item_name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                )}

                <td className="sales-common-td">
                  <input
                    type="text"
                    className="form-control sales-common-table-input"
                    placeholder="Hsn"
                    value={row?.hsn}
                  />
                </td>

                <td className="sales-common-td">
                  <input
                    type="text"
                    className="form-control sales-common-table-input"
                    value={
                      row.item
                        ? `${row.taxLabel || 0} (${row.is_tax_inclusive ? "Incl.Tax" : "Excl.Tax"
                        })`
                        : `${row.taxLabel || 0}`
                    }
                  />
                </td>

                {((Array.isArray(saleInvoiceCopy?.bill_items) &&
                  saleInvoiceCopy?.bill_items.length > 0) ||
                  (Array.isArray(isQuotationSaleOrder?.bill_items) &&
                    isQuotationSaleOrder?.bill_items.length > 0) ||
                  (Array.isArray(isQuotationInvoice?.bill_items) &&
                    isQuotationInvoice?.bill_items.length > 0) ||
                  (Array.isArray(isSaleOrderDeliveryChallan?.bill_items) &&
                    isSaleOrderDeliveryChallan?.bill_items.length > 0) ||
                  (Array.isArray(isSaleOrderInvoice?.bill_items) &&
                    isSaleOrderInvoice?.bill_items.length > 0)) &&
                  ((Array.isArray(saleInvoiceCopy?.reference_no) &&
                    saleInvoiceCopy?.reference_no.some((ref) => ref !== "")) ||
                    (Array.isArray(isQuotationSaleOrder?.reference_no) &&
                      isQuotationSaleOrder?.reference_no.length > 0) ||
                    (Array.isArray(isQuotationInvoice?.reference_no) &&
                      isQuotationInvoice?.reference_no.length > 0) ||
                    (Array.isArray(isSaleOrderDeliveryChallan?.reference_no) &&
                      isSaleOrderDeliveryChallan?.reference_no.length > 0) ||
                    (Array.isArray(isSaleOrderInvoice?.reference_no) &&
                      isSaleOrderInvoice?.reference_no.length > 0)) ? (
                  <td className="sales-common-td">
                    <input
                      type="number"
                      className="form-control sales-common-table-input"
                      placeholder="Quantity"
                      value={row?.qty || quotationCopy?.bill_items?.quantity}
                      onChange={(e) =>
                        handleQtyChange(row.id, Number(e.target.value))
                      }
                      disabled={row.id}
                    />
                  </td>
                ) : (
                  <td className="sales-common-td">
                    <input
                      type="number"
                      className="form-control sales-common-table-input"
                      placeholder="Quantity"
                      value={row?.qty || quotationCopy?.bill_items?.quantity}
                      onChange={(e) =>
                        handleQtyChange(row.id, Number(e.target.value))
                      }
                    />
                  </td>
                )}

                <td className="sales-common-td">
                  <input
                    type="text"
                    className="form-control sales-common-table-input"
                    placeholder="Unit"
                    value={row?.unit || ""}
                  />
                </td>

                <td className="sales-common-td">
                  <input
                    type="text"
                    className="form-control sales-common-table-input"
                    placeholder={
                      type === "purchase_quotation"
                        ? "Purchase Price"
                        : "Sales Price"
                    }
                    value={(type === "purchase_quotation"
                      ? Number(row?.purchasePrice)
                      : Number(row?.salesPrice) || 0
                    ).toFixed(2)}
                  />
                </td>

                <td className="sales-common-td">
                  <input
                    type="text"
                    className="form-control sales-common-table-input"
                    placeholder="Discount"
                    value={(row?.discountAmount * row.qty).toFixed(2)}
                  />
                </td>

                <td className="sales-common-td">
                  <input
                    type="text"
                    className="form-control sales-common-table-input"
                    placeholder="Amount"
                    value={row?.total_taxable_price}
                  />
                </td>

                <td className="sales-common-td text-center">
                  <span className="sale-comon-td-delete-con">
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => handleDeleteRow(row.id)}
                    />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="sales-common-table-footer-container">
        <div className="sale-common-table-footer-left">
          <span className="sale-common-table-add-item" onClick={handleAddItem}>
            <FontAwesomeIcon icon={faPlus} /> Add Item
          </span>
        </div>
        <div className='sale-common-table-footer-right'>
          {/* <div className='sale-common-footer-div'>
            <span className="sales-common-footer-label">Packing and Forwarding Charges</span>
            <span className="sales-common-footer-input">
              <input
                type="number"
                value={packingCharges}
                onChange={(e) => setPackingCharges(e.target.value)}
                className="form-control sale-common-table-input"
                placeholder="Charges"
              />
            </span>
          </div> */}
          <div className='sale-common-footer-div'>
            <span className="sales-common-footer-label">Subtotal</span>
            <input
              className="form-control sale-common-table-input"
              type="number"
              readOnly
              value={subtotal?.toFixed(2) || '0.0'}
            />
          </div>
          <div className='sale-common-footer-div'>
            <span className="sales-common-footer-label">Parcel Charges</span>
            {(
              quotationCopy?.parcel_charges || isQuotationSaleOrder?.parcel_charges ||
              isQuotationInvoice?.parcel_charges || saleOrderCopy?.parcel_charges ||
              saleInvoiceCopy?.parcel_charges || saleDeliveryChallanCopy?.parcel_charges ||
              saleCreditNoteCopy?.parcel_charges || saleDebitNoteCopy?.parcel_charges
            ) ? (
              <input
                type="number"
                value={quotationCopy?.parcel_charges?.toFixed(2) ||
                  isQuotationSaleOrder?.parcel_charges?.toFixed(2) ||
                  isQuotationInvoice?.parcel_charges?.toFixed(2) ||
                  saleOrderCopy?.parcel_charges?.toFixed(2) ||
                  saleInvoiceCopy?.parcel_charges?.toFixed(2) ||
                  saleDeliveryChallanCopy?.parcel_charges?.toFixed(2) ||
                  saleCreditNoteCopy?.parcel_charges?.toFixed(2) ||
                  saleDebitNoteCopy?.parcel_charges?.toFixed(2)
                }
                onChange={(e) => setPackingCharges(e.target.value)}
                className="form-control sale-common-table-input"
                placeholder="Parcel Charges"
                min="0"
              />
            ) : (
              <input
                type="number"
                value={packingCharges}
                onChange={(e) => setPackingCharges(e.target.value)}
                className="form-control sale-common-table-input"
                placeholder="Parcel Charges"
                min="0"
              />
            )}
          </div>

          {!isTotalTaxApplied && (
            <div className="sale-common-footer-div">
              <span className="sales-common-footer-label">Tax</span>
              {/* <span className="sales-common-footer-input"> */}
              <select
                value={taxRate}
                onChange={(e) => setTaxRate(e.target.value)}
                className="form-select select_pq"
              >
                <option value="noTax">No Tax</option>
                <option value={12}>GST 12%</option>
                <option value={18}>GST 18%</option>
                <option value={28}>GST 28%</option>
              </select>
              {/* </span> */}
            </div>
          )}

          {(taxRate !== "noTax" || isTotalTaxApplied) && (
            <>
              {gstEnable && igstEnable ? (
                <div className="sale-common-footer-div">
                  <span className="sales-common-footer-label">
                    IGST {!isTotalTaxApplied ? `(${taxRate}%)` : ""}
                  </span>
                  <input
                    type="number"
                    value={igst}
                    onChange={(e) => setIgst(e.target.value)}
                    className="form-control sale-common-table-footer-input-2"
                    placeholder="Gst"
                    readOnly
                  />
                </div>
              ) : gstEnable ? (
                <>
                  <div className="sale-common-footer-div">
                    <span className="sales-common-footer-label">
                      CGST {!isTotalTaxApplied ? `(${taxRate / 2}%)` : ""}
                    </span>
                    <input
                      type="number"
                      value={cgst}
                      onChange={(e) => setCgst(e.target.value)}
                      className="form-control sale-common-table-footer-input-2"
                      placeholder="Gst"
                      readOnly
                    />
                  </div>
                  <div className="sale-common-footer-div">
                    <span className="sales-common-footer-label">
                      SGST {!isTotalTaxApplied ? `(${taxRate / 2}%)` : ""}
                    </span>
                    <input
                      type="number"
                      value={sgst}
                      onChange={(e) => setSgst(e.target.value)}
                      className="form-control sale-common-table-footer-input-2"
                      placeholder="Gst"
                      readOnly
                    />
                  </div>
                </>
              ) : null}
            </>
          )}

          {type !== "purchase_quotation" ? (
            <div className="sale-common-footer-div">
              <span className="sales-common-footer-label">Discount</span>
              {quotationCopy?.total_discount ||
                isQuotationSaleOrder?.total_discount ||
                isQuotationInvoice?.total_discount ||
                saleOrderCopy?.total_discount ||
                saleInvoiceCopy?.total_discount ||
                saleDeliveryChallanCopy?.total_discount ||
                saleCreditNoteCopy?.total_discount ||
                saleDebitNoteCopy?.total_discount ? (
                <input
                  type="number"
                  value={(
                    quotationCopy?.total_discount ||
                    isQuotationSaleOrder?.total_discount ||
                    isQuotationInvoice?.total_discount ||
                    saleOrderCopy?.total_discount ||
                    saleInvoiceCopy?.total_discount ||
                    saleDeliveryChallanCopy?.total_discount ||
                    saleCreditNoteCopy?.total_discount ||
                    saleDebitNoteCopy?.total_discount
                  ).toFixed(2)}
                  onChange={handleDiscountChange}
                  className="form-control sale-common-table-footer-input-2"
                  placeholder="discount"
                  min="0"
                  max={subtotal}
                />
              ) : (
                <input
                  type="number"
                  value={discount}
                  onChange={handleDiscountChange}
                  className="form-control sale-common-table-footer-input-2"
                  placeholder="discount"
                  min="0"
                  max={subtotal}
                />
              )}
            </div>
          ) : (
            ""
          )}

          {/* <div className="sale-common-footer-div">
            <span className="sales-common-footer-label">Total Amount</span>
            {quotationCopy?.total_amount ||
              isQuotationSaleOrder?.total_amount ||
              isQuotationInvoice?.total_amount ||
              saleOrderCopy?.total_amount ||
              saleInvoiceCopy?.total_amount ||
              saleDeliveryChallanCopy?.total_amount ||
              saleCreditNoteCopy?.total_amount ||
              saleDebitNoteCopy?.total_amount ? (
              <input
                type="number"
                readOnly
                value={(
                  quotationCopy?.total_amount ||
                  isQuotationSaleOrder?.total_amount ||
                  isQuotationInvoice?.total_amount ||
                  saleOrderCopy?.total_amount ||
                  saleInvoiceCopy?.total_amount ||
                  saleDeliveryChallanCopy?.total_amount ||
                  saleCreditNoteCopy?.total_amount ||
                  saleDebitNoteCopy?.total_amount
                ).toFixed(2)}
                className="form-control sale-common-table-footer-input-2"
                placeholder="Total Amount"
              />
            ) : (
              <input
                type="number"
                readOnly
                value={totalAmount.toFixed(2)}
                className="form-control sale-common-table-footer-input-2"
                placeholder="Total Amount"
              />
            )}
          </div> */}
          <div className="sale-common-footer-div">
            <span className="sales-common-footer-label">Total Amount</span>
            <input
              type="number"
              readOnly
              value={totalAmount?.toFixed(2) || '0.0'}
              className="form-control sale-common-table-footer-input-2"
              placeholder="Total Amount"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CommonSalePopupTable;
