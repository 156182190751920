import React, { useState, useEffect, useRef } from "react";
import "./PurchaseOrder.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewPurchaseOrder from "./NewPurchaseOrder";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import PaginationControls from "../../Common/PaginationControls";
import NewPurchaseBill from "../PurchaseBill/NewPurchaseBill";

function PurchaseOrder() {
  const [openNewPurchaseOrder, setNewPurchaseOrder] = useState(false);
  const [newPB, setNewPB] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeActionDropdownIndex, setActiveActionDropdownIndex] =
    useState(null);
  const [activeStatusDropdownIndex, setActiveStatusDropdownIndex] =
    useState(null);
  const [editedRowData, setEditedRowData] = useState();
  const [isUpdated, setIsUpdated] = useState();
  const [isCreated, setIsCreated] = useState();
  const [isCreatedPB, setIsCreatedPB] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quotationId, setQuotationId] = useState(null);
  const [purchaseOrderCopy, setPurchaseOrderCopy] = useState(null);
  const [isOrderUpdated, setIsOrderUpdated] = useState(false);
  const [title, setTitle] = useState();
  const [type, setType] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveActionDropdownIndex(null);
      setActiveStatusDropdownIndex(null);
    }
  };

  const handlePurchaseOrderCopy = async (orderId) => {
    if (orderId) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/transactions/entries/${orderId}?type=purchase_order`
        );

        const { entry } = response.data;
        setPurchaseOrderCopy(entry);
        setNewPurchaseOrder(true);
        setTitle("Copy Purchase Order");
        setIsOrderUpdated(true);
        setIsUpdated(false);
        setIsCreated(false);

        successNotification("Purchase Order copied successfully!");
      } catch (error) {
        console.error("Error copying the purchase order:", error);
        ErrorNotification(
          "An error occurred while copying the purchase order."
        );
      }
    }
  };

  useEffect(() => {
    if (
      activeActionDropdownIndex !== null ||
      activeStatusDropdownIndex !== null
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeActionDropdownIndex, activeStatusDropdownIndex]);

  const handlePurchaseOrder = () => {
    setTitle("New Purchase Order");
    setType("purchase_order");
    setIsCreated(true);
    setIsUpdated(false);
    setNewPurchaseOrder(true);
  };

  const closeNewPurchaseOrderPopup = () => {
    setNewPurchaseOrder(false);
  };

  const fetchPurchaseOrders = async (pageNumber = 1, perPage = 10) => {
    try {
      const url = `${BASE_URL}/v1/transactions/entries?type=purchase_order&page=${pageNumber}&per_page=${perPage}`;
      const response = await sendRequest("GET", url);
      setPurchaseOrders(response.data.data);
      setTotalRecords(response.data.total_count);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error("Error fetching purchase orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPurchaseOrders(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  const toggleDropdown = (index) => {
    setActiveStatusDropdownIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  const toggleActionDropdown = (index) => {
    setActiveActionDropdownIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  const handleEditPQ = (order) => {
    setTitle("Edit Purchase Order");
    setIsUpdated(true);
    setEditedRowData(order);
    setNewPurchaseOrder(true);
    setType("purchase_order");
    setActiveActionDropdownIndex(null);
  };

  const handleDeleteClick = (id) => {
    setQuotationId(id);
    setIsModalOpen(true);
    setActiveActionDropdownIndex(null);
  };

  const handleDeleteCancel = () => {
    setIsModalOpen(false);
    setQuotationId(null);
    setActiveActionDropdownIndex(null);
  };

  const handleDeleteConfirm = async () => {
    if (!quotationId) return;

    try {
      const response = await sendRequest(
        "delete",
        `${BASE_URL}/v1/transactions/entries/${quotationId}?type=purchase_order&is_purchase=true`
      );

      if (response.status === 200) {
        successNotification("Purchase order deleted");
        fetchPurchaseOrders();
      }
    } catch (error) {
      console.error("Error deleting purchase order:", error);
      alert("Failed to delete purchase order");
    } finally {
      setIsModalOpen(false);
      setQuotationId(null);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handleCreatePB = (order) => {
    setEditedRowData(order);
    setNewPB(true);
    setTitle("New Purchase Bill");
    setIsCreated(true);
    setIsCreatedPB(true);
    setIsUpdated(false);
  };

  const handleClose = () => {
    setNewPB(false);
  };

  console.log("purchaseOrders", purchaseOrders);

  return (
    <>
      <div className="purchase-order-main-container">
        <div className="purchase-order-container-first-div">
          <div className="purchase-order-first-left">
            <span className="all-purchase-order-show">
              All Purchase Orders {totalRecords > 0 ? `(${totalRecords})` : ""}
            </span>
          </div>
          <div className="purchase-order-first-right">
            <button
              className="purchase-order-first-right-botton"
              onClick={handlePurchaseOrder}
            >
              New Order
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </button>
          </div>
        </div>

        <div className="purchase-order-table-group">
          <div className="purchase-quotation-pagination-header mb-1">
            <EntriesPerPageDropdown
              recordPerPage={recordsPerPage}
              handleRecordPerPageChange={handleRecordPerPageChange}
            />
          </div>
          <table className="purchase-order-table">
            <thead>
              <tr className="purchase-quote-thead-row">
                <th className="purchase-quote-tab-th">Date</th>
                <th className="purchase-quote-tab-th">Purchase Order No</th>
                <th className="purchase-quote-tab-th">Contact</th>
                <th className="purchase-quote-tab-th">Supplier Order No</th>
                <th className="purchase-quote-tab-th">Supplier Order Date</th>
                <th className="purchase-quote-tab-th">Ref.No</th>
                <th className="purchase-quote-tab-th">Due Days</th>
                <th className="purchase-quote-tab-th text-center">Status</th>
                <th className="purchase-quote-tab-th text-end">Amount</th>
                <th className="purchase-quote-tab-th text-end">Due Amount</th>
                <th className="purchase-quote-tab-th text-center">Action</th>
              </tr>
            </thead>
            <tbody className="h-10">
              {loading ? (
                <tr>
                  <td colSpan="11">Loading...</td>
                </tr>
              ) : (
                purchaseOrders.map((order, index) => (
                  <tr key={index}>
                    <td className="purchase-quote-tab-td">{order.date}</td>
                    <td className="purchase-quote-tab-td">
                      {order.bill_number}
                    </td>
                    <td className="purchase-quote-tab-td">
                      {order.supplier.supplier_name}
                    </td>
                    <td className="purchase-quote-tab-td">{order.order_no}</td>
                    <td className="purchase-quote-tab-td">
                      {order.order_date}
                    </td>
                    <td className="purchase-quote-tab-td">
                      {order.reference_no}
                    </td>
                    <td className="purchase-quote-tab-td">{order.dueDays}</td>
                    <td className="purchase-quote-tab-td text-center">
                      <span
                        style={{
                          color: ["sent", "confirmed"].includes(order.status)
                            ? "green"
                            : "inherit",
                        }}
                      >
                        {order.status}
                      </span>

                      {order.status === "sent" && (
                        <>
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="purchase-quote-table-body-td"
                            onClick={() => toggleDropdown(index)}
                            style={{ cursor: "pointer", marginLeft: "8px" }}
                          />

                          {activeStatusDropdownIndex === index && (
                            <ul
                              className="po-status-dropdown"
                              ref={dropdownRef}
                            >
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => console.log("Approved selected")}
                              >
                                Approved
                              </li>
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => console.log("Pending selected")}
                              >
                                Pending
                              </li>
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => console.log("Rejected selected")}
                              >
                                Rejected
                              </li>
                            </ul>
                          )}
                        </>
                      )}
                    </td>
                    <td className="purchase-quote-tab-td text-end">
                      {(order.total_amount ?? 0).toFixed(2)}
                    </td>
                    <td className="purchase-quote-tab-td text-end">
                      {(order.due_amount ?? 0).toFixed(2)}
                    </td>
                    <td className="purchase-quote-tab-td  text-center">
                      {order.status === "sent" && (
                        <span
                          style={{
                            color: ["sent", "confirmed"].includes(order.status)
                              ? "green"
                              : "inherit",
                            cursor: "pointer",
                          }}
                          onClick={() => handleEditPQ(order)}
                        >
                          Edit
                        </span>
                      )}
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        className="purchase-quote-table-body-td"
                        onClick={() => toggleActionDropdown(index)}
                        style={{ cursor: "pointer", marginLeft: "8px" }}
                      />
                      {activeActionDropdownIndex === index && (
                        <ul
                          className="po-action-dropdown text-start"
                          ref={dropdownRef}
                        >
                          {order.status === "sent" && (
                            <>
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => handleCreatePB(order)}
                              >
                                Create Purchase Bill
                              </li>
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => handleEditPQ(order)}
                              >
                                Edit PO
                              </li>
                            </>
                          )}
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteClick(order.id)}
                          >
                            Delete PO
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => console.log("Rejected selected")}
                          >
                            Print
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => console.log("Rejected selected")}
                          >
                            Sent SMS
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => console.log("Rejected selected")}
                          >
                            Send WhatsApp
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => handlePurchaseOrderCopy(order.id)}
                          >
                            Copy
                          </li>
                        </ul>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="modal bg-light">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{ fontSize: "20px", color: "red", marginBottom: "10px" }}
          />
          <h2>Warning</h2>
          <p>Do you want to delete this Purchase Order ?</p>
          <div className="d-flex justify-content-between">
            <button className="btn btn-primary" onClick={handleDeleteConfirm}>
              Confirm
            </button>
            <button
              className="btn btn-cancel bg-body-secondary"
              onClick={handleDeleteCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {newPB && (
        <div className="new-purchase-order-popup-container">
          <NewPurchaseBill
            isCreatedPB={isCreatedPB}
            rowData={editedRowData}
            title={title}
            isCreatedPO={false}
            isOrderUpdated={false}
            isUpdated={isUpdated}
            isCopy={false}
            isCreated={isCreated}
            onClose={handleClose}
          />
        </div>
      )}

      {openNewPurchaseOrder && (
        <div className="new-purchase-order-popup-container">
          <NewPurchaseOrder
            fetchPurchaseOrders={fetchPurchaseOrders}
            isUpdated={isUpdated}
            isCreated={isCreated}
            editedRowData={editedRowData}
            type={type}
            title={title}
            onClose={closeNewPurchaseOrderPopup}
            purchaseOrderCopy={purchaseOrderCopy}
            isOrderUpdated={isOrderUpdated}
          />
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default PurchaseOrder;