import React from "react";
import './FreeTextEditPopup.css'

function FreeTextEditPopup({onClose, editedText, onSave, optionToEdit }) {

  const handleTextChange = (e) => {
    editedText(e.target.value);
  }
  return (
    <>
      <div className="free-text-main-container">
        <div className="free-text-header-container">
          <span className="free-text-head-title">Free Text</span>
          <span className="free-text-head-icon" onClick={onClose}>X</span>
        </div>
        <div className="free-text-sub-container">
          <div className="free-text-field-grp">
            <lable className="free-text-label">Title</lable>
            <input className="form-control" 
            value={optionToEdit?.template_config?.free_text || ""}
            type="text"
            onChange={handleTextChange}
            />
          </div>
          <div className="free-text-btn-grp-">
            <button className="free-text-btn-perform" onClick={onSave}>Save</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FreeTextEditPopup;
