import React, { useEffect, useState } from "react";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import './CreateCategory.css';
import { useTranslation } from "react-i18next";
import Search from "../Search/Search";
import EntriesPerPageDropdown from "../Common/EntriesPerPageDropdown";
import PaginationControls from "../Common/PaginationControls";
import { BsPencilSquare, BsFillTrashFill } from "react-icons/bs";
import successNotification from "../../Notification/SuccessNotification";
import ErrorNotification from "../../Notification/ErrorNotification";
import Copyrights from "../Copyrights";

const CreateCategory = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: "", parent_id: "" });
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedCategory, setEditedCategory] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchCategory(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage, searchQuery]);

  const fetchCategory = async (pageNumber = 1, perPage = 10) => {
  try {
    let requestUrl = `${BASE_URL}/v1/inventory_managment/categories?page=${pageNumber}&per_page=${perPage}`;
    if (searchQuery) {
      requestUrl += `&query=${searchQuery}`;
    }
    
    const response = await sendRequest('GET', requestUrl);
    if (response.status === 200) {
      setCategoryData(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    }
  } catch (error) {
    console.error("Error fetching categories:", error);
    ErrorNotification(t("Error fetching categories"));
  }
};

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewCategory((prevState) => ({
      ...prevState,
      category_image: file, 
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setEditedCategory((prevState) => ({
      ...prevState,
      category_image: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("category[name]", newCategory.name);
      formData.append("category[parent_id]", newCategory.parent_id);
      if (newCategory.category_image instanceof File) {
        formData.append("category[category_image]", newCategory.category_image);
      }

      const response = await sendRequest('POST', `${BASE_URL}/v1/inventory_managment/categories`, formData );  
      
      if (response.status === 201) {
        setCategoryData([response.data, ...categoryData]);
        setIsModalOpen(false);
        setNewCategory({ name: "", parent_id: "", category_image: "" });
        successNotification("Category Created successfully");
      }
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  };
  
  const handleSearch = (query) => {

    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery('');
      setCurrentPage(1);
    }
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getParentCategoryName = (parentId) => {
    if (!parentId) return "";
    const parentCategory = categoryData.find((category) => category.id === parentId);
    return parentCategory ? parentCategory.name : "";
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handleEditCategory = (category) => {
    setEditedCategory(category);
    setIsEditModalOpen(true);
  };

  const handleUpdateCategory = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();      
      formData.append("category[name]", editedCategory.name);
      formData.append("category[parent_id]", editedCategory.parent_id);
      if (editedCategory.category_image instanceof File) {
        formData.append("category[category_image]", editedCategory.category_image);
      }
      const response = await sendRequest(
        'PUT',
        `${BASE_URL}/v1/inventory_managment/categories/${editedCategory.id}`,
        formData );
  
      if (response.status === 200) {
        const updatedCategoryData = categoryData.map((category) => {
          if (category.id === editedCategory.id) {
            const parentCategory = categoryData.find(cat => cat.id === editedCategory.parent_id);
            return {
              ...category,
              name: editedCategory.name,
              parent_id: editedCategory.parent_id,
              parent_name: parentCategory ? parentCategory.name : '',
              category_image_url: response.data.category_image_url,
            };
          }
          return category;
        });
  
        await fetchCategory(currentPage, recordsPerPage);
        setCategoryData(updatedCategoryData);
        setIsEditModalOpen(false);
        successNotification(t("Category updated successfully"));
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.forEach((errorMsg) => {
          ErrorNotification(errorMsg);
        });
      } else {
        ErrorNotification("An unexpected error occurred");
      }
    }
  };
  
  const handleDeleteCategory = (category) => {
    setCategoryToDelete (category);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDeleteCategory = async () => {
    try {
      const response = await sendRequest('DELETE', `${BASE_URL}/v1/inventory_managment/categories/${categoryToDelete.id}`);
      if (response.status === 200) {
        const updatedCategoryData = categoryData.filter((category) => category.id !== categoryToDelete.id);
        setCategoryData(updatedCategoryData);
        setIsDeleteModalOpen(false);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleCancelDeleteCategory = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      
      <div className="category-main-container">
        <div className="category-headers-details">
          <div>
            <h3 className="title">{t("Category List")} ({totalRecords})</h3>
          </div>
          <button className="btn btn-primary" onClick={handleOpenModal}>
            + {t("Add Category")}
          </button>
        </div>

        <div className="item-category-second-header">
          <div className="category-pagination-dropdown">
            <EntriesPerPageDropdown
              recordPerPage={recordsPerPage}
              handleRecordPerPageChange={handleRecordPerPageChange}
            />
          </div>
          <div className="category-search-field" value={searchQuery} onChange={e => handleSearch(e.target.value)}>
            <Search placeholder={t("Search Categories ...")}/>
          </div>
        </div>
        <div className="category-table-list">
          <table>
            <thead>
              <tr>
                <th>{t("S. No.")}</th>
                <th>{t("Category Name")}</th>
                <th>{t("Parent Category")}</th>
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {categoryData.length > 0 ? (
                categoryData.map((category, index) => (
                  <tr key={category.id}>
                    <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                    <td>{category.name}</td>
                    <td>{getParentCategoryName(category.parent_id)}</td>
                    <td>
                      <span>
                        <a className="show-icon" onClick={() => handleEditCategory(category)}><BsPencilSquare /></a>
                        <a className="deletebtn delete-icon" onClick={() => handleDeleteCategory(category)}>
                          <BsFillTrashFill />
                        </a>
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">{searchQuery ? t("No match found") : t("No records found")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div>
            <div className="report-pagination-info">
              <div className="report-pagination-container">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                  totalRecords={totalRecords}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="cc-modal-overlay">
          <div className="cc-modal-content">
            <div className="cc-modal-header">
              <span className="cc-modal-title">{t("Create New Category")}</span>
              <button className="cc-modal-close" onClick={handleCloseModal}>&times;</button>
            </div>
            <div className="cc-model-sub-container">
              <form onSubmit={handleSubmit}>
                <div className="">
                  <label htmlFor="name">{t("Category Name")}</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={newCategory.name}
                    onChange={handleChange}
                    maxLength={30}
                    required
                  />
                </div>
                <div className="">
                  <label htmlFor="parent_id">{t("Parent Category")}</label>
                  <select
                    name="parent_id"
                    value={newCategory.parent_id}
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option value="">{t("Select a parent category")}</option>
                    {categoryData.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="category-image-field">
                  <label htmlFor="category_image">{t("Category Image")}</label>
                  <input
                    type="file"
                    name="category_image"
                    className="form-control"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </div>
                <div className="cc-modal-footer">
                  <button type="submit" className="cc-modal-btn-create">{t("Create")}</button>
                  <button type="button" className="cc-modal-btn-cancel" onClick={handleCloseModal}>{t("Cancel")}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {isEditModalOpen && (
        <div className="cc-modal-overlay">
          <div className="cc-modal-content">
            <div className="cc-modal-header">
              <span className="cc-modal-title">{t("Edit Category")}</span>
              <button className="cc-modal-close" onClick={() => setIsEditModalOpen(false)}>&times;</button>
            </div>
            <div className="cc-model-sub-container">
              <form onSubmit={handleUpdateCategory}>
                <div className="">
                  <label htmlFor="name">{t("Category Name")}</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={editedCategory.name}
                    onChange={(e) => setEditedCategory({ ...editedCategory, name: e.target.value })}
                    maxLength={30}
                    required
                  />
                </div>
                <div className="">
                  <label htmlFor="parent_id">{t("Parent Category")}</label>
                  <select
                    name="parent_id"
                    value={editedCategory.parent_id || ''}
                    onChange={(e) => setEditedCategory({ ...editedCategory, parent_id: e.target.value })}
                    className="form-control"
                  >
                    <option value="">{t("Select a parent category")}</option>
                    {categoryData.map((category) => (
                      <option 
                        key={category.id} 
                        value={category.id}
                        selected={category.id === editedCategory.parent_id}
                      >
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="category-image-field">
                  <label htmlFor="parent_id">{t("Category Image")}</label>
                  {editedCategory.category_image_url && (
                    <div className="existing-image">
                      <span>{editedCategory.category_image_name}</span>
                    </div>
                  )}
                  <input
                    type="file"
                    name="category_image"
                    className="form-control"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
                <div className="cc-modal-footer">
                  <button type="submit" className="cc-modal-btn-create">{t("Update")}</button>
                  <button type="button" className="cc-modal-btn-cancel" onClick={() => setIsEditModalOpen(false)}>{t("Cancel")}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="cc-modal-overlay">
          <div className="cc-modal-content">
            <div className="cc-modal-header">
              <span className="cc-modal-title">{t("Confirm Delete")}</span>
              <button className="cc-modal-close" onClick={handleCancelDeleteCategory}>&times;</button>
            </div>
            <div className="cc-model-sub-container">
              <p>{t("Are you sure you want to delete this category?")}</p>
              <div className="cc-modal-footer">
                <button type="button" className="cc-modal-btn-create" onClick={handleConfirmDeleteCategory}>{t("Yes")}</button>
                <button type="button" className="cc-modal-btn-cancel" onClick={handleCancelDeleteCategory}>{t("No")}</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Copyrights/>
    </>
  );
};

export default CreateCategory;