import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Kitchenceff.css";
import ItemStatusCard from "./ItemStatusCard";
import successNotification from "../../Notification/SuccessNotification";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import createCable from "../../websocket";
import { getToken } from '../SecureStorage/Token';
import { current_user } from "../../Authentication/AuthenticationContext";

const cable = createCable();

const Kitchenceff = ({ handleLogout }) => {
  const [boardData, setBoardData] = useState({
    lanes: [
      { id: "placed", title: "Placed", cards: [] },
      { id: "priority_list", title: "Priority list", cards: [] },
      { id: "preparation", title: "Preparation", cards: [] },
      { id: "ready_to_serve", title: "Ready to serve", cards: [] },
    ],
  });

  const [itemStatusData, setItemStatusData] = useState({
    placed: [],
    priority_list: [],
    preparation: [],
    ready_to_serve: [],
  });

  const [currentUser, setCurrentUser] = useState({})

  useEffect(() => {
    const fetchUser = async () => {
      const loggedInUser = await current_user();
      setCurrentUser(loggedInUser);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    fetchAllOrders();
    fetchAllItemStatus();
    const channel = cable.subscriptions.create(
      { channel: "OrdersChannel",
        company_id: currentUser?.company?.id,
        branch_id: currentUser?.company?.branch_info?.id,
        token: getToken()
      },
      {
        received: (data) => {
          const order = JSON.parse(data);
          const newOrder = {
            id: order.kot_number?.toString() || '',
            title: `Order #${order.order_number || order.kot_number || 'N/A'}`,
            kotNo: order.kot_number,
            orderNumber: order.order_number || order.kot_number,
            tableNumber: order.table_number,
            orderDate: order.date,
            orderTime: order.time,
            items: formatOrderItems(order.items)
            
          };

          setBoardData((prevBoardData) => {
            const updatedBoardData = { ...prevBoardData };
            const lane = updatedBoardData.lanes.find(
              (lane) => lane.id === order.status
            );
            if (lane) {
              lane.cards.push(newOrder);
            }
            return updatedBoardData;
          });
        },
      }
    );

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const formatOrderItems = (items) => {
    return items.map(item => {
      const itemName = item.item_name?.en || item.item_name || 'Unknown Item';
      const quantity = item.total_quantity || item.quantity || 0;
      const size = item.size || 'Regular';
      const modifiers = item.modifiers || [];
      
      return {
        name: itemName,
        quantity: quantity,
        size: size,
        modifiers: modifiers
      };
    });
  };
  

  const fetchAllOrders = async () => {
    const statuses = ["placed", "priority_list", "preparation", "ready_to_serve"];
    try {
      const ordersPromises = statuses.map((status) =>
        sendRequest(
          "GET",
          `${BASE_URL}/v1/restaurant/orders/orders/order_status_display?status=${status}`
        )
      );

      const responses = await Promise.all(ordersPromises);

      const updatedBoardData = { ...boardData };
      responses.forEach((response, index) => {
        const orders = response.data;
        const status = statuses[index];

        const orderArray = Array.isArray(orders) ? orders : [];

        updatedBoardData.lanes.find((lane) => lane.id === status).cards =
        orderArray.map((order) => ({
          id: order.kot_number?.toString() || '',
          kotNo: order.kot_number,
          orderNumber: order.order_number,
          tableNumber: order.table_number,
          orderDate: order.date,
          orderTime: order.time,
          items: formatOrderItems(order.items)
        }));
      });

      setBoardData(updatedBoardData);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  console.log('boardData', boardData);

  const fetchAllItemStatus = async () => {
    const statuses = ["placed", "priority_list", "preparation", "ready_to_serve"];
    try {
      const itemStatusPromises = statuses.map((status) =>
        sendRequest(
          "GET",
          `${BASE_URL}/v1/restaurant/orders/orders/live_order_items?status=${status}`
        )
      );

      const responses = await Promise.all(itemStatusPromises);

      const updatedItemStatusData = {};
      responses.forEach((response, index) => {
        const status = statuses[index];
        // Ensure we're getting the data array from the response
        updatedItemStatusData[status] = response.data || [];
      });

      setItemStatusData(updatedItemStatusData);
      console.log("reponseeeeeeee", updatedItemStatusData);

    } catch (error) {
      console.error("Error fetching item status:", error);
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const sourceLaneIndex = boardData.lanes.findIndex((lane) => lane.id === source.droppableId);
    const destinationLaneIndex = boardData.lanes.findIndex((lane) => lane.id === destination.droppableId);

    const sourceLane = boardData.lanes[sourceLaneIndex];
    const destinationLane = boardData.lanes[destinationLaneIndex];

    const [movedCard] = sourceLane.cards.splice(source.index, 1);
    destinationLane.cards.splice(destination.index, 0, movedCard);

    const updatedBoardData = { ...boardData };
    updatedBoardData.lanes[sourceLaneIndex] = sourceLane;
    updatedBoardData.lanes[destinationLaneIndex] = destinationLane;

    setBoardData(updatedBoardData);

    try {
      await sendRequest(
        "PUT",
        `${BASE_URL}/v1/restaurant/orders/orders/update_order_status?id=${movedCard.id}`,
        { status: destination.droppableId }
      );
      await fetchAllItemStatus();
      successNotification("Order status updated successfully");
    } catch (error) {
      console.error("Error updating order status:", error);
      alert("Failed to update order status");
      fetchAllOrders();
    }
  };

  const formatDescription = (description) => {
    const items = description.split(", ");
    return (
      <div>
        {items.map((item, index) => (
          <div key={index} className="description-item">
            {item}
          </div>
        ))}
      </div>
    );
  };

  const getCardClass = (laneId) => {
    switch (laneId) {
      case "placed":
        return "placed";
      case "priority_list":
        return "priority-list";
      case "preparation":
        return "preparation";
      case "ready_to_serve":
        return "ready-to-serve";
      default:
        return "";
    }
  };

  return (
    <div className="Kitchenceff-intro">
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="react-trello">
          {boardData.lanes.map((lane) => (
            <Droppable key={lane.id} droppableId={lane.id}>
              {(provided) => (
                <div
                  className="react-trello-lane"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <div className="react-trello-lane-header">{lane.title}</div>
                  <div className="react-trello-lane-content">
                    {lane.cards.map((card, index) => (
                      <Draggable key={card.id} draggableId={card.id} index={index}>
                      {(provided) => (
                        <div
                          className={`react-trello-card ${getCardClass(lane.id)}`}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {/* Card Header */}
                          <div className="card-header">
                            <div className="header-row">
                              <div className="header-item">
                                <i className="fas fa-table"></i> Table No{card.tableNumber}
                              </div>
                              <div className="header-item">
                                <i className="fas fa-receipt"></i> KOT No {card.kotNo}
                              </div>
                            </div>
                            <div className="header-row">
                              <div className="header-item">
                                <i className="far fa-clock"></i>
                                {`${card.orderDate} ${card.orderTime}`}
                              </div>
                            </div>
                          </div>
                    
                          {/* Token Number */}
                          <div className="token-number">
                            Token: {card.tokenNumber}
                          </div>
                    
                          {/* Items List */}
                          <div className="items-container">
                            {card.items && card.items.map((item, idx) => (
                              <div key={idx} className="item-entry">
                                <div className="item-main">
                                  <span className="item-name">{item.name}</span>
                                  <span className="item-quantity">x {item.quantity}</span>
                                </div>
                                <div className="item-size">{item.size}</div>
                                {item.modifiers && item.modifiers.length > 0 && (
                                  <div className="item-modifiers">
                                    {item.modifiers.map((modifier, midx) => (
                                      <div key={midx} className="modifier">
                                        • {modifier}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
      <ItemStatusCard itemStatusData={itemStatusData} />
    </div>
  );
};

export default Kitchenceff;
