import React from "react";
import './RestourentBillings.css'

const GroupCategory = ({ category, isActive }) => {

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  }; 

  return (
    <div>
      <div className={`card-content-kot ${category.id === isActive ? 'active' : ''}`}
         style={{
          backgroundImage: category?.category_image_url
            ? `url(${category.category_image_url})`
            : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <span className="cat-font-size">
          {truncateText(category?.name, 12)}
        </span>
      </div>
    </div>
  );
};

export default GroupCategory;
