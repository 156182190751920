import React, { useEffect, useState } from "react";
import "./DeliveryChallanThermalTemplate.css"
import { current_user } from "../../../../Authentication/AuthenticationContext";

function DeliveryChallanThermalTemplate({ onClose, deliveryChallanPrint, removeTablehead, enabledTemplateDetails }) {
  const [isCompanydata, setCompanyData] = useState("");
  const [isBranchData, setBranchData] = useState("");
  const [isUserData, setUserData] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setCompanyData(currentUser.company);
      setBranchData(currentUser.company.branch_info);
      setUserData(currentUser.company.user_info);
    };
    fetchUser();
  }, []);

  const renderDeliveryChallanThermalTable = () => {
    return (
      <div>
        {deliveryChallanPrint?.bill_items && deliveryChallanPrint.bill_items.length > 0 ? (
          <>
            <table className="delivery-challan-thermal-temp-table">
              <thead>
                <tr className='delivery-challan-thermal-temp-tab-head-row'>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Item</th>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Qty</th>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Unit</th>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Rate</th>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Tax</th>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Amount</th>
                </tr>
              </thead>
              <tbody>
                {deliveryChallanPrint?.bill_items && deliveryChallanPrint?.bill_items.map((item, index) => (
                  <tr key={index} className='delivery-challan-thermal-temp-table-body-row'>
                    <td className='delivery-challan-thermal-temp-table-body-data'>{item?.name}</td>
                    <td className='delivery-challan-thermal-temp-table-body-data'>{item?.quantity}</td>
                    <td className='delivery-challan-thermal-temp-table-body-data'>{item?.unit?.unit_name}</td>
                    <td className='delivery-challan-thermal-temp-table-body-data'>{item?.sales_price.toFixed(2)}</td>
                    <td className='delivery-challan-thermal-temp-table-body-data'>
                      <div className='delivery-challan-thermal-temp-tax-details'>
                        {item?.tax_details?.length > 0
                          ? `${item.tax_details.join(", ")} (${item?.tax_inclusive ? "Inc" : "Exc"})`
                          : ""}
                      </div>
                      {item?.item_total_tax.toFixed(2)}
                    </td>
                    <td className='delivery-challan-thermal-temp-table-body-data'>{(item?.quantity * item?.sales_price).toFixed(2)}</td>
                  </tr>
                ))}
                <tr className='delivery-challan-thermal-temp-calculation-row'>
                  <td colSpan={4} className='delivery-challan-thermal-temp-extra-row'>
                    <span className='delivery-challan-thermal-temp-extra-row-data'>Sub Total</span>
                  </td>
                  <td></td>
                  <td>{deliveryChallanPrint?.sub_total?.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
            <table className="delivery-challan-thermal-temp-footer-table">
              <tbody>
                <tr className='delivery-challan-thermal-temp-table-footer-div'>
                  <td colSpan={3} rowSpan={5} className='delivery-challan-thermal-temp-table-footer-bdelivery-challan'>
                    <div className="delivery-challan-thermal-temp-footer-data">
                      <div className="delivery-challan-thermal-temp-footer-data-row">
                        <span className="delivery-challan-thermal-temp-footer-label">Sub Total:</span>
                        <span className="delivery-challan-thermal-temp-footer-value">{deliveryChallanPrint?.sub_total?.toFixed(2)}</span>
                      </div>
                      <div className="delivery-challan-thermal-temp-footer-data-row">
                        <span className="delivery-challan-thermal-temp-footer-label">Discount:</span>
                        <span className="delivery-challan-thermal-temp-footer-value">{deliveryChallanPrint?.total_discount?.toFixed(2) || '0.00'}</span>
                      </div>
                      <div className="delivery-challan-thermal-temp-footer-data-row">
                        <span className="delivery-challan-thermal-temp-footer-label">Parcel Charges:</span>
                        <span className="delivery-challan-thermal-temp-footer-value">{deliveryChallanPrint?.parcel_charges?.toFixed(2) || '0.00'}</span>
                      </div>
                      {deliveryChallanPrint?.is_gst_enable && deliveryChallanPrint?.is_igst_enable ? (
                        <div className="delivery-challan-thermal-temp-footer-data-row">
                          <span className="delivery-challan-thermal-temp-footer-label">IGST:</span>
                          <span className="delivery-challan-thermal-temp-footer-value">{deliveryChallanPrint?.total_gst?.toFixed(2) || '0.00'}</span>
                        </div>
                      ) : (
                        <>
                          <div className="delivery-challan-thermal-temp-footer-data-row">
                            <span className="delivery-challan-thermal-temp-footer-label">SGST:</span>
                            <span className="delivery-challan-thermal-temp-footer-value">{(deliveryChallanPrint?.total_gst / 2).toFixed(2) || '0.00'}</span>
                          </div>
                          <div className="delivery-challan-gen-temp-footer-data-row">
                            <span className="delivery-challan-gen-temp-footer-label">CGST:</span>
                            <span className="delivery-challan-gen-temp-footer-value">{(deliveryChallanPrint?.total_gst / 2).toFixed(2) || '0.00'}</span>
                          </div>
                        </>
                      )}
                      <div className="delivery-challan-thermal-temp-footer-data-row">
                        <span className="delivery-challan-thermal-temp-footer-label">TOTAL:</span>
                        <span className="delivery-challan-thermal-temp-footer-value">{deliveryChallanPrint?.total_amount?.toFixed(2) || '0.00'}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <>
            <table className="delivery-challan-thermal-temp-table">
              <thead>
                <tr className='delivery-challan-thermal-temp-tab-head-row'>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Item</th>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Qty</th>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Unit</th>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Rate</th>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Tax</th>
                  <th className='delivery-challan-thermal-temp-table-header-attribute'>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr className='delivery-challan-thermal-temp-table-body-row'>
                  <td className='delivery-challan-thermal-temp-table-body-data'>item1</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>1</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>piece</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>2000</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>
                    <div className='delivery-challan-thermal-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>2000</td>
                </tr>
                <tr className='delivery-challan-thermal-temp-table-body-row'>
                  <td className='delivery-challan-thermal-temp-table-body-data'>item2</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>1</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>piece</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>2000</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>
                    <div className='delivery-challan-thermal-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>2000</td>
                </tr>
                <tr className='delivery-challan-thermal-temp-table-body-row'>
                  <td className='delivery-challan-thermal-temp-table-body-data'>item3</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>1</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>piece</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>2000</td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>
                    <div className='delivery-challan-thermal-temp-tax-details'>
                      0.0
                    </div>
                    0.0
                  </td>
                  <td className='delivery-challan-thermal-temp-table-body-data'>2000</td>
                </tr>
                <tr className='delivery-challan-thermal-temp-calculation-row'>
                  <td colSpan={4} className='delivery-challan-thermal-temp-extra-row'>
                    <span className='delivery-challan-thermal-temp-extra-row-data'>Sub Total</span>
                  </td>
                  <td></td>
                  <td>6000</td>
                </tr>
              </tbody>
            </table>
            <table className="delivery-challan-thermal-temp-footer-table">
              <tbody>
                <tr className='delivery-challan-thermal-temp-table-footer-div'>
                  <td colSpan={3} rowSpan={5} className='delivery-challan-thermal-temp-table-footer-bdelivery-challan'>
                    <div className="delivery-challan-thermal-temp-footer-data">
                      <div className="delivery-challan-thermal-temp-footer-data-row">
                        <span className="delivery-challan-thermal-temp-footer-label">Sub Total:</span>
                        <span className="delivery-challan-thermal-temp-footer-value">6000</span>
                      </div>
                      <div className="delivery-challan-thermal-temp-footer-data-row">
                        <span className="delivery-challan-thermal-temp-footer-label">Discount:</span>
                        <span className="delivery-challan-thermal-temp-footer-value">0.0</span>
                      </div>
                      <div className="delivery-challan-thermal-temp-footer-data-row">
                        <span className="delivery-challan-thermal-temp-footer-label">Parcel Charges:</span>
                        <span className="delivery-challan-thermal-temp-footer-value">0.0</span>
                      </div>
                      <div className="delivery-challan-thermal-temp-footer-data-row">
                        <span className="delivery-challan-thermal-temp-footer-label">TOTAL:</span>
                        <span className="delivery-challan-thermal-temp-footer-value">6000</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  };

  const getOption = (optionName) => {
    return enabledTemplateDetails?.find((opt) => opt?.option_name === optionName) || {};
  };

  const currentTime = new Date().toLocaleTimeString();

  return (
    <>
      <div className="delivery-challan-thermal-main-container">
        {!removeTablehead && (
          <div className="delivery-challan-thermal-header-div">
            <span className='delivery-challan-thermal-header-left-title'>Delivery Challan Template</span>
            <span className='delivery-challan-thermal-header-right-title' onClick={onClose}>x</span>
          </div>
        )}
        <div className="delivery-challan-thermal-sub-container">
          <div className="delivery-challan-thermal-company-info-grp">
            {getOption("company_logo")?.is_enable && (
              <div>
                <img src={isCompanydata.logo_url} alt="Company Logo" className="delivery-challan-thermal-company-logo" />
              </div>
            )}
            {getOption("company_name")?.is_enable && (
              <div className="delivery-challan-thermal-company-info">
                <span className='delivery-company-name'>{isCompanydata.name}</span>
              </div>
            )}
            {getOption("branch_name")?.is_enable && (
              <div className="delivery-challan-thermal-company-info">
                <span className="delivery-challan-thermal-com-info-title">{isCompanydata.branch_name}</span>
              </div>
            )}
            {getOption("address")?.is_enable && (
              <div className="delivery-challan-thermal-company-info">
                <span className="delivery-challan-thermal-com-info-title">{isBranchData.address}</span>
              </div>
            )}
            {getOption("phone_number")?.is_enable && (
              <div className="delivery-challan-thermal-company-info">
                <span className="delivery-challan-thermal-com-info-title">Phone: {isUserData.phone}</span>
              </div>
            )}
            {getOption("email")?.is_enable && (
              <div className="delivery-challan-thermal-company-info">
                <span className="delivery-challan-thermal-com-info-title">Email: {isUserData.email}</span>
              </div>
            )}
            {getOption("gst_number")?.is_enable && (
              <div className="delivery-challan-thermal-company-info">
                <span className="delivery-challan-thermal-com-info-title">GSTIN : {isCompanydata.gst_number}</span>
              </div>
            )}
          </div>
          <div className="delivery-challan-thermal-dotted-line"></div>
          {getOption("text_title")?.is_enable && (
            <div className="delivery-challan-thermal-header-title-grp">
              <span className="delivery-challanr-thermal-header-title">
                {getOption("text_title")?.free_text ? getOption("text_title")?.free_text : "Delivery Challan"}
              </span>
            </div>
          )}
          <div className="delivery-challan-thermal-cust-info-grp">
            {getOption("invoice_number")?.is_enable && (
              <span className="delivery-challan-thermal-cust-info-titles">Bill No: {deliveryChallanPrint?.bill_number}</span>
            )}
            {getOption("date")?.is_enable && (
              <span className="delivery-challan-thermal-cust-info-titles">Date:
                {deliveryChallanPrint?.date
                  ? new Date(deliveryChallanPrint?.date).toLocaleDateString('en-GB')
                  : ''}
              </span>
            )}
            {getOption("time")?.is_enable && (
              <span className="delivery-challan-thermal-cust-info-titles">Time: {currentTime}</span>
            )}
            {getOption("cust_company")?.is_enable && (
              <span className="delivery-challan-thermal-cust-info-titles">
                Customer Comapany: {deliveryChallanPrint?.customer?.company_name}</span>
            )}
            {getOption("cust_name")?.is_enable && (
              <span className="delivery-challan-thermal-cust-info-titles">
                Customer Name: {deliveryChallanPrint?.customer?.customer_name} </span>
            )}
            {getOption("cust_address")?.is_enable && (
              <span className="delivery-challan-thermal-cust-info-titles">
                {deliveryChallanPrint?.customer?.billing_address_one
                  ||
                  deliveryChallanPrint?.customer?.billing_address_two}
              </span>
            )}
            {getOption("cust_gst")?.is_enable && (
              <span className="delivery-challan-thermal-cust-info-titles">
                GSTIN: {deliveryChallanPrint?.customer?.gst_number} </span>
            )}
            {getOption("cust_phone")?.is_enable && (
              <span className="delivery-challan-thermal-cust-info-titles">
                Phone: {deliveryChallanPrint?.customer?.mobile_number}
              </span>
            )}
            {getOption("cust_email")?.is_enable && (
              <span className="delivery-challan-thermal-cust-info-titles">
                Email: {deliveryChallanPrint?.customer?.email}</span>
            )}
          </div>
          <div className="delivery-challan-thermal-dotted-line"></div>
          <div className="delivery-challan-thermal-cust-order-container">
            {getOption("cust_order_no")?.is_enable && (
              <div className="delivery-challan-thermal-cust-order-no-grp">
                <span className="delivery-challan-thermal-cust-order-title">Buyer's Order No#:</span>
                <span className="delivery-challan-thermal-order-value">{deliveryChallanPrint?.order_no}</span>
              </div>
            )}
            {getOption("cust_order_date")?.is_enable && (
              <div className="delivery-challan-thermal-cust-order-date-grp">
                <span className="delivery-challan-thermal-cust-order-title">Buyer's Order Date:</span>
                <span className="delivery-challan-thermal-order-value">
                  {deliveryChallanPrint?.order_date
                    ? new Date(deliveryChallanPrint?.order_date).toLocaleDateString('en-GB')
                    : ''}
                </span>
              </div>
            )}
          </div>
          <div className="delivery-challan-thermal-temp-table-group">
            {renderDeliveryChallanThermalTable()}
          </div>
          <div className="delivery-challan-thermal-temp-signature-section">
            <span className='delivery-challan-thermal-temp-first-title'>For {isCompanydata?.name}</span>
            {getOption("cust_signature")?.is_enable && (
              <span className='delivery-challan-thermal-temp-first-title'>Customer Signature</span>
            )}
            {getOption("authorized_signature")?.is_enable && (
              <span className='delivery-challan-thermal-temp-first-title'>Authorized Signature</span>
            )}
            {getOption("terms_conditions")?.is_enable && (
              <div>
                <span className='delivery-challan-thermal-temp-first-title'>Terms&Conditions</span>
                <span className='delivery-challan-thermal-temp-first-title'>
                  Goods once sold will not be taken back
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DeliveryChallanThermalTemplate;
