import React, { useEffect, useRef, useState } from 'react';
import './DeliveryChallanInvoice.css'
import { useReactToPrint } from 'react-to-print';
import { current_user } from '../../../Authentication/AuthenticationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const DeliveryChallanInvoice = ({ isSaleInvoicePrint, onClose }) => {
  const [isCompanydata, setCompanyData] = useState("");
  const [isDomain, isSetDomain] = useState("");
  const [isBranchData, setBranchData] = useState("");
  const [isUserData, setUserData] = useState("");
  const saleInvoice = isSaleInvoicePrint

  const componentRef = useRef();

  const handleDeliveryInvoicePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Sale Invoice',
  });

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setCompanyData(currentUser.company);
      isSetDomain(currentUser.company.subdomain)
      setBranchData(currentUser.company.branch_info);
      setUserData(currentUser.company.user_info);
    };
    fetchUser();
  }, []);

  // const renderHeader = () => (
  //   <div className="delivery-invoice-header">
  //     <img src={isCompanydata.logo_url} alt="Company Logo" className="delivery-company-logo" />
  //     <div className="delivery-company-info">
  //       <span className='delivery-company-name'>{isCompanydata.name}</span>
  //       <span>Address: {isBranchData.address}</span>
  //       <span>Phone: {isUserData.phone}</span>
  //       <span>Email: {isUserData.email}</span>
  //       <span>GSTIN : {isCompanydata.gst_number}</span>
  //     </div>
  //     <div className="delivery-invoice-info">
  //       <span className='delivery-invoice-challan-name'>Tax Invoice</span>
  //       <span>Invoice No: {saleInvoice.bill_number}</span>
  //       <span>Date: {saleIn{isCompanydata.gst_number}voice.date}</span>
  //       <span>Reference: {saleInvoice.reference_no}</span>
  //     </div>
  //   </div>
  // );

  const renderCompanyHeader = () => {
    const normalizedDomain = isDomain?.toString().toLowerCase().trim();
    if (normalizedDomain === "tanish-enterprises") {
      return (
        <div className='delivery-invoice-extra-pad-com-info-container'>
          <div className='delivery-invoice-extra-img-grp'>
            <img
              src={require("../../../Assets/Images/tanis.jpg")}
              alt="Delivery invoice Logo"
              className="delivery-invoice-extra-company-logo" />
          </div>
          <div className='delivery-invoice-extra-com-info-grp'>
            <div className='delivery-invoice-extra-com-grp'>
              <span className='delivery-invoice-extra-com-name-label'>{isCompanydata?.name}</span>
            </div>
            <div className='delivery-invoice-extra-branch-grp'>
              <span className='delivery-invoice-extra-branch-label'>{isBranchData?.address}</span>
            </div>
            <div className='delivery-invoice-extra-header-com-data-grp'>
              <span className='delivery-invoice-extra-com-data-label'>Phone No. : {isUserData?.phone}</span>
              <span className='delivery-invoice-extra-com-data-label'>Email : {isUserData?.email}</span>
              <span className='delivery-invoice-extra-com-data-label'>GSTIN/UIN : {isCompanydata?.gst_number}</span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderTable = () => (
    <>
      <table className="delivery-invoice-table">
        <thead>
          <tr className='delivery-tab-head-row'>
            <th className='delivery-table-header-attribute'>SNo</th>
            <th className='delivery-table-header-attribute'>Description</th>
            <th className='delivery-table-header-attribute'>HSN</th>
            <th className='delivery-table-header-attribute'>Qty</th>
            <th className='delivery-table-header-attribute'>Rate</th>
            <th className='delivery-table-header-attribute'>Tax</th>
            <th className='delivery-table-header-attribute'>Amount</th>
          </tr>
        </thead>
        <tbody>
          {saleInvoice.bill_items && saleInvoice.bill_items.map((item, index) => (
            <tr key={index} className='delivery-table-body-row'>
              <td>{index + 1}</td>
              <td className='delivery-table-body-data'>{item?.name}</td>
              <td className='delivery-table-body-data'>
                {item.hsn_number}
              </td>
              <td className='delivery-table-body-data'>{item?.quantity}</td>
              <td className='delivery-table-body-data'>{item?.sales_price.toFixed(2)}</td>
              <td className='delivery-table-body-data'>
                <div className='tax-details-invoice'>
                  {/* {item?.tax_details?.length > 0 ? item?.tax_details.join(", ") : ""} ({item.tax_inclusive ? "Inc" : "Exc"}) */}
                  {item?.tax_details?.length > 0
                    ? `${item.tax_details.join(", ")} (${item.tax_inclusive ? "Inc" : "Exc"})`
                    : ""}
                </div>
                {item?.item_total_tax.toFixed(2)}
              </td>
              <td className='delivery-table-body-data'>{(item?.quantity * item?.sales_price).toFixed(2)}</td>
            </tr>
          ))}
          <tr className='delivery-calculation-row'>
            <td colSpan={5} className='delivery-extra-row'>
              <span className='delivery-extra-row-data'>Sub Total</span>
            </td>
            <td></td>
            <td>{saleInvoice.sub_total.toFixed(2)}</td>
          </tr>
        </tbody>
        {/* <tfoot>
        <tr className='delivery-invoice-table-footer-div'>
          <td colSpan={4} rowSpan={5}></td>
          <td colSpan={3} rowSpan={5}>
            <div className="delivery-invoice-footer-data">
              <div className="delivery-invoice-footer-data-row">
                <span className="delivery-invoice-footer-label">Sub Total:</span>
                <span className="delivery-invoice-footer-value">{saleInvoice?.sub_total.toFixed(2)}</span>
              </div>
              <div className="delivery-invoice-footer-data-row">
                <span className="delivery-invoice-footer-label">Discount:</span>
                <span className="delivery-invoice-footer-value">{saleInvoice?.total_discount || '0.00'}</span>
              </div>
              {saleInvoice.is_gst_enable && saleInvoice.is_igst_enable ? (
                <div className="delivery-invoice-footer-data-row">
                  <span className="delivery-invoice-footer-label">IGST:</span>
                  <span className="delivery-invoice-footer-value">{saleInvoice?.total_gst.toFixed(2) || '0.00'}</span>
                </div>
              ) : (
                <>
                  <div className="delivery-invoice-footer-data-row">
                    <span className="delivery-invoice-footer-label">SGST:</span>
                    <span className="delivery-invoice-footer-value">{(saleInvoice?.total_gst / 2).toFixed(2) || '0.00'}</span>
                  </div>
                  <div className="delivery-invoice-footer-data-row">
                    <span className="delivery-invoice-footer-label">CGST:</span>
                    <span className="delivery-invoice-footer-value">{(saleInvoice?.total_gst / 2).toFixed(2) || '0.00'}</span>
                  </div>
                </>
              )}
              <div className="delivery-invoice-footer-data-row">
                <span className="delivery-invoice-footer-label">TOTAL:</span>
                <span className="delivery-invoice-footer-value">{saleInvoice?.total_amount.toFixed(2) || '0.00'}</span>
              </div>
            </div>
          </td>
        </tr>
      </tfoot> */}
      </table>
      <table className="delivery-invoice-footer-table">
        <tbody>
          <tr className='delivery-invoice-table-footer-div'>
            {/* <td colSpan={4} rowSpan={5}></td> */}
            <td colSpan={3} rowSpan={5} className='delivery-invoice-table-footer-border'>
              <div className="delivery-invoice-footer-data">
                <div className="delivery-invoice-footer-data-row">
                  <span className="delivery-invoice-footer-label">Sub Total:</span>
                  <span className="delivery-invoice-footer-value">{saleInvoice?.sub_total.toFixed(2)}</span>
                </div>
                <div className="delivery-invoice-footer-data-row">
                  <span className="delivery-invoice-footer-label">Discount:</span>
                  <span className="delivery-invoice-footer-value">{saleInvoice?.total_discount || '0.00'}</span>
                </div>
                {saleInvoice.is_gst_enable && saleInvoice.is_igst_enable ? (
                  <div className="delivery-invoice-footer-data-row">
                    <span className="delivery-invoice-footer-label">IGST:</span>
                    <span className="delivery-invoice-footer-value">{saleInvoice?.total_gst.toFixed(2) || '0.00'}</span>
                  </div>
                ) : (
                  <>
                    <div className="delivery-invoice-footer-data-row">
                      <span className="delivery-invoice-footer-label">SGST:</span>
                      <span className="delivery-invoice-footer-value">{(saleInvoice?.total_gst / 2).toFixed(2) || '0.00'}</span>
                    </div>
                    <div className="delivery-invoice-footer-data-row">
                      <span className="delivery-invoice-footer-label">CGST:</span>
                      <span className="delivery-invoice-footer-value">{(saleInvoice?.total_gst / 2).toFixed(2) || '0.00'}</span>
                    </div>
                  </>
                )}
                <div className="delivery-invoice-footer-data-row">
                  <span className="delivery-invoice-footer-label">TOTAL:</span>
                  <span className="delivery-invoice-footer-value">{saleInvoice?.total_amount.toFixed(2) || '0.00'}</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  return (
    <div className="delivery-invoice-container" ref={componentRef}>
      <div className='delivery-invoice-button-group'>
        <button className='delivery-invoice-button-icon'><FontAwesomeIcon icon={faPrint} onClick={handleDeliveryInvoicePrint} /></button>
        <button className="delivery-invoice-button-icon" onClick={onClose}>X</button>
      </div>
      {renderCompanyHeader()}
      {/* {renderHeader()} */}
      {/* <div className='delivery-invoice-horizontal-line'></div>
      <div className='delivery-invoice-billing-address'>
        <div className='delivery-invoice-bill-div'>
          <span>Issued to :</span>
          <span>{saleInvoice?.customer?.customer_name}</span>
          <span>POS : Indore</span>
        </div>
        <div className='delivery-invoice-bill-div'>
          <span>Billing & Shipping Address :</span>
          <span>Demo Address</span>
          <span>Phone: {saleInvoice?.customer?.mobile_number}</span>
          <span>Email: {saleInvoice?.customer?.email}</span>
        </div>
      </div> */}
      <div className='delivery-challan-invoice-bill-first-row'>
        <span className='delivery-challan-invoice-label-invoice'>Invoice No. : {saleInvoice?.bill_number || ''}</span>
        <span className='delivery-challan-invoice-label-invoice'>Date: {saleInvoice?.date
          ? new Date(saleInvoice.date).toLocaleDateString('en-GB')
          : ''}
        </span>
      </div>
      <div className='delivery-challan-invoice-bill-second-row'>
        <span className='delivery-challan-invoice-label-invoice'>Ref. No. : {saleInvoice?.reference_no || ''}</span>
      </div>
      <div className='delivery-challan-invoice-bill-user-row'>
        <span className='delivery-challan-invoice-label'>GSTIN/UIN : {isCompanydata?.gst_number || ''}</span>
      </div>
      <div className='delivery-challan-invoice-bill-party-row'>
        <span className='delivery-challan-invoice-label'>Party : {saleInvoice?.customer?.company_name ||
          (saleInvoice?.customer?.first_name && saleInvoice?.customer?.last_name
            ? `${saleInvoice?.customer?.first_name} ${saleInvoice?.customer?.last_name}`
            : '')
        }
        </span>
      </div>
      <div className='delivery-challan-invoice-bill-third-row'>
        <span className='delivery-challan-invoice-label'>GSTIN/UIN : {saleInvoice?.customer?.gst_number}</span>
      </div>
      <div className='delivery-challan-invoice-bill-third-row'>
        <span className='delivery-challan-invoice-label'>State Name : {saleInvoice?.customer?.billing_state}</span>
      </div>
      <div className='delivery-invoice-horizontal-line'></div>
      {renderTable()}
      <div className="delivery-invoice-signature-section">
        <span className='delivery-invoice-first-title'>For {isCompanydata.name}</span>
        <span className='delivery-invoice-first-title'>Authorized Signature</span>
      </div>
    </div>
  );
};

export default DeliveryChallanInvoice;
