import React, { useState, useEffect, useRef, useContext } from "react";
import "./BillingDropdown.css";
import { BASE_URL } from "../../Url/constants";
import ErrorNotification from "../../Notification/ErrorNotification";
import { useTranslation } from "react-i18next";
import { sendRequest } from "../../ApiRequest";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import selectedItemsContext from '../../selectedItemsContext';

const SearchableDropdown = ({ label, id, handleChange, autoFocus }) => {

  const { itemCodes, barCodes, setSelectedItems } = useContext(selectedItemsContext);

  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [SearchedValue, setSearchedValue] = useState("");
  const inputRef = useRef(null);
  const debounceRef = useRef(null); // Ref to store the debounce timer
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [autoFocus]);

  useEffect(() => {
    if (query.length >= 2) {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }

      debounceRef.current = setTimeout(() => {
        searchItems(query);
        setSearchedValue(query);
      }, 300);
    } else {
      setOptions([]);
      setIsOpen(false);
    }

    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [query]);

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const searchItems = async (query) => {
    const foundByItemCode = itemCodes[query];
    const foundByBarCode = barCodes[query];

    if (foundByItemCode || foundByBarCode) {
      const item = foundByItemCode || foundByBarCode;
      item.quantity += 1;
      handleChange(item);
      setQuery("");
      return;
    }

    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/items?query=${query}&is_normal_billing=true`
      );
      const items = response.data.data;
      setOptions(items);
      setIsOpen(true);

      if (items.length === 1) {
        selectOption(items[0]);
      }
    } catch (error) {
      ErrorNotification(error.message);
    }
  };

  const selectOption = (option) => {
    if (option.quantity < 1) {
      ErrorNotification(
        `${option.item_name} - ${option.item_code} is out of stock.`
      );
    } else {
      const itemCode = option.item_code;
      const barCode = option.bar_code;

      if (itemCodes[itemCode] || barCodes[barCode]) {
        const existingItem =
          itemCodes[itemCode] || barCodes[barCode];
        existingItem.quantity += 1;
        handleChange(existingItem);
      } else {
        itemCodes[itemCode] = { ...option, quantity: 1 };
        barCodes[barCode] = itemCodes[itemCode];

        handleChange(option);
      }
      setQuery("");
      setIsOpen(false);
    }
  };

  return (
    <div className="searchable-dropdown">
      <BarcodeScannerComponent
        width={0}
        height={0}
        onUpdate={(err, result) => {
          if (result) {
            setQuery(result.text);
            searchItems(result.text);
          }
        }}
      />
      <div className="input-container">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={t("Search by Items Name, Items Code, or Bar Code")}
          className="form-control"
          ref={inputRef}
        />
        <div className="info-icon-information">
          <FontAwesomeIcon icon={faInfoCircle} 
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}/>
        </div>
        {showTooltip && (
        <div className="tooltip-content-information">

            {t("Search by Item Name, Item Code, Unit, Category, and Bar Code")}

          </div>
        )}
      </div>
      {isOpen && options.length > 1 && (
        <div
          className="dropdown-container"
          style={{ maxHeight: "100px", overflowY: "auto" }}
        >
          {options.map((option) => (
            <div
              key={option.id}
              className="dropdown-option"
              onClick={() => selectOption(option)}
            >
              {option.item_name} - {option.item_code} ({option.bar_code})
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;