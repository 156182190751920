import React, { useEffect, useState } from "react";
import "./CommonToggleButton.css"

function CommonToggleButton({ onToggleChange, toggleLabel = "Item Wise Tax", isBilling = false, callback }) {
  const [isChecked, setIsChecked] = useState(!isBilling);

  useEffect(() => {
    setIsChecked(!isBilling);
  }, [isBilling]);

  const handleToggleChange = (...args) => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    if (onToggleChange){
      onToggleChange(newValue);
    }
    if (callback) {
      callback(newValue, ...args);
    }
  };

  return (
    <>
      <div className="common-tax-wise-toggle-wrapper">
        <label className="common-tax-wise-toggle-container">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleToggleChange}
          />
          <span className="common-tax-wise-toggle-slider"></span>
        </label>
        <span className="common-tax-wise-toggle-label">
          {toggleLabel || "Item Wise Tax"}
        </span>
      </div>
    </>
  );
}

export default CommonToggleButton;

