import Modal from 'react-modal';
import '../../components/Restaurant/PaymentModel.css';
import { sendRequest } from '../../ApiRequest';
import { BASE_URL, paymentModes } from '../../Url/constants';
import successNotification from '../../Notification/SuccessNotification';
import ErrorNotification from '../../Notification/ErrorNotification';
import { useTranslation } from "react-i18next";


Modal.setAppElement('#root');

const PaymentModal = ({ isOpen, onRequestClose, tableNumber, tableData, updateTableStatus, UpdateInvoiceOrder}) => {
  const { t} = useTranslation();

  const handleSelectedPaymentSettlement = async (mode) => {
    try {
      let url = `${BASE_URL}/v1/restaurant/orders/orders/settlement?payment_mode=${mode}`
      if (tableData && tableNumber) {
        url += `&floor_table_id=${tableNumber}&floor_id=${tableData?.floor_id}`
      } else if (UpdateInvoiceOrder) {
        url += `&order_id=${UpdateInvoiceOrder?.id}`
      }
      const response = await sendRequest('PUT', url)

      if (response.status === 200) {
        successNotification("Settlement done successfully");
        if (tableNumber) {
          updateTableStatus(tableNumber, 'available', tableData?.floor_id);
        }
        onRequestClose();
      } else {
        ErrorNotification("No invoice for settlement");
      }
    }
    catch (error) {
      ErrorNotification("No invoice for settlement");
    }
  }

 

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Payment Options"
      className="payment-modal"
      overlayClassName="payment-modal-overlay"
    >
      <h5 className='payment-heading'>{t("Payment Mode")}</h5>
      <div className="payment-options">
        <button className="payment-option cash" onClick={() => handleSelectedPaymentSettlement(paymentModes['cash'])}>{t("Cash")}</button>
        <button className="payment-option card" onClick={() => handleSelectedPaymentSettlement(paymentModes['card'])}>{t("Card")}</button>
        <button className="payment-option paytm" onClick={() => handleSelectedPaymentSettlement(paymentModes['online'])}>{t("PayTM")}</button>
        <button className="payment-option upi" onClick={() => handleSelectedPaymentSettlement(paymentModes['online'])}>{t("UPI")}</button>
        <button className="payment-option multi" onClick={() => handleSelectedPaymentSettlement(paymentModes['multi_payment'])}>{t("Multi Payment")}</button>
        <button className="payment-option cancel" onClick={onRequestClose}>{t("Cancel")}</button>
      </div>
    </Modal>
  );
};

export default PaymentModal;
